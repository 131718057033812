import React from 'react'
import App from './App'
import withClearCache from './ClearCache';

const ClearCacheComponent = withClearCache(App);

export default function MainApp() {
  return (
    <ClearCacheComponent />
  )
}