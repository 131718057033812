const initialState = {
    missingDetail: false
};

const missingDetailReducer = (state = initialState ,action) => {

    switch(action.type) {
        case 'SET_MISSING_DETAIL' : 
        state.missingDetail = action.payload
        return {
            missingDetail: state.missingDetail
        }
        default :
        return state;
    }

}

export default missingDetailReducer;