import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom';
import PhysicalResourceDetail from './PhysicalResourceDetail';
import DigitalResourceDetail from './DigitalResourceDetail';
import { getResourceDetails } from '../../services/StudentHubService';
import SkeletonTicketList from '../../loaders/SkeletonTicketList';

export default function ResourceDetail(props) {
  const { tab, page, id, type } = useParams();
  const history = useHistory();
  const { state } = useLocation();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const getData = () => {
    setLoading(true);
    const param = { resource_id: id }
    getResourceDetails(param).then((res) => {
      if (res.status === 200) {
        setData(res.data);
        setLoading(false);
      }
    }).catch((err) => { console.log("err", err) })
  }


  useEffect(() => {
    getData();
  }, [])

  return (
    <>
      {/* <div className="d-flex justify-content-between mb-4 advance-top">
        <div className='detail-back-con'>
          <h5>{data?.resourceDetails?.title ? data?.resourceDetails?.title : ""}</h5>
          <div className='separator-line'/>
          <button type="button" onClick={() => history.push(`/student-hub/${tab}/table`)} className="btn btn-primary" title="Back"><i className="fal fa-chevron-left"></i> Back</button>
        </div>
        <div className="custom-breadcrumb">
          <ul>
            <li className="custom-breadcrumb-item active">
              <a href="javascript:void(0);"><i className="fal fa-books"></i></a>
            </li>
            <li className="custom-breadcrumb-item active">
              <a href="javascript:void(0);">{data?.resourceDetails?.title ? data?.resourceDetails?.title : "-"}</a>
            </li>
          </ul>
        </div>
      </div> */}
      {loading ? <SkeletonTicketList />
        // : type === "physical" ? <PhysicalResourceDetail ResourceData={data} />
          : 
          <DigitalResourceDetail ResourceData={data} refreshData={() => { getData() }} dropdownList={props.dropdownList}/>}
    </>
  )
}
