import React, { useState, useEffect } from "react";
import { getExamSubjectList ,getSubmitedExamRegistration} from "../../services/ExamService";

export default function ExamSubjectAppendReadOnly(props) {
  const [subjectList, setSubjectList] = useState([]);
  const [listData, setListData] = useState([]);
  const { formik } = props;
  const isWithHeldStatus = localStorage.getItem("resultWithHeld");

  useEffect(() => {
    getExamSubjectList(props.examId).then((res) => {
      setSubjectList(res.data);
    });
  }, [props.examId]);


  useState(() => {
    getSubmitedExamRegistration(props.examId).then((res) => {
      setListData(res.data.examSubjectList)
    });
  }, [props.examId]);


  return (
    <>
      <div className="table-responsive instructions-table">
        <div className="d-flex add-row-head w-100">
          <div className="form-group col-5 cust_tbl_col_1 m-0">
            <label>
              <strong>Subject:</strong>
            </label>
          </div>
          <div className="form-group col-2 cust_tbl_col_2 m-0">
            <label>
              <strong>Is this your first attempt?</strong>
            </label>
          </div>

          <div className="form-group col-2 cust_tbl_col_3  m-0">
            <label><strong>Term Mark</strong></label>
          </div>

          <div className="form-group col-1 cust_tbl_col_4 m-0">
            <label><strong>Written</strong></label>
          </div>

          <div className="form-group col-2 cust_tbl_col_5 m-0">
            <label><strong>DHET Result</strong></label>
          </div>
        </div>

        <div className="d-flex add-row-repeat w-100">
          <div className="form-group">
            <label className="color-red">
              <strong>
                Important: Term mark for selected Intake will be used
              </strong>
            </label>
          </div>
        </div>
        {/* End  add-row-repeat */}


        {formik.values.subject_.map((subject, index) => (
          <div className="d-flex add-row-repeat w-100 parent_div" key={index}>
            <div className="form-group col-5 cust_tbl_col_1  m-0">

              {listData && listData.length ? (
                <select
                  className={
                    "form-control " +
                    (formik.touched.subject_ && formik.errors.subject_
                      ? " is-invalid"
                      : "")
                  }
                  name={`subject_[${index}]`}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  key={index}
                  value={formik.values.subject_[index]}
                  disabled="disabled"
                >
                  <option value="">---</option>
                  {listData.map((element, index2) => {
                    return (
                      <option
                        key={index2}
                        value={element.intake_id}
                      >{`${element.subject_name}-
                      Intake ${element.intake_number}, ${element.intake_year}
                      `}</option>
                    );
                  })}
                </select>
                
              ) : null}
            </div>
            <div className="form-group col-2 cust_tbl_col_2  m-0">
               {listData && listData.length ? (
                <select
                  className={
                    "form-control " +
                    (formik.touched.subject_ && formik.errors.subject_
                      ? " is-invalid"
                      : "")
                  }
                  name={`subject_[${index}]`}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  key={index}
                  value={listData.find(elem => elem.intake_id ===formik.values.subject_[index] && elem.first) ? "1" : "0"}
                  disabled="disabled"
                >
                  {listData.map((element, index2) => {
                    return (
                     <>

                     {element.first ==1 && 
                      <option key={`${index}_1`} value="1">
                       Yes
                    </option>
                     }
                  
                     {element.first ==0 && 
                      <option key={`${index}_2`} value="0">
                       No
                    </option>
                     }
                     </>
                    );
                  })}
                </select>
                
              ) : null}
            </div>
            {isWithHeldStatus!= "true" ? 
            <div className="form-group col-2 cust_tbl_col_3 ui-select  m-0">
            {listData && listData.length ? (                
                <select
                  className={
                    "form-control " +
                    (formik.touched.subject_ && formik.errors.subject_
                      ? " is-invalid"
                      : "")
                  }
                  name={`subject_[${index}]`}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  key={index}
                  value={formik.values.subject_[index]}
                  disabled="disabled"
                >
                  <option value="">---</option>
                  {listData.map((element, index2) => {
                    return (
                      <option
                        key={index2}
                        value={element.intake_id}
                      >{`${element.mark}`}</option>
                    );
                  })}
                </select>
             
                
              ) : null}
              
            </div>
            :<div className="col-2 cust_tbl_col_3 m-0 witheld-status">Results Withheld</div>}            
            <div className="form-group col-1 cust_tbl_col_4  m-0">
            {listData && listData.length ? (                
                <select
                  className={
                    "form-control " +
                    (formik.touched.subject_ && formik.errors.subject_
                      ? " is-invalid"
                      : "")
                  }
                  name={`subject_[${index}]`}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  key={index}
                  value={listData.find(elem => elem.intake_id ===formik.values.subject_[index] && elem.written) ? "1" : "0"}
                  disabled="disabled"
                >
                  {listData.map((element, index2) => {
                    return (
                      <>
                        {element.written ==1 && 
                        <option key={`${index}_1`} value="1">
                          Yes
                        </option>
                        }

                        {element.written == 0 && 
                        <option key={`${index}_2`} value="0">
                          No
                        </option>
                        }
                        </>
                    );
                  })}
                </select>
             
                
              ) : null}
              
            </div>
            {isWithHeldStatus!= "true" ? 
            <div className="form-group col-2 cust_tbl_col_5 m-0">
            {listData && listData.length ? (
                <select
                  className={
                    "form-control remove" +
                    (formik.touched.subject_ && formik.errors.subject_
                      ? " is-invalid"
                      : "")
                  }
                  name={`subject_[${index}]`}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  key={index}
                  value={formik.values.subject_[index]}
                  disabled="disabled"
                >
                  <option value="">---</option>
                  {listData.map((element, index2) => {
                    return (
                      <option
                        key={index2}
                        value={element.intake_id}
                      >{`${element.dhetresult==''?'---':element.dhetresult}`}</option>
                    );
                  })}
                </select>
              ) : null}
              
            </div>
            :<div className="col-2 cust_tbl_col_5 m-0 witheld-status">Results Withheld</div>}
            
          </div>
        ))}

        {/* End  add-row-repeat */}
      </div>

      {/* <div className="form-group col-12">
        <a
          href
          className="btn btn-link p-0 btn-additional"
          onClick={() => handleSubjectAppend()}
        >
          <i className="fal fa-plus-circle"></i> Add additional line item
        </a>
      </div> */}
    </>
  );
}
