import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Open from "../../assets/images/Open.png";
import InProgress from "../../assets/images/In-Progress.png";
import Closed from "../../assets/images/closed.png";

export default function ExamSummeryList(props) {
  const [exam, setExam] = useState([]);

  useEffect(() => {
    setExam(props.exam);
  }, []);

  return (
    <>
      {Object.keys(exam).length ? (
        <li title={exam.period} key={exam.exam_id}>
          <div className="card exam-bx-card">
            <Link
              to={`/myexamdetails/${exam.exam_id}`}
              className="exam-link-redirect"
            ></Link>
            <div className="icon">EP</div>
            <h3>
              Exam Period
              <br /> {exam.period}
            </h3>
            <p>
              <i className="">
                <img src={exam.registrationStatus == "OPEN" ? Open : Closed} />
              </i>

              {exam.registrationStatus}
            </p>
            <p
              className={
                exam.registrationStudentStatus == "Not Registered"
                  ? "colorRed"
                  : "colorGreen"
              }
            >
              <i className={exam.registrationStudentIcon}></i>
              {exam.registrationStudentStatus}
            </p>
            <p>
              <i className="fal fa-calendar-alt"></i>
              {`${exam.startPriodTime} to ${exam.endPriodTime}`}
            </p>
            <p>
              <i className="">
                <img
                  src={
                    exam.exam_status_text == "IN PROGRESS" ? InProgress : Closed
                  }
                />
              </i>
              {exam.exam_status_text == "IN PROGRESS"
                ? "EXAM IN PROGRESS"
                : "EXAM CLOSED"}
            </p>
          </div>
        </li>
      ) : null}
    </>
  );
}
