import React, { useState, useEffect } from "react";
import $ from "jquery";
import InProgress from "../../assets/images/In-Progress.png";
import Open from "../../assets/images/Open.png";
import Close from "../../assets/images/closed.png";
import { Link } from "react-router-dom";
import SkeletonExamLeftBox from "../../loaders/SkeletonExamLeftBox";

export default function ExamLeftBox(props) {
  //const [examListsRow,setExamListRow] = useState([]);
  const [examid, setExamId] = useState(props.examid);
  const [loading, setLoading] = useState(true);
  const [activeButton, setActiveButton] = useState(true);

  useState(() => {
    $("body").removeClass("hide-subject-sidebar");
  }, []);

  // useEffect(()=>{
  //     getMyExam(examid).then((res) => {
  //         setExamListRow(res.data.examList[0]);
  //     }).catch((error) => {
  //         console.log(error);
  //     })
  // },[examid])

  const { examListsRow } = props;

  setTimeout(() => {
    setLoading(false);
  }, 1000);

  const handleChildExamStatus = (status) => {
    if (status == "") {
      setActiveButton(true);
    } else {
      setActiveButton(false);
    }
    props.handleParentExamStatus(status);
  };

  const handleSideBar = () => {
    $("body").toggleClass("hide-subject-sidebar");
  };

  return (
    <>
      {loading && <SkeletonExamLeftBox />}
      {!loading && (
        <div className="exam-detail-lft bg-white">
          <button className="close-btn-sidebar" onClick={() => handleSideBar()}>
            <i className="close-bar close-bar-1"></i>
            <i className="close-bar close-bar-2"></i>
            <i className="close-bar close-bar-3"></i>
          </button>
          <div className="exam-lft-head">
            <div className="all-exam">
              <Link to="/myexam">
                <i className="fal fa-angle-left"></i>All Exam
              </Link>
            </div>

            <div className="exam-bx-info-top d-flex">
              <div className="icon">EP</div>
              <div className="exam-bx-info-right">
                <h3>
                  Exam Period <br />
                  {examListsRow?.period}
                </h3>
                <ul className="fa-ul">
                  <li>
                    <i className="fa-li fal fa-calendar-alt"></i>
                    {`${examListsRow?.startPriodTime} to ${examListsRow?.endPriodTime}`}
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="exam-lft-body">
            <div className="exam-list-opt">
              <div className="exam-list-opt-card card">
                <h3>Your Status</h3>

                <ul className="fa-ul list-unstyled">
                  <li>
                    <i
                      className={`fa-li ${examListsRow?.registrationStudentIcon}`}
                    ></i>
                    {examListsRow?.registrationStudentStatus}
                  </li>
                </ul>
                {examListsRow?.hideRegistrationButton ? (
                  examListsRow?.registrationStudentStatus == "Not Registered" ? (
                    examListsRow?.readOnly ? (
                      <a
                        title="Register Now"
                        className={`btn btn-white-bordered btn-disabled ${
                          !activeButton ? "btn-active" : ""
                        }`}
                      >
                        <i className="fal fa-file-check"></i>Register Now
                      </a>
                    ) : (
                      <a
                      title="Register Now"
                        className={`btn btn-white-bordered ${
                          !activeButton ? "btn-active" : ""
                        }`}
                        onClick={() =>
                          handleChildExamStatus("new_registration")
                        }
                      >
                        <i className="fal fa-file-check"></i>Register Now
                      </a>
                    )
                  ) : (
                    <a
                      title="View"
                      className={`btn btn-white-bordered ${
                        !activeButton ? "btn-active" : ""
                      }`}
                      onClick={() => handleChildExamStatus("viewed")}
                    >
                      <i className="fal fa-file-check"></i>View
                    </a>
                  )
                ) : null}
              </div>

              <div className="exam-list-opt-card card">
                <h3>Registration Status</h3>
                {examListsRow?.registrationStatus == "OPEN" ? (
                  <ul className="fa-ul list-unstyled">
                    <li>
                      <i className="fa-li">
                        <img src={Open} />
                      </i>
                      Open
                    </li>
                  </ul>
                ) : (
                  <ul className="fa-ul list-unstyled">
                    <li>
                      <i className="fa-li">
                        <img src={Close} />
                      </i>
                      CLOSED
                    </li>
                  </ul>
                )}

                <a
                  title="Exam info"
                  className={`btn btn-white-bordered ${
                    activeButton ? "btn-active" : ""
                  }`}
                  onClick={() => handleChildExamStatus("")}
                >
                  <i className="fal fa-info-circle"></i>Exam info
                </a>
              </div>

              <div className="exam-list-opt-card card">
                <h3>Registration Close Date</h3>
                <ul className="fa-ul list-unstyled">
                  <li>
                    <i className="fa-li fal fa-calendar-alt"></i>
                    {examListsRow?.registrationCloseDate}
                  </li>
                </ul>
              </div>

              <div className="exam-list-opt-card card">
                <h3>Exam Status</h3>
                <ul className="fa-ul list-unstyled">
                  <li>
                    {examListsRow?.exam_status_text == "IN PROGRESS" ? (
                      <React.Fragment>
                        <i className="fa-li">
                          <img src={InProgress} />
                        </i>
                        In-Progress
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <i className="fa-li">
                          <img src={Close} />
                        </i>
                        CLOSED
                      </React.Fragment>
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
