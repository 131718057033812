import React from "react";

const AssignmentText = (props) => {
    return (
        <>
         {/* <div class="row rowAssessment">
            <div class="col-12 assessment-col-left">
                <div class="d-flex flex-wrap assMentbox">
                    <div>
                        <b>Conditions of appeal text:</b>
                    </div>
                    <div class="flex-shrink">                        
                    <ul class="list-number">
                        <li>Marking of appealed assessments will take up to two weeks to mark however, there could be a further delay due to different projects occurring.</li>
                        <li>Dates are set for appealed assessments and will be open on the student portal. This cannot be changed and appealed again.</li>
                        <li>The below are only applicable to School of Engineering and School of Draughting. All other schools are requested to refer to their School Handbook on their student portal for guidance related to their specific school. 
                            <ul class="list-bullet">
                                <li>Appeals may be requested up to 3 times across all subjects per N level.</li>
                                <li>Appeals approved will cap your result at 70%.</li>
                                <li>Dependant on when the subject runs, delayed marking may affect your term mark capturing for the DHET external examinations.</li>
                                <li>If an appeal is missed, you will be required to redo the subject should your term mark from one assessment not meet the qualifying term mark.</li>
                            </ul>                                                      
                        </li>
                    </ul>
                    </div>
                </div>
            </div>
        </div> */}
        <div class="row rowAssessment">
            <div class="col-12 assessment-col-left">
                <div class="d-flex flex-wrap assMentbox">
                    <div>
                    <b>Conditions of appeal text:</b>
                    </div>
                    <div class="flex-shrink">
                        <p><em>I have read and understand the terms of the appeal process.</em></p>
                        <br />
                        <p><em>To obtain a comprehensive understanding of the regulations and requirements related to appeals and extenuating circumstances, we recommend consulting the corresponding programme handbook/assessment policy.</em></p>
                        <br />
                        <p><em>By submitting this appeal, you agree that you have read and understand the terms of the appeal process.</em></p>
                    </div>
                </div>
            </div>
        </div>  
         </>
    );
}
export default AssignmentText;
