import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { LoginService } from "../services/LoginService";
import { getMyAllExamCountService } from "../services/ExamService";
import { setUserSession, setUserSettings } from "../utils/Auth";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import BackgroundImage from "../assets/images/login-bg.jpg";
import LoginImage from "../assets/images/card-header.png"

import { useSelector, useDispatch } from "react-redux";
import {
  setUser,
  setUnreadNotificationCount,
  setWidget,
  setTab,
  setUserMissingDetails,
} from "../store/actions/index";

import { getDocumentDownload } from "../services/GeneralService.js";
import { Link } from "react-router-dom";
import { checkUserKeys } from "../utils/commonFunction";
const LoginForm = (props) => {
  const user = useSelector((state) => state.user);
  const brandConfig = useSelector((state) => state.brand);
  const checkMissingKey = useSelector((state) => state.missingDetail.missingDetail);
  // console.log(brandConfig.configuration.login_header_color, 'header-color')
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  let history = useHistory();
  // checkUserKeys.map((item)=>{
  //     if(!user[item]){
  //       dispatch(setUserMissingDetails(true))
  //     }
  //  })
 
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      keepMe: false,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      password: Yup.string().required("Password is required"),
    }),
    onSubmit: (values) => {
      LoginService(values)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: res.data.message,
            timer: 3000,
            onOpen: function () {
              Swal.showLoading();
            },
          }).then(function (result) {
            if (result.dismiss === "timer") {
              let token = res.data.token;
              let user = res.data.user;
              let missingKey = checkMissingKey
              checkUserKeys.map((item)=>{
                if(!user[item]){
                  missingKey = true
                  dispatch(setUserMissingDetails(true))
                }
              })

              //let notificationCount = res.data.unreadNotificationCount ? res.data.unreadNotificationCount : 0;
              //localStorage.setItem("unreadNotificationCount", notificationCount);
              localStorage.setItem("widgetStatus", 1);

              dispatch(setUser(user));
              dispatch(setWidget({ status: 1 }));
              //dispatch(setUnreadNotificationCount(notificationCount));
              setUserSession(token, user);
              setUserSettings(user.settings);
              dispatch(setTab()); //side bar tab value set default to 1
              getMyAllExamCountService()
                .then((res) => {
                  localStorage.setItem(
                    "examReg",
                    res.data.registration.examList > 0 ? true : false
                  );
                  localStorage.setItem(
                    "reReg",
                    res.data.reregistration.open +
                      res.data.reregistration.close >
                      0
                      ? true
                      : false
                  );
                  setLoading(false);
                  if (user.status == "On Hold") {
                    history.push("/status");
                  }
                  else if (missingKey) {
                    history.push("/forceAboutDetails");
                  }  
                  else {
                    history.push("/home", { widgetLoad: true });
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          });
        })
        .catch(function (err) {
          Swal.fire({
            icon: "error",
            title: "Login Failed",
            text: err.response.data.message,
          });
          setLoading(false);
          return false;
        });
    },
  });

  const BackgroundStyle = {
    backgroundImage: `url(${brandConfig.configuration && brandConfig.configuration.login_header_color ? brandConfig.configuration.login_header_color : BackgroundImage})`,
  };
  return (
    <div className="card-body-inr text-center">
      <div className="card-header-new" >
        <div className="card-header-img">

          {/* api img */}
          <img
            src={brandConfig.configuration && brandConfig.configuration.login_header ? brandConfig.configuration.login_header : LoginImage}
            alt="Background"
          />

        </div>
      </div>
      <div className="portal-login-info">
        {/* <h1>COMMUNICATOR</h1> */}
        <h3>Sign In</h3>
      </div>
      <div className="portal-login-form">
        <form onSubmit={formik.handleSubmit}>
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text" id="">
                <i className="fal fa-user"></i>
              </span>
            </div>
            <input
              type="email"
              className={
                "form-control" +
                (formik.errors.email && formik.touched.email
                  ? " is-invalid"
                  : "")
              }
              placeholder="Email"
              name="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
          </div>

          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text" id="">
                <i className="fal fa-lock"></i>
              </span>
            </div>
            <input
              type="password"
              className={
                "form-control" +
                (formik.errors.password && formik.touched.password
                  ? " is-invalid"
                  : "")
              }
              placeholder="Password"
              name="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
            />
          </div>

          <div className="custom-control custom-checkbox text-left">
            <input
              type="checkbox"
              className="custom-control-input"
              id="customCheck1"
              name="keepMe"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <label className="custom-control-label" htmlFor="customCheck1">
              Keep me signed in
            </label>
          </div>

          <button
            className="btn btn-white-bordered btn-submit-info"
            type="submit"
            disabled={loading}
            title="Sign In"
          >
            {loading ? (
              <i className="fas fa-cog fa-spin"></i>
            ) : (
              <i className="fal fa-sign-in-alt"></i>
            )}{" "}
            Sign In
          </button>

          {formik.touched.email && formik.errors.email ? (
            <div className="invalid-feedback d-block">
              {formik.errors.email}
            </div>
          ) : null}
          {formik.touched.password && formik.errors.password ? (
            <div className="invalid-feedback d-block">
              {formik.errors.password}
            </div>
          ) : null}
        </form>
        <br />
        <small>
          By signing in, you agree that you have read the{" "}
          <a
            href="https://aie.ac/pgs-189-student-privacy-policy.html"
            target="_blank"
          >
            Privacy Policy.
          </a>
        </small>

        <div className="forgot-pass-bx">
          <Link title="Forgot your Password?" to="/resetPassword">Forgot your Password?</Link>
        </div>
      </div>
    </div>
  );
};
export default LoginForm;
