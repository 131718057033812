import React from "react";

export const SysCheckSideIcon = (params) => {
    let props = params.props
    return (
        <div className="test-series-act-info mb-5">
            <ul>
                <li className={`${props.data.camera
                    ? props.hasCamera
                        ? "active"
                        : "non-active"
                    : "system-check-opacity "
                    }`}
                    data-toggle="tooltip"
                    data-placement="right"
                    title={props.hasCamera ? '' : 'This condition is not set'}
                >
                    <i className="fal fa-webcam"></i>
                </li>
                <li className={`${props.data.audio
                    ? props.hasMicrophone
                        ? "active"
                        : "non-active"
                    : "system-check-opacity "
                    }`}
                    data-toggle="tooltip"
                    data-placement="right"
                    title={props.hasMicrophone? '' : 'This condition is not set'}
                >
                    <i className="fal fa-microphone"></i>
                </li>
                <li className={`${props.data.screen_share
                    ? props.isRecord
                        ? "active"
                        : "non-active"
                    : "system-check-opacity "
                    }`}
                    data-toggle="tooltip"
                    data-placement="right"
                    title={props.isRecord ? '' : 'This condition is not set'}
                >
                    <i className="fal fa-desktop"></i>
                </li>
            </ul>
        </div>
    )
};