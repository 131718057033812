import React, { createRef, useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import TextareaAutosize from "react-autosize-textarea";
import FeedComments from "./FeedComments";
import FeedAttachments from "./FeedAttachments";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import SocketConnection from "../../utils/SocketConnection";
import _ from "underscore";
import { FilePond, File, registerPlugin, setOptions } from "react-filepond";
import "filepond/dist/filepond.min.css";
import { API_BASE } from "../../utils/Constants";
import { getToken, removeUserSession } from "../../utils/Auth";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "../../assets/css/filePond.css";
import { MentionsInput, Mention } from "react-mentions";
import defaultStyle from "../../utils/defaultStyle";
import defaultMentionStyle from "../../utils/defaultMentionStyle";

registerPlugin(FilePondPluginImagePreview);
const FeedMessage = (props) => {
  const user = useSelector((state) => state.user);
  const [comments, setComments] = useState([]);
  const [comment, setComment] = useState("");
  const [chat, setChat] = useState(null);
  const commentTxt = createRef();
  const btnAttachment = createRef();

  const filePondRef = createRef();
  const [files, setFiles] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [showFilePond, setShowFilePond] = useState(false);
  const [removeFile, setRemoveFile] = useState(null);
  const [taggedUsers, setTaggedUsers] = useState([]);

  useEffect(() => {
    if (props.feed.comments) {
      setComments(props.feed.comments);
    }

    //console.log(_.contains(props.online, props.feed.user.id));
  }, []);

  useEffect(() => {
    if (chat) {
      chat.on("message", (message) => {
        //debugger;
        setComments((pre) => [...pre, message]);
        // toast.success(`Hey new comment pops up.`, {
        //   position: toast.POSITION.TOP_LEFT,
        // });
      });
    }
  }, [chat]);

  const chatSubscription = async () => {
    if (props.feed.id) {
      const messageID = props.feed.id;
      const roomID = props.feed.room_id;

      //if (subscription) subscription.close();

      let subscription = await SocketConnection.subscribe(
        `message:${messageID}-${roomID}`,
        "test"
      );

      await setChat(subscription);
    }
  };
  useEffect(() => {
    chatSubscription();
  }, [props.feed.id]);

  useEffect(
    () => () => {
      if (chat) {
        chat.close();
      }
    },
    []
  );

  const handleComment = (e) => {
    setComment(e.target.value);
  };

  const sendComment = () => {
    chat.emit("message", {
      message: comment,
      feed: props.feed.id,
      type: "comment",
      files: attachments,
      taggedUsers,
    });
    setComment("");
    setAttachments([]);
    setFiles([]);
    setShowFilePond(false);
    //commentTxt.current.value = "";
  };
  const handleAttachment = () => {
    btnAttachment.current.click();
  };
  const handleUploadedFiles = (response) => {
    setAttachments((attachments) => [...attachments, response]);
  };
  const handleFiles = (event) => {
    const ufiles = event.target.files;
    if (ufiles.length > 0) {
      for (var i = 0; i < ufiles.length; i++) {
        if (ufiles[i]) {
          if (filePondRef.current) {
            filePondRef.current
              .addFile(ufiles[i])
              .then((file) => {})
              .catch((error) => console.log(error));
          }
        }
      }
    }
  };
  const handleUserTagged = (user) => {
    const extendedList = taggedUsers;
    setTaggedUsers([...extendedList, user]);
  };

  return (
    <>
      {props.feed.user ? (
        <div className="post-by">
          <div className="post-by-left">
            <div className="post-by-img">
              <img src={props.feed.user.photo} alt={props.feed.user.fullname} />
            </div>
            <div className="post-by-status">
              <i
                className={
                  "fas fa-check" +
                  (_.contains(props.online, props.feed.user.id)
                    ? ""
                    : " offline")
                }
              ></i>
            </div>
          </div>
          <div className="post-by-right">
            <div className="ps-by-card">
              <div
                className={
                  "ps-by-card-header" +
                  (props.feed.user.id === user.id ? " mypost" : "")
                }
              >
                <h5>
                  <a>{props.feed.user.fullname}</a>
                  <span className="post-by-time">{props.feed.created_at}</span>
                </h5>
                <p> {ReactHtmlParser(props.feed.message)}</p>
                {props.feed.attachments && props.feed.attachments.length ? (
                  <FeedAttachments attachments={props.feed.attachments} />
                ) : null}
              </div>
              <div className="ps-by-card-body">
                {comments && comments.length ? (
                  <FeedComments
                    comments={comments}
                    user={user}
                    online={props.online}
                  />
                ) : null}
              </div>
              <div className="ps-by-card-footer">
                <div className="card-footer">
                  <div className="card-left">
                    <div className="card-left-by-img">
                      <img src={user.photo} alt={user.fullname} />
                    </div>
                    <div className="card-left-status">
                      <i className="fas fa-check"></i>
                    </div>
                  </div>
                  <div className="card-right">
                    <div className="textarea-btn-submit">
                      <MentionsInput
                        value={comment}
                        onChange={(e) => handleComment(e)}
                        style={defaultStyle}
                        placeholder={"Add your comment..."}
                      >
                        <Mention
                          trigger="@"
                          data={props.taggingUsers}
                          style={defaultMentionStyle}
                          onAdd={handleUserTagged}
                          markup={"@__display__#"}
                        />
                      </MentionsInput>

                      {/* <TextareaAutosize
                     placeholder="Add your comment..."
                     className="form-control"
                     onChange={(e) => handleComment(e)}
                     ref={commentTxt}
                   ></TextareaAutosize> */}
                      <button
                        className="btn btn-submit-arrow"
                        onClick={handleAttachment}
                      >
                        <i className="fal fa-paperclip"></i>
                      </button>
                      <input
                        type="file"
                        ref={btnAttachment}
                        style={{ display: "none" }}
                        onChange={(e) => handleFiles(e)}
                      />

                      {!comment && !files.length ? (
                        <button
                          className="btn btn-submit-arrow"
                          onClick={() => sendComment()}
                          disabled={true}
                        >
                          <i className="fal fa-paper-plane"></i>
                        </button>
                      ) : (
                        <button
                          className="btn btn-submit-arrow"
                          onClick={() => sendComment()}
                        >
                          <i className="fal fa-paper-plane"></i>
                        </button>
                      )}
                    </div>
                    <div
                      className={
                        !showFilePond ? "hideFilePond" : "showFilePond"
                      }
                    >
                      <FilePond
                        name="post"
                        allowMultiple={true}
                        maxFiles={3}
                        ref={filePondRef}
                        files={files}
                        onupdatefiles={setFiles}
                        beforeRemoveFile={(f) => setRemoveFile(f.file.name)}
                        onaddfile={(err, fileItem) => {
                          setShowFilePond(true);
                        }}
                        server={{
                          url: API_BASE,
                          process: {
                            url: "/addFeedAttachments",
                            headers: {
                              Authorization: `Bearer ${getToken()}`,
                            },
                            onload: (response) => {
                              handleUploadedFiles(response);
                            },
                          },
                          revert: {
                            url: `/removeFeedAttachments/${removeFile}`,
                            headers: {
                              Authorization: `Bearer ${getToken()}`,
                            },
                            onload: (response) => {
                              setRemoveFile(null);
                            },
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {/* <div className="media user-post-main  border d-flex border-0">
        <div className="media-icon rounded-circle">
          <div className="user-profile-pic">
            <img src={props.feed.user.photo} alt="Leon S" />
          </div>
          <div className="online-status">
            <i data-v-54ded044="" className="fas fa-check"></i>
          </div>
        </div>
        <div className="media-body card">
          <div className="card-header">
            <h6>
              {props.feed.user.fullname}
              <small>{props.feed.created_at}</small>
            </h6>
            <p className="m-0">{props.feed.message}</p>
            <div className="dropdown dropdown-dots">
              <button
                type="button"
                className="btn  dropdown-toggle"
                data-toggle="dropdown"
              >
                <i className="fal fa-ellipsis-v"></i>
              </button>
              <div className="dropdown-menu">
                <a className="dropdown-item" href="#">
                  Link 1
                </a>
                <a className="dropdown-item" href="#">
                  Link 2
                </a>
                <a className="dropdown-item" href="#">
                  Link 3
                </a>
              </div>
            </div>
          </div>
          <div className="card-body">
            {comments && comments.length ? (
              <FeedComments comments={comments} />
            ) : null}
          </div>
          <div className="card-footer">
            <div className="media border d-flex align-items-center border-0">
              <div className="media-icon rounded-circle">
                <div className="user-profile-pic">
                  <img src={user.photo} alt="Leon S" />
                </div>
                <div className="online-status">
                  <i data-v-54ded044="" className="fas fa-check"></i>
                </div>
              </div>
              <div className="media-body">
                <div className="comment-submit-bx form-group m-0 d-flex">
                  <textarea
                    className="form-control"
                    rows="1"
                    placeholder="Add your comment..."
                    onChange={(e) => handleComment(e)}
                    value={comment}
                  ></textarea>
                  <button
                    className="comment-submit"
                    onClick={() => sendComment()}
                    disabled={!comment}
                  >
                    <i className="fal fa-angle-right"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>*/}
    </>
  );
};

export default React.memo(FeedMessage);
