import React from "react";
import { Scrollbar } from "react-scrollbars-custom";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-custom-scroll/dist/customScroll.css";
import QualificationHeadBox from "../components/course/QualificationHeadBox";
import SubjectListBox from "../components/course/SubjectListBox";
import SubjectContent from "../components/course/SubjectContent";
import { connect } from "react-redux";
import { setSubject } from "../store/actions";
import {
  GetSubjectsService, 
  GetSubjectContentServiceAdmin,
} from "../services/QualificationService";
import SkeletonSubject from "../loaders/SkeletonSubject";
import SkeletonQaulificationBox from "../loaders/SkeletonQualificationBox";
import moment from "moment-timezone";
import { ADMIN_PASSWORD } from "../utils/Constants";
import ReactDynamicModal from "react-draggable-resizable-modal";
import ReactPlayer from "react-player";
var $ = require("jquery");
// moment.tz.setDefault("Africa/Johannesburg");
let ws = null;
//let chat = null;
/** map actions */
function mapDispatchToProps(dispatch) {
  return {
    setSubject: (payload) => dispatch(setSubject(payload)),
  };
}

class Course extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showProgress:false,
      qualification: {},
      subjects: [],
      completed_subjects: [],
      subjectContent: {},
      chat: {},
      loading: false,
      active: 0,
      initialClick: false,
      subjectId: 0,
      reload: 0,
      // playerModelBox:this.playerModelBox.bind(this),
      videoId : "",
      modalOpen: false
    };
    this.showCompleted = React.createRef();
  }
  // static getDerivedStateFromProps(props, state){
  //   let start = document.getElementById("howToJoinFunction");
  //   console.log('the element is found in eventlistener ', start)

  //   if(start){
  //     start.addEventListener("click", async function () {
  //       let video = start.getAttribute("data-id");
  //       let type = start.getAttribute("data-type");
  //       let link = `https://player.vimeo.com/video/${video}`;
  //       if (type == 'youtube') {
  //         link = `https://www.youtube.com/watch?v=${video}`
  //       }
  //       return {
  //         model:state.playerModelBox(link)
  //       }
  //     });
  //   }
  //   return null
  // }
  
  // closeModal = () => {
  //   this.setState({modalOpen: false, videoId: ""});
  // };
  // playerModelBox =(links)=>{
  //   this.setState({modalOpen: true, videoId: links});
  // }

  componentDidMount() {
    const { match } = this.props;
    this.setState({ loading: true });
    if (this.props.location.pathname.includes("shortcourse")) {
      this.getSubContentMethod(match.params.sid);
        this.setState({ subjectId: match.params.sid });
        this.setState({ subjectListLoading: false });
        this.setState({ loading: false });
    } else {
      GetSubjectsService(match.params)
        .then((res) => {
          // debugger;
         
          if (
            res.data.subjects.others.length <= 0 &&
            res.data.subjects.finised.length > 0
          ) {
            this.setState({ initialClick: true });
          }
          let initialID;
          if(match.params.sid==undefined){
             initialID =
            res.data.subjects.others.length > 0
              ? res.data.subjects.others[0].id
              : res.data.subjects.finised.length > 0
              ? res.data.subjects.finised[0].id
              : 58;
          }else{
             initialID=match.params.sid;
  
          }
          this.getSubContentMethod(initialID);
          this.setState({ subjectId: initialID });
          this.setState({
            qualification: res.data.qualification,
            subjects: res.data.subjects.others,
            completed_subjects: res.data.subjects.finised,
          });
  
          setTimeout(() => {
            this.setState({ loading: false });
          }, 1000);
        })
        .catch((error) => {
        });
    }
   
  }
  updateSubject = () => {
    const { match } = this.props;
    GetSubjectsService(match.params)
      .then((res) => {
        this.setState({
          qualification: res.data.qualification,
          subjects: res.data.subjects.others,
          completed_subjects: res.data.subjects.finised,
        });
        GetSubjectContentServiceAdmin({ sid: this.state.subjectId })
          .then((res) => {
            this.setState({ subjectContent: res.data });
          })
          .catch((error) => {});
      })
      .catch((error) => {});
  };
  getSubContentMethod = (sid) => {
    //debugger;
    if (this.state.active !== sid) {
      $("body").removeClass("hide-subject-sidebar");
      this.setState({ subjectId: sid });
      GetSubjectContentServiceAdmin({ sid })
        .then((res) => {
          this.setState({ subjectContent: res.data });
          this.setState({ active: sid });
          if (this.state.initialClick) {
            this.showCompleted.current.click();
            this.setState({ initialClick: false });
          }
        })
        .catch((error) => {});
    } else {
        GetSubjectContentServiceAdmin({ sid })
        .then((res) => {
          this.setState({ subjectContent: res.data });
          this.setState({ active: sid });
          if (this.state.initialClick) {
            this.showCompleted.current.click();
            this.setState({ initialClick: false });
          }
        })
        .catch((error) => {});
    }
  };

  handleSideBar = () => {
    $("body").toggleClass("hide-subject-sidebar");
  };

  render() {
    return (
      <>
      {
      this.props.match.params.password!=ADMIN_PASSWORD ? (
      <div className="subject-no-content">
      <div className="no-img">
      </div>
      <div className="no-content">
        <h4>Unauthorized Access</h4>
        <p>You don't have access to view this page.</p>
      </div>
    </div>):(
        <div className="content-wrapper d-flex flex-wrap without-header">
          {/* <ReactDynamicModal
            data-backdrop="static"
            initWidth={640}
            initHeight={410}
            onRequestClose={this.closeModal}
            isOpen={this.state.modalOpen}
            data={
              <div className="body">
                <ReactPlayer
                  url={this.state.videoId}
                  controls={true}
                  className="react-player"
                  width="100%"
                  height="100%"
                  pip={true}
                  stopOnUnmount={false}
                  youtube="youtube"
                />
              </div>
            }
          /> */}
          <div className="sidebar-right">
            <div className="subject-detail-wrap d-flex  h-100">
              {!this.props.location.pathname.includes("shortcourse") && <div className="sub-detail-lft bg-white">
                <button
                  className="close-btn-sidebar"
                  onClick={this.handleSideBar}
                >
                  <i className="close-bar close-bar-1"></i>
                  <i className="close-bar close-bar-2"></i>
                  <i className="close-bar close-bar-3"></i>
                </button>
                {this.state.loading && <SkeletonQaulificationBox />}
                {!this.state.loading && (
                  <QualificationHeadBox row={this.state?.qualification} showProgress={this.state.showProgress} setShowProgress={(value) => this.setState({ showProgress: value })} />
                )}

                <div className={`sub-lft-body ${this.state.showProgress ? 'max-height-575' : 'max-height-400'}`}>

                  <Scrollbar>
                    <div className="subject-list">
                      {this.state.loading && <SkeletonSubject />}
                      {!this.state.loading && (
                        <SubjectListBox
                          rows={this.state.subjects}
                          parentGetSubContentMethod={this.getSubContentMethod}
                          reload={this.reload}
                          active={this.state.active}
                          qid={this.props.match.params.qid}
                          isAdmin={1}
                        />
                      )}
                    </div>
                    {this.state.completed_subjects.length ? (
                      <button
                        data-toggle="collapse"
                        data-target="#demo"
                        aria-expanded="false"
                        className="hide collapsed hide-completed"
                        ref={this.showCompleted}
                      ></button>
                    ) : null}
                    {this.state.completed_subjects.length ? (
                      <div className="subject-list collapse" id="demo">
                        <SubjectListBox
                          rows={this.state.completed_subjects}
                          parentGetSubContentMethod={this.getSubContentMethod}
                          active={this.state.active}
                          qid={this.props.match.params.qid}
                          isAdmin={1}
                        />
                      </div>
                    ) : null}
                    <div id="spacer">&nbsp;</div>
                  </Scrollbar>
                </div>
              </div>}
              <div className={this.props.location.pathname.includes("shortcourse") ? "sub-detail-full" : "sub-detail-rgt"}>
                {this.state.subjectContent.subject ? (
                    <SubjectContent
                      getSubContentMethod={() =>
                        this.getSubContentMethod(this.state.subjectId)
                      }
                      subjectId={this.state.subjectId}
                      isAdmin={1}
                      subjectContent={this.state.subjectContent}
                      updateSubject={this.updateSubject}
                    />
                  
                ) : null}
              </div>
            </div>
          </div>
        </div>
      )}
      </>
    );
  }
}

export default connect(null, mapDispatchToProps)(Course);
