import axiosInstance from "../utils/axios";
  export const GetSubjectEvent = async (values) => {
    return await axiosInstance().post(`/getSubjectEventTz`,values);
  };
  export const GetSubject = async () => {
    return await axiosInstance().get(`/getAllSubjectCalendar`);
  };
  export const GetSubjectEventPageWise = async (values) => {
    return await axiosInstance().post(`/getSubjectEventPageWiseTz`, values );
  };

  export const UpdateStudentTimeZone = async (values) => {
    return await axiosInstance().post(`/updateStudentTimeZone`, values);
  };

export const GetSubjectEventTzCalender = async (values) => {
  return await axiosInstance().post(`/getSubjectEventTzCalender`,values);
};