import React from "react";

import { connect } from "react-redux";
import { setPayer } from "../../../store/actions";
import moment from "moment";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import Swal from "sweetalert2";

import { PayerAboutService } from "../../../services/ProfileService";
import { capitalize, languageList } from "../../../utils/commonFunction";

/** map actions */
function mapDispatchToProps(dispatch) {
  return {
    setPayer: (payload) => dispatch(setPayer(payload)),
  };
}

/** map state */
const mapStateToProps = (state) => ({ user: state.user, payer: state.payer });

class PayerAboutDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: true,
      yearoptions: [],
      daysoptions: [],
      loading: false,
      languageList: languageList(),
    };
  }

  handleEdit = () => {
    this.setState({ edit: !this.state.edit });
  };

  //this.props.decrement();
  handleMonths = () => { };
  handleYears = () => {
    let options = [];
    for (let index = 1900; index < moment().year(); index++) {
      options.push(index);
    }
    this.setState({ yearoptions: options });
  };
  handleDays = () => {
    let options = [];
    for (let index = 1; index <= 31; index++) {
      options.push(index);
    }
    this.setState({ daysoptions: options });
  };
  componentDidMount() {
    this.handleYears();
    this.handleDays();
  }

  render() {
    var { user = {}, payer = {} } = this.props;

    const tempPayer = {
      first_name: "",
      second_name: "",
      last_name: "",
      title: "Mr",
      number: "",
      bday_year: "",
      bday_month: "",
      bday_day: "",
      race: "african",
      gender: "",
      language: "",
      bday_month_label: moment.months()[0]
    };

    (Object.keys(payer).length > 0) && (payer.bday_month_label = moment.months()[payer.bday_month-1])
    return (
      <>
        <Formik
          enableReinitialize={true}
          initialValues={Object.keys(payer).length > 0 ? payer : tempPayer}
          validationSchema={Yup.object().shape({
            first_name: Yup.string().required("First Name is required"),
            last_name: Yup.string().required("Last Name is required"),
            title: Yup.string().required("Title is required"),
            number: Yup.string().required("Id Number is required"),
            bday_year: Yup.string().required("Year is required"),
            bday_month: Yup.string().required("Month is required"),
            bday_day: Yup.string().required("Day is required"),
            race: Yup.string().required("Race is required"),
            gender: Yup.string().required("Gender is required"),
            language: Yup.string().required("Language is required"),
          })}
          onSubmit={(values) => {
            this.setState({ loading: true });
            PayerAboutService(values)
              .then((res) => {
                Swal.fire({
                  icon: "success",
                  title: "Success",
                  text: "Updated successfully",
                }).then((result) => {
                  if (result.value) {
                    this.props.setPayer(res.data);
                    this.setState({ edit: true });
                  }
                });
                this.setState({ loading: false, edit: true });
              })
              .catch((error) => {
                this.setState({ loading: false, edit: true });
              });
          }}
        >
          {({ values, errors, status, setFieldValue, touched, handleChange, handleBlur }) => (
            <div className="card card-profile-info-card">
              <div className="card-header">About Sponsor/Account Payer</div>
              <div
                className={`card-body ${this.state.edit ? "" : "edit-profile-icon-active"
                  }`}
              >
                {!user.payer_same ? (
                  <div className="edit-icon">
                    (As per your ID document)
                    <button
                      className="edit-profile-icon"
                      onClick={() => this.handleEdit()}
                      title={this.state.edit ? "Edit" : "Cancel"}
                    >
                      <i className="fal fa-edit"></i>
                    </button>
                  </div>
                ) : null}
                {this.state.edit ? (
                  <div className="card-body-inr">
                    <div className="form-group">
                      <label>First Name</label>
                      <div className="label-selected form-control">
                        {this.props.payer.first_name}
                      </div>
                    </div>

                    <div className="form-group">
                      <label>Second Name (Optional)</label>
                      <div className="label-selected form-control">
                        {this.props.payer.second_name}
                      </div>
                    </div>

                    <div className="form-group">
                      <label>Last Name</label>
                      <div className="label-selected form-control">
                        {this.props.payer.last_name}
                      </div>
                    </div>
                    <hr />
                    <div className="form-group">
                      <label>Title</label>
                      <div className="label-selected form-control">
                        {this.props.payer.title}
                      </div>
                    </div>

                    <div className="form-group">
                      <label>Id Number</label>
                      <div className="label-selected form-control">
                        {this.props.payer.number}
                      </div>
                    </div>

                    <div className="form-group">
                      <label>Date Of Birth</label>
                      <div className="label-selected form-control">
                        {this.props.payer.bday_day}/
                        {this.props.payer.bday_month}/
                        {this.props.payer.bday_year}
                      </div>
                    </div>

                    <div className="form-group">
                      <label>Race</label>
                      <div className="label-selected form-control">
                        {capitalize(this.props.payer.race)}
                      </div>
                    </div>

                    <div className="form-group">
                      <label>Gender</label>
                      <div className="label-selected form-control">
                        {capitalize(this.props.payer.gender)}
                      </div>
                    </div>

                    <div className="form-group">
                      <label>Home Language</label>
                      <div className="label-selected form-control">
                        {capitalize(this.props.payer.language)}
                      </div>
                    </div>
                  </div>
                ) : (
                  <Form>
                    <div className="card-body-inr">
                      <div className="form-group">
                        <label>First Name *</label>
                        <input
                          type="text"
                          className={
                            "form-control" +
                            (errors.first_name && touched.first_name
                              ? " is-invalid"
                              : "")
                          }
                          placeholder="First Name"
                          value={values.first_name}
                          name="first_name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.first_name && touched.first_name ? (
                          <span className="exclamation">
                            <i className="fal fa-exclamation-circle"></i>
                          </span>
                        ) : null}
                      </div>

                      <div className="form-group">
                        <label>Second Name (Optional)</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Second Name"
                          value={values.second_name}
                          name="second_name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>

                      <div className="form-group">
                        <label>Last Name *</label>
                        <input
                          type="text"
                          className={
                            "form-control" +
                            (errors.last_name && touched.last_name
                              ? " is-invalid"
                              : "")
                          }
                          placeholder="Last Name"
                          value={values.last_name}
                          name="last_name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.last_name && touched.last_name ? (
                          <span className="exclamation">
                            <i className="fal fa-exclamation-circle"></i>
                          </span>
                        ) : null}
                      </div>
                      <hr />

                      <div className="form-group">
                        <label>Title *</label>
                        <div className="form-icon-group">
                          <Select
                            className={
                              "form-control custom-select-box" +
                              (errors.title && touched.title
                                ? " is-invalid"
                                : "")
                            }
                            name="title"
                            value={values.title}
                            onChange={(value) => { setFieldValue("title", value.value) }}
                            onBlur={handleBlur}
                            maxMenuHeight={175}
                            placeholder={values.title ? capitalize(values.title) : "Title"}
                            options={[
                              { value: 'Mr', label: 'Mr' },
                              { value: 'Mrs', label: 'Mrs' },
                              { value: 'Miss', label: 'Miss' },
                              { value: 'Ms', label: 'Ms' }
                            ]}
                          />
                          {errors.title && touched.title ? (
                            <span className="exclamation">
                              <i className="fal fa-exclamation-circle"></i>
                            </span>
                          ) : null}
                        </div>
                      </div>

                      <div className="form-group">
                        <label>Id Number *</label>
                        <input
                          type="text"
                          className={
                            "form-control" +
                            (errors.number && touched.number
                              ? " is-invalid"
                              : "")
                          }
                          placeholder="Id Number"
                          name="number"
                          value={values.number}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.number && touched.number ? (
                          <span className="exclamation">
                            <i className="fal fa-exclamation-circle"></i>
                          </span>
                        ) : null}
                      </div>

                      {/* <div className="form-group date-form-group">
                        <label>Date Of Birth *</label>
                        <div className="row">
                          <div className="col-4">
                            <select
                              className={
                                "form-control" +
                                (errors.bday_year && touched.bday_year
                                  ? " is-invalid"
                                  : "")
                              }
                              value={values.bday_year}
                              name="bday_year"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <option value="">Year</option>
                              {this.state.yearoptions.map((year) => {
                                return (
                                  <option value={year} key={year}>
                                    {year}
                                  </option>
                                );
                              })}
                            </select>
                            {errors.bday_year && touched.bday_year ? (
                              <span className="exclamation">
                                <i className="fal fa-exclamation-circle"></i>
                              </span>
                            ) : null}
                          </div>
                          <div className="col-5">
                            <select
                              className={
                                "form-control" +
                                (errors.bday_month && touched.bday_month
                                  ? " is-invalid"
                                  : "")
                              }
                              value={values.bday_month}
                              name="bday_month"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <option value="">Month</option>
                              {moment.months().map((month, index) => {
                                return (
                                  <option value={index + 1} key={month}>
                                    {month}
                                  </option>
                                );
                              })}
                            </select>
                            {errors.bday_month && touched.bday_month ? (
                              <span className="exclamation">
                                <i className="fal fa-exclamation-circle"></i>
                              </span>
                            ) : null}
                          </div>
                          <div className="col-3">
                            <select
                              className={
                                "form-control" +
                                (errors.bday_day && touched.bday_day
                                  ? " is-invalid"
                                  : "")
                              }
                              value={values.bday_day}
                              name="bday_day"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <option value="">Date</option>
                              {this.state.daysoptions.map((day) => {
                                return (
                                  <option value={day} key={day}>
                                    {day}
                                  </option>
                                );
                              })}
                            </select>
                            {errors.bday_day && touched.bday_day ? (
                              <span className="exclamation">
                                <i className="fal fa-exclamation-circle"></i>
                              </span>
                            ) : null}
                          </div>
                        </div>
                      </div> */}
                      <div className="form-group date-form-group">
                        <label>Date Of Birth *</label>
                        <div className="row form-icon-group">
                          <div className="col-4">
                            <Select
                              className={
                                "form-control custom-select-box" +
                                (errors.bday_year && touched.bday_year
                                  ? " is-invalid"
                                  : "")
                              }
                              name="bday_year"
                              value={values.bday_year}
                              onChange={(value) => {
                                setFieldValue("bday_year", value.value)
                                setFieldValue("bday_year", value.label)
                              }}
                              onBlur={handleBlur}
                              maxMenuHeight={175}
                              placeholder={values.bday_year ? values.bday_year : ""}
                              options={this.state.yearoptions.map((year) => {
                                return (
                                  { value: year, label: year }
                                );
                              })}
                            />
                            {errors.bday_year && touched.bday_year ? (
                              <span className="exclamation">
                                <i className="fal fa-exclamation-circle"></i>
                              </span>
                            ) : null}
                          </div>
                          <div className="col-5">
                            <Select
                              className={
                                "form-control custom-select-box" +
                                (errors.bday_month && touched.bday_month
                                  ? " is-invalid"
                                  : "")
                              }
                              name="bday_month"
                              value={values.bday_month_label}
                              onChange={(value) => {
                                setFieldValue("bday_month", value.value)
                                setFieldValue("bday_month_label", value.label)
                              }}
                              onBlur={handleBlur}
                              maxMenuHeight={175}
                              placeholder={values.bday_month_label ? values.bday_month_label : ""}
                              options={moment.months().map((month, index) => {
                                return (
                                  { value: index + 1, label: month }
                                );
                              })}
                            />
                            {errors.bday_month && touched.bday_month ? (
                              <span className="exclamation">
                                <i className="fal fa-exclamation-circle"></i>
                              </span>
                            ) : null}
                          </div>
                          <div className="col-3">
                            <Select
                              className={
                                "form-control custom-select-box" +
                                (errors.bday_day && touched.bday_day
                                  ? " is-invalid"
                                  : "")
                              }
                              name="bday_day"
                              value={values.bday_day}
                              onChange={(value) => { setFieldValue("bday_day", value.value) }}
                              onBlur={handleBlur}
                              maxMenuHeight={175}
                              placeholder={values.bday_day ? values.bday_day : ""}
                              options={this.state.daysoptions.map((key) => {
                                return (
                                  { value: key, label: key }
                                );
                              })}
                            />
                            {errors.bday_day && touched.bday_day ? (
                              <span className="exclamation">
                                <i className="fal fa-exclamation-circle"></i>
                              </span>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label>Race *</label>
                        <div className="form-icon-group">
                          <Select
                            className={
                              "form-control custom-select-box" +
                              (errors.race && touched.race
                                ? " is-invalid"
                                : "")
                            }
                            name="race"
                            value={values.race}
                            onChange={(value) => { setFieldValue("race", value.value) }}
                            onBlur={handleBlur}
                            maxMenuHeight={175}
                            placeholder={values.race ? capitalize(values.race) : "Race"}
                            options={[
                              { value: 'African', label: 'African' },
                              { value: 'Asian', label: 'Asian' },
                              { value: 'Coloured', label: 'Coloured' },
                              { value: 'Indian', label: 'Indian' },
                              { value: 'White', label: 'White' },
                              { value: 'Other', label: 'Other' }
                            ]}
                          />
                          {errors.race && touched.race ? (
                            <span className="exclamation">
                              <i className="fal fa-exclamation-circle"></i>
                            </span>
                          ) : null}
                        </div>
                      </div>

                      <div className="form-group">
                        <label>Gender *</label>
                        <div className="form-icon-group">
                          <Select
                            className={
                              "form-control custom-select-box" +
                              (errors.gender && touched.gender
                                ? " is-invalid"
                                : "")
                            }
                            name="gender"
                            value={values.gender}
                            onChange={(value) => { setFieldValue("gender", value.value) }}
                            onBlur={handleBlur}
                            maxMenuHeight={175}
                            placeholder={values.gender ? capitalize(values.gender) : "Gender"}
                            options={[
                              { value: 'male', label: 'Male' },
                              { value: 'female', label: 'Female' }
                            ]}
                          />
                          {errors.gender && touched.gender ? (
                            <span className="exclamation">
                              <i className="fal fa-exclamation-circle"></i>
                            </span>
                          ) : null}
                        </div>
                      </div>

                      <div className="form-group">
                        <label>Home Language *</label>
                        <div className="form-icon-group">
                          <Select
                            className={
                              "form-control custom-select-box" +
                              (errors.language && touched.language
                                ? " is-invalid"
                                : "")
                            }
                            name="language"
                            value={values.language}
                            onChange={(value) => { setFieldValue("language", value.value) }}
                            onBlur={handleBlur}
                            maxMenuHeight={175}
                            placeholder={values.language ? capitalize(values.language) : "Language"}
                            options={Object.keys(this.state.languageList).map((key) => {
                              return (
                                { value: key, label: this.state.languageList[key] }
                              );
                            })}
                          />
                          {errors.language && touched.language ? (
                            <span className="exclamation">
                              <i className="fal fa-exclamation-circle"></i>
                            </span>
                          ) : null}
                        </div>
                      </div>


                      <div className="form-group form-group-save-cancel">
                        <button
                          className="btn btn-save btn-success"
                          type="submit"
                          disabled={this.state.loading}
                          title="Save"
                        >
                          {this.state.loading ? (
                            <i className="fas fa-cog fa-spin"></i>
                          ) : <i className="fal fa-save"></i>}{" "}
                          Save
                        </button>

                        <button
                          className="btn btn-close btn-danger"
                          type="button"
                          onClick={() => this.setState({ edit: true })}
                          title="Cancel"
                        >
                          <i className="fal fa-times"></i>
                          Cancel
                        </button>
                        <ErrorMessage
                          name="first_name"
                          component="div"
                          className="invalid-feedback d-block"
                        />
                        <ErrorMessage
                          name="last_name"
                          component="div"
                          className="invalid-feedback d-block"
                        />
                        <ErrorMessage
                          name="title"
                          component="div"
                          className="invalid-feedback d-block"
                        />
                        <ErrorMessage
                          name="number"
                          component="div"
                          className="invalid-feedback d-block"
                        />
                        <ErrorMessage
                          name="bday_year"
                          component="div"
                          className="invalid-feedback d-block"
                        />
                        <ErrorMessage
                          name="bday_month"
                          component="div"
                          className="invalid-feedback d-block"
                        />
                        <ErrorMessage
                          name="bday_day"
                          component="div"
                          className="invalid-feedback d-block"
                        />
                        <ErrorMessage
                          name="race"
                          component="div"
                          className="invalid-feedback d-block"
                        />
                        <ErrorMessage
                          name="gender"
                          component="div"
                          className="invalid-feedback d-block"
                        />
                        <ErrorMessage
                          name="language"
                          component="div"
                          className="invalid-feedback d-block"
                        />
                      </div>
                    </div>
                  </Form>
                )}
              </div>
            </div>
          )}
        </Formik>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PayerAboutDetails);
