import React, { createRef, useState, useRef } from "react";
import { useSelector } from "react-redux";

import { FilePond } from "react-filepond";
import "filepond/dist/filepond.min.css";
import { API_BASE } from "../../utils/Constants";
import { getToken } from "../../utils/Auth";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "../../assets/css/filePond.css";
import UserHover from "../common/UserHover";
import { getFormattedTime, getCurrentTime } from "../common/Helper";
import { download } from "../../utils/FileDownload";
import { InitalRender, originalValues } from "../../components/common/Helper";
import { TrimText } from "../common/TrimText";
import TempProfileImage from "../../assets/images/default-profile.jpeg";


export default function TicketComments(props) {
  const [loading, setLoading] = useState(false);
  const { ticketThread } = props;
  const { ticketData } = props;
  const scroll_down_bottom = useRef();
  const btnAttachment = createRef();
  const filePondRef = createRef();
  const [files, setFiles] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [showFilePond, setShowFilePond] = useState(false);
  const [removeFile, setRemoveFile] = useState(null);
  const [currentimage, setcurrentimage] = useState('');

  const [taggedUsers, setTaggedUsers] = useState([]);

  const [InptMessage, setInptMessage] = useState("");

  const [copyText, setCopyText] = useState(0);

  const copyEmailText = (email) => {
    setCopyText(1);
    navigator.clipboard.writeText(email);
    setTimeout(() => {
      setCopyText(0)
    }, 1000);
  }

  const user = useSelector((state) => state.user);
  const temp_profile_photo = TempProfileImage;

  const handleInputChange = (e) => {
    setInptMessage(e.target.value);
  };

  // console.log('ticket thread is ', ticketThread)
  // console.log('showfilepond is like ', files)
  const downloadFile = async (absolutePath, fileName, index) => {
    // setTimeout(() => {
    //   if (value) setDownloadLoader("0");
    // }, 1000);
  };

  const handleAttahment = () => {
    btnAttachment.current.click();
  };

  const handleFiles = async (event) => {
    const ufiles = await event.target.files;
    if (ufiles.length > 0) {
      if (ufiles[0]) {
        if (filePondRef.current) {
          filePondRef.current
            .addFile(ufiles[0])
            .then((file) => {
              // window.scrollTo(0, scroll_down_bottom.current.offsetTop);

              // alert(scroll_down_bottom.current.offsetTop);
            })
            .catch((error) => console.log(error));
        }
      }
    }
  };

  const handleFiles1 = async (event) => {
    const ufiles = await event.clipboardData.files;
    if (ufiles.length > 0) {
      if (ufiles[0]) {
        if (filePondRef.current) {
          filePondRef.current
            .addFile(ufiles[0])
            .then((file) => {
            })
            .catch((error) => console.log(error));
        }
      }
    }
  };

  const handleBtnMessage = () => {
    let messagpost = {
      message: InptMessage,
      files: attachments,
      taggedUsers,
      id_ticket: props.id_ticket,
    };

    props.parentHandleTicketThread(messagpost);

    setInptMessage("");
    setFiles([]);
    setAttachments([]);
    setShowFilePond(false);
  };

  // const fileInput = document.getElementById("document_attachment_doc");

  window.addEventListener("paste", async (e) => {
    // fileInput.files = e.clipboardData.files;
    e.target.files = e.clipboardData.files;
    // filePondRef.current=null;
    await handleFiles1(e);
    if (e.clipboardData && e.clipboardData.files && e.clipboardData.files.length > 0) {
      let oldFile = files
      if (oldFile.length < 3) {
        oldFile.push(e.clipboardData.files[0])
        setFiles(oldFile)
      }

    }
  });


  const handleFilePondInit = () => { };
  const handleUserTagged = (user) => {
    const extendedList = taggedUsers;
    setTaggedUsers([...extendedList, user]);
  };

  const handleUploadedFiles = (response) => {
    setAttachments((attachments) => [...attachments, response]);
  };

  const handleDragEnter = event => {
    setShowFilePond(true);
    // event.stopPropogation()
    // event.preventDefault()
  };

  const handleDragExit = event => {
    setShowFilePond(false);
    // event.stopPropogation()
    // event.preventDefault()
  };

  return (
    <>
      <div className="comment-details col-md-9">
        <div className="comment-main ac-white-card p-0">
          <div className="card-header comments-card-header">Comments</div>
          <div className="card-body comments-card-body">
            <div className="comment-conversation-list-wrap">
              <ul className="comment-conversation-list list-unstyled">
                {ticketThread && ticketThread.length > 0 && (
                  ticketThread.map((thread, index) => {
                    return (
                      <React.Fragment key={index}>
                        {thread.id_sender > 0 ? (
                          <li
                            className="comment-user-current"
                            key={thread.id_sender}
                          >
                            <div className="comment-user-bx">

                              <div className="comment-user-img comment-box-pro assigned-title-blk">
                                <div className="comment-box-pro-item">
                                  {user?.photo ? <img src={user.photo} />: InitalRender(originalValues(user.first_name + " " + user.last_name, 23))}
                                  
                                </div>
                                  <span className="portal-status-icon online-u"></span>
                                <UserHover user={user} online="online" />
                              </div>
                              {/* <div className="comment-user-img">
                                <img src={user.photo} />
                              </div> */}
                              <div className="comment-user-detail">

                                {thread.attachments.length > 0
                                  ? thread.attachments.map((file, indKey) => {
                                    if (file.fileType == "download") {
                                      return (
                                        <div
                                          className="Attachment-area"
                                          key={indKey}
                                        >
                                          <i
                                            className="fa fa-paperclip"
                                            aria-hidden="true"
                                          ></i>{" "}
                                          Attached File :{" "}
                                          <a
                                            href={file.attachment}
                                            target="_blank"
                                          >
                                            {file.originalName}
                                          </a>
                                        </div>
                                      );
                                    } else {
                                      return (
                                        <div
                                          key={indKey}
                                          onClick={() =>
                                            downloadFile(
                                              file.attachment,
                                              file.originalName,
                                              1
                                            )
                                          }
                                          className="attached-media-comment Attachment-area"
                                        >
                                          <img className="curser" onClick={() => { setcurrentimage(file.attachment);}} src={file.attachment} />
                                        </div>
                                      );
                                    }
                                  })
                                  : null}
                                {thread.message && thread.message !== "" ? (
                                  <div className="posted-comment">
                                    {thread.message}
                                  </div>
                                ) : null}
                                <small className="date">
                                  {getFormattedTime(thread.send_date)}
                                </small>
                              </div>

                            </div>
                          </li>
                        ) : (
                          <li
                            className="comment-user-other"
                            key={thread.id_sender}
                          >
                            <div className="comment-user-bx">


                              <div className="comment-user-img comment-box-pro assigned-title-blk">
                                <div className="comment-box-pro-item">
                                  {thread.picture_me ? <img src={thread.picture_me} onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.closest('.comment-box-pro-item').append(InitalRender(TrimText(thread.userFullName, 23))); 
                                    currentTarget.remove();
                                  }} /> : InitalRender(TrimText(thread.userFullName, 23))}

                                </div>
                                   {thread?.assign_to_status == "Offline" ? <span className="portal-status-icon offline-u"></span> : <span className="portal-status-icon online-u"></span>}
                                <div className="assigned-title-info online_newpoints01">
                                  <article>
                                    <figure class="bgBlue">
                                      {thread.picture_me ? <img src={thread.picture_me} onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.closest('.bgBlue').append(InitalRender(TrimText(thread.userFullName, 23)));
                                        currentTarget.remove();
                                      }} /> : InitalRender(TrimText(thread.userFullName, 23))}
                                      {thread?.assign_to_status == "Offline" ? <span className="portal-status-icon offline-u">ello</span> : <span className="portal-status-icon online-u">kjkgkgb</span>}
                                      {/* <span class="portal-status-icon offline-u"></span> */}

                                    </figure>
                                    <figcaption>
                                      <h4>{TrimText(thread.userFullName, 23)}</h4>
                                      <p>
                                        <i class="fal fa-envelope"></i>
                                        {/* { thread.userEmail ? thread.userEmail : " " } */}
                                        {/* {thread.userEmail ? <><a href={"mailto:" + thread.userEmail} target="_blank">{thread.userEmail}</a> */}
                                        {thread.userEmail ? <><a class="hover-color-blue" onClick={() => copyEmailText(thread.userEmail)}>{thread.userEmail}</a>
                                          {copyText ? (
                                            <i class="fas fa-check-circle cat-greenhex"></i>
                                          ) : (
                                            thread.userEmail && <i
                                              className="fal fa-copy hover-color-blue"
                                              onClick={() => copyEmailText(thread.userEmail)}
                                            ></i>
                                          )}

                                        </> : ""}
                                      </p>
                                    </figcaption>
                                  </article>
                                </div>
                              </div>

                              {/* <div className="comment-user-img">
                                <div className="comment-box-pro-item">
                                  <img src={thread.picture_me} />
                                </div>
                              </div> */}
                              <div className="comment-user-detail">


                                {thread.attachments.length > 0
                                  ? thread.attachments.map((file, ind) => {
                                    if (file.fileType == "download") {
                                      return (
                                        <div
                                          className="Attachment-area"
                                          key={ind}
                                        >
                                          <i
                                            className="fa fa-paperclip"
                                            aria-hidden="true"
                                          ></i>{" "}
                                          Attached File :{" "}
                                          <a href={file.attachment}>
                                            {file.originalName}
                                          </a>
                                        </div>
                                      );
                                    } else {
                                      return (
                                        <div
                                          onClick={() =>
                                            downloadFile(
                                              file.attachment,
                                              file.originalName,
                                              1
                                            )
                                          }
                                          className="attached-media-comment Attachment-area"
                                        >
                                          <img className="curser" onClick={() => { setcurrentimage(file.attachment); }} src={file.attachment} />
                                        </div>
                                      );
                                    }
                                  })
                                  : null}
                                {thread.message && thread.message !== "" ? (
                                  <div className="posted-comment">
                                    {thread.read_at ? <>{thread.message}</> : <b>{thread.message}</b>}
                                  </div>
                                ) : null}
                                <small className="date">
                                  {getFormattedTime(thread.send_date)}
                                </small>
                              </div>
                            </div>
                          </li>
                        )}
                      </React.Fragment>
                    );
                  })
                )
                  // :
                  // (
                  //   <li className="comment-user-other">
                  //     <div className="comment-user-bx">
                  //       <div className="comment-user-img">
                  //         <img src="http://1.gravatar.com/avatar/ad516503a11cd5ca435acc9bb6523536?s=100" />
                  //       </div>
                  //       <div className="comment-user-detail">
                  //         <div className="posted-comment">
                  //           Hi there, what can we help you with?
                  //         </div>
                  //         <small className="date">{getCurrentTime()}</small>
                  //       </div>
                  //     </div>
                  //   </li>
                  // )
                }
              </ul>
            </div>
          </div>
          {ticketData && ticketData.status &&
            <div className="card-footer comments-card-footer">
              {ticketData && (ticketData.status != "Closed") &&
                <>
                  <div className={(ticketData && (ticketData.status == "Closed")) ? "comment-area-control-full" : "comment-area-control"}>
                    <textarea
                      className="form-control comment-form-control"
                      placeholder="Start typing..."
                      onChange={handleInputChange}
                      value={InptMessage}
                      onDragEnter={handleDragEnter}
                      // onDragLeave={handleDragExit}
                      disabled={(ticketData && (ticketData.status == "Closed")) ? true : false}
                    />
                  </div>

                  {(ticketData && (ticketData.status !== "Closed")) ?

                    <div className="comment-area-actions">
                      <button
                        disabled={(ticketData && (ticketData.status == "Closed")) ? true : false}
                        title="Add Attachment"
                        className="btn btn-submit-arrow no-shadow"
                        onClick={handleAttahment}
                      >
                        <i className="fal fa-paperclip"></i>
                      </button>
                      <div>
                        <input
                          disabled={(ticketData && (ticketData.status == "Closed")) ? true : false}
                          type="file"
                          ref={btnAttachment}
                          style={{ display: "none" }}
                          onChange={(e) => handleFiles(e)}
                          // id="document_attachment_doc"
                          showalerts={"false"}
                        />
                      </div>

                      {!InptMessage && !files.length ? (
                        <button
                          title="Send Message"
                          className="btn btn-submit-arrow no-shadow"
                          onClick={() => handleBtnMessage()}
                          disabled={true}
                        >
                          <i className="fal fa-paper-plane"></i>
                        </button>
                      ) : (
                        <button
                          title="Send Message"
                          className="btn btn-submit-arrow no-shadow"
                          onClick={() => handleBtnMessage()}
                          disabled={loading ? true : false}
                        >
                          <i className="fal fa-paper-plane"></i>
                        </button>
                      )}
                    </div>
                    : null}
                </>
              }

            </div>
          }
          <div
            ref={scroll_down_bottom}
            className={!showFilePond ? "hideFilePond" : "showFilePond"}
          >
            <FilePond
              name="post"
              allowMultiple={true}
              maxFiles={3}
              ref={filePondRef}
              files={files}
              onupdatefiles={setFiles}
              beforeRemoveFile={(f) => setRemoveFile(f.file.name)}
              init={handleFilePondInit}
              onaddfile={(err, fileItem) => {
                setShowFilePond(true);
              }}
              server={{
                url: API_BASE,
                process: {
                  url: "/addTicketAttachments",
                  headers: {
                    Authorization: `Bearer ${getToken()}`,
                  },
                  ondata: (formData) => {
                    setLoading(true)
                    return formData;
                  },
                  onload: (response) => {
                    handleUploadedFiles(response);
                    setLoading(false)
                  },
                },
                revert: {
                  url: `/removeTicketAttachments/${removeFile}`,
                  headers: {
                    Authorization: `Bearer ${getToken()}`,
                  },
                  onload: (response) => {
                    setRemoveFile(null);
                  },
                },
              }}
            />

            {/* <div ref={scroll_down_bottom}>scroll_down_bottom</div> */}
          </div>
        </div>
      </div>
      <div onClick={() => { setcurrentimage('') }} className={`image-popup-block ${currentimage != '' ? 'active' : ''}`}>
        <div className="image-popup flexible-modal">
          <button type="button" onClick={() => { setcurrentimage('') }} className="close-icon"><i class="fas fa-times"></i></button>


          <div className="image-popup-img">
            <img src={currentimage} />
          </div>
        </div>
      </div>
    </>
  );
}
