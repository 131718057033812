import React from "react";
import Skeleton from "react-loading-skeleton";
const SkeletonReRegistrationList = () => {
  return (
    <React.Fragment>
      {Array(3)
        .fill()
        .map((item, index) => (
          <li key={index}>
            <Skeleton width={252} height={252}/>
          </li>
        ))}
    </React.Fragment>
  );
};
export default SkeletonReRegistrationList;
