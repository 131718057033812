import React, { useState, useEffect } from "react";
import Select from "react-select";
import { addAssistanceRequest, getAssistanceRequired, getClosestCampus } from "../../services/StudentHubService";
import { useFormik, FormikProvider, Form } from 'formik';
import * as Yup from 'yup';
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import moment from "moment";

function SearchAssistance(props) {
  const [assistanceData, setAssistanceData] = useState([]);
  const [campusData, setCampusData] = useState([]);
  const brand_id = useSelector((state) => state.brand && state.brand.configuration && state.brand.configuration.id ? state.brand.configuration.id : null);

  const AssistanceRequired = () => {
    getAssistanceRequired().then((res) => {
      if (res.status === 200) {
        setAssistanceData(res.data.resource)
      }
    }).catch((err) => {
      console.error(err)
    })
  }

  const ClosestCampus = () => {
    getClosestCampus({ brand_id }).then((res) => {
      if (res.status === 200) {
        const data = res.data.resource.map((item) => ({ value: item.id, label: item.campus_name }));
        setCampusData(data);
      }
    }).catch((errr) => {
      console.log(errr);
    })
  }

  useEffect(() => {
    AssistanceRequired();
    ClosestCampus();
  }, []);


  const searchAssistanceSchema = Yup.object().shape({
    assistance_required: Yup.string().required("Please select assistance required"),
    closest_campus: Yup.string().required("Please select campus"),
    // cell_number: Yup.string().required("Please enter cell number"),
    // email: Yup.string().email("Enter a valid email").required("Please enter email"),
    message: Yup.string().required("Please enter message"),
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      assistance_required: "",
      closest_campus: "",
      closest_campus_label: "",
      // cell_number: "",
      // email: "",
      message: ""
    },
    validationSchema: searchAssistanceSchema,
    onSubmit: (values, { resetForm }) => {
      const sendData = {
        assistance_required: values.assistance_required,
        closest_campus: values.closest_campus,
        // cell_number: values.cell_number,
        // email: values.email,
        message: values.message,
        date_add: moment(new Date).format("YYYY-MM-DD HH:mm:ss")
        // "2023-03-31 12:13:07"
      }

      addAssistanceRequest(sendData).then((res) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: 'Search Assistance Request Submitted',
          timer: 3000,
          onOpen: function () {
            Swal.showLoading();
          },
        }).then((result) => {
          resetForm();
        })
      }).catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: err.response.data.message,
        });
      })
    }
  });

  const { values, errors, touched, setValues, handleSubmit, handleChange } = formik;


  return (
    <div className="row">
      <div className="col-md-6">
        <div class="card card-profile-info-card mb-4">
          <div class="card-body ">
            <FormikProvider value={formik}>
              <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div class="form-group-blk mb-3">
                      <label>Assistance Required</label>
                      <div className="input-group-2" title="Assistance Required">
                        <Select
                          className={"form-control custom-select-box " + (touched.assistance_required && errors.assistance_required ? " is-invalid" : "")}
                          name="assistance_required"
                          onChange={(item) => { setValues({ ...values, assistance_required: item.value }) }}
                          value={values.assistance_required}
                          // title="Assistance Required"
                          placeholder={values.assistance_required ? values.assistance_required : "Assistance Required"}
                          options={assistanceData}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div class="form-group-blk mb-3">
                      <label>Closest Campus</label>
                      <div className="input-group-2" title="Closest Campus">
                        <Select
                          className={"form-control custom-select-box " + (touched.closest_campus && errors.closest_campus ? " is-invalid" : "")}
                          name="closest_campus"
                          placeholder={values.closest_campus_label ? values.closest_campus_label : "Select Campus"}
                          // title="Closest Campus"
                          options={campusData}
                          value={values.closest_campus}
                          onChange={(item) => { setValues({ ...values, closest_campus: item.value, closest_campus_label: item.label }) }}
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-md-6">
                    <div class="form-group-blk mb-3">
                      <label>Cell Number</label>
                      <div className="input-group-2">
                        <input
                          type="number"
                          className={"form-control" + (touched.cell_number && errors.cell_number ? " is-invalid" : "")}
                          placeholder="Enter cell number"
                          name="cell_number"
                          title="Cell number"
                          value={values.cell_number}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div class="form-group-blk mb-3">
                      <label>Email</label>
                      <div className="input-group-2">
                        <input
                          type="email"
                          className={"form-control" + (touched.email && errors.email ? " is-invalid" : "")}
                          placeholder="Enter Email"
                          title="Email"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div> */}
                  <div className="col-md-12">
                    <div class="form-group-blk mb-3">
                      <label>Message*</label>
                      <div className="input-group-2">
                        <textarea
                          name="message"
                          title="Message"
                          value={values.message}
                          onChange={handleChange}
                          className={"form-control" + (touched.message && errors.message ? " is-invalid" : "")}
                          placeholder="Enter Message here.."
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 ">
                    {Object.keys(errors).map((key) => (<div className="invalid-feedback d-block">{errors[key]}</div>))}
                  </div>
                  <div className="col-md-12 mt-3 text-center">
                    <button type="submit" class="btn btn-primary" title="Submit Assistance Request"><i class="fal fa-paper-plane"></i> Submit Assistance Request</button>
                  </div>
                </div>
              </Form>
            </FormikProvider>
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="header-info-box mb-4">
          <h4>How can we help you?</h4>
          <p>
            Please give as much information as you can to facilitate and help us give you
            the correct information. Remember to include the course/class details related
            to this query.
          </p>
        </div>
        {/* <div class="accordion" id="accordionExample">
          <div class="card">
            <div class="card-header" id="headingOne">
              <h2 class="mb-0">
                <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  Collapsible Group Item #1
                </button>
              </h2>
            </div>

            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
              <div class="card-body">
                Some placeholder content for the first accordion panel. This panel is shown by default, thanks to the <code>.show</code> class.
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingTwo">
              <h2 class="mb-0">
                <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  Collapsible Group Item #2
                </button>
              </h2>
            </div>
            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
              <div class="card-body">
                Some placeholder content for the second accordion panel. This panel is hidden by default.
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingThree">
              <h2 class="mb-0">
                <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                  Collapsible Group Item #3
                </button>
              </h2>
            </div>
            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
              <div class="card-body">
                And lastly, the placeholder content for the third and final accordion panel. This panel is hidden by default.
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default SearchAssistance;
