import React from "react";
import Skeleton from "react-loading-skeleton";

const SkeletonSubjectHeadBox = () => {
  return (
    <>
      <div className="lecture-detail-top d-flex justify-content-between">
        <div className="lecture-detail-top-left">
          <div className="lecture-bx-info  d-flex">
            <div className="icon" style={{ backgroundColor: "none" }}>
              <Skeleton height={100} />
            </div>
            <div className="lecture-bx-info-rgt">
              <h3>
                <Skeleton height={20} />
              </h3>
              <p>
                {/* <i className="fal fa-calendar-alt"></i> */}
                <Skeleton height={20} width={200} />
              </p>
            </div>
          </div>
        </div>
        <div className="lecture-detail-top-right d-flex">
          <ul className="lec-dtl-rgt-ul m-0 d-flex list-unstyled align-items-center">
            <li>
              <div className="msg-icon">
                <i className="fal fa-comment-alt-lines"></i>
              </div>
            </li>
            <li>
              <div className="your-complete-date d-flex bg-white">
                <div className="yr-com-lft">
                  {/* <i className="fal fa-calendar-alt"></i> */}
                </div>
                <div className="yr-com-rgt">
                  {/* <small>Your Completion Date</small> */}
                  <p className="m-0">
                    <Skeleton height={20} width={200} />
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="sign-off-pending bg-white d-flex">
                <div className="sign-off-lft">
                  <Skeleton height={20} />
                </div>
                <div className="sign-off-rgt">
                  <p className="m-0">
                    <Skeleton height={20} width={200} />
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
export default SkeletonSubjectHeadBox;
