import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { GetMyCertificateService } from "../../services/ProfileService";
import { download } from "../../utils/FileDownload";
import ResultWithHeldCard from "../common/ResultWithHeldCard";
import SkeletonTicketList from "../../loaders/SkeletonTicketList";
import Str from "../common/Str";

const downloadFile = async (absolutePath, fileName, index) => {
  let value = await download(absolutePath, fileName);
};

const Certificates = () => {
  const [search, setSearch] = useState("");
  const [certificates, setCertificate] = useState([]);
  const [renderTable, setRenderTable] = useState(true);
  const isWithHeldStatus = localStorage.getItem("resultWithHeld");

  useEffect(() => {
    setRenderTable(false);
    GetMyCertificateService().then((res) => {
      const respData = res.data.certificates;
      setCertificate(respData);
    }).catch(err=>{
      console.log(err)
    }).finally(()=>{
      setRenderTable(true);
    });
  }, []);

  const columns = [
    {
      name: "Certificate Name",
      selector: "certificate_name",
      sortable: true,
      wrap: true,
      cell: (row) => <div class="assessment-01">
        <p class="as-text-blue"><span className="overflow-ellipsis2"><a href={row.certificate} target="_blank" title={row.certificate_name}>{row.certificate_name}</a></span></p>
      </div>
    },
    {
      name: "Your Certificate",
      // selector: "certificate",
      selector: "certificate_original_name",
      sortable: true,
      wrap: true,
    },
    {
      name: "Action",
      sortable: false,
      cell: (row) => (
        <div className="assessment-08 advSearch-action-con">
          <div className="as-buttons">
          <a
            href={row.certificate}
            target="_blank"
            className="btn btn-primary rounded-circle view-btn"
            title="View"
          >
            <i className="fal fa-eye"></i>
          </a>
          </div>
          <div className="as-buttons">
          <a
            target="_blank"
            onClick={() =>
              downloadFile(row.certificate, row.certificate_original_name, row.id)
            }
            className="btn btn-primary rounded-circle download-btn"
            title="Download"
          >
            <i className="fal fa-download"></i>
          </a>
          </div>
        </div>
      ),
    },
  ];

  const resetFilter = () => {
    setSearch("")
  }

  const handleSearchFilter = (e) => {
    setSearch(e.target.value);
  }

  const dataToRender = () =>{
    let updatedData = [];
    let data = [...certificates];

    if(search.length){
      let tempName = data.filter(item =>{
        let includes = item?.certificate_name.toLowerCase().includes(search.toLowerCase());
        if(includes){
          return includes
        }else{
          return null;
        }
      })
      let tempOriginName = data.filter(item =>{
        let includes = item?.certificate_original_name.toLowerCase().includes(search.toLowerCase());
        if(includes){
          return includes
        }else{
          return null;
        }
      })

      const finalData = [...tempName, ...tempOriginName];

      let unique = [...new Set(finalData)];

      updatedData = unique;      
    }else{
      updatedData = data;
    }

    return updatedData;
  }

  return (
    <>
      {isWithHeldStatus == "true" ? (
        <ResultWithHeldCard />
      ) : (
        <div className="custom-table-div filter-search-icon card card-table-custom">
          <div className="search-filter-div">
            <div className="search-filter-div-left">
                <div className="system-administration-table table-responsive">
                    <div className="table-responsive-div">
                        <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                            <div id="assessment-table-main_filter" className="dataTables_filter">
                                <label for="filterDataTable" className="icon"></label>
                                <input
                                    type="text"
                                    name="filterDataTable"
                                    className="filter-text"
                                    placeholder="Search"
                                    onChange={handleSearchFilter}
                                    value={search}
                                />
                                <div className="filter-eff filter-data-btn">
                                    <button className="filter-buttons">
                                        <i className="fal fa-filter"></i>
                                    </button>
                                </div> 
                            </div>
                        </div>
                    </div>
                    <div className="filter-button-group">
                        <div className="reset-btn-group">
                            <div className="button-reset">
                                <button className="btn btn-primary" onClick={resetFilter} title="Reset">
                                    <i className="fal fa-redo"></i>Reset
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
          </div>                
          <DataTable
            columns={columns}
            data={dataToRender()}
            progressPending={!renderTable}
            pagination={true}
            defaultSortField="certificate_name"
            defaultSortAsc={false}
            highlightOnHover={false}
            progressComponent={<SkeletonTicketList />}
            noDataComponent={Str.noRecord}
          />
        </div>        
      )}
    </>
  );
};

export default Certificates;
