import React, { useState } from "react";
import ReReSubject from "./ReReSubject";

function ReSubmission(props) {
  const {
    formik,
    setparentSubjectReSubLength,
    parentSubjectReSubLength,
    reRegSubject,
  } = props;

  const [fields, setFields] = useState([{ subject: "" }]);

  function handleAdd() {
    const values = [...fields];
    values.push({ subject: "" });
    setFields(values);
    props.setparentSubjectReSubLength(values);
  }

  function handleRemove(i) {
    const values = [...fields];
    values.splice(i, 1);
    setFields(values);
    props.setparentSubjectReSubLength(values);
  }

  function handleSubjectChange(i, event) {
    const values = [...fields];
    values[i].subject = event.target.value;
    setFields(values);
    props.setparentSubjectReSubLength(values);
  }

  return (
    <>
      {formik.values.subjectReSub_.map((item, idx) => (
        <React.Fragment>
          <div className="row">
            <div className="col-sm-6">
              <select
                className="form-control"
                name={`subjectReSub_[${idx}].subject`}
                onChange={(e) => handleSubjectChange(idx, e)}
                onBlur={formik.handleBlur}
                key={idx}
                value={formik.values.subjectReSub_[idx].subject}
                disabled="disabled"
              >
                <option value="">---</option>
                <ReReSubject reRegSubject={reRegSubject} />
              </select>

              {formik.touched.subjectReSub_ &&
              formik.errors.subjectReSub_ &&
              formik.touched.subjectReSub_[idx] &&
              formik.errors.subjectReSub_[idx] &&
              formik.touched.subjectReSub_[idx].subject &&
              formik.errors.subjectReSub_[idx].subject ? (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  {formik.errors.subjectReSub_[idx].subject}
                </div>
              ) : null}
            </div>
            {/* <div className="col pl-0">
              <a
                href
                className="btn btn-danger"
                onClick={() => handleRemove(idx)}
              >
                <i className="fal fa-trash-alt"></i>
              </a>
            </div> */}
          </div>
        </React.Fragment>
      ))}
      {/* <React.Fragment>
        <div className="form-group m-0">
          <a
            href
            className="btn btn-link p-0 btn-additional"
            onClick={() => handleAdd()}
          >
            <i className="fal fa-plus"></i> Add additional line item
          </a>
        </div>
      </React.Fragment> */}
    </>
  );
}

export default ReSubmission;
