import { render } from "@testing-library/react";
import React, { Component } from "react";
import $ from "jquery";
import Header from "../components/common/Header";
import SideBar from "../components/common/SideBar";
import ReRegistration from "../components/re_registration/ReRegistration";

class ReRegistrationPage extends Component {
  render() {
    const id = this.props.match.params.id;
    return (
      <>
        <Header></Header>
        <div className="content-wrapper d-flex flex-wrap">
          <SideBar></SideBar>
          <div className="sidebar-right">
            <ReRegistration id={id} />
          </div>
        </div>
      </>
    );
  }
}

export default ReRegistrationPage;
