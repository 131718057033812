import React, { useState, useEffect } from "react";
import Select from "react-select";
import Ebsco from "../../assets/images/EBSCO_logo.svg";
import Google from "../../assets/images/Google-Scholar.png";
import JSTOR from "../../assets/images/JSTOR.png";

function ResearchAssistance(props) {
  const [inputValue, setValue] = useState("");
  const [selectedValue, setSelectedValue] = useState(null);

  useEffect(() => {

  }, []);

  return (
    <div class="collapse-style-2 accordion" id="accordionExample">
      <div class="card">
        <div class="card-header" id="headingOne">
          <h2 class="mb-0">
            <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
              <div className="tabs-style-info-box">
                <div className="tabs-style-info-box-icon">
                  <img src={Ebsco} alt="Ebsco logo" />
                </div>
                <div className="tabs-style-info-box-content">
                  <p>EBSCO host is an intuitive online research platform with quality databases and search feature</p>
                </div>
              </div>
              <i class="fal fa-chevron-down"></i>
            </button>
          </h2>
        </div>

        <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
          <div class="card-body">
            Click <a href="https://www.ebsco.com/products/ebscohost-research-platform" target="_blank"> here </a>to search on EBSCOhost
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingTwo">
          <h2 class="mb-0">
            <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              <div className="tabs-style-info-box">
                <div className="tabs-style-info-box-icon">
                  <img src={Google} alt="Ebsco logo" />
                </div>
                <div className="tabs-style-info-box-content">
                  <p>Google Scholar provides a simple way to broadly search for scholarly literature. Search across a wide variety of disciplines and sources</p>
                </div>
              </div>
              <i class="fal fa-chevron-down"></i>
            </button>
          </h2>
        </div>
        <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
          <div class="card-body">
          Click <a href="https://scholar.google.com/" target="_blank"> here </a>to search on Google Scholar
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="headingThree">
          <h2 class="mb-0">
            <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
              <div className="tabs-style-info-box">
                <div className="tabs-style-info-box-icon">
                  <img src={JSTOR} alt="Ebsco logo" />
                </div>
                <div className="tabs-style-info-box-content">
                  <p>JSTOR</p>
                </div>
              </div>
              <i class="fal fa-chevron-down"></i>
            </button>
          </h2>
        </div>
        <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
          <div class="card-body">
            Click <a href="https://www.jstor.org/" target="_blank"> here </a>to search on JSTOR
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResearchAssistance;
