export const Settings = {
  "calendar": { "defaultView": "list" }
  // "notifications": {"push": true,"email": true},
  // "onlineClasses": {"reminder": false}
};

// Acces of Pages for student on On Hold & In Active Students
export const InActiveStudentAcess = [
  'mytickets',
  'myticketsdetail'
];

// Acces of Pages for student on re-registration
export const ReRegAccess = [
  'reregistration',
  'profile',
  'reregistrationSchool'
];

// Acces of Pages for student on exam-registration
export const ExamAccess = [
  'myexam',
  'profile',
  'myexamdetails'
];

export const gdcVariables = {
  brand: "GDC",
  assets: {
    stylesheet: "./assets/css/custom.css",
    logo: "https://designcenter.co.za/favicon.png",
    favicon: "https://designcenter.co.za/favicon.png",
  },
  metadata: {
    title: "myGDC Student Portal",
    description: "At GDC; we believe in a future of possibilities and abundance. A future filled with excellence made possible by a new generation of visionaries and leaders. It's in our programs as well as our people. Excellence rubs off onto our learners, it lives on campus and online.",
    keywords: "private education, education, study, studies, online learning, study online, virtual class, onlin",
  }
};

export const aieVariables = {
  brand: "AIE",
  assets: {
    stylesheet: "./assets/css/custom.css",
    logo: "/logo192.png",
    favicon: "/favicon.ico",
  },
  metadata: {
    title: "myAIE Student Portal",
    description: "At the Academic Institute of Excellence; we believe in a future of possibilities and abundance. A future filled with excellence made possible by a new generation of visionaries and leaders. Excellence is in our name! It's in our programs as well as our people. Excellence rubs off onto our learners, it lives on campus and online.",
    keywords: "private education, education, study, studies, online learning, study online, virtual class, onlin",
  }
}
