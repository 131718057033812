import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { getAllCampus, getClosestCampus } from "../../services/StudentHubService";
import { BASE_URL } from "../../utils/Constants";

function getImageURL(path) {
    if (path && path.length) {
        const image_path = BASE_URL + "/" + path.toString().replace("/home/myaie/public_html/", "")
        return image_path
    }
    return null;
}

export default function CampusLibraries() {
    const [campus, setCampus] = useState([]);
    const [noCampus, setNoCampus] = useState('');
    const [selectedCampus, setSelectedCampus] = useState({});
    const brand_id = useSelector((state) => state.brand && state.brand.configuration && state.brand.configuration.id ? state.brand.configuration.id : null);

    function getCampusData() {
        getClosestCampus({ brand_id }).then((res) => {
            if (res.status === 200) {
                const data = res.data.resource;
                setCampus(data);
                if (data.length) {
                    setSelectedCampus(data[0]);
                }
                else {
                    setNoCampus('Location not available')
                }
            }
        }).catch((err) => {
            console.error(err);
            setNoCampus('Location not available')
        });
    }

    useEffect(() => {
        getCampusData()
    }, [])

    function handleChangeCampus(campus) {
        setSelectedCampus(campus);
    }

    return (
        <div className="row">
            {noCampus ? <div className="no-campus-con">{noCampus}</div>
                :
                <div className="col-md-4">
                    {campus.map((item, index) => {
                        return (
                            <div key={index} className={"card campus-lib-card" + (selectedCampus?.id == item?.id ? " active " : "")} onClick={() => { handleChangeCampus(item) }}>
                                <h4 className="landing-heading no-margin heading_color_dynamic">
                                    <span>{item.campus_name.split(" ")[0]} </span> {item.campus_name.split(" ")[1]}
                                </h4>
                                <p dangerouslySetInnerHTML={{ __html: item.campus_address }} />
                                <div className="week-days">
                                    <div className="day">
                                        <h4>Mon</h4>
                                        <p>{item?.open_time != "" && item?.open_time ? item?.open_time : "NA"}</p>
                                        <p>{item?.close_time != "" && item?.close_time ? item?.close_time : "NA"}</p>
                                    </div>
                                    <div className="day">
                                        <h4>Tue</h4>
                                        <p>{item?.open_time != "" && item?.open_time ? item?.open_time : "NA"}</p>
                                        <p>{item?.close_time != "" && item?.close_time ? item?.close_time : "NA"}</p>
                                    </div>
                                    <div className="day">
                                        <h4>Wed</h4>
                                        <p>{item?.open_time != "" && item?.open_time ? item?.open_time : "NA"}</p>
                                        <p>{item?.close_time != "" && item?.close_time ? item?.close_time : "NA"}</p>
                                    </div>
                                    <div className="day">
                                        <h4>Thur</h4>
                                        <p>{item?.open_time != "" && item?.open_time ? item?.open_time : "NA"}</p>
                                        <p>{item?.close_time != "" && item?.close_time ? item?.close_time : "NA"}</p>
                                    </div>
                                    <div className="day">
                                        <h4>Fri</h4>
                                        <p>{item?.open_time != "" && item?.open_time ? item?.open_time : "NA"}</p>
                                        <p>{item?.close_time != "" && item?.close_time ? item?.close_time : "NA"}</p>
                                    </div>
                                    <div className="day disabled">
                                        <h4>Sat</h4>
                                        <p>Closed</p>
                                    </div>
                                    <div className="day disabled">
                                        <h4>Sun</h4>
                                        <p>Closed</p>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                    }
                </div>
            }
            <div className="col-md-8">

                {getImageURL(selectedCampus.campus_image) ?
                    <div className="map-image-container">
                        <a href={selectedCampus.url} target="_blank" rel="noopener noreferrer">
                            <img src={getImageURL(selectedCampus.campus_image)} alt="Map not available" />
                        </a>
                    </div>
                    : <div className="campus-image-div">
                        <p></p>

                    </div>}

            </div>
        </div>
    );
}