import React, { useState, useEffect, memo, useRef } from "react";
import VideoPlayer from "./VidoePlayer";
import QuizeImage from "../../assets/images/ic-1.png";
import {
  updateVideoTimeService,
  getVideoTimeService,
  getAssembliesItem,
  getAssembliesItemAdmin,
  fileTrackingService,
  updateWatchCountService,
} from "../../services/AssembliesService";
import { download } from "../../utils/FileDownload";
import { image_url } from "../../utils/ImageUrl";
import { useParams } from "react-router-dom";
import { ADMIN_PASSWORD } from "../../utils/Constants";
import { useDispatch, useSelector } from "react-redux";
import { clearVideoDuration, UpdateLessonType, updateVideoDuration, updateVideoPlayer } from "../../store/actions";
import SkeletonSubject from "../../loaders/SkeletonSubject";
import ViewQuiz from "./quiz/ViewQuiz";
import ReviewQuiz from "./quiz/ReviewQuiz";
import QuizDescription from "./quiz/QuizDescription";
import ViewQuestion from "./quiz/ViewQuestion";
import { reverse } from "@tensorflow/tfjs";
import { TrimText } from "../common/TrimText";
let obj = {};

function AssembliesItems(props) {
  const {
    assemblyItem,
    lesson,
    currentVideoId,
    buttonCheckedStatus,
    documentId,
    setQuizId,
    QuizId,
    setshow,
    setrId,
    show,
    activeTab,
    showactiveTab,
    rId,
    // showNext,
    nextActivity,
    getSubContentMethod,
    lessonindex,
    isAdmin,
    refreshProgress
  } = props;

  const dispatch = useDispatch();
  const currentAssembly = useSelector(state => state.learningMaterial.currentAssembly)
  const currentActivity=useSelector(state=>state.learningMaterial.current_activity)

  const lesson_type = useSelector(state => state.learningMaterial.active_lesson_type);
  const videoPlayerShow = useSelector(state => state.learningMaterial.videoPlayer);
  const lessonId = useSelector(state => state.learningMaterial.lessonId);
  const {password} = useParams();
  const [videoId, setVideoId] = useState(0);
  const [playing, setPlaying] = useState(false);
  const [watchedVideo, setWatchedVideo] = useState([]);
  const [watchTime, setWatchTime] = useState(lesson?.watch_in_m_s);
  const [watchPercentage, setWatchPercentage] = useState(lesson?.watch_percentage);
  const [isWatchedVideo, setIsWatchedVideo] = useState(lesson?.is_video_watched);
  const [alreadyWatched, setAlreadyWatched] = useState(lesson?.alreadyWatched ? true : false);
  const [buttonCheckedStatus1, setButtonCheckedStatus1] = useState(0);
  const [documentId1, setDocumentId1] = useState([]);
  const [currentVideoId1, setCurrentVideoId1] = useState(0);
  const [playerSeek, setPlayerSeek] = useState(0);
  const [playerPipMode, setPlayerPipMode] = useState(false);
  const [resumeBtn, setResumeBtn] = useState(
    lesson?.watch_percentage > 0 && lesson?.watch_percentage < 100 ? true : false
  );
  const [result, setresult] = useState();
  const [list, setlist] = useState([]);

  const[videoDuration, setVideoDuration]=useState(0)
  const [videoState, setVideoState] = useState({
    startDuration: 0,
    pauseDuration: 0,
  });

  useEffect(()=>{
    dispatch(clearVideoDuration())
  },[])

  useEffect(()=>{
    dispatch(updateVideoPlayer(false))
    setResumeBtn(false)
    props.parentSetCurrentVideoId("");
  },[lesson_type])

  const handleVideoTogglePIP = (player, lesson, videoId, action) => {
    // e.preventDefault()
    setPlayerPipMode(action);
  };
  const handleVideoStart = (player, lesson, videoId) => {
    setResumeBtn(true);
    setPlaying(true);
    setVideoId(videoId);
    setWatchedVideo([...watchedVideo, videoId]);

    //set current time of video to 0 on start video
    const watchedVd = 0;
    const duration = Math.round(player.getDuration());
    if(password!=ADMIN_PASSWORD){
      updateVideoTimeService(lesson, watchedVd, true, duration).then(
        (res) => {
          // setWatchTime(res.data[0].watchedTimeSecond);
          // setWatchPercentage(res.data[0].watch_percentage);
          if (parseFloat(res.data[0]?.watch_percentage) >= 95) {
            updateWatchCountService(lesson)
              .then((res) => {
                // props.parentGetLearningMaterialData(props.parentSubId);
                // props.subassembliesItems(props.parentSubId);
              })
              .catch((error) => { });
          } else if (parseFloat(res.data[0]?.watch_percentage) >= 99) {
            setResumeBtn(false);
          }
  
          //Set resume time of video
          player.seekTo(res.data[0].watchedTimeInSecond);
          setPlayerSeek(res.data[0].watchedTimeInSecond);
        }
      );
    }

    props.parentSetCurrentVideoId(videoId);
    getSubContentMethod();
   
  };


  const setCustomeCurrentVideoId = (videoId) => {
    setCurrentVideoId1(videoId);
  };
  
  const handleFileTracking = (itemId) => {
    fileTrackingService(itemId).then((res) => {
      setButtonCheckedStatus1(true);
      setDocumentId1([...documentId1, itemId]);
      // props.subassembliesItems(props.parentSubId);
      // props.parentGetLearningMaterialData(props.parentSubId);
      props.refreshProgress(props.lesson.module_id);
    });
    getSubContentMethod();
  };
  
  const handleVideoResume = (player, lesson, videoId, action) => {

    setResumeBtn(true);
    setVideoId(videoId);
    setWatchedVideo([...watchedVideo, videoId]);
    
    //get current time of video
    const watchedVd = Math.round(player.getCurrentTime());
    const duration = Math.round(player.getDuration());
    setVideoDuration(duration)
    if (action == false) {
      setVideoState((prevState) => ({
        ...prevState,
        pauseDuration: watchedVd,
        isPaused: true,
      }));

      getVideoTimeService(lesson).then((res) => {
        // setWatchTime(res.data[0].watchedTimeSecond);
        // setWatchPercentage(res.data[0].watch_percentage);

        //Set resume time of video
        //player.seekTo(res.data[0].watchedTimeInSecond);
        setPlayerSeek(res.data[0].watchedTimeInSecond);
        if (parseFloat(res.data[0]?.watch_percentage) >= 95) {
          updateWatchCountService(lesson)
            .then((res) => {
              // props.parentGetLearningMaterialData(props.parentSubId);
              // props.subassembliesItems(props.parentSubId);
            })
            .catch((error) => { });
        } else if (parseFloat(res.data[0]?.watch_percentage) >= 99) {
          setResumeBtn(false);
        }
      });
    } else {
      const totalPauseDuration = Math.round(player.getCurrentTime()) - videoState.pauseDuration;
      setVideoState((prevState) => ({
        ...prevState,
        startDuration: totalPauseDuration,
        isPaused: false,
      }));

      let updateVideoTime = true;
      if(password!=ADMIN_PASSWORD){
        updateVideoTimeService(
          lesson,
          watchedVd,
          updateVideoTime,
          duration
        ).then((res) => {
          // setWatchTime(res.data[0].watchedTimeSecond);
          // setWatchPercentage(res.data[0].watch_percentage);
          if (parseFloat(res.data[0]?.watch_percentage) >= 95) {
            updateWatchCountService(lesson)
              .then((res) => {
                // props.subassembliesItems(props.parentSubId);
                // props.parentGetLearningMaterialData(props.parentSubId);
              })
              .catch((error) => { });
          } else if (parseFloat(res.data[0]?.watch_percentage) >= 99) {
            setResumeBtn(false);
          }
          //Set resume time of video
          // player.seekTo(res.data[0].watchedTimeInSecond);
          setPlayerSeek(res.data[0].watchedTimeInSecond);
        });
      }
    }
    setPlaying(action);
    
    props.parentSetCurrentVideoId(videoId);
    getSubContentMethod();
  };

  const handleVideoInProgress = (player, lesson, videoId) => {
    // set(prev => !prev)
    // e.preventDefault()
    setResumeBtn(true);
    setVideoId(videoId);
    setWatchedVideo([...watchedVideo, videoId]);

    //get current time of video
    const watchedVd = Math.round(player.getCurrentTime());
    const duration = Math.round(player.getDuration());

    let updateVideoTime = true;
    if(password!=ADMIN_PASSWORD){
      updateVideoTimeService(
        lesson,
        watchedVd,
        updateVideoTime,
        duration
      ).then((res) => {
        setWatchPercentage(res.data[0]?.watch_percentage);
        if (parseFloat(res.data[0]?.watch_percentage) >= 95) {
          updateWatchCountService(lesson)
            .then((res) => {
              // props.parentGetLearningMaterialData(props.parentSubId);
              props.subassembliesItems(props.parentSubId);

            })
            .catch((error) => { });
        } else if (parseFloat(res.data[0]?.watch_percentage) >= 99) {
          setResumeBtn(false);
        }
        // props.parentGetLearningMaterialData(props.parentSubId);
        // props.subassembliesItems(props.parentSubId);
        props.refreshProgress(props.lesson.module_id);
        setPlayerSeek(res.data[0].watchedTimeInSecond);
      });
    }

    // props.parentSetCurrentVideoId(videoId);
    // getSubContentMethod();
   
  };
  
  const handleVideoRewind = (player, lesson, videoId) => {
    // e.preventDefault()
    setResumeBtn(true);
    setVideoId(videoId);
    setWatchedVideo([...watchedVideo, videoId]);

    //get current time of video
    const watchedVd = Math.round(player.getCurrentTime());
    const duration = Math.round(player.getDuration());

    let updateVideoTime = true;
    if(password!=ADMIN_PASSWORD){
      updateVideoTimeService(
        lesson,
        watchedVd,
        updateVideoTime,
        duration
      ).then((res) => {
        setWatchPercentage(res.data[0]?.watch_percentage);
        if (parseFloat(res.data[0]?.watch_percentage) >= 95) {
          updateWatchCountService(lesson)
            .then((res) => {
              // props.parentGetLearningMaterialData(props.parentSubId);
              props.subassembliesItems(props.parentSubId);
            })
            .catch((error) => { });
        } else if (parseFloat(res.data[0]?.watch_percentage) >= 99) {
          setResumeBtn(false);
        }
        // props.parentGetLearningMaterialData(props.parentSubId);
        // props.subassembliesItems(props.parentSubId);
        props.refreshProgress(props.lesson.module_id);
      });
    }

    props.parentSetCurrentVideoId(videoId);
    getSubContentMethod();
   
  };
  

  const handleVideoReady = (player, lesson, videoId) => {
    setResumeBtn(true);
    setPlaying(true);
    setVideoId(videoId);
    setWatchedVideo([...watchedVideo, videoId]);
    const watchedVd = 0;
    let updateVideoTime = false;
    setAlreadyWatched(true);
    if(password!=ADMIN_PASSWORD){
      updateVideoTimeService(lesson, watchedVd, updateVideoTime).then(
        (res) => {
          setWatchTime(res.data[0].watchedTimeSecond);
          setWatchPercentage(res.data[0]?.watch_percentage);
          //  player.seekTo(res.data[0].watchedTimeInSecond);
          if (parseFloat(res.data[0]?.watch_percentage) >= 95) {
            updateWatchCountService(lesson)
              .then((res) => {
                // props.parentGetLearningMaterialData(props.parentSubId);
                // props.subassembliesItems(props.parentSubId);
              })
              .catch((error) => { });
          } else if (parseFloat(res.data[0]?.watch_percentage) >= 99) {
            setResumeBtn(false);
          }
          // props.parentGetLearningMaterialData(props.parentSubId);
          // props.subassembliesItems(props.parentSubId);
          props.refreshProgress(props?.lesson?.module_id);
          setPlayerSeek(res.data[0].watchedTimeInSecond);
        }
      );
    }
    props.parentSetCurrentVideoId(videoId);
   
  };

  const downloadFile = async (absolutePath, fileName, itemId) => {
    if (isAdmin != 1) {
      props.parentFileTracking(itemId);
    }
    let value = await download(absolutePath, fileName);
  };

  const handleViewItem = async (itemId) => {
    if (isAdmin != 1) {
      props.parentFileTracking(itemId);
    }    
  };

  // const handleItem = (item) => {
  //   if (props.isAdmin == 1) {
  //     getAssembliesItemAdmin(item).then((res) => {
  //       setsubAssembliesItems(res.data);
  //       setModuleId(item);
  //     });
  //   } else {
  //     getAssembliesItem(item).then((res) => {
  //       setsubAssembliesItems(res.data);
  //       setModuleId(item);
  //     });

  //   }

  // };

  const fetchIcon = (path) => {
    let ext = path?.split('.')
    switch (ext[ext?.length - 1]) {
      case 'docx':
        return 'fal fa-file-word';
        break;
      case 'zip':
        return 'fal fa-file-archive';
        break;
      case 'pdf':
        return 'fal fa-file-pdf';
        break;
      case 'xlsx':
        return 'fal fa-file-excel';
        break;
      case 'jpg':
      case 'jpeg':
      case 'png':
        return 'fal fa-file-image';
        break;
      case 'txt':
        return 'fal fa-file-alt';
        break;
      case 'pptx':
        return 'fal fa-file-powerpoint';
        break;
      default:
        return 'fal fa-file';
        break;
    }
  }


  return (
    <>
    <li key={lesson.item_id}>
        <div className={"sub-body" + (lesson.item_type === 2 ? ' video-sec' : '')}>
          {currentActivity == lesson.item_id && (lesson_type == 2 || lesson.item_type === 2) ? (
            <VideoPlayer
              isAdmin={isAdmin}
              ParentResumeBtn={resumeBtn}
              ParentHandleVideoStart={handleVideoStart}
              playing={playing}
              videoId={videoId}
              lessonindex={lessonindex}
              loopId={lesson.item_id}
              assemblyName={lesson.item_name}
              assemblyDescription={lesson.item_desc}
              videoType={lesson.video_type}
              url={lesson.youtube_url}
              item_url={lesson.item_url}
              parentRefId={`${currentAssembly}_${lesson.item_id}`}
              watchedVideo={watchedVideo}
              video_duration={lesson.video_duration}
              ParentHandleVideoResume={handleVideoResume}
              ParentHandleVideoInProgress={handleVideoInProgress}
              ParentHandleVideoReady={handleVideoReady}
              ParentHandleVideoRewind={handleVideoRewind}
              watch_in_m_s={watchTime}
              watchPercentage={watchPercentage}
              isWatchedVideo={isWatchedVideo}
              alreadyWatched={alreadyWatched}
              currentVideoId={currentVideoId}
              playerSeek={playerSeek}
              ParentPlayerPipMode={playerPipMode}
              ParentHandleVideoTogglePIP={handleVideoTogglePIP}
              lessonItemId={lesson.item_id}
              assembliesItems={assemblyItem}
              videoState={videoState}
              setPlayerSeek={setPlayerSeek}
              videoDuration={videoDuration}
            />
            // console.log("lesson.videoType",lesson)
          ) : null}
          {currentActivity == lesson.item_id && (!videoPlayerShow && lesson.item_type == 8) ? (
            <div className="lesson__content">
              <div className="card-list-inner d-flex " style={{paddingBottom:"20px"}}>
                <div className="sub-header">
                  <p className="label__lesson__title">
                    {lesson.item_name}
                  </p>
                  <p className="m-0">{new DOMParser().parseFromString(
                    lesson?.item_desc,
                    "text/html"
                  ).body.textContent}</p>
                </div>
              </div>
              <div className="lesson__detail__doc">File </div>
              {lesson?.all_multiple_documents?.map((item, index) => 
                <div className="lesson__card__container" style={{margin:"10px 0"}}>
                <div className="lesson__file__icon">
                  {(() => {
                    const documentExt = item?.docs_path?.split(".").pop();
                    // const uploadLinkExt = lesson?.upload_link?.split(".").pop();
                    const fileExt = documentExt 
                    // || uploadLinkExt;
                    return fileExt ? (
                      {
                        // Document icons
                        doc: <i title="Document" className="fal fa-file-word"></i>,
                        docx: <i title="Document" className="fal fa-file-word"></i>,
                        pdf: <i title="Document" className="fal fa-file-pdf"></i>,
                        ppt: <i title="Document" className="fal fa-file-powerpoint"></i>,
                        xlsx: <i title="Document" className="fal fa-file-excel"></i>,
                        zip: <i title="Document" className="fal fa-file-archive"></i>,

                        // Image icons (with more formats)
                        jpg: <i title="Image" className="fal fa-image"></i>,
                        jpeg: <i title="Image" className="fal fa-image"></i>,
                        png: <i title="Image" className="fal fa-image"></i>,
                        gif: <i title="Image" className="fal fa-image"></i>,
                        svg: <i title="Image" className="fal fa-image"></i>,
                        bmp: <i title="Image" className="fal fa-image"></i>,
                        tiff: <i title="Image" className="fal fa-image"></i>,
                        webp: <i title="Image" className="fal fa-image"></i>,
                        ico: <i title="Image" className="fal fa-image"></i>,
                        jfif: <i title="Image" className="fal fa-image"></i>,  // Added JFIF support

                        // Add more formats if necessary
                      }[fileExt] || (
                        <>
                          {/* Default generic file icon */}
                          <i title="File" className="fal fa-file"></i>
                        </>
                      )
                    ) : null;
                  })()}
                </div>
                  <div className="card-list-inner d-flex card__doc__lesson">
                    <div className="card-list-left">
                      <p className="lead-text-sm m-0 lesson__doc__name">
                        {item?.docs_name.includes(": ") ? item?.docs_name.split(": ")[1] : item?.docs_name}
                        {/* {TrimText(lesson.item_name.includes(": ") ? lesson.item_name.split(": ")[1] : lesson.item_name,28)} */}
                      </p>
                      <div className="lesson__doc__details">
                        <p className="doc__size">Size:<span>{item?.docs_size || "NA"}</span></p>
                        <p className="doc__type">
                          Type:
                          {(() => {
                            const documentExt = item?.docs_path?.split(".").pop();
                            // const uploadLinkExt = item?.upload_link?.split(".").pop();
                            const fileExt = documentExt 
                            // || uploadLinkExt;

                            // Mapping file extensions to descriptive names
                            const fileTypes = {
                              doc: "Word Document",
                              docx: "Word Document",
                              pdf: "PDF Document",
                              ppt: "PowerPoint Document",
                              xlsx: "Excel Spreadsheet",
                              zip: "Zip Folder",
                              jpg: "Image File",
                              jpeg: "Image File",
                              png: "Image File",
                              gif: "Image File",
                              svg: "Image File",
                              bmp: "Image File",
                              tiff: "Image File",
                              webp: "Image File",
                              ico: "Image File",
                              jfif: "Image File",
                            };

                            // Return the type or 'Unknown Type' if not recognized
                            return <span>{fileExt ? fileTypes[fileExt] || 'Unknown Type' : 'Unknown Type'}</span>
                          })()}
                        </p>
                      </div>
                    </div>
                    <div className="card-list-right ml-auto">
                      <ul className="learningright-inner d-flex align-items-center">
                        <li className="border-left item-action">
                          <div className="media-gp">
                            <a
                              title="Download"
                              onClick={() => {
                                // lesson.document,
                                downloadFile(
                                  item?.absolutePath,
                                  item.docs_name + "." + (item.docs_path?.split(".").reverse()[0]),
                                  item.id
                                );
                              }}
                              className="btn btn-white-bordered btn__download"
                            >
                              <i className="fal fa-download"></i>Download
                            </a>
                          </div>
                        </li>
                        {item.buttonCheckedStatus ||
                          (buttonCheckedStatus &&
                            documentId.includes(item.id)) ? (
                          <li className="item-status">
                            <div className="header-status ">
                              <div className="status-gol bg-success">
                                <i className="fal fa-check"></i>
                              </div>
                            </div>
                          </li>
                        ) : (
                          ""
                        )}
                      </ul>
                    </div>
                  </div>
              </div>
                )}
            </div>
          ) : null}
          {currentActivity == lesson.item_id && (!videoPlayerShow && lesson.item_type === 4) ? (
            <div className="lesson__content">
              <div className="lesson__detail__doc">File Download</div>
              <div className="lesson__card__container">
                <div className="lesson__file__icon">
                  {(() => {
                    const documentExt = lesson?.documentName?.split(".").pop();
                    const uploadLinkExt = lesson?.upload_link?.split(".").pop();
                    const fileExt = documentExt || uploadLinkExt;
                    return fileExt ? (
                      {
                        // Document icons
                        docx: <i title="Document" className="fal fa-file-word"></i>,
                        doc: <i title="Document" className="fal fa-file-word"></i>,
                        pdf: <i title="Document" className="fal fa-file-pdf"></i>,
                        ppt: <i title="Document" className="fal fa-file-powerpoint"></i>,
                        xlsx: <i title="Document" className="fal fa-file-excel"></i>,
                        zip: <i title="Document" className="fal fa-file-archive"></i>,

                        // Image icons (with more formats)
                        jpg: <i title="Image" className="fal fa-image"></i>,
                        jpeg: <i title="Image" className="fal fa-image"></i>,
                        png: <i title="Image" className="fal fa-image"></i>,
                        gif: <i title="Image" className="fal fa-image"></i>,
                        svg: <i title="Image" className="fal fa-image"></i>,
                        bmp: <i title="Image" className="fal fa-image"></i>,
                        tiff: <i title="Image" className="fal fa-image"></i>,
                        webp: <i title="Image" className="fal fa-image"></i>,
                        ico: <i title="Image" className="fal fa-image"></i>,
                        jfif: <i title="Image" className="fal fa-image"></i>,  // Added JFIF support

                        // Add more formats if necessary
                      }[fileExt] || (
                        <>
                          {/* Default generic file icon */}
                          <i title="File" className="fal fa-file"></i>
                        </>
                      )
                    ) : null;
                  })()}
                </div>
                <div className="card-list-inner d-flex card__doc__lesson">
                  <div className="card-list-left">
                    <p className="lead-text-sm m-0 lesson__doc__name">
                    {lesson.item_name.includes(": ") ? lesson.item_name.split(": ")[1] : lesson.item_name}
                    {/* {TrimText(lesson.item_name.includes(": ") ? lesson.item_name.split(": ")[1] : lesson.item_name,28)} */}
                    </p>
                    <div className="lesson__doc__details">
                      <p className="doc__size">Size:<span>{lesson?.document_size || "NA"}</span></p>
                      <p className="doc__type">
                        Type:
                        {(() => {
                          const documentExt = lesson?.documentName?.split(".").pop();
                          const uploadLinkExt = lesson?.upload_link?.split(".").pop();
                          const fileExt = documentExt || uploadLinkExt;

                          // Mapping file extensions to descriptive names
                          const fileTypes = {
                            doc: "Word Document",
                            docx: "Word Document",
                            pdf: "PDF Document",
                            ppt: "PowerPoint Document",
                            xlsx: "Excel Spreadsheet",
                            zip: "Zip Folder",
                            jpg: "Image File",
                            jpeg: "Image File",
                            png: "Image File",
                            gif: "Image File",
                            svg: "Image File",
                            bmp: "Image File",
                            tiff: "Image File",
                            webp: "Image File",
                            ico: "Image File",
                            jfif: "Image File",
                          };

                          // Return the type or 'Unknown Type' if not recognized
                          return <span>{fileExt ? fileTypes[fileExt] || 'Unknown Type' : 'Unknown Type'}</span>
                        })()}
                      </p>
                    </div>
                  </div>
                  <div className="card-list-right ml-auto">
                    <ul className="learningright-inner d-flex align-items-center">
                      <li className="border-left item-action">
                        <div className="media-gp">
                          <a
                            title="Download"
                            onClick={() => {
                              // lesson.document,
                              downloadFile(
                                lesson?.upload_type === "library" ? image_url(lesson?.upload_link) : lesson.absolutePath,
                                lesson.item_name + "." + (lesson?.upload_type === "library" ? lesson?.upload_link?.split(".").reverse()[0] : lesson.document?.split(".").reverse()[0]),
                                lesson.item_id
                              );
                            }}
                            className="btn btn-white-bordered btn__download"
                          >
                            <i className="fal fa-download"></i>Download
                          </a>
                        </div>
                      </li>
                      {lesson.buttonCheckedStatus ||
                        (buttonCheckedStatus &&
                          documentId.includes(lesson.item_id)) ? (
                        <li className="item-status">
                          <div className="header-status ">
                            <div className="status-gol bg-success">
                              <i className="fal fa-check"></i>
                            </div>
                          </div>
                        </li>
                      ) : (
                        ""
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {/* Information */}
          {currentActivity == lesson.item_id && (lesson_type !==3 && !videoPlayerShow && lesson_type !== 2 &&  lesson.item_type === 7) ? (
            <div className="lesson__content">
              <div className="card-list-inner d-flex ">
                <div className="sub-header">
                  <p className="label__lesson__title">
                    {lesson.item_name}
                  </p>
                  <p className="m-0">{lesson.item_desc}</p>
                </div>
              </div>
            </div>
          ) : null}
      

          {currentActivity == lesson.item_id && (lesson_type !== 3 && !videoPlayerShow && lesson.item_type === 5) ? (
            <div className="lesson__content">
              <div className="lesson__detail__doc">
                <p className="lesson__link__name">{lesson.item_name}</p>
                <p className="lesson__link__desc">{lesson.item_desc}</p>
              </div>
              <div className="lesson__card__container">
                <div className="lesson__file__icon">
                  <i className="fal fa-external-link"></i>
                </div>
                <div className="card-list-inner d-flex card__doc__lesson">
                  <div className="card-list-left">
                    <p className="lead-text-sm m-0 lesson__doc__name">
                      {lesson.item_name}
                    </p>
                    <p className="link__url">
                      {lesson.link_url}
                    </p>
                  </div>
                  <div className="card-list-right ml-auto">
                    <ul className="learningright-inner d-flex align-items-center">
                      <li title="View" className="border-left item-action">
                        <div className="media-gp">
                          <a
                            title="View"
                            onClick={() => { handleViewItem(lesson.item_id) }}
                            href={lesson.link_url}
                            target="_blank"
                            className="btn btn-white-bordered btn__download"
                          >
                            <i className="fal fa-eye"></i> View
                          </a>
                        </div>
                      </li>
                      {lesson.buttonCheckedStatus ||
                        (buttonCheckedStatus &&
                          documentId.includes(lesson.item_id)) ? (
                        <li className="item-status">
                          <div className="header-status ">
                            <div className="status-gol bg-success">
                              <i className="fal fa-check"></i>
                            </div>
                          </div>
                        </li>
                      ) : (
                        ""
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {currentActivity == lesson.item_id && (lesson_type !== 3 && !videoPlayerShow && lesson?.item_type === 6) ? (
            <div className="d-flex flex-column">
            {(show?.desc || show?.attempt || show?.review) ? <div className="card-list-inner d-flex align-items-center w-100" style={{marginBottom:"15px"}}>
                <div className="card-list-left">
                  <div className="card-list-ic d-flex">
                    <div className="card-media card-red-1 icon__div">
                      <i className="fal fa-ballot"/>
                        {/* <img src={QuizeImage} alt="" /> */}
                    </div>
                    <div className="card-media-text d-flex align-items-center">
                      <p className="quize__name">
                        {lesson?.item_name}
                      </p>
                      {lesson?.quiz_percentage > 0 && (
                        <div className="progress border-left">
                          <div
                            className="progress-bar progress-bar-striped progress-bar-animated"
                            role="progressbar"
                            style={{ width: lesson?.quiz_percentage + "%" }}
                            aria-valuenow="25"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          >
                            {lesson?.quiz_percentage}%
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                </div>
                <div className="card-list-right ml-auto">
                  <ul className="learningright-inner d-flex align-items-center">

                    <li className="border-left">
                      <div className="media-gp">
                        {show?.review ? <a
                          onClick={() => setshow({list :true,
                            review: false,
                            attempt:false,
                            desc:true,
                            question:false})}
                          class="btn btn-white-bordered  btn-back m-0"
                        >
                          <i class="fal fa-angle-left"></i>Back
                        </a> : null}
                        {/* {lesson.button_status && (
                        <a
                          title="Result"
                          onClick={() => {
                            if (lesson.button_status) {
                              setQuizId(lesson.quiz_id);
                              setshow({ attempt: true });
                            }
                          }}
                          className="btn btn-white-bordered"
                          style={{
                            cursor: lesson.button_status
                              ? "pointer"
                              : "not-allowed",
                          }}
                        >
                          <i className="fal fa-sign-in-alt"></i> Result
                        </a>
                      )} */}

                        {(show?.review || show?.attempt) ? isAdmin == 1 ? (<button
                          title="Attempt"
                          disabled="disabled"
                          className="btn btn-white-bordered ml-2">
                          <i className="fal fa-undo-alt"></i> Attempt Again
                        </button>) :
                          <a
                            title="Attempt"
                            onClick={() => {
                              if (!lesson.maximum_attempts) {
                                setQuizId(lesson.quiz_id);
                                props.parentFileTracking(lesson.item_id);
                              }
                              setshow({review: false,
                                attempt:false,
                                desc:false,
                                question:true});
                            }}
                            className="btn btn-white-bordered"
                            style={
                              lesson.maximum_attempts
                                ? {
                                  cursor: "not-allowed",
                                  backgroundColor: "#d7dee8",
                                }
                                : {
                                  cursor: "pointer",
                                }
                            }
                          >
                            <i className="fal fa-undo-alt"></i> Attempt Again
                          </a>: null}

                        {(result > 0 && (show?.desc)) && (
                          <a
                            onClick={() =>  setshow({
                              attempt: true,
                              desc :false
                            })}
                            class="btn btn-white-bordered result__btn"
                          >
                            <i class="fal fa-clipboard-list"></i>Results List
                          </a>
                        )}
                      </div>
                    </li>
                    {lesson.buttonCheckedStatus ? (
                      <li className="item-status">
                        <div className="header-status ">
                          <div className="status-gol bg-success">
                            <i className="fal fa-check"></i>
                          </div>
                        </div>
                      </li>
                    ) : (
                      <li className="item-status">
                        <div className="header-status">
                          <div
                            className="status-gol bg-danger"
                            data-toggle="dropdown"
                          >
                            <i className="fal fa-ellipsis-h"></i>
                          </div>
                          {/* <div className="dropdown-menu dropdown-menu-right">
                            <button title="Action" className="dropdown-item" type="button">
                              Action
                            </button>
                            <button title="Another action" className="dropdown-item" type="button">
                              Another action
                            </button>
                            <button title="Something else here" className="dropdown-item" type="button">
                              Something else here
                            </button>
                          </div> */}
                        </div>
                      </li>
                    )}
                  </ul>
                </div>
              </div> : null}

              {show.attempt && <ViewQuiz list={list} setlist={setlist} quizId={lesson.quiz_id} setQuizId={setQuizId} setshow={setshow} setrId={setrId} rId={rId} />}
              {show.desc && <QuizDescription result={result} setresult={setresult} quizId={lesson.quiz_id} setQuizId={setQuizId} setshow={setshow} setrId={setrId} rId={rId} />}
              {show.question && <ViewQuestion result={result} setresult={setresult} quizId={lesson.quiz_id} setQuizId={setQuizId} setshow={setshow} setrId={setrId} rId={rId} />}
              {show.review && <ReviewQuiz result={result} setresult={setresult} quizId={lesson.quiz_id} setQuizId={setQuizId} setshow={setshow} setrId={setrId} rId={rId} />}             </div>

          ) : null}

            {/* sub-category */}

          {/* {lesson_type==3 && lesson.item_type === 3 ? (
            <div>
              <ul className="sub-ul">
                  {subassembliesItems.length
                    ? subassembliesItems.map((lesson1, index1) => {
                      return (
                        <SubAssembliesItems
                          isAdmin={isAdmin}
                          key={index1}
                          parentSubId={lesson.item_id}
                          // parentGetLearningMaterialData={
                          //   handleItem
                          // }
                          assemblyItem={lesson1}
                          setQuizId={setQuizId}
                          lessonindex={
                            lessonindex != ""
                              ? lessonindex + "." + parseInt(index1 + 1)
                              : ""
                          }
                          setshow={setshow}
                          setrId={setrId}
                          lesson={lesson}
                          parentSetCurrentVideoId={setCustomeCurrentVideoId}
                          parentFileTracking={handleFileTracking}
                          currentVideoId={currentVideoId1}
                          getSubContentMethod={getSubContentMethod}
                          buttonCheckedStatus={buttonCheckedStatus1}
                          documentId={documentId1}
                          refreshProgress={refreshProgress}
                          refreshParentProgress={()=>{props.parentGetLearningMaterialData(props.parentSubId)}}
                        />
                      );
                    })
                    : null}
                </ul>
              </div>
          ) : null} */}
        </div>
      </li>
    </>
  );
}
export default memo(AssembliesItems)