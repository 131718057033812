import axiosInstance from "../utils/axios";

  export const GetSubjectClasses = async (values) => {
    // return await axiosInstance().get(`/getSubjectClasses`, { params: values });
    return await axiosInstance().get(`/getSubjectClassesTz`, { params: values });
  };

 export const GetSubjectClassesPageWise = async (values) => {
   // return await axiosInstance().get(`/getSubjectClassesPageWise`, { params: values });
    return await axiosInstance().get(`/getSubjectClassesPageWiseTz`, { params: values });
  };
  
  export const GetClassDetails = async (values) => {
    return await axiosInstance().get(`/getClassDetails`, { params: values });
  };
  
  export const JoinBBBClass = async (values) => {
    return await axiosInstance().get(`/joinBBBClass`, { params: values });
  };
  
  export const CampusClass = async (values) => {
    return await axiosInstance().get(`/campusClass`, { params: values });
  };
  
  export const SaveRecordingAction = async (values) => {
    return await axiosInstance().get(`/saveRecordingAction`, { params: values });
  };

  export const SaveFeedbackRating = async (values) => {
    return await axiosInstance().get(`/saveFeedbackRating`, { params: values });
  };
  
  export const GetClassFiles = async (values) => {
    return await axiosInstance().get(`/getClassFiles`, { params: values });
  };