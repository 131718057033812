import React, { useEffect, useState, useRef } from "react";
import ReactHtmlParser from "react-html-parser";
import AppealText from "../course/AppealText";

const ViewAppeal = (props) => {
  const {setAssignmentReload} = props;
  return (
    <>
        <div class="ac-card">
          <h3 class="header-title">View Appeal</h3>
          <div class="ac-white-card">
              <div class="row rowAssessment">
                  <div class="col-12 assessment-col-left">
                      <div class="d-flex flex-wrap assMentbox">
                          <div>
                              <b>Reason For Not Submitting
                                  Assessment:</b>
                          </div>
                          <div class="flex-shrink">
                              <div class="fmInput">
                                  <select value= {props.row.reason}  class="form-control" disabled>
                                  <option>--Select Reason--</option>
                                  <option value="Sick" >Sick</option>	
                                  <option value="Family Responsibility">Family Responsibility</option>	
                                  <option value="Pre-Arranged Appeal">Pre-Arranged Appeal</option>	
                                  <option value="Portal Difficulties">Portal Difficulties</option>	
                                  <option value="Other">Other</option>
                                  </select>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="row rowAssessment">
                  <div class="col-12 assessment-col-left">
                      <div class="d-flex flex-wrap assMentbox">
                          <div>
                              <b>Description:</b>
                          </div>
                          <div class="flex-shrink">
                              <div class="fmInput">
                                  <textarea name="content" class="comments form-control" placeholder="" value={props.row.description} disabled></textarea>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>	
              <div class="row rowAssessment">
                  <div class="col-12 assessment-col-left">
                  <div class="d-flex flex-wrap assMentbox">
                      <div>
                      <b>Supporting Files:</b>
                      </div>
                      { Array.isArray(props.row.Appeal_Files) && props.row.Appeal_Files.length  ? (
                      <div class="flex-shrink">
                      <b>Files Uploaded</b>
                        <div class="list-file-wrap">
                          <button data-toggle="collapse" data-target="#download-list-wrap" aria-expanded="false" class="hide List-of-files collapsed"></button>
                            <div class="download-list collapse" id="download-list-wrap">
                            <ul class="list-unstyled attached-file-ul m-0">
                              { props.row.Appeal_Files.map((item,index) => (
                                <li key={index}>
                                  <i class="fal fa-file"></i> {item.fileName}
                                  <div class="download-list-action-btns">
                                  <a href={item.filePath} target="_blank">
                                    <button class="btn rounded-circle view-btn">
                                      <i class="fal fa-eye"></i>
                                    </button>
                                  </a>
                                  </div>
                                </li>
                                )
                              ) 
                              }
                            </ul>
                          </div>
                        </div>
                      </div>
                      ):(
                        <div class="flex-shrink">
                        <b>No Documents</b>
                        </div>
                      ) }
                    </div>
                  </div>
              </div>
              <AppealText/> 
          </div>
        </div>
    </>
  );
}
export default ViewAppeal;
