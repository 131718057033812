import React, { useEffect, useState } from "react";
import DataTable, { createThem } from "react-data-table-component";
import { GetMyLicensesService } from "../../services/ProfileService";
import Str from "../common/Str";
import SkeletonTicketList from "../../loaders/SkeletonTicketList";


const Licenses = (props) => {
  const [licenses, setLicenses] = useState([]);
  const [search, setSearch] = useState("");
  const [renderTable, setRenderTable] = useState(true);

  useEffect(() => {
    setRenderTable(false);
    GetMyLicensesService()
      .then((res) => {
        setLicenses(res.data.licenses_data);
      })
      .catch(err=>{
        console.log(err)
      }).finally(()=>{
        setRenderTable(true);
      });
  }, []);

  const columns = [
    {
      name: "Licenses Type",
      selector: "license_type",
      sortable: true,
    },
    {
      name: "Valid From",
      selector: "valid_from",
      sortable: true,
    },
    {
      name: "Valid Until",
      selector: "valid_until",
      sortable: true,
    },
    {
      name: "Details",
      selector: "userPrincipalName",
      sortable: true,
    },
    {
      name: "Action",
      selector: "action",
      sortable: true,
    },
    {
      name: "Expires in",
      selector: "expiredTime",
      sortable: true,
    },
  ];
  
  const resetFilter = () => {
    setSearch("")
  }

  const handleSearchFilter = (e) => {
    setSearch(e.target.value);
  }

  const dataToRender = () =>{
    let updatedData = [];
    let data = [...licenses];

    if(search.length){
      let tempName = data.filter(item =>{
        let includes = item?.license_type.toLowerCase().includes(search.toLowerCase());
        if(includes){
          return includes
        }else{
          return null;
        }
      })
      let tempOriginName = data.filter(item =>{
        let includes = item?.userPrincipalName.toLowerCase().includes(search.toLowerCase());
        if(includes){
          return includes
        }else{
          return null;
        }
      })

      const finalData = [...tempName, ...tempOriginName];

      let unique = [...new Set(finalData)];

      updatedData = unique;      
    }else{
      updatedData = data;
    }

    return updatedData;
  }

  return (
    <div className="custom-table-div filter-search-icon card card-table-custom">
      <div className="search-filter-div">
        <div className="search-filter-div-left">
            <div className="system-administration-table table-responsive">
                <div className="table-responsive-div">
                    <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                        <div id="assessment-table-main_filter" className="dataTables_filter">
                            <label for="filterDataTable" className="icon"></label>
                            <input
                                type="text"
                                name="filterDataTable"
                                className="filter-text"
                                placeholder="Search"
                                onChange={handleSearchFilter}
                                value={search}
                            />
                            <div className="filter-eff filter-data-btn">
                                <button className="filter-buttons">
                                    <i className="fal fa-filter"></i>
                                </button>
                            </div> 
                        </div>
                    </div>
                </div>
                <div className="filter-button-group">
                    <div className="reset-btn-group">
                        <div className="button-reset">
                            <button className="btn btn-primary" onClick={resetFilter} title="Reset">
                                <i className="fal fa-redo"></i>Reset
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>            
      </div>                
      <DataTable
        columns={columns}
        data={dataToRender()}
        progressPending={!renderTable}
        pagination={true}
        defaultSortField="license_type"
        defaultSortAsc={false}
        highlightOnHover={false}
        progressComponent={<SkeletonTicketList />}
        noDataComponent={Str.noRecord}
      />
    </div>  
  );
};

export default Licenses;
