import React, { useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';
import Str from "../../common/Str";
// import Exam_recording from "../../../pages/object-detection/Exam_recording";
import AssignmentStatus from "../../course/AssignmentStatus";
import AssignmentType from "../../course/AssignmentType";
import { zeroPad } from "react-countdown";
import { SystemIcons } from "../../common/SystemIcons";
import { SysCheckSideIcon } from "../../common/SysCheckSideIcon";
import { RenderAttachList } from "../../common/RenderAttachList";
import Swal from "sweetalert2";
import { GetAssignmentDetail } from "../../../services/QualificationService";
import ViewQuestion from "../quiz/ViewQuestionExam";
import { useDispatch, useSelector } from "react-redux";
import { setIsExamLaunched } from "../../../store/actions";
import { useHistory } from "react-router-dom";
import $ from 'jquery';

const Step5 = (props) => {
  const [showFile, setShowFile] = useState(true);
  const [showToast, setShowToast] = useState(true);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(null);
  const [quizArr, setQuizArr] = useState(props.quizArr);
  const [disableFileUpload, setDisableFileUpload] = useState(false);
  const [open, setOpen] = useState(true);
  const {quizTotalTime, isTimer} = props;
  const { examLaunched } = useSelector(state=>state.examLaunched);
  const dispatch = useDispatch();
  const history = useHistory();
  const [hasBeenSetToFinal, setHasBeenSetToFinal] = useState(false);
  const isExamLaunched = history.location.pathname === examLaunched?.url && examLaunched?.isExamLaunched;
  const examPopup = () => {dispatch(setIsExamLaunched({...examLaunched, isClicked: true}))};
  const [submitQuizAssessment, setSubmitQuizAssessment] = useState(false);
  const [fileInProgress, setFileInProgress] = useState(0);
  const [fileStatus, setFileStatus] = useState({});

  useEffect(() => {
    if(props?.submitQuizAssessment){
      setSubmitQuizAssessment(props.submitQuizAssessment);
    }
  }, [props?.submitQuizAssessment]);

  const updateSubmitQuizAssessment = () => {
    props.updateSubmitQuizAssessment();
    setSubmitQuizAssessment(false);
  }

  useEffect(()=>{
    if(props?.data.timeLimitActive && props?.data.remainingDuration < 0 && props?.data.launched === 1 && !hasBeenSetToFinal){
      if(props.data.quiz_id == 0){
        if(fileInProgress<=0 && props.fileSubmitted==true){
          props.setToFinal(true);
          setHasBeenSetToFinal(true);
        }
      } else {
        setSubmitQuizAssessment(true);
        setHasBeenSetToFinal(true);
      }
    } 
  },[props, hasBeenSetToFinal, setHasBeenSetToFinal]);

  useEffect(() => {
    if (props?.data?.timeLimitActive && props?.data?.launched === 1 && props?.data.remainingDuration > 0 ) {
      let myInterval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }
        if (seconds === 0) {
          if (minutes === 0) {
            if (hours === 0) {
              // props.setAssignmentReload(getRandomInt(5000));
              if(props.data.quiz_id > 0 && !hasBeenSetToFinal){
                // console.log("_____________ Run S5 1")
                setSubmitQuizAssessment(true);
                setHasBeenSetToFinal(true)
              } else if(!hasBeenSetToFinal) {
                if(fileInProgress<=0 ){
                  if(props.fileSubmitted==true){
                    props.setToFinal(true);
                    setHasBeenSetToFinal(true)                                      
                  }else{
                    $(".submit-file-button").click();                  
                  }
                }else{
                  props.setFileSubmitted(false);
                  setDisableFileUpload(true);
                }                
              }
              clearInterval(myInterval)
            }
            else {
              setHours(hours - 1);
              setMinutes(59)
              setSeconds(59);
            }

          } else {
            setMinutes(minutes - 1);
            setSeconds(59);
          }
        }
      }, 1000)
      return () => {
        clearInterval(myInterval);
      };
    }
  });

  useEffect(() => {
    if (props.assessmentId) {
      let aid = props.assessmentId;
      let tId = setTimeout(() => {
        GetAssignmentDetail({ aid })
          .then((res) => {
              if (res.data && res.data.remainingDuration > 0) {
                var hoursDifference = ~~(res.data.remainingDuration / 3600);
                var minutesDifference = ~~((res.data.remainingDuration % 3600) / 60);
                var secondsDifference = ~~res.data.remainingDuration % 60;
  
                setHours(hoursDifference)
                setMinutes(minutesDifference)
                setSeconds(secondsDifference)
              } else {
              setHours(res.data.timeHour)
              setMinutes(res.data.timeMin)
              setSeconds(0)
            }
          })
          .catch((error) => { });
      }, 1000);

      return ()=>{clearTimeout(tId)}
    }
  }, []);

  useEffect(() => {
    if (((hours * 60) + minutes) > 0 && ((hours * 60) + minutes) <= 3 && props?.data.launched && props?.data.timeLimitActive && props?.data?.remainingDuration > 0) {
      if (((hours * 60) + minutes) <= 3 && showToast) {
        Swal.fire(`<p style='color:red'> Only ${zeroPad(((hours * 60) + minutes))} minutes left </p>`);
        setShowToast(false);
      }
    }
  }, [hours, minutes]);


  const handleChangeStatus = ({ meta, file }, status) => {
    let fileQueue = {...fileStatus, [meta.id]:status};
    const inProgressStatus = ["preparing", "ready", "ready", "getting_upload_params", "uploading", "restarted"]
    const fileProgressCount = Object.keys(fileQueue).filter(item => inProgressStatus.includes(fileQueue[item])).length;
    setFileInProgress(fileProgressCount);
    if(status!="done" && (props?.data.timeLimitActive && hours==0 && minutes==0 && seconds==0)){
      setDisableFileUpload(true)
    }
    if(inProgressStatus.includes(status)){
      props.setFileSubmitted(false);
    }
    setFileStatus({...fileQueue})    
  }

  
  const currentTimeStatus = (h, m, s) => {
    setHours(h)
    setMinutes(m)
    setSeconds(s)
  }

  return (
    <>
      <div className="tab-pane fade show active" id="Assessment-Detail">
        <div className="test-series-main-block">
          <div className="test-series-main-left-blk">
            <div className="test-series-main-heading icon-add mb-15">
              <h4>{props.data && props.data.name ? props.data.name : ""}{props.data && props.data.code ? ` (${props.data.code})` : ""}</h4>
              <span className="test-series-main-left-heading-act">
                <SystemIcons data={props.data} />
              </span>
            </div>

            <div className="breadcrumb-btn-blk">
              <div className="custom-breadcrumb">
                <ul>
                  <li className="custom-breadcrumb-item inactive">
                    <a href="javascript:void(0);">
                      <i className="fal fa-home"></i>
                    </a>
                  </li>
                  {props.showSystemCheck &&
                    <li className="custom-breadcrumb-item inactive">
                      <a href="javascript:void(0);">System Check</a>
                    </li>
                  }
                  {props.showIdentification &&
                    <li className="custom-breadcrumb-item inactive">
                      <a href="javascript:void(0);">Student Identification</a>
                    </li>
                  }
                  <li className="custom-breadcrumb-item inactive">
                    <a href="javascript:void(0);">Test</a>
                  </li>
                </ul>
              </div>
              <div className="breadcrumb-btns">
                <button onClick={()=>props.backBtn()} type="button" class="btn btn-primary">
                  <i class="fal fa-chevron-left"></i> Back{" "}
                </button>
                <div className="breadcrumb-right-btns">
                  {(props.data.quiz_id == 0 && props.data.launched == 0) ? 
                    props.data.assFile != '' ? 
                      <button
                        type="button"
                        class="btn btn-primary"
                        disabled={props?.loader==true}
                        onClick={()=>{props.launchDownload({isLaunchedAndDownload: true, isOnlyDownload: false})}}
                      >
                        {props?.loader==true ?  <i className={`fas fa-cog fa-spin`}></i>  : <i class="fal fa-download"></i>}&nbsp; Launch & Download
                      </button>
                    :   
                      <button
                        type="button"
                        class="btn btn-primary"
                        disabled={props?.loader==true}
                        onClick={()=>{props.launchDownload({isLaunchedAndDownload: false, isOnlyDownload: false})}}
                      >
                        {props?.loader==true ?  <i className={`fas fa-cog fa-spin`}></i>  : <i className="fal fa-rocket"></i>}&nbsp; Launch
                      </button>
                  : null }
                  {props.data.quiz_id == 0 && props.data.launched == 1 && props.data.assFile != '' ?
                    <button
                      type="button"
                      class="btn btn-primary"
                      disabled={props?.loader==true}
                      onClick={()=>{props.launchDownload({isLaunchedAndDownload: false, isOnlyDownload: true})}}
                    >
                      {props?.loader==true ?  <i className={`fas fa-cog fa-spin`}></i>  : <i class="fal fa-download"></i>}&nbsp; Download
                    </button>
                  : null}
                </div>
              </div>
            </div>

            <div class="test-series-main-heading mb-15">
              <h4>Details</h4>
            </div>
            <div className="assessment__details__wrapper">
              <div className="custom__accordion__box">
                <div className="accordion__head d-flex align-items-center justify-content-between">
                  <div className="head__bar">
                    { props?.data?.timeLimitActive === 1 ? 
                      <i className="fal fa-clock"></i>
                      : <i className="fal fa-ballot-check"></i>}
                      {props?.data?.timeLimitActive === 1 
                        ? <div className="timer">Time Limit: <span className="as-text-red">
                          {(hours === 0 && minutes === 0 && seconds === 0)
                            ? `00:00`
                            : `${hours > 9 ? hours : '0' + hours}:${minutes > 9 ? minutes : '0' + minutes}:${seconds > 9 ? seconds : '0' + (seconds !== null ? seconds : '0')} `
                          }
                      </span>
                      </div>
                      : "Assessment Information" }
                  </div>
                  <button type="button" onClick={() => setOpen(!open)} className="btn btn-primary">
                    <i className={open ? "fal fa-chevron-up" : "fal fa-chevron-down"}></i>
                  </button>
                </div>
            <div className={"textseries-content-blk white-brd-blk mb-4 "  + (open ? "show" : "")}>
              <div className={`row ${props.quizId ? '' : 'mb-30'}`}>
                <div className="col-md-6">
                  <div className="textseries-info-details">
                    <ul>
                      <li>
                        <span className="heading">Type:</span>
                        <span className="content">
                          {props?.data?.ass_type &&
                            <AssignmentType ass_type={props?.data?.ass_type ? props.data.ass_type : ""} />
                          }
                        </span>
                      </li>
                      <li>
                        <span className="heading">Status:</span>
                        <span className="content">
                          {props?.data?.status &&
                            <AssignmentStatus status={props?.data?.status ? props.data.status : ""} />
                          }
                        </span>
                      </li>
                      <li>
                        <span className="heading">Condition:</span>
                        <span className="content">
                          <span className="ts-info-act">
                            <SystemIcons data={props.data} />
                          </span>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="textseries-info-details">
                    <ul>
                      <li>
                        <span className="heading">Open Date:</span>
                        <span className="content">
                          {props?.data?.open_date_time
                            ? props.data.open_date_time
                            : ""}
                        </span>
                      </li>
                      <li>
                        <span className="heading">Close Date:</span>
                        <span className="content">
                          {props?.data?.close_date_time
                            ? props.data.close_date_time
                            : ""}
                        </span>
                      </li>
                      <li>
                        {props?.data?.timeLimitActive === 1 && <span className="heading">Time Limit</span>}
                        {props?.data?.timeLimitActive === 1 && <span className="content">{quizTotalTime}</span>}
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-12 mt-15">
                  <div className="textseries-info-details">
                    <ul>
                      <li className="">
                        <span className="heading">Submission:</span>
                        {((props.data.Files && (props.data.Files.length > 0)) || props.data.fileName != "") ?
                          <span onClick={() => setShowFile(!showFile)} className="content">
                            List of files <i class={`fal ${showFile ? 'fa-chevron-down' : 'fa-chevron-up'} `}></i>
                          </span>
                          :
                          <span className="content">
                            No attempt
                          </span>
                        }
                      </li>
                          {props.data.ass_type === "Supplementary Exam" && <li className="">
                            <span className="heading">Maximum Mark Achievable: &nbsp;</span>
                            {props.data.maximum_grade_achievable ?
                              <span className="content">
                                {props.data.maximum_grade_achievable}
                              </span>
                              :
                              <span className="content">
                                -
                              </span>
                            }
                          </li>}
                      {showFile && (props.data.Files && (props.data.Files.length > 0) || props.data.fileName != "") ?
                        <div className="uploded-files-block mt-15">
                          <div className="row">
                            {props.data.Files && (props.data.Files.length > 0) ?
                              props.data.Files.map(i => {
                                i.removeF = props.handleRemoveFile
                                i.isInArray = true
                                i.showClose = true
                                return (
                                  RenderAttachList(i)
                                )
                              })
                              :
                              props.data.fileName != "" ?
                                RenderAttachList({ fileName: props.data.fileName, size: props.data.subFileSize, filePath: props.data.filePath, removeF: props.handleRemoveFile, id: props.data.subID, isInArray: false, subFilePath: props.data.subFilePath, showClose: true })
                                :
                                null}
                          </div>
                        </div>
                        : null}
                      {props.quizId &&
                        <li className="">
                          <span className="heading">Attempts:</span>
                          <span className="content">
                            {props.maxAttempt ? props.maxAttempt : 0}
                          </span>
                        </li>
                      }
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            </div>
            </div>
              {(!props.data.quiz_id && props?.data?.launched === 1) && <div className="textseries-content-blk white-brd-blk mb-4">
                <div className={`profile-doc-upload-blk ${props.data.subFilePath !== null && props.data.subFilePath !== '' && (props.data.subTime == null || props.data.subTime == 0) ? 'final_status_change' : ''}`}>
                  <div className="profile-doc-upload" style={{}}>
                    <Dropzone
                      getUploadParams={props.getUploadParams}
                      onChangeStatus={handleChangeStatus}
                      disabled={disableFileUpload}
                      onSubmit={props.onSubmit}
                      classNames={{ submitButton: 'btn btn-primary submit-file-button', submitButtonContainer: ' btn-upload' }}
                      styles={{ dropzone: { /*minHeight: 225*/ } }}
                      accept=".zip,.jpg,.png,.jpeg,.pdf,.doc,.docx,.txt,.mp4,.mp3,.otf,.xlsx"
                      // SubmitButtonComponent={null}
                      inputContent={
                        <lable>
                          <i className="fas fa-file-upload"></i>
                          <h4>
                            To submit assessment, Drop your file here, or <span>browse</span>
                          </h4>
                          <p>Supports: PDF, DOCX and more</p>
                        </lable>
                      }
                      maxFiles={10}
                      // LayoutComponent={({ input, previews, submitButton, dropzoneProps, files, extra: { maxFiles } }) => {
                      //   console.log(input, "---->", previews, '=========>', dropzoneProps);
                      //   if (previews) {
                      //     return React.useMemo(() => <div>
                      //       {previews}

                      //       <div {...dropzoneProps}>
                      //         {files.length < maxFiles && input}
                      //       </div>

                      //       {files.length > 0 && submitButton}
                      //     </div>)
                      //   }
                      // }
                      // }
                      maxSizeBytes="1200MB"
                      submitButtonContent={<div class="submit-files"><i class="fal fa-check"></i> Submit Files</div>}
                    />
                    {/* {props.data.subFilePath !== null && props.data.subFilePath !== '' && (props.data.subTime == null || props.data.subTime == 0) ? ( */}
                    {props?.data?.Files && props?.data?.Files?.length > 0 && (props.data.subTime == null || props.data.subTime == 0) ? (
                      <div className="btn-action-block examfile-upload-btn">
                        <button
                          onClick={()=>props.setToFinal(false)}
                          type="button"
                          className="btn btn-danger"
                        >
                          {props?.loadFinal==true ? <i className={`fas fa-cog fa-spin`}></i> : <i className="fal fa-check"></i>}&nbsp; Change to Final Status
                        </button>
                      </div>
                    ) : null}
                  </div>
                </div>
            </div>}
            {(props.data.quiz_id && props?.data?.launched === 1) ?
              <ViewQuestion
                quizId={props.quizId}
                setQuizId={props.setQuizId}
                setshow={props.setshow}
                setrId={props.setrId}
                rId={props.rId}
                quizArr={quizArr}
                aid={props.assessmentId}
                currentTimeStatus={currentTimeStatus}
                initiateReload={props.initiateReload}
                setIsExamLaunchedToFalse={props.setIsExamLaunchedToFalse}
                isSubmiting={props.isSubmiting}
                stop={props.stop}
                // quizTotalTime={setQuizTotalTime}
                assPassMark={props?.assPassMark}
                submitQuizAssessment={submitQuizAssessment}
                updateSubmitQuizAssessment={updateSubmitQuizAssessment}
              />
              : null
            }
            <div className="textseries-content-blk desc-instruction-blk white-brd-blk mb-15">
              <h4>{Str.detailDes}:</h4>
              {props?.data?.details
                ? ReactHtmlParser(props.data.details)
                : Str.noInstruction}
            </div>
          </div>
          <div className="test-series-main-right-blk">
            <div className="step-sidebar-space">
              <SysCheckSideIcon props={props} />
            </div>

            <div className="test-series-steps">
              <ul>
                <li className="test-series-steps-item active done">
                  <a href="javascript:void(0);">
                    <span>1</span> <b>Assessment Detail</b>
                  </a>
                </li>
                {props.showSystemCheck ?
                  <li className="test-series-steps-item active done">
                    <a href="javascript:void(0);">
                      <span>2</span> <b>System Check</b>
                    </a>
                  </li>
                  : null}
                {props.showIdentification ?
                  <li className="test-series-steps-item active done">
                    <a href="javascript:void(0);">
                      <span>3</span> <b>Identification</b>
                    </a>
                  </li>
                  : null}
                <li className="test-series-steps-item active done">
                  <a href="javascript:void(0);">
                    <span>4</span> <b>Launch Exam</b>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Step5;
