// import React, { useEffect, useState } from "react";
// import NotificationUnreadTodayFilterTabs from "./unreadNotification/NotificationUnreadTodayFilterTabs";
// import NotificationUnreadYesterdayFilterTabs from "./unreadNotification/NotificationUnreadYesterdayFilterTabs";
// import NotificationUnreadPastFilterTabs from "./unreadNotification/NotificationUnreadPastFilterTabs";

// import NotificationUnreadLastWeekFilterTabs from "./unreadNotification/NotificationUnreadLastWeekFilterTabs";
// import NotificationUnreadTwoWeekAgoFilterTabs from "./unreadNotification/NotificationUnreadTwoWeekAgoFilterTabs";
// import NotificationUnreadThreeWeekAgoFilterTabs from "./unreadNotification/NotificationUnreadThreeWeekAgoFilterTabs";
// import NotificationUnreadLastMonthFilterTabs from "./unreadNotification/NotificationUnreadLastMonthFilterTabs";

// export default function NotificationUnreadFilterTabs(props) {
//   const [notificationID, setNotificationID] = useState([0]);
//   const [nextPage, setNextPage] = useState(1);
//   const [filteredArr, setFilteredArr] = useState([]);

//   // Unread
//   const { UnreadvisibilityTab } = props.functionalParentCompneneLvl1;

//   const handleSelectedMyNotification = (filter, day) => {
//     props.handleSelectedMyNotification(filter, day);
//   };

//   const handleSelectNotification = (e, notificationId) => {
//     props.handleSelectNotification(e, notificationId);
//   };

//   return (
//     <>
//       <div className="tab-pane  fade" id="unread">
//         {UnreadvisibilityTab &&
//         UnreadvisibilityTab.todayNotification &&
//         UnreadvisibilityTab.todayNotification.total > 0 ? (
//           <NotificationUnreadTodayFilterTabs
//             functionalParentCompneneLvl2={props}
//             handleSelectNotification={handleSelectNotification}
//             defaultTab={UnreadvisibilityTab.defaultTodayTab}
//           />
//         ) : null}

//         {UnreadvisibilityTab &&
//         UnreadvisibilityTab.yesterdayNotification &&
//         UnreadvisibilityTab.yesterdayNotification.total > 0 ? (
//           <NotificationUnreadYesterdayFilterTabs
//             functionalParentCompneneLvl2={props}
//             handleSelectNotification={handleSelectNotification}
//             defaultTab={UnreadvisibilityTab.defaultYesterdayTab}
//           />
//         ) : null}

//         {UnreadvisibilityTab &&
//         UnreadvisibilityTab.last_weekNotification &&
//         UnreadvisibilityTab.last_weekNotification.total > 0 ? (
//           <NotificationUnreadLastWeekFilterTabs
//             functionalParentCompneneLvl2={props}
//             handleSelectNotification={handleSelectNotification}
//             defaultTab={UnreadvisibilityTab.defaultLastWeekTab}
//           />
//         ) : null}

//         {UnreadvisibilityTab &&
//         UnreadvisibilityTab.twoWeekNotification &&
//         UnreadvisibilityTab.twoWeekNotification.total > 0 ? (
//           <NotificationUnreadTwoWeekAgoFilterTabs
//             functionalParentCompneneLvl2={props}
//             handleSelectNotification={handleSelectNotification}
//             defaultTab={UnreadvisibilityTab.defaultTwoWeekAgoTab}
//           />
//         ) : null}

//         {UnreadvisibilityTab &&
//         UnreadvisibilityTab.threeWeekNotification &&
//         UnreadvisibilityTab.threeWeekNotification.total > 0 ? (
//           <NotificationUnreadThreeWeekAgoFilterTabs
//             functionalParentCompneneLvl2={props}
//             handleSelectNotification={handleSelectNotification}
//             defaultTab={UnreadvisibilityTab.defaultThreeWeekAgoTab}
//           />
//         ) : null}

//         {UnreadvisibilityTab &&
//         UnreadvisibilityTab.LastMonthNotification &&
//         UnreadvisibilityTab.LastMonthNotification.total > 0 ? (
//           <NotificationUnreadLastMonthFilterTabs
//             functionalParentCompneneLvl2={props}
//             handleSelectNotification={handleSelectNotification}
//             defaultTab={UnreadvisibilityTab.defaultLastMonthTab}
//           />
//         ) : null}

//         {UnreadvisibilityTab &&
//         UnreadvisibilityTab.pastNotification &&
//         UnreadvisibilityTab.pastNotification.total > 0 ? (
//           <NotificationUnreadPastFilterTabs
//             functionalParentCompneneLvl2={props}
//             handleSelectNotification={handleSelectNotification}
//             defaultTab={UnreadvisibilityTab.defaultPastTab}
//           />
//         ) : null}
//       </div>
//     </>
//   );
// }

import React, { useEffect, useState } from "react";
import NotificationUnreadTodayFilterTabs from "./unreadNotification/NotificationUnreadTodayFilterTabs";
import NotificationUnreadYesterdayFilterTabs from "./unreadNotification/NotificationUnreadYesterdayFilterTabs";
import NotificationUnreadPastFilterTabs from "./unreadNotification/NotificationUnreadPastFilterTabs";

import NotificationUnreadLastWeekFilterTabs from "./unreadNotification/NotificationUnreadLastWeekFilterTabs";
import NotificationUnreadTwoWeekAgoFilterTabs from "./unreadNotification/NotificationUnreadTwoWeekAgoFilterTabs";
import NotificationUnreadThreeWeekAgoFilterTabs from "./unreadNotification/NotificationUnreadThreeWeekAgoFilterTabs";
import NotificationUnreadLastMonthFilterTabs from "./unreadNotification/NotificationUnreadLastMonthFilterTabs";
import { useSelector, useDispatch } from "react-redux";
import {
  setNotificationCount,
  setUnreadNotificationCount,
  setSelectedNotification,
} from "../../store/actions";

export default function NotificationUnreadFilterTabs(props) {
  const { notificationcount, selectednotification } = useSelector(
    (state) => state.notification
  );
  const dispatch = useDispatch();
  const [notificationID, setNotificationID] = useState([0]);
  const [nextPage, setNextPage] = useState(1);
  const [filteredArr, setFilteredArr] = useState([]);

  // Unread
  const { UnreadvisibilityTab, notificationTabDef } = props.functionalParentCompneneLvl1;

  const handleSelectedMyNotification = (filter, day) => {
    props.handleSelectedMyNotification(filter, day);
  };

  const handleSelectNotification = (e, notificationId, readNotificationId) => {
    props.handleSelectNotification(e, notificationId, readNotificationId);
  };

  return (
    <>
      <div className={"tab-pane " + (notificationTabDef == 'unread' ? 'show active' : 'fade')} id="unread">
        { UnreadvisibilityTab && UnreadvisibilityTab.totalCount ? (
          <>
            {UnreadvisibilityTab &&
            UnreadvisibilityTab.todayNotification &&
            UnreadvisibilityTab.todayNotification.total > 0 ? (
              <NotificationUnreadTodayFilterTabs
                notificationTabDef={notificationTabDef}
                functionalParentCompneneLvl2={props}
                handleSelectNotification={handleSelectNotification}
                defaultTab={UnreadvisibilityTab.defaultTodayTab}
              />
            ) : null}

            {UnreadvisibilityTab &&
            UnreadvisibilityTab.yesterdayNotification &&
            UnreadvisibilityTab.yesterdayNotification.total > 0 ? (
              <NotificationUnreadYesterdayFilterTabs
                notificationTabDef={notificationTabDef}
                functionalParentCompneneLvl2={props}
                handleSelectNotification={handleSelectNotification}
                defaultTab={UnreadvisibilityTab.defaultYesterdayTab}
              />
            ) : null}

            {UnreadvisibilityTab &&
            UnreadvisibilityTab.last_weekNotification &&
            UnreadvisibilityTab.last_weekNotification.total > 0 ? (
              <NotificationUnreadLastWeekFilterTabs
                notificationTabDef={notificationTabDef}
                functionalParentCompneneLvl2={props}
                handleSelectNotification={handleSelectNotification}
                defaultTab={UnreadvisibilityTab.defaultLastWeekTab}
              />
            ) : null}

            {UnreadvisibilityTab &&
            UnreadvisibilityTab.twoWeekNotification &&
            UnreadvisibilityTab.twoWeekNotification.total > 0 ? (
              <NotificationUnreadTwoWeekAgoFilterTabs
                notificationTabDef={notificationTabDef}
                functionalParentCompneneLvl2={props}
                handleSelectNotification={handleSelectNotification}
                defaultTab={UnreadvisibilityTab.defaultTwoWeekAgoTab}
              />
            ) : null}

            {UnreadvisibilityTab &&
            UnreadvisibilityTab.threeWeeNotification &&
            UnreadvisibilityTab.threeWeeNotification.total > 0 ? (
              <NotificationUnreadThreeWeekAgoFilterTabs
                notificationTabDef={notificationTabDef}
                functionalParentCompneneLvl2={props}
                handleSelectNotification={handleSelectNotification}
                defaultTab={UnreadvisibilityTab.defaultThreeWeekAgoTab}
              />
            ) : null}

            {UnreadvisibilityTab &&
            UnreadvisibilityTab.LastMonthNotification &&
            UnreadvisibilityTab.LastMonthNotification.total > 0 ? (
              <NotificationUnreadLastMonthFilterTabs
                notificationTabDef={notificationTabDef}
                functionalParentCompneneLvl2={props}
                handleSelectNotification={handleSelectNotification}
                defaultTab={UnreadvisibilityTab.defaultLastMonthTab}
              />
            ) : null}

            {UnreadvisibilityTab &&
            UnreadvisibilityTab.pastNotification &&
            UnreadvisibilityTab.pastNotification.total > 0 ? (
              <NotificationUnreadPastFilterTabs
                notificationTabDef={notificationTabDef}
                functionalParentCompneneLvl2={props}
                handleSelectNotification={handleSelectNotification}
                defaultTab={UnreadvisibilityTab.defaultPastTab}
              />
            ) : null}
          </>
        ) :
          <>
            <div className="no-notification">
              No unread notifications.
            </div>
          </>
        }
      </div>
    </>
  );
}
