import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

function Home(props) {

  const brand = useSelector((state) => state.brand);

  return (
    <div className="lib-home-right">
      {/* <div className="header-info-box">
        <h4>Library Home</h4>
        <p>Welcome to LIBRARY @{brand?.configuration?.brand_name}. Here you can search for any academic information regarding your projects, class discussions or any other interesting topic. The library consists of books found in our campus libraries as well as online resource such nas links, videos, online documents and articles.</p>
      </div> */}
      <div className="card-list-blk">
        <ul className="home-ul-list list-unstyled">
          <li>
            <Link className="home-link" to={"/student-hub/advance-search/query"}>
              <div className="card home-bx-card">
                <div class="icon">
                  <i class="fal fa-search-plus"></i>
                </div>
                <div className="home-bx-card-info">
                  <h3>Search Library</h3>
                  <p>Search by category & advanced filter.</p>
                </div>
              </div>

            </Link>
          </li>
          <li>
            <Link className="home-link" to={"/student-hub/research-assistance"} >
              <div className="card home-bx-card">
                <div class="icon">
                  <i class="fal fa-print-search"></i>
                </div>
                <div className="home-bx-card-info">
                  <h3>Research Assistance</h3>
                  <p>Information search Lab Access/Bookings.</p>
                </div>
              </div>
            </Link>
          </li>
          <li>
            <Link className="home-link" to={"/student-hub/search-assistance"} >
              <div className="card home-bx-card">
                <div class="icon">
                  <i class="fal fa-hands-helping"></i>
                </div>
                <div className="home-bx-card-info">
                  <h3>Search Assistance</h3>
                  <p>Request for help from a librarian.</p>
                </div>
              </div>
            </Link>
          </li>
          <li>
            <Link className="home-link" to={"/student-hub/campus-library"} >
              <div className="card home-bx-card">
                <div class="icon">
                  <i class="fal fa-university"></i>
                </div>
                <div className="home-bx-card-info">
                  <h3>Campus Libraries</h3>
                  <p>Get important information of direct interest to you.</p>
                </div>
            </div>
            </Link>
          </li>
          <li>
            <Link className="home-link" to={"/student-hub/student-support"} >
              <div className="card home-bx-card">
                <div class="icon">
                  <i class="fal fa-headset"></i>
                </div>
                <div className="home-bx-card-info">
                  <h3>Student Support</h3>
                  <p>Options to contact the AIE support team</p>
                </div>
              </div>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Home;
