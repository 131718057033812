import React from "react";
import FeedBackForm from "./FeedBackForm";
import { Link } from "react-router-dom";
import HelloPeter from "../../assets/images/hello-peter.svg";
export default function VeryGood(props) {
  return (
    <>
      <div
        className="tab-pane fade show active"
        id="verygood"
        role="tabpanel"
        aria-labelledby="verygood-tab"
      >
        <div className="tab-pane-head">
          <h3>
            <span>WOW,</span> thanks for such a great rating
          </h3>
          <p>
            We would appreciate a public comment. Your review will put you in
            our elite #RavingFans club and you will receive a surprise gift.
          </p>
        </div>
        <div className="row reviw-content mb-4 justify-content-center align-items-center">
          <div className="reviw-col">
            <div className="">
              <a
                href="https://www.google.com/search?client=firefox-b-d&amp;q=AIE"
                className="btn btn-primary align-center"
                target="_blank"
                title="Google review"
              >
                <i className="fab fa-google"></i>
                Google Review
              </a>
            </div>
          </div>
          <div className="reviw-col or-text">
            <div className="color text-uppercase">
              <b>Or</b>
            </div>
          </div>
          <div className="reviw-col">
            <div className="">
              <a
                href="https://www.hellopeter.com/"
                className="btn btn-primary align-center"
                target="_blank"
                title="Hello Peter"
              >
                <img
                  src={HelloPeter}
                  alt=""
                  style={{ width: "10px", marginRight: "3px" }}
                />
                Hello Peter
              </a>
            </div>
          </div>
        </div>

        <FeedBackForm rating_type={props.rating_type} />
      </div>
    </>
  );
}
