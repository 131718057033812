import axiosInstance from "../utils/axios";

export const AttachFileFeedService = async (values) => {
  return await axiosInstance().post("/feedAttachment", values);
};

export const DownloadFileFeedService = async (values) => {
  return await axiosInstance().get("/download", {
    params: {
      url: values,
    },
    responseType: "blob",
  });
};
export const getAvailableUsersService = async (values) => {
  return await axiosInstance().get("/getAvailableUsers", {
    params: {
      search: values,
    },
  });
};

export const createChatGroupService = async (group, message) => {
  return await axiosInstance().post("/createChatGroup", {
    params: { enteries: group, data: message },
  });
};

export const getMyGroupsService = async (values) => {
  return await axiosInstance().get("/getMyGroups", {
    params: {
      search: values,
    },
  });
};

export const getMyGroupFeedService = async (values) => {
  return await axiosInstance().get("/getMyGroupFeed", {
    params: {
      groupId: values,
    },
  });
};

export const GetChatDocuments = async (values) => {
  return await axiosInstance().post("/getChatDocuments", values);
};

export const GetChatDocumentsFilters = async (params) => {
  return await axiosInstance().get("/getChatDocumentsFilters", {params});
};
