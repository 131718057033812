import React, { useEffect, useState } from "react";
import HighSchoolDetails from "./blocks/HighSchoolDetails";
import SubjectDetails from "./blocks/SubjectDetails";
import QualificationDetails from "./blocks/QualificationDetails";
import PersonalDocuments from "./blocks/PersonalDocuments";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import * as Yup from "yup";
import { GetUserEducationDetails, ProfileCertificateService, ProfilePersonalDocService, RemoveMyHighQualificationCertificateService, RemoveMyPersonalDocumentsService, UpdateUserEducationDetails } from "../../services/ProfileService";
import DownloadCertificate from "./blocks/DownloadCertificate";
import { useSelector } from "react-redux";

const EducationalDetails = () => {
  const [detail, setDetail] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const userDetail = useSelector(state => state.user);

  useEffect(()=>{
    GetUserEducationDetails().then((res)=>{
      if(res.status === 200){
        setDetail(res.data)
      }
    }).catch((err)=>{
      console.log(err)
    })
  },[refresh])
  
  
  const educationSchema = Yup.object({
    high_year: Yup.string().required("Matriculated year is required"),
    high_name: Yup.string().required("School name is required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      high_name:detail?.high_name ? detail?.high_name : "",
      high_year:detail?.high_year ? detail?.high_year : "",
      metric_certificates : detail?.userMetricsFiles ? detail?.userMetricsFiles : [],
      subjects : detail?.subjects ? detail?.subjects : [],
      highQualification : detail?.userHighQualification ? detail?.userHighQualification :[]
    },
    validationSchema: educationSchema,
    onSubmit: (values) => {   
      setLoading(true);   
      const payload = {
        high_year : values.high_year,
        high_name: values.high_name,
        high_qualifications: values.highQualification.map((qual) =>({id: qual?.id, qualification: qual?.qualification, place: qual?.place, year: qual?.year})),
        subjects: values.subjects.map((sub) =>({id:sub?.id, student:sub?.student, subject:sub?.subject, mark:sub?.mark, active:sub?.active}))
      }
      console.log(payload)
      UpdateUserEducationDetails(payload).then((res)=>{
        if(res.status===200){
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Updated successfully",
          });
          setRefresh(!refresh)
        }
      }).catch((err)=>{
        Swal.fire({
          icon: "error",
          title: "Error",
          text: err.response.data.message,
        });
      }).finally(()=>{
        setLoading(false);
      })

    },
  });

  const { values, errors, touched, setValues, setFieldValue,  handleChange, handleBlur, handleSubmit } = formik;

  const handleFileChange = (e) => {
    if (e.target.files.length) {
      const formInputs = new FormData();
      formInputs.append("doc", e.target.files[0]);
      formInputs.append("dt", "metric_certificate");

      Swal.fire({
        title: "File Uploading....",
        onOpen: function () {
          Swal.showLoading();
        },
      });
      ProfilePersonalDocService(formInputs)
        .then((res) => {
          Swal.close();
          if(res.status===200){
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Updated successfully",
            })
            setRefresh(!refresh);
          }
        })
        .catch((error) => {
          Swal.close();
          Swal.fire({
            icon: "error",
            title: "Error",
            text: error.response.data.message,
          })
        });
    }
  };

  const handleRemoveFile = (rowId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        RemoveMyPersonalDocumentsService({ rowId, dt: "metric_certificate" })
          .then((res) => {
            if(res.status === 200){
              Swal.fire({
                icon: "success",
                title: "Success",
                text: "Personal document removed successfully",
              });
              setRefresh(!refresh);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };


  const handleRemoveHighQualCertFile = (rowId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        RemoveMyHighQualificationCertificateService({ rowId })
          .then((res) => {
            if(res.status === 200){
              Swal.fire({
                icon: "success",
                title: "Success",
                text: "Certificate removed successfully",
              });
              setRefresh(!refresh)
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };

  const handleHighQualCertFileChange = (e, id) => {
    if (e.target.files.length) {
      const formInputs = new FormData();
      formInputs.append("certificate", e.target.files[0]);
      formInputs.append("rowId", id);
      Swal.fire({
        title: "File Uploading....",
        onOpen: function () {
          Swal.showLoading();
        },
      });
      ProfileCertificateService(formInputs)
        .then((res) => {
          Swal.close();
          if(res.status === 200){
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Updated successfully",
            })
            setRefresh(!refresh);
          }
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: error.response.data.message,
          }).then((result) => {});
        });
    }
  };

  const handleAddNewSubject = () =>{
    const prevValue = [...values.subjects];    
    prevValue.push({
      id: 0,
      student: userDetail?.id,
      subject: "",
      mark: "",
      active: 1
    })
    setValues({
      ...values,
      subjects : [...prevValue]
    })    
  }

  const handleRemoveSubject = (index) =>{
    const currentValue = [...values.subjects];
    currentValue.splice(index, 1);

    setValues({...values, subjects:[...currentValue]})
  }

  const handleChangeSubjectName = (e, idx) =>{
    const currentValue = [...values.subjects];
    currentValue[idx].subject = e.target.value;

    setValues({...values, subjects:[...currentValue]})
  }

  const handleChangeSubjectMark = (e, idx) =>{
    const currentValue = [...values.subjects];
    currentValue[idx].mark = e.target.value;

    setValues({...values, subjects:[...currentValue]})
  }

  const handleAddNewQualification = () =>{
    const currentValue = [...values.highQualification];
    currentValue.push({
      id: 0,
      student: userDetail?.id,
      qualification: "",
      place: "",
      year: "",
      active: 1,
      temp_id: "0",
      files: []
    });

    setValues({
      ...values,
      highQualification:[...currentValue]
    })
  }

  const handleRemoveQualification = (index) =>{
    const currentValue = [...values.highQualification];
    currentValue.splice(index, 1);
    setValues({
      ...values, highQualification:[...currentValue]
    });    
  }

  const handleChangeQualFields = (e, idx, field) =>{
    const currentValue = [...values.highQualification];
    currentValue[idx][field] = e.target.value;
    setValues({
      ...values,
      highQualification:[...currentValue]
    })
  }

  return (
    <div className="card card-profile-info-card">
      <div className="card-header mb-4">High School</div>
      <form onSubmit={handleSubmit}>
        <div className="row">          
          <div className="col-md-3">
            <div className="form-group" title="School Name">
              <label>School Name</label>
              <input
                type="text"
                className={
                  "form-control" +
                  (errors.high_name && touched.high_name
                    ? " is-invalid"
                    : "")
                }
                placeholder="School Name"
                value={values.high_name}
                name="high_name"
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group" title="Year Matriculated">
              <label>Year Matriculated</label>
              <input
                type="text"
                className={
                  "form-control" +
                  (errors.high_year && touched.high_year
                    ? " is-invalid"
                    : "")
                }
                placeholder="Year Matriculated"
                value={values.high_year}
                name="high_year"
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
          </div>
          <div className="col-md-3">
            <label>Matric Certificate</label>
            <div className="form-group atttach-file m-0">
              <label>
                <i className="fal fa-upload"></i>
                <span>Upload</span>
                <input
                  type="file"
                  className="form-control  form-control-aatch-file"
                  onChange={(e) => handleFileChange(e)}
                />                
              </label>
            </div>
            <div className="frm-group">
              <ul className="list-unstyled attached-file-ul m-0">
                {values.metric_certificates.map((document) => {
                  return (
                    <li key={document.id}>
                      <DownloadCertificate
                        item={document}
                        canRemove={true}
                        handleRemoveFile={handleRemoveFile}
                      />
                    </li>
                  );
                })}                  
              </ul>
            </div>
          </div>
        </div>
        <hr />
        <div className="subject-container">
          <div className="card-header mb-4">Subject Details</div>
          {values?.subjects.map((subject, key) =>(
            <div className="row align-items-center mb-sm-3" key={key}>
              <div className="col-md-3">
                <div className="form-group" title="Subject">
                  <label>Subject</label>
                  <input
                    type="text"
                    className={"form-control"}
                    placeholder="Subject"
                    value={subject?.subject}
                    name="subject"
                    onChange={(e) => {handleChangeSubjectName(e, key)}}
                  />
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group" title="Symbol(%)">
                  <label>Symbol(%)</label>
                  <input
                    type="number"
                    className={"form-control"}                    
                    value={subject?.mark}
                    name="mark"
                    onChange={(e) => {handleChangeSubjectMark(e, key)}}
                  />
                </div>
              </div>
              <div className="col-md-1">
                <button
                  type="button"
                  className="btn btn-danger rounded-circle"
                  onClick={() => handleRemoveSubject(key)}
                  title="Delete"
                >
                <i className="fal fa-trash-alt"></i>
                </button>
              </div>
            </div>
          ))}
          <button type="button" title="Add Subject" className="btn btn-white-bordered btn-back" onClick={()=>{handleAddNewSubject()}}>
            <i className="fal fa-plus"></i> Add Subject
          </button>
        </div>
        <hr />
        <div className="qualification-container">
          <div className="card-header mb-4">High Qualification</div>
          {values.highQualification.map((qualification, key) =>(
            <div className="row mb-sm-3" key={key}>
              <div className="col-md-3">
                <div className="form-group" title="Qualification">
                  <label>Qualification</label>
                  <input
                    type="text"
                    className={"form-control"}
                    placeholder="Qualification"
                    value={qualification.qualification}
                    name="qualification"
                    onChange={(e) =>{handleChangeQualFields(e, key, "qualification")}}
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group" title="Place of Study">
                  <label>Place of Study</label>
                  <input
                    type="text"
                    className={"form-control"}
                    placeholder="Place of Study"
                    value={qualification.place}
                    name="place"
                    onChange={(e) =>{handleChangeQualFields(e, key, "place")}}
                  />
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group" title="Year Graduated">
                  <label>Year Graduated</label>
                  <input
                    type="number"
                    className={"form-control"}
                    placeholder="Year Graduated"
                    value={qualification.year}
                    name="year"
                    onChange={(e) =>{handleChangeQualFields(e, key, "year")}}
                  />
                </div>
              </div>
              <div className="col-md-3">
                <label>Certificate</label>                
                {qualification.files.length > 0 ? 
                <div className="frm-group">
                  <ul className="list-unstyled attached-file-ul m-0">
                    {qualification.files.map((document) => {
                      return (
                        <li key={document.id} className="p-0">
                          <DownloadCertificate
                            item={document}
                            canRemove={true}
                            canEdit={true}
                            handleRemoveFile={handleRemoveHighQualCertFile}
                            handleFileEdit={(e) =>{handleHighQualCertFileChange(e, qualification.id)}}
                          />
                        </li>
                      );
                    })}                  
                  </ul>
                </div>
                :
                <div className="form-group atttach-file m-0">
                  <label>
                    <i className="fal fa-paperclip"></i>
                    <span>Add Attachment</span>
                    <input
                      type="file"
                      className="form-control  form-control-aatch-file"
                      onChange={(e) => handleHighQualCertFileChange(e, qualification.id)}
                    />                
                  </label>
                </div>}       
              </div>
              <div className="col-md-1 align-items-center d-flex">
                <button
                  type="button"
                  className="btn btn-danger rounded-circle"
                  onClick={() => handleRemoveQualification(key)}
                  title="Delete"
                >
                <i className="fal fa-trash-alt"></i>
                </button>
              </div>
            </div>
          ))}
          <button title="Add Qualification" className="btn btn-white-bordered btn-back" type="button" onClick={()=>{handleAddNewQualification()}}>
            <i className="fal fa-plus"></i> Add Qualification
          </button>
        </div>
        <div className="form-group form-group-save-cancel">              
          <button
            className="btn btn-save btn-success"
            type="submit"
            title="Save"
            disabled={loading}
          >
            {loading ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
            Save Changes
          </button>
          <button
            className="btn btn-close btn-danger"
            type="button"
            onClick={() => {formik.resetForm()}}
            title="Cancel"
          >
            <i className="fal fa-times"></i>
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default EducationalDetails;
