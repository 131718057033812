import axiosInstance from "../utils/axios";

export const ratingFormSubmitService = async (values) => {
  return await axiosInstance().post("/ratingFormSubmit/", values);
};

export const getStudentDetails = async (values) => {
  return await axiosInstance().get("/getStudentDetails", { params: values });
};

export const viewStudent = async (values) => {
  return await axiosInstance().get("/viewStudent", { params: values });
};

export const SaveSettings = async (values) => {
  return await axiosInstance().post("/settings/", values);
};

export const getClassAttendance = async (values) => {
  return await axiosInstance().get("/getClassAttendance/", { params: values });
};

