import React from "react";

export const SystemIcons = (props) => {
    return (
        <>
            {props.data.camera ? <i data-toggle="tooltip" title="This exam requires your computer/Laptop to have an operational webcam" class={`fal fa-webcam ${props.data.camera ? "active" : ""}`}></i> : null}
            {props.data.audio ? <i data-toggle="tooltip" title="This exam requires your computer/Laptop to have an operational microphone" class={`fal fa-microphone  ${props.data.audio ? "active" : ""}`}></i> : null}
            {props.data.screen_share ? <i data-toggle="tooltip" title="This exam will record the contents of your screen, please have one screen and disconnect secondary screens" class={`fal fa-desktop ${props.data.screen_share ? "active" : ""}`}></i> : null}
            {props.data.timeLimitActive ? <i data-toggle="tooltip" title="This is a timed exam" class={`fal fa-clock ${props.data.timeLimitActive ? "active" : ""}`}></i> : null}
        </>
    )
};