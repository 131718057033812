import React from "react";
import moment from 'moment';
import * as cocoSsd from "@tensorflow-models/coco-ssd";
import "@tensorflow/tfjs";
import "./styles.css";

class ObjectDetection extends React.Component {
  videoRef = React.createRef();
  canvasRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      'person': 0,
      "phone": 0,
      "absent": 0,
    };
  }

  componentDidMount() {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      const webCamPromise = navigator.mediaDevices
        .getUserMedia({
          audio: false,
          video: {
            facingMode: "user",
          },
        })
        .then((stream) => {
          window.stream = stream;
          this.videoRef.current.srcObject = stream;
          return new Promise((resolve, reject) => {
            this.videoRef.current.onloadedmetadata = () => {
              resolve();
            };
          });
        })
      //   .then((model) => {
      //     this.videoRef.current.addEventListener('loadeddata', (e) => {
      //       console.log('---- Video is loaded!');

      //       const runDetection = () => {
      //         // Pass in a video stream to the model to obtain a prediction from the MediaPipe graph
      //         model.estimateHands(this.videoRef.current).then((predictions) => {
      //           // ...
      //         });
      //       };
      //       runDetection();
      //     });
      //   })

      const modelPromise = cocoSsd.load();
      Promise.all([modelPromise, webCamPromise])
        .then((values) => {
          this.detectFrame(this.videoRef.current, values[0]);
        })
        .catch((error) => {
          console.error(error);
        });

      // var constraints = { audio: true, video: false };

      // navigator.mediaDevices.getUserMedia(constraints).then(


      //   function (stream) {
      //     var max_level_L = 0;
      //     var old_level_L = 0;
      //     var cnvs = document.getElementById("test");
      //     var cnvs_cntxt = cnvs.getContext("2d");

      //     var audioContext = new AudioContext();
      //     var microphone = audioContext.createMediaStreamSource(stream);
      //     var javascriptNode = audioContext.createScriptProcessor(1024, 1, 1);

      //     microphone.connect(javascriptNode);
      //     javascriptNode.connect(audioContext.destination);
      //     javascriptNode.onaudioprocess = function (event) {

      //       var inpt_L = event.inputBuffer.getChannelData(0);
      //       var instant_L = 0.0;

      //       var sum_L = 0.0;
      //       for (var i = 0; i < inpt_L.length; ++i) {
      //         sum_L += inpt_L[i] * inpt_L[i];
      //       }
      //       instant_L = Math.sqrt(sum_L / inpt_L.length);
      //       max_level_L = Math.max(max_level_L, instant_L);
      //       instant_L = Math.max(instant_L, old_level_L - 0.008);
      //       old_level_L = instant_L;

      //       cnvs_cntxt && cnvs_cntxt.clearRect(0, 0, cnvs.width, 50);
      //       cnvs_cntxt && (cnvs_cntxt.fillStyle = '#00ff00');
      //       cnvs_cntxt && cnvs_cntxt.fillRect(10, 10, (cnvs.width - 20) * (instant_L / max_level_L), (cnvs.height - 20)); // x,y,w,h

      //     }
      //   }).catch(function (err) {
      //     console.log(err);
      //   });
    }
  }

  componentWillUnmount() {
    const stream = this.videoRef.current.srcObject;
    const tracks = stream && stream.getTracks();

    tracks && tracks.forEach(function (track) {
      track.stop();
    });
    this.videoRef.current.srcObject = null;
  }

  detectFrame = (video, model) => {
    const predictClass = ["person", "cell phone"]
    model.detect(video).then((predictions) => {
      // const highScorePredictions = predictions.filter(prediction => (prediction.score*100 > 80) && predictClass.includes(prediction.class));
      const sortedPredictions = predictions.sort((a, b) => b.score - a.score);
      // const mostConfidentPrediction = sortedPredictions[0];
      // const accuratePredictions = predictClass.includes(mostConfidentPrediction?.class) ? [mostConfidentPrediction] : [];
      const accuratePredictions = predictions.filter(prediction => (prediction.class === "person" || prediction.class == "cell phone") && prediction.score > 0.7);
      this.renderPredictions(accuratePredictions);
    });
    requestAnimationFrame(() => {
      this.detectFrame(video, model);
    });
  };

  renderPredictions = (predictions) => {
    const ctx = this.canvasRef.current && this.canvasRef.current.getContext("2d");
    if (ctx) {
      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
      // Font options.
      const font = "16px sans-serif";
      ctx.font = font;
      ctx.textBaseline = "top";
      predictions.forEach((prediction) => {
        const x = prediction.bbox[0];
        const y = prediction.bbox[1];
        const width = prediction.bbox[2];
        const height = prediction.bbox[3];
        // Draw the bounding box.
        ctx.strokeStyle = "#FF0000";
        ctx.lineWidth = 2;
        ctx.strokeRect(x, y, width, height);
        // Draw the label background.
        ctx.fillStyle = "#FF0000";
        const textWidth = ctx.measureText(prediction.class).width;
        const textHeight = parseInt(font, 10); // base 10
        ctx.fillRect(x, y, textWidth + 4, textHeight + 4);
        
        // Draw the text last to ensure it's on top.
        ctx.fillStyle = "#000000";
        ctx.fillText(prediction.class, x, y);
      });
    }
  };

  render() {
    return (
      <div className="">
      <video
        className="size"
        autoPlay
        playsInline
        muted
        ref={this.videoRef}
        width="570"
        height="500"
      />
      <canvas
        className="size"
        ref={this.canvasRef}
        width="570"
        height="500"
      />
    </div>
    );
  }
}

export default ObjectDetection;
