import React from "react";
import HtmlParser from "react-html-parser";
import FeedAttachments from "../feed/FeedAttachments";

export class ShowMoreHide extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.state = {
      showMore: false,
      showLess: false,
    };
  }

  handleClickOutside(event) {
    if (this.ref.current && !this.ref.current.contains(event.target) && (this.state.showLess || this.state.showMore)) {
      this.props.onClickOutside && this.props.onClickOutside();
      this.setState({ showMore: true });
      this.setState({ showLess: false });

    }
    if (this.ref.current && this.ref.current.contains(event.target) && (this.state.showLess || this.state.showMore)) {
      this.props.onClickOutside && this.props.onClickOutside();
      this.setState({ showMore: false });
      this.setState({ showLess: true });
    }
  }

  componentDidMount() {
    // || this.props.show.includes("\n<p></p>\n")
    if ((this.props.show && (this.props.show.length > 280 || (this.props && this.props.attachInfo)))) {
      this.setState({ showMore: true });
    }
    document.addEventListener("click", this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside, true);
  }

  render() {
    if (!this.props.show) return null;
    let tempData = this.props.show
    let src = tempData.replaceAll('src="//', 'src="https://')
    let finalHTML = src.replaceAll('frameBorder="', 'allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen" frameborder="')
    return (
      <>
        <div ref={this.ref}>
          <div className={`discuss-text ${this.state.showMore ? "show-more-div " : " " + this.props.className ? this.props.className : ""}`} >
            {/* {this.props.show} */}
            {HtmlParser(finalHTML)}
            {this.props && this.props.attachInfo ? this.props.attachInfo : ""}
            {/* {this.props.attachInfo && this.props.attachInfo.feed.attachments && this.props.attachInfo.feed.attachments.length ? (
              <FeedAttachments
                attachments={this.props.attachInfo.feed.attachments}
                isEditable={this.props.attachInfo.feed.id == this.props.attachInfo.showHidePostEdit ? true : false}
                allData={this.props.attachInfo.feed}
                deletedFile={this.props.attachInfo.deletedFile}
              />
            ) : null} */}
          </div>
          <div>
            {this.state.showMore ? (
              <div
                onClick={() =>
                  this.setState({ showMore: false, showLess: true })
                }
                className="show-more-txt"
              >
                See more
              </div>
            ) : null}

            {this.state.showLess ? (
              <div
                onClick={() =>
                  this.setState({ showMore: true, showLess: false })
                }
                className="show-more-txt"
              >
                See less
              </div>
            ) : null}
          </div>
        </div>
      </>
    );
  }
}
