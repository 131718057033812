import { useEffect } from "react";
// import { GetCategoryDetail } from "../../../services/StudentService";
// import "jspdf-autotable";
import React, { useState, useRef } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw, convertToRaw, ContentState, Modifier, Entity, AtomicBlockUtils } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import $ from "jquery";
import embed from "embed-video";

function HtmlInputEditor({ editorState, setEditorState, readOnly, data, attachmentClick, sendClick, placeholder, subjectLine, updateSubjectLine, resetFeed, attachments, isImportant, setIsImportant, showcurservalue }) {
    const [users, setUsers] = useState('');
    const isInvalid = false;
    // const [isImportant, setIsImportant] = useState(false);
    const [sendActive, setSendActive] = useState(false);
    const [editorActive, setEditorActive] = useState(false);
    const [currentimage, setcurrentimage] = useState(true);
    const [inputFocus, setInputFocus] = useState(false);
    const editorRefInput = useRef();
    const editorReference = useRef();

    const onEditorStateChange = (editorState) => {
        setEditorState(editorState)
    };

    useEffect(() => {
        const messageValue = convertToRaw(editorState.getCurrentContent());
        feedStateChange(messageValue)
    }, [attachments]);

    useEffect(() => {
        editorReference.current.focusEditor();
    }, [showcurservalue]);

    useEffect(() => {
        setIsImportant(false)
        setEditorActive(false)
    }, [resetFeed]);

    useEffect(() => {
        setTimeout(() => {
            editorRefInput.current.focus();
        }, 50);
    }, []);

    const getBase64 = async (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    const addCls = () => {
        setEditorActive(true)
    }

    const removeCls = (status = '') => {
        // setTimeout(() => {
        //     setEditorActive(false)
        // }, 50);
    }

    function uploadImageCallBack(file) {
        return new Promise((resolve, reject) => {
            getBase64(file)
                .then((data) => {
                    resolve({ data: { link: data } });
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    const handlePastedFiles = async (files) => {
        getBase64(files[0])
            .then((data) => {
                setEditorState(insertImage(data));
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const insertImage = (url) => {
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity("IMAGE", "IMMUTABLE", { src: url });
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });
        return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, " ");
    };

    // const toggleImportant = () => {
    //     setIsImportant(!isImportant)
    // }

    const feedStateChange = (e) => {
        let blocks = e.blocks
        let tempTxt = ''
        blocks.map(i => {
            tempTxt = tempTxt + '' + (i.text).trim()
        })
        let entitymap = e.entityMap
        let embedArr = []
        Object.values(entitymap).forEach(entity => {
            if (entity.type === 'EMBEDDED_LINK') {
                embedArr.push(entity.data.src);
            }
        });

        if ((tempTxt != '') || (embedArr.length != 0) || (attachments.length != 0)) {
            setSendActive(true)
        }
        else {
            setSendActive(false)
        }
    }

    return (


        <div className={(!isInvalid ? "post-input-content mb-0" : "file-req is-invalid mb-0") + ' ' + (isImportant ? "isimportant" : "")}>

            {isImportant ? <i class="fal fa-exclamation important-icon"></i> : null}
            {/* {(editorActive || inputFocus) ? */}
            <div className="post-static-input">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Add a Subject"
                    ref={editorRefInput}
                    onChange={updateSubjectLine}
                    value={subjectLine}
                    onFocus={() => { setInputFocus(true) }}
                    onBlur={() => {
                        setTimeout(() => {
                            setInputFocus(false)
                        }, 200);
                    }}
                    maxLength={140}
                />
            </div>
            {/* : null} */}
            <Editor
                readOnly={readOnly}
                editorState={editorState}
                editorClassName="editorClassName"
                onEditorStateChange={onEditorStateChange}
                handlePastedFiles={handlePastedFiles}
                onChange={e => feedStateChange(e)}
                placeholder={placeholder}
                ref={editorReference}
                toolbarHidden={false} // !editorActive && !inputFocus
                onFocus={() => {
                    addCls()
                }}
                // onBlur={() => {
                //     removeCls()
                // }}
                mention={{
                    separator: ' ',
                    trigger: '@',
                    suggestions: data,
                }}
                toolbar={{
                    options: ['inline', 'textAlign', 'list', 'embedded'
                        //  'blockType', 'fontSize', 'fontFamily',   'colorPicker',  'image', 'link', 'embedded', 'emoji',  'remove', 'history'
                    ],
                    inline: {
                        options: ['bold', 'italic', 'underline'],
                        italic: { className: 'i-italic-icon' },
                        bold: { className: 'i-bold-icon' },
                        underline: { className: 'i-underline-icon' },
                    },
                    list: {
                        options: ['unordered', 'ordered'],
                        unordered: { className: 'i-unordered-icon' },
                        ordered: { className: 'i-ordered-icon' },
                        // indent: { className: 'i-indent-icon' },
                        // outdent: { className: 'i-outdent-icon' },
                    },
                    textAlign: {
                        options: ['left', 'center', 'right', 'justify'],
                        left: { className: 'i-left-icon' },
                        center: { className: 'i-center-icon' },
                        right: { className: 'i-right-icon' },
                        justify: { className: 'i-justify-icon' },
                    },
                    blockType: {
                        inDropdown: false,
                        options: ['H1', 'Blockquote'],
                        className: 'i-bloctype-icon',
                        // H1: {className: 'i-h1-icon' },
                        // Blockquote: {className: 'i-blockquote-icon' },
                    },
                    image: {
                        urlEnabled: true,
                        uploadEnabled: true,
                        previewImage: true,
                        uploadCallback: uploadImageCallBack,
                        inputAccept: 'application/zip,application/pdf,text/plain,application/vnd.openxmlformatsofficedocument.wordprocessingml.document,application/msword,application/vnd.ms-excel,image/gif,image/jpeg,image/jpg,image/png,image/svg'
                    },
                    embedded: {
                        className: 'i-embedded-icon',
                        embedCallback: link => {
                            const detectedSrc = /<iframe.*? src="(.*?)"/.exec(embed(link));
                            return (detectedSrc && detectedSrc[1]) || link;
                        }
                    }
                }}
            />
            <div className="action-input-content">
                <button onClick={() => { setIsImportant(!isImportant) }} className={`btn btn-input-action ${isImportant ? 'active' : ''}`} title="Important"><i className="fal fa-exclamation"></i></button>
                <button onClick={attachmentClick} className="btn btn-input-action" title="Add Attachment"><i className="fal fa-paperclip"></i></button>
                {sendActive ?
                    <button onClick={sendClick} className="btn btn-input-action" title="Send"><i className="fal fa-paper-plane"></i></button>
                    :
                    <button onClick={() => { }} className="btn btn-input-action" title="Send" disabled><i className="fal fa-paper-plane"></i></button>
                }
            </div>
        </div>

    );
}

export default HtmlInputEditor;