const initialState = {
    ticketnotificationcount: 0
  };
  
  const ticketNotificationReducer = (state = initialState, action) => {
    switch (action.type) {
      case "SET_TICKET_NOTIFICATION_COUNT":
        state.ticketnotificationcount = action.payload;
        return {
          ticketnotificationcount: state.ticketnotificationcount
        };
      case "SET_UNREAD_TICKET_NOTIFICATION_COUNT":
        state.ticketnotificationcount = action.payload;
        return {
          ticketnotificationcount: state.ticketnotificationcount
        };
      case "UNSET_UNREAD_TICKET_NOTIFICATION_COUNT":
        return {
          ticketnotificationcount: 0
        };
      case "SET_SELECTED_TICKET_NOTIFICATION":
        state.selectednotification[action.payload[0]] = action.payload[0];
        return {
          ticketnotificationcount: state.ticketnotificationcount
        };
      default:
        return state;
    }
  };
  
  export default ticketNotificationReducer;
  