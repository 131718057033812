import React, { useEffect, useState, useRef } from "react";
import ReactHtmlParser from "react-html-parser";
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';
import { Formik } from "formik";
import * as Yup from "yup";
import { useFormik } from "formik";
import AppealText from "../course/AppealText";
import {
  AppealSubmission,
  GetAssignmentDetail, 
} from "../../services/QualificationService";
import Swal from "sweetalert2";
import {getRandomInt}  from '../../utils/commonFunction.js'
import { indexOf } from "underscore";

 const SubmitAppeal = (props) => {
  const [selectedFile, setSelectedFile] = useState([]);
  const [loading, setLoading] = useState(false);
  const Layout = ({ input, previews, submitButton, dropzoneProps, files, extra: { maxFiles } }) => {
    return (
      <div>
        {previews}
        <div {...dropzoneProps}>
          {files.length < maxFiles && input}
        </div>
        </div>
    )
  }
  const getUploadParams = ({ meta }) => { 
    return {
       url: 'https://httpbin.org/post' 
    }
  }
  
   const handleChangeStatus = ({ meta, remove,file }, status) => { 
    //console.log(file)
    if (status === 'headers_received') {
      setSelectedFile((files) => [...files, file]);
      //toast(`${meta.name} uploaded!`)
      //remove()
    } else if (status === 'removed') {
      const currentFiles = [...selectedFile];
      currentFiles.splice(currentFiles.indexOf(file),1);
      setSelectedFile(currentFiles)
      //toast(`${meta.name}, upload failed...`)
    }
   }
  
  const validationSchema = Yup.object({
    reason: Yup.string().required("Reason for not submitting is required"),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      description: "",
      reason: "",
      assignment_id:props.row.id
    },
    validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      const formInputs = new FormData();
      selectedFile.map((item, index) => {
        formInputs.append(`file_${index + 1}`, item);
      });
      Object.keys(values).map((item) => {
        formInputs.append(item, values[item]);
      });

      AppealSubmission(formInputs)
      .then((res) => {
        Swal.close();
        Swal.fire({
          icon: "success",
          title: 'Appeal Submitted',
          text:'You will receive a response within 48 hours',
          showConfirmButton: true
        }).then((result) => {
          props.setAssignmentReload(getRandomInt(7));
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: error.response?.data?.message,
        }).then((result) => {});
      }).finally(()=>{setLoading(false)});
    },
  });
  const {setAssignmentReload} = props;
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div class="ac-card">
          <h3 class="header-title">Register an Appeal</h3>
          <div class="ac-white-card">
              <div class="row rowAssessment">
                  <div class="col-12 assessment-col-left">
                      <div class="d-flex flex-wrap assMentbox">
                           <div>
                            <b>Reason For Not Submitting Assessment:</b>
                          </div>
                          <div class="flex-shrink">
                              <div class="fmInput">
                                  <select  
                                  class="form-control"  
                                  onChange={formik.handleChange}
                                  value={formik.values.reason}
                                  name="reason"
                                  id="reason" >
                                  <option>--Select Reason--</option>
                                  <option value="Sick" >Sick</option>	
                                  <option value="Family Responsibility">Family Responsibility</option>	
                                  <option value="Pre-Arranged Appeal">Pre-Arranged Appeal</option>	
                                  <option value="Portal Difficulties">Portal Difficulties</option>	
                                  <option value="Other">Other</option>
                                  </select>
                              </div>                              
                          </div>
                      </div>
                  </div>
              </div>
              <div class="row rowAssessment">
                  <div class="col-12 assessment-col-left">
                      <div class="d-flex flex-wrap assMentbox">
                          <div>
                              <b>Description:</b>
                          </div>
                          <div class="flex-shrink">
                              <div class="fmInput">
                                  <textarea class="comments form-control"
                                  onChange={formik.handleChange}
                                  value={formik.values.description}
                                  name="description"
                                  id="description" ></textarea>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>	
              <div class="row rowAssessment">
                  <div class="col-12 assessment-col-left">
                      <div class="d-flex flex-wrap assMentbox">
                          <div>
                              <b>Supporting Files:</b>
                          </div>
                          <div class="flex-shrink">
                            <p>Do not upload assessments for appeal!</p><br/>
                              <div class="fmInput">
                              <Dropzone
                              getUploadParams={getUploadParams}
                              onChangeStatus={handleChangeStatus}
                              styles={{ dropzone: { minHeight: 200, maxHeight: 250 } }}
                              accept=".zip,.jpg,.png,.jpeg,.pdf,.doc,.docx,.txt"
                              inputContent="Drop files here"
                              maxFiles={10}
                              LayoutComponent={Layout}
                              maxSizeBytes="5MB"
                               />
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <AppealText/> 
              <div class="row rowAssessment">
                  <div class="col-12 assessment-col-left">
                      <div class="d-flex flex-wrap assMentbox">
                          <div>
                              
                          </div>
                          <div class="flex-shrink">
                              <input onChange={formik.handleChange}
                                  value={formik.values.assignment_id}
                                  name="assignment_id"
                                  id="assignment_id" 
                                  type="hidden"/>
                              <button disabled={loading} type="submit" className="btn btn-submit btn-primary">Submit</button>
                              {formik.touched.reason && formik.errors.reason && (
                                <div className="invalid-feedback d-block"><strong> {formik.errors.reason} </strong></div>
                              )}
                          </div>                          
                      </div>
                      
                  </div>
              </div>
          </div>
        </div>
      </form>
    </>
  );
}
export default SubmitAppeal;
