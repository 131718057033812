import React from "react";
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import Swal from "sweetalert2";
import Str from "./Str";

export const TakePhoto = (props) => {
    // function handleTakePhoto(dataUri) {
    //     // Do stuff with the photo...
    //     console.log('takePhoto', dataUri);
    // }

    // function handleTakePhotoAnimationDone(dataUri) {
    //     // Do stuff with the photo...
    //     console.log('takePhoto');
    // }

    // function handleCameraError(error) {
    //     console.log('handleCameraError', error);
    // }

    function handleCameraStart(stream) {
        // console.log('handleCameraStart');
    }

    function handleCameraStop() {
        // console.log('handleCameraStop');
    }


    // const handleClick = () => {
    //     console.log('the value of cameraref is ', document.getElementById('outer-circle').click())
    // }

    return (
        <Camera
            onTakePhoto={props.handleTakePhoto}
            onTakePhotoAnimationDone={props.handleTakePhotoAnimationDone}
            onCameraError={props.handleCameraError}
            idealFacingMode={FACING_MODES.ENVIRONMENT}
            // idealResolution={{ width: 640, height: 480 }}
            imageType={IMAGE_TYPES.JPG}
            imageCompression={0.97}
            isMaxResolution={true}
            isImageMirror={false}
            isSilentMode={false}
            isDisplayStartCameraError={true}
            isFullscreen={false}
            sizeFactor={1}
            onCameraStart={(stream) => { handleCameraStart(stream); }}
            onCameraStop={() => { handleCameraStop(); }}
        />
    )

};


export class CameraFeed extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasPermission: '',
        };
    }

    processDevices(devices) {
        const videoDevices = devices.filter(dev => dev.kind=="videoinput")
        videoDevices.forEach(device => {
            this.setDevice(device);
        });
    }

    async setDevice(device) {
        const { deviceId } = device;
        if (deviceId) {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: false, video: { deviceId } })
                .then(stream => {
                    // console.log('the value of camera is 1 ', stream)
                    this.setState({ hasPermission: 'yes' })
                    this.videoPlayer.srcObject = stream;
                    setTimeout(() => {

                        this.videoPlayer.play();
                    }, 1000);

                })
                .catch(error => {
                    // console.log('error is ', error.message)
                    this.setState({ hasPermission: 'no' })
                })
        }
    }

    async componentDidMount() {
        let cameras = await navigator.mediaDevices.enumerateDevices();
        this.processDevices(cameras);
    }

    componentWillUnmount() {
        const stream = this.videoPlayer.srcObject;
        const tracks = stream && stream.getTracks();
        tracks && tracks.forEach(function (track) {
            track.stop();
        });
        this.videoPlayer.srcObject = null;
    }

    takePhoto = async () => {
        const stream = this.videoPlayer.srcObject;
        if (stream && stream.active) {
            const { sendFile } = this.props;
            const context = this.canvas.getContext('2d');
            context.drawImage(this.videoPlayer, 0, 0, 680, 360);
            // this.canvas.toBlob(sendFile);
            sendFile(this.canvas.toDataURL())
            Swal.fire({
                icon: "success",
                title: "Image Captured",
            }).then((result) => { });
        }
        else {
            this.setState({ hasPermission: 'no' })
            const cameras = await navigator.mediaDevices.enumerateDevices();
            this.processDevices(cameras);
        }
    };

    render() {
        return (
            <>
                {<h1 className="cam-permission">{(this.state.hasPermission == 'yes') ? '' : (this.state.hasPermission == 'no') ? Str.noCamPermission : ''}</h1>}
                <div className="c-camera-feed">
                    <div className={`c-camera-feed__viewer  ${this.props.showCamFeed ? '' : 'student-card-image-blk-mobile'}`}>
                        <i onClick={this.takePhoto} class={this.state.hasPermission == 'yes' ? "fal fa-camera" : this.state.hasPermission == 'no' ? "fal fa-redo" : ''}></i>
                        <video ref={ref => (this.videoPlayer = ref)} width="680" heigh="360" />
                    </div>

                    <div className="c-camera-feed__stage student-card-image-blk-mobile">
                        <canvas width="680" height="360" ref={ref => (this.canvas = ref)} />
                    </div>
                    {/* <button onClick={this.takePhoto}>Take photo!</button> */}
                </div>
            </>
        );
    }
}

