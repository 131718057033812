import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GetQuestionsService,
  SubmitQuizService,
  SubmitSingleAnswerService,
} from "../../../services/QuizService";
import { clearQuiz, setQuiz, setQuizComplete } from "../../../store/actions";
import ReactHtmlParser from "react-html-parser";
import DisplayQuestion from "./DisplayQuestion";
import Modal from "react-modal";
import Swal from "sweetalert2";
import PublicIP from "public-ip";
import Str from "../../common/Str";


export default function ViewQuestion(props) {
  const [submitLoading, setSubmitLoading] = useState(false);
  const dispatch = useDispatch();
  var interval = null;
  const { quiz } = useSelector((state) => state.quiz);
  const quizData = Object.values(quiz);
  const keys = Object.keys(quiz);

  // console.log(quizData);

  // const [modalIsOpen, setIsOpen] = React.useState(false);
  const [time, settime] = useState(1);
  const [question, setquestion] = useState(0);
  const [quesno, setquesno] = useState(1);
  const [refresh, setrefresh] = useState(false);
  const [unlimited, setunlimited] = useState(false);
  const [clear, setclear] = useState(false);
  const [showmodal, setshowmodal] = useState(false);
  const [questionData, setquestionData] = useState({});
  const [quizdesc, setquizdesc] = useState({});
  const [optionData, setoptionData] = useState([]);
  const [starttime, setstarttime] = useState(0);
  const [loading, setLoading] = useState(false);
  const [attempt_no, setattempt_no] = useState(0);
  const [duration_consumed, setduration_consumed] = useState(0);

  useEffect(() => {
    setclear(!clear);
    if (quizData.length) {
      if (question == 0) {
        setstarttime(new Date().valueOf());
        if(Array.isArray(quizData[0])){
          setquestion(quizData[0][0].qid);  
        } else {
          setquestion(quizData[0].qid);
        }
        setquesno(1);
        setquestionData(quizData[0]);
      }
    }
  },[quiz]);

  function convertMinutestoSeconds(minutes) {
    return Math.floor(minutes * 60);
  }

  useEffect(() => {
    localStorage.removeItem("totaltime");
    GetQuestionsService({ quid: props.quizId, assignment_id: 0 })
      .then((res) => {
        // settime(convertMinutestoSeconds(res.data.description.duration));
        // settime(res.data.description.duration);
        const hr = res?.data?.description?.time_limit_hours;
        const mn = res?.data?.description?.time_limit_minutes;
        const totalSeconds = hr*60*60 + mn*60;
        settime(totalSeconds);
        setquizdesc(res.data.description);
        setattempt_no(parseInt(res.data.totalattempt) + 1);
        setduration_consumed(parseInt(res.data.duration_consumed));
        if (res.data.description.duration != 0) {
          setunlimited(false);
          interval = setInterval(
            () =>
              settime((prevstate) =>
                prevstate != -1 ? prevstate - 1 : prevstate
              ),
            1000
          );
        } else {
          setunlimited(true);
          settime(1);
        }
      })
      .catch((err) => console.log(err));

    return () => {
      if (quizdesc.description != 0) {
        clearInterval(interval);
      }
      localStorage.removeItem("totaltime");
    };
  }, []);

  useEffect(() => {
    // localStorage.removeItem("totaltime");
    var ttimer = setInterval(() => {
      localStorage.setItem(
        "totaltime",
        parseInt(localStorage.getItem("totaltime") || 1) + 1
      );
      setrefresh(!refresh);
    }, 1000);
    return () => {
      clearInterval(ttimer);
      ttimer = null;
      localStorage.removeItem("totaltime");
      for (let i = 0; i < localStorage.length; i++) {
        let value = localStorage.key(i);
        if (value.startsWith("@")) {
          localStorage.removeItem(value);
        }
      }
    };
  }, []);

  const handleQuestion = (id) => {
    const fData = quizData.filter((item) => Array.isArray(item) ? item[0].qid == id : item.qid == id);
    setquestionData(fData[0]);
  };

  const onChange = (oid = []) => {
    dispatch(
      setQuiz([question, { ...questionData, selected: oid, choice: [] }])
    );
    setclear(!clear);
  };

  function fancyTimeFormat(duration) {
    // Hours, minutes and seconds
    var hrs = ~~(duration / 3600);
    var mins = ~~((duration % 3600) / 60);
    var secs = ~~duration % 60;

    var ret = "";

    if (hrs > 0) {
      ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }

    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    return ret;
  }

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const submitQuiz = () => {
    setquizdesc({ ...quizdesc, duration: 0 });
    // settime(-2);
    handleSubmit();
    // props.setshow({ review: true });
  };

  const handleSubmit = async (section = '') => {
    // settime(-2);
    setLoading(true);
    setSubmitLoading(true);
    var score_individual = [];
    var total_score_individual = [];
    var individual_time = [];
    var individual_time_db = [];
    var quizanswer = [];
    var qid = [];

    for (let i = 0; i < localStorage.length; i++) {
      let value = localStorage.key(i);
      if (value.startsWith("@qa")) {
        // console.log(value);
        // const key = value.substr(1);
        // console.log("________ value", value);
        const key = value.replace("@qa","").replace("qa","");
        // console.log("________ key", key);
        const score = quizData.filter((data) => Array.isArray(data) ? data.filter(d=> d.qid == key) : data.qid == key);
        let scoreTemp = 0;
        if (score.length > 0) {
          scoreTemp = score[0].score;
        }
        individual_time.push({
          key,
          time: localStorage.getItem(value),
          score: scoreTemp,
        });
      }
    }

    const getAnswerItem = (item) =>{
      const answerItemList = []
      if (item.question_type == "Multiple Choice Single Answer") {
        if (
          item.selected != "" &&
          item.selected != [] &&
          item.selected != null
        ) {
          answerItemList.push({
            aid: 0,
            qid: item.qid,
            q_option: item.selected,
            score: item.score,
            docs: null,
          });
        }
      }
  
      // Multiple Choice Multiple Answer
      if (item.question_type == "Multiple Choice Multiple Answer") {
        item && item?.selected && item?.selected?.length && item.selected.map((data) => {
          if (
            item.selected != "" &&
            item.selected != [] &&
            item.selected != null
          ) {
            if (data != "" && data != null) {
              answerItemList.push({
                aid: 0,
                qid: item.qid,
                q_option: data,
                score: item.score,
                docs: null,
              });
            }
          }
        });
      }
  
      // Match the Column
      if (item.question_type == "Match the Column") {
        item.choice.map((data, itr) => {
          if (data != "" && data != [] && data != null) {
            answerItemList.push({
              aid: 0,
              qid: item.qid,
              q_option: data,
              score: item.scoreArr[itr],
              docs: null,
            });
          }
        });
      }
  
      // Short Answer
      if (item.question_type == "Short Answer") {
        if (
          item.selected != "" &&
          item.selected != [] &&
          item.selected != null
        ) {
          answerItemList.push({
            aid: 0,
            qid: item.qid,
            q_option: item.selected.toString().trim(),
            score: item.score,
            docs: null,
          });
        }
      }
  
      // Long Answer
      if (item.question_type == "Long Answer") {
        if (
          item.selected != "" &&
          item.selected != [] &&
          item.selected != null
        ) {
          answerItemList.push({
            aid: 0,
            qid: item.qid,
            q_option: item.selected.toString().trim(),
            score: item.score,
            docs: null,
          });
        }
      }
  
      // order list
      if (item.question_type == "Order List") {
        item.choice.map((data, ind)=>{
          if (data !== "" && data.length && data !== null) {
            answerItemList.push({
              aid: 0,
              qid: item.qid,
              q_option: data,
              score: item.scoreArr[ind],
              docs: null,
            });
          }
        })
      }
  
      // Fill In The Blank
      if (item.question_type == "Fill in the blank") {
        item.selected.map((data)=>{
          if (data != "" && data != [] && data != null) {
            answerItemList.push({
              aid: 0,
              qid: item.qid,
              q_option: `${data.q_option}___${JSON.stringify([data.q_option_match.trim()])}`,
              score: data.score,
              docs: null,
            });
          }
        })
      }
  
      // Choice Matrix
      if (item.question_type == "Choice Matrix") {
        item.selected.map((data)=>{
          if (data != "" && data != [] && data != null) {
            answerItemList.push({
              aid: 0,
              qid: item.qid,
              q_option: `${JSON.stringify(data.q_option)}___${JSON.stringify([data.q_option_match])}`,
              score: data.score,
              docs: null,
            });
          }
        })
      }
  
      // Document Upload
      if (item.question_type === "Document Upload") {
        if(item?.docs){
          item.docs.map((d)=>{
            answerItemList.push({
              aid: 0,
              qid: item.qid,
              docs: d,
            });
          })
        }
      }
      return answerItemList;
    }

    quizData.map((item) => {
      // total_score_individual.push(item.correct_score)
      // Multiple Choice Single Answer
      if(item.question_type){
        quizanswer = [...quizanswer, ...getAnswerItem(item)]
        // quizanswer.push(...getAnswerItem(item))
        qid.push(item.qid);
      }else if(Array.isArray(item) && item.length){
        item.map((subItem)=>{
          // total_score_individual.push(subItem.correct_score)
          // quizanswer.push(...getAnswerItem(subItem));
          quizanswer = [...quizanswer, ...getAnswerItem(subItem)]
          qid.push(subItem.qid);
        })
      }
    });

    individual_time.sort(function (a, b) {
      return a.key - b.key;
    });

    individual_time.map((item) => {
      // qid.push(item.key);
      // score_individual.push(item.score);
      individual_time_db.push(item.time);
    });

    qid.map(q=>{
      quizData.map(qd=>{
        if(Number(q) === qd?.qid){
          total_score_individual.push(qd.correct_score)
          score_individual.push(qd.score)
        } else if(Array.isArray(qd)){
            qd.map(subQd=>{
            if(Number(q) === subQd.qid){
              total_score_individual.push(subQd.correct_score)
              score_individual.push(subQd.score)
            }
          })
        }
      })
    })

    // const percentage = individual_time.reduce(
    //   (acc, item, index) => acc + item.score,
    //   0
    // );
    const percentage = score_individual.reduce((acc, item, ind)=>acc+item,0);
    
    const maxMarks = quizData.reduce((acc, item, index) => Array.isArray(item) ? acc + item.reduce((a,v,i)=>a + v.correct_score, 0) : acc + item.correct_score, 0);

    if (section == 'unanswerStatus') {
      let setFinalStr = false
      // for (var i = 0; i < qid.length; i++) {
      //   let isAnswered = false
      //   for (var j = 0; j < quizanswer.length; j++) {
      //     if (qid[i] == quizanswer[j].qid) {
      //       isAnswered = true;
      //       break;
      //     }
      //   }
      //   if (!isAnswered) {
      //     setFinalStr = false
      //     break;
      //   }
      //   else {
      //     setFinalStr = true
      //   }
      // }
      const answeredQues = quizanswer.map((ans)=>(ans.qid));
      const uniqueAnseweredQues = [...new Set(answeredQues)];
      // console.log("_______qid",qid);
      // console.log("_______uniqui",uniqueAnseweredQues);
      if(uniqueAnseweredQues.length===qid.length){
        setFinalStr = true;
      } 
      setToFinal(setFinalStr ? '' : '<p><span style="color:#e74c3c">You have unanswered questions</span></p>')
      return
    }
    // let score_individualsss = {}
    // quizanswer.map(item => {
    //   if (score_individualsss[item.qid]) {
    //     score_individualsss[item.qid] = score_individualsss[item.qid] + item.score
    //   } else {
    //     score_individualsss[item.qid] = item.score
    //   }
    // })

    // check hybrid question
    let isHybrid = false;
    const hybridTypeQues = ["Long Answer", "Document Upload"];
    for (let q = 0; q < quizData.length; q++) {
      const question = Array.isArray(quizData[q]) ? quizData[q] : [quizData[q]];
      for (let inQ = 0; inQ < question.length; inQ++) {
        if (hybridTypeQues.includes(question[inQ].question_type)) {
          isHybrid = true;
          break;
        }
      }
      if (isHybrid) {
        break;
      }
    }

    const body = {
      quid: props.quizId,
      totaltime:
        parseInt(localStorage.getItem("totaltime")) + duration_consumed ? parseInt(localStorage.getItem("totaltime")) + duration_consumed : 0,
      score_individual: score_individual.join(),
      total_score_individual: total_score_individual.join(),
      individual_time_db: individual_time_db.join(),
      score: percentage,
      percentage: ((percentage / maxMarks) * 100).toFixed(4),
      result_status:
        (percentage / maxMarks) * 100 >= quizdesc.pass_percentage
          ? "pass"
          : "fail",
      user_type: "student",
      starttime: starttime,
      endtime: new Date().valueOf(),
      publicip: await PublicIP.v4(),
      quizanswer,
      r_qids: qid.join(),
      assessment_id: props.aid,
      total_score:maxMarks,
      hybrid: isHybrid ? 1 : 0,
    };
    SubmitQuizService(body)
      .then((res) => {
        Swal.close()
        // props.stop(true)
        if (res.data.status) {
          localStorage.removeItem("totaltime");
          for (let i = 0; i < localStorage.length; i++) {
            let value = localStorage.key(i);
            if (value.startsWith("@")) {
              localStorage.removeItem(value);
            }
          }
          for (let i = 0; i < localStorage.length; i++) {
            let value = localStorage.key(i);
            if (value.startsWith("@")) {
              localStorage.removeItem(value);
            }
          }
          localStorage.removeItem("totaltime");
          props.setrId(res.data.rid);
          setLoading(false);
          props.setshow((prevState) => ({
            ...prevState,
            review: true,
          }));
          // props.initiateReload()
        }
      })
      .catch((err) => {Swal.close();console.log(err);})
      .finally(()=>{setSubmitLoading(false);});
  };

  const AutoSubmitModal = () => {
    // if (time == -1) {
    //   // setshowmodal(true);
    //   Swal.fire({
    //     icon: "success",
    //     title: "Quiz Submitted",
    //   })
    //     .then((res) => {
    //       submitQuiz();
    //     })
    //     .catch(() => {
    //       submitQuiz();
    //     });
    // }
    return (
      <div className="card card-header-transparent card-header-transparent-no-padding">
        <div className="card-header">{quizdesc && quizdesc.quiz_name}</div>
        <div className="card-body">
          <div className="card card-quiz card-header-dark">
            <div className="card-header">Submit Quiz</div>
            <div className="card-body">
              <div className="form-group">
                <label className="d-block mb-3">
                  <strong>Are you sure you want to submit this quiz?</strong>
                </label>
              </div>
            </div>
          </div>

          <div className="quiz-footer-btns mt-4">
            {/* <a
              onClick={handleSubmit}
              className="btn btn-white-bordered btn-primary"
            > */}
            <button
              onClick={handleSubmit}
              className="btn btn-white-bordered btn-primary"
              disabled={loading}
            >
              {loading ? (
                <i className="fas fa-cog fa-spin"></i>
              ) : (
                <i className="fal fa-check"></i>
              )}
              Submit Quiz
            </button>
            {/* </a> */}

            <button
              onClick={() => setshowmodal(false)}
              className="btn btn-danger"
              disabled={loading}
            >
              <i className="fal fa-times"></i>Cancel
            </button>
          </div>
        </div>
      </div>
    );
  };

  if (time == 0) {
    if (quizdesc.description != 0) {
      clearInterval(interval);
    }
    Swal.fire({
      icon: "success",
      title: "Quiz Submitted",
    }).then(() => {
      submitQuiz();
    });
  }

  const isShowing = (index) => {
    if (quizData.length <= 10) {
      return ''
    }
    else if (quesno > 4 && quesno < quizData.length - 5) {
      if (inRange(index, quesno - 4, quesno + 5)) {
        return ''
      }
      else {
        return 'd-none'
      }
    }
    else if (quesno <= 4) {
      if (inRange(index, 0, 9)) {
        return
      }
      else {
        return 'd-none'
      }
    }
    else if (quesno >= quizData.length - 5) {
      if (inRange(index, quizData.length - 10, quizData.length)) {
        return
      }
      else {
        return 'd-none'
      }
    }
  }

  function inRange(x, min, max) {
    return ((x - min) * (x - max) <= 0);
  }

  const handleNav = (nextNav) => {
    if (nextNav == 'next' && quesno != (quizData.length)) {
      setquesno(quesno + 1);
      localStorage.setItem(
        Array.isArray(quizData[quesno]) ? `@qa${quizData[quesno][0].qid}qa` : `@qa${quizData[quesno].qid}qa`,
        parseInt(localStorage.getItem(Array.isArray(quizData[quesno]) ? `@qa${quizData[quesno][0].qid}qa` : `@qa${quizData[quesno].qid}qa`) || 0) + 1
      );
      setquestion(Array.isArray(quizData[quesno]) ? quizData[quesno][0].qid : quizData[quesno].qid);
      handleQuestion(Array.isArray(quizData[quesno]) ? quizData[quesno][0].qid : quizData[quesno].qid);
      // handleSaveAndNext();
    }
    else if (nextNav == 'back' && quesno != 1) {
      // console.log('the ques and quizData is ', quesno, quizData, quizData.length)
      setquesno(quesno - 1)
      localStorage.setItem(
        Array.isArray(quizData[quesno - 2]) ? `@qa${quizData[quesno - 2][0].qid}qa` : `@qa${quizData[quesno - 2].qid}qa`,
        parseInt(localStorage.getItem(Array.isArray(quizData[quesno - 2]) ? `@qa${quizData[quesno - 2][0].qid}qa` : `@qa${quizData[quesno - 2].qid}qa`) || 0) + 1
      );
      setquestion(Array.isArray(quizData[quesno - 2]) ? quizData[quesno - 2][0].qid : quizData[quesno - 2].qid);
      handleQuestion(Array.isArray(quizData[quesno - 2]) ? quizData[quesno - 2][0].qid : quizData[quesno - 2].qid);
      // handleSaveAndNext();
    }
    else if (nextNav == 'last' && quesno != (quizData.length)) {
      setquesno(quizData.length)
      localStorage.setItem(
        Array.isArray(quizData[quizData.length - 1]) ? `@qa${quizData[quizData.length - 1][0].qid}qa` : `@qa${quizData[quizData.length - 1].qid}qa`,
        parseInt(localStorage.getItem(Array.isArray(quizData[quizData.length - 1]) ? `@qa${quizData[quizData.length - 1][0].qid}qa` : `@qa${quizData[quizData.length - 1].qid}qa`) || 0) + 1
      );
      setquestion(Array.isArray(quizData[quizData.length - 1]) ? quizData[quizData.length - 1][0].qid : quizData[quizData.length - 1].qid);
      handleQuestion(Array.isArray(quizData[quizData.length - 1]) ? quizData[quizData.length - 1][0].qid : quizData[quizData.length - 1].qid);
      // handleSaveAndNext();
    }
    else if (nextNav == 'first' && quesno != 1) {
      setquesno(1)
      localStorage.setItem(
        Array.isArray(quizData[0]) ? `@qa${quizData[0][0].qid}qa` : `@qa${quizData[0].qid}qa`,
        parseInt(localStorage.getItem(Array.isArray(quizData[0]) ? `@qa${quizData[0][0].qid}qa` : `@qa${quizData[0].qid}qa`) || 0) + 1
      );
      setquestion(Array.isArray(quizData[0]) ? quizData[0][0].qid : quizData[0].qid);
      handleQuestion(Array.isArray(quizData[0]) ? quizData[0][0].qid : quizData[0].qid);
      // handleSaveAndNext();
    }
  }

  const setToFinal = (str) => {
    Swal.close();
    Swal.fire({
      title: 'Are you sure?',
      // text: Str.setFinalPopup,
      html: `${Str.setFinalPopup} ${str}`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: Str.Submit,

    }).then(function (result) {
      if (result.value) {
        handleSubmit('')
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "",
          timer: 3000,
          onOpen: function () {
            Swal.showLoading();
          },
        })
        // Swal.close()

      }else{
        setSubmitLoading(false);
      }
    });
  }

  const renderQuesType = (type) => {
    switch (type) {
      case "Multiple Choice Single Answer":
        return <span className="ques__type">Single Choice <i className="fal fa-tasks"></i></span>;
      case "Multiple Choice Multiple Answer":
        return <span className="ques__type">Multiple Choice <i className="far fa-check-square"></i></span>;
      case "Match the Column":
        return <span className="ques__type">Match The Column <i className="far fa-line-columns"></i></span>;
      case "Short Answer":
        return <span className="ques__type">Short Answer <i className="far fa-comment-alt-lines"></i></span>;
      case "Long Answer":
        return <span className="ques__type">Long Answer <i className="fal fa-align-left"></i></span>;
      case "Fill in the blank":
        return <span className="ques__type">Fill In The Blanks <i className="far fa-keyboard"></i></span>;
      case "Order List":
        return <span className="ques__type">Order List <i className="fas fa-sort-numeric-down"></i></span>;
      case "Choice Matrix":
        return <span className="ques__type">Choice Matrix <i className="fal fa-table"></i></span>;
      case "Document Upload":
        return <span className="ques__type">Document Upload <i className="fal fa-file-upload"></i></span>;
      default:
        return
    }
  }

  const checkLocalstorageKeys = (item) => {
    let isZero = 0;
    if (Array.isArray(item)) {
      for (let i = 0; i < item.length; i++) {
        if (!localStorage.getItem(`@qa${item[i].qid}qa`) || localStorage.getItem(`@qa${item[i].qid}qa`)==null || localStorage.getItem(`@qa${item[i].qid}qa`) == 0) {
          isZero = 0;
        } else {
          isZero = 1;
          break;
        }
      }
    } else {
      isZero = (!localStorage.getItem(`@qa${item.qid}qa`) || localStorage.getItem(`@qa${item.qid}qa`)==null || localStorage.getItem(`@qa${item.qid}qa`) == 0) ? 0 : 1;
    }
    return isZero;
  }

  const isSelectedAnswer = (item) => {
    let isSelected = false;
    if (Array.isArray(item)) {
      for (let i = 0; i < item.length; i++) {
        // console.log("___________________ selected ______", item[i].selected);
        if (item[i]?.selected?.length > 0 && item[i]?.selected !== "" && item[i].selected !== undefined && item[i].selected !== null) {
          isSelected = true;
          break;
        } else {
          isSelected = false;
        }
      }
    } else {
      isSelected = (item?.selected?.length > 0 && item?.selected !== "" && item?.selected !== undefined && item?.selected !== null) ? true : false;
    }
    return isSelected;
  }

  return (
    <div class="card card-header-transparent learning__material__quiz">
      {/* {!showmodal && time != 0 ? ( */}
        <>
          {/* <div class="card-header">{quizdesc && quizdesc.quiz_name}</div> */}
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col-md-4">
                {unlimited ? (
                  <></>
                ) : (
                  <div class="quicz-time py-3">
                    Time Left: <strong>{fancyTimeFormat(time)}</strong>
                  </div>
                )}
              </div>
              <div class="col-md-8 text-right">
                {/* <div class="quiz-labels">
                  <label class="label label-quiz-red">
                    <span class="label-square-icon"></span>Unanswered
                  </label>
                  <label class="label label-quiz-green">
                    <span class="label-square-icon"></span>Answered
                  </label>
                  <label class="label label-quiz-orange">
                    <span class="label-square-icon"></span>Not-visited
                  </label>
                </div>
              </div> */}
            </div>
            </div>

            {/* <DisplayQuestion
              question={questionData}
              refresh={refresh}
              setrefresh={setrefresh}
              quesno={quesno}
              clear={clear}
            /> */}
            {/* Question Group */}
            {Array.isArray(questionData) ? (
              <div class="card card-quiz card-header-dark">
                <div class="card-header">{questionData[0]?.group_name}</div>
                <div class="card-body">
                  {questionData.map((q,i)=>(
                    <div key={i} className={(questionData.length -1) === i ? "" : "mb-4"}>
                    <DisplayQuestion
                      grpId={questionData[0].qid}
                      qInd={i}
                      question={q}
                      setquestion={setquestionData}
                      refresh={refresh}
                      setrefresh={setrefresh}
                      // quesno={quesno}
                      clear={clear}
                      showQuesType={renderQuesType(q?.question_type)}
                    />
                    {i !== questionData.length -1 ? <hr/> : null}
                    </div>
                  ))}
                </div>
              </div>
            ) :
              (<div class="card card-quiz card-header-dark">
                <div class="card-header">Question {quesno} {renderQuesType(questionData?.question_type)}</div>
                <div class="card-body">
                  <DisplayQuestion
                    question={questionData}
                    setquestion={setquestionData}
                    refresh={refresh}
                    setrefresh={setrefresh}
                    // quesno={quesno}
                    clear={clear}
                  />
                </div>
              </div>)
            }

            <ul className="quiz-info">
              <li className="Unanswered-quiz">Unanswered</li>
              <li className="answered-quiz">Answered</li>
              <li className="non-visited">Not-visited</li>
            </ul>
            <div className="pagination-btn-group d-flex justify-content-between mt-4 mb-4">

              <ul class="quiz-pagination d-flex">

                <li
                  class={"page-item action-pagination " + (quesno != 1 ? '' : 'disabled')}
                  onClick={() => { handleNav('first') }}
                >

                  <span class={"page-link pre-page-link "}>
                    <i class="fas fa-angle-double-left"></i>
                  </span>
                </li>
                <li
                  onClick={() => { handleNav('back') }}
                  class={"page-item action-pagination pre-step-link " + (quesno != 1 ? '' : 'disabled')}>
                  <span class="page-link" aria-label="Previous">
                    <i class="fas fa-chevron-left"></i>
                    <span class="sr-only"><i class="fas fa-angle-double-left"></i></span>
                  </span>
                </li>

                {quizData.map((item, index) => {
                  var cls = "btn btn-white-bordered ";
                  if (isSelectedAnswer(item)) {
                    cls += "btn-white-bordered-green";
                  } else if (
                    // parseInt(localStorage.getItem((Array.isArray(item) ? `@${item.map(i=>i.qid).join()}` : `@${item.qid}`))) == 0
                    checkLocalstorageKeys(item) === 0 && index !== 0
                  ) {
                    cls += "btn-white-bordered-orange";
                  } else if (
                    // (Array.isArray(item) ? item.map(i=>i.selected).join('') : item.selected) == "" &&
                    // parseInt(localStorage.getItem((Array.isArray(item) ? `@${item.map(i=>i.qid).join()}` : `@${item.qid}`))) != 0
                    index === 0 || (!isSelectedAnswer(item) && checkLocalstorageKeys(item) !== 0)
                  ) {
                    cls += "btn-white-bordered-red";
                  }
                  cls = cls + ` ${quesno - 1 == index ? 'active' : ''} ${isShowing(index)}`
                  return (
                    // <a
                    //   onClick={() => {
                    //     setquesno(index + 1);
                    //     localStorage.setItem(
                    //       "@" + item.qid,
                    //       parseInt(localStorage.getItem("@" + item.qid) || 0) + 1
                    //     );
                    //     setquestion(item.qid);
                    //     handleQuestion(item.qid);
                    //     handleSaveAndNext();
                    //   }}
                    //   class={cls}
                    // >
                    //   {index + 1}
                    // </a>
                    <li
                      class={cls}
                      onClick={() => {
                        setquesno(index + 1);
                        localStorage.setItem(
                          `@qa${(Array.isArray(item) ? item[0].qid : item.qid)}qa`,
                          parseInt(localStorage.getItem(`@qa${(Array.isArray(item) ? item[0].qid : item.qid)}qa`) || 0) + 1
                        );
                        setquestion(Array.isArray(item) ? item[0].qid : item.qid);
                        handleQuestion(Array.isArray(item) ? item[0].qid : item.qid);
                        // handleSaveAndNext();
                      }}>
                      <span class="page-link">{index + 1}</span>
                    </li>
                  );
                })}
                <li
                  class={"page-item action-pagination next-step-link" + (quesno == quizData.length ? ' disabled' : '')}
                  onClick={() => { handleNav('next') }}
                >
                  <span class="page-link " aria-label="Next">
                    <i class="fas fa-chevron-right"></i>
                    <span class="sr-only">Next</span>
                  </span>
                </li>
                <li
                  class={"page-item action-pagination" + (quesno == quizData.length ? ' disabled' : '')}
                  onClick={() => { handleNav('last') }}
                >
                  <span class="page-link next-page-link"><i class={"fas fa-angle-double-right"}></i></span>
                </li>
              </ul>

              <div class="quiz-footer-btns ">
                {/* <a onClick={() => onChange()} class="btn btn-danger">
                  <i class="fal fa-redo-alt fa-flip-horizontal"></i>Clear
                </a>
                {quizData[0]?.qid != question && (
                  <a
                    onClick={() => {
                      const val = keys.indexOf(question.toString());
                      setquestion(keys[val - 1]);
                      setquesno(val);
                      handleQuestion(keys[val - 1]);
                      handleSaveAndNext();
                    }}
                    class="btn btn-white-bordered btn-secondary"
                  >
                    <i class="fal fa-angle-left"></i>Back
                  </a>
                )} */}

                {quizData.length != quesno ? (
                  <span
                    onClick={() => {
                      const val = keys.indexOf(`qa${question.toString()}qa`);
                      localStorage.setItem(
                        `@${keys[val + 1]}`,
                        parseInt(localStorage.getItem(`@${keys[val + 1]}`) || 0) +
                        1
                      );
                      setquestion(keys[val + 1].replaceAll("qa",""));
                      setquesno(val + 2);
                      handleQuestion(keys[val + 1].replaceAll("qa",""));
                      // handleSaveAndNext();
                    }}
                    className="btn btn-white-bordered btn-primary"
                  >
                    {/* <i className="fal fa-angle-right"></i> */}
                    Next
                  </span>
                ) : (
                  <button
                    onClick={() => {
                      // setshowmodal(true);
                      handleSubmit('unanswerStatus')
                    }}
                    className={"btn btn-primary check-btn "}
                    disabled={submitLoading==true}
                  >
                    <i className={submitLoading==true ? "fas fa-cog fa-spin" : "fas fa-check"}></i>
                    Set to Final
                  </button>
                )}
              </div>
            </div>
          </div>
        </>
      {/* ) : (
         <AutoSubmitModal />
       )} */}
    </div>
  );
}
