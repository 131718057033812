import React, { useState } from 'react'
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { submitReRegistrationService } from '../../../services/ReRegistrationService';

export default function SchoolOfEmendyReadOnly(props) {
  const { reRegSubject, registration,regForReadOnly,repeatReadOnly, setReadOnly, setStudentNewRegId, electiveReadOnly } = props;
  const [loading, setLoading] = useState(false);  
  const ReRegisterSchema = Yup.object({
    term_condn: Yup.bool().oneOf([true], "Accept Terms & Conditions is required")
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      rereg_academy: registration.academy,
      rereg_id: registration.id,
      registerFor: regForReadOnly,
      repeat: repeatReadOnly?.length ? repeatReadOnly.split(",") : [""],
      elective: electiveReadOnly?.length ? electiveReadOnly.split(",") : [""],
      term_condn: true
    },
    validationSchema: ReRegisterSchema,
    onSubmit: (values) => {
      submitReRegistrationService(values).then((res) => {
        Swal.fire({
          icon: "success",
          title: "Successfully Submitted",
          text: "This does not confirm approval of your re-registration!!",
          timer: 3000,
          onOpen: function () {
            Swal.showLoading();
          },
        }).then(function (result) {
          setLoading(true);
          setStudentNewRegId(res.data.studentNewRegId);
          setReadOnly(true);
          props.updateStatus();
        });
      });
    }
  });

  const { values, errors, touched, handleSubmit, handleBlur, setValues } = formik;

  const handleCheck = (e) => {
    setValues({
      ...values,
      term_condn: e.target.checked
    })
  }


  return (
    <div className="card-body">
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <div className="theme-form application-form">
            <div className="row">
              <div className="form-group col-sm-12 inputfield">
                <h5 className="textdanger heading-5">Register For:</h5>
                <label>
                Should you have passed your current level, as per your transcript results, please choose the programme you would like to register for/register for the next level of your programme.
                </label>
                <div className="form-group inputfield col-6">
                <select
                          className={"form-control " + (touched.registerFor && errors.registerFor ? " is-invalid" : "")}
                          name="register_for"
                          id="registerFor"
                          value={values.registerFor}
                          disabled
                          // onChange={(e) => { handleChangeRegisterFor(e, idx) }}
                        >
                          <option value="">---</option>
                          <option value="21">Higher Certificate in Game Design and Development</option>
                          <option value="22">Higher Certificate in Creative Music Technology</option>
                          <option value="23">Diploma in Music Technology (1st Year)</option>
                          <option value="24">Diploma in Music Technology (2nd Year)</option>
                          <option value="25">Diploma in Music Technology (3rd Year)</option> 
                        </select>
                </div>
              </div>
              <div className="form-group col-sm-12 inputfield">
                <h5 className="textdanger heading-5">Repeat:</h5>
                <label>
                  Refer to your transcript to determine which modules you will need to repeat. Select all appropriate modules.
                </label>
                <div className="form-group inputfield col-12">
                  {Array.isArray(values.repeat) && values.repeat.map((element, idx) => (
                    <div className="row" key={idx}>
                      <div className="col-6">
                        <select
                          className={"form-control " + (touched.repeat && errors.repeat ? " is-invalid" : "")}
                          name="register_for"
                          id="repeat"
                          value={element}
                          disabled
                          // onChange={(e) => { handleChangeRepeat(e, idx) }}
                        >
                          <option value="">---</option>
                          <option value="101">Applied Digital Arts 101</option>
                          <option value="102">3D Modelling and Animation 101</option>
                          <option value="103">Game Design Practice 101</option>
                          <option value="104">Game Development and Scripting 101</option>
                          <option value="105">Contemporary Game Theory 101</option>
                          <option value="106">Audio for Game Design</option>
                          <option value="107">Audio Fundamentals 101</option>
                          <option value="108">Modern Music Theory 101</option>
                          <option value="109">Music Ear Training 101</option>
                          <option value="110">Music Production Technique 101</option>
                          <option value="111">Music Software Cubase 101</option>
                          <option value="112">Studio Sound Engineering 101</option>
                          <option value="113">Signal Processing 102</option>
                          <option value="114">Modern Music Theory 102</option>
                          <option value="115">Music Ear Training 102</option>
                          <option value="116">Music Production Technique 102</option>
                          <option value="117">Music Software FL Studio 101</option>
                          <option value="118">Recording Projects 102</option>
                          <option value="119">Music Production Software 101</option>
                          <option value="120">Music Production Software 110</option>
                          <option value="121">Music for Game Audio 102</option>
                          <option value="122">Music Production Technique 201</option>
                          <option value="123">Studio Sound Engineering 201</option>
                          <option value="124">Modern Music Theory 201</option>
                          <option value="125">Music Business 201</option>
                          <option value="126">Music Production Software 201</option>
                          <option value="128">Live Sound Engineering 201</option>
                          <option value="129">Music Production Software 210</option>
                          <option value="130">Audio Post Production 301</option>
                          <option value="131">Music Business 301</option>
                          <option value="132">Music Industry Praxis 301</option>
                          <option value="133">Audio Mastering Techniques 302</option>
                          <option value="134">Audio Ear Training 201</option>
                          <option value="135">Recording Project 102</option>
                          <option value="136">Music Business Entrepreneurship 301</option>
                        </select>
                      </div>                      
                    </div>
                  ))}
                </div>
              </div>
              {values.registerFor == "25" ? <div className="form-group col-sm-12 inputfield">
                <h5 className="textdanger heading-5">Repeat Electives:</h5>                
                <div className="form-group inputfield col-12">
                  {Array.isArray(values.elective) && values.elective.map((element, idx) => (
                    <div className="row" key={idx}>
                      <div className="col-6">
                        <select
                          className={"form-control " + (touched.elective && errors.elective ? " is-invalid" : "")}
                          name="elective"
                          id="elective"
                          value={element}
                          disabled
                        >
                          <option value="">---</option>
                          <option value="1001">Media Music Composition 301</option>
                          <option value="1002">Electronic Music Production 301</option>
                          <option value="1003">Sound Synthesis and Design 301</option>
                          <option value="1004">Studio Sound Engineering 301</option>
                        </select>
                      </div>                      
                    </div>
                  ))}                  
                </div>
              </div> : "" }
              <div className="col-12 form-group inputfield">
                <div className="custom-control custom-checkbox text-left box-tm">
                  <input
                    className={
                      "custom-control-input " +
                      (touched.term_condn && errors.term_condn
                        ? " is-invalid"
                        : "")
                    }
                    type="checkbox"
                    name="term_condn"
                    onChange={(e) => handleCheck(e)}
                    onBlur={handleBlur}
                    checked={values.term_condn}
                    id="customCheck1"
                    disabled="disabled"
                  />

                  <label
                    className="custom-control-label textdanger"
                    htmlFor="customCheck1"
                  >
                    I hereby confirm that I have read, understood and accepted
                    all <a href='https://emendy.co.za/pgs-142-terms-and-conditions.html' target="_blank">Terms and Conditions</a> as set out in the application form that the above subject selections are correct, and understand
                    that there may be changes made based on the academic outcome.
                  </label>
                  {formik.touched.term_condn && formik.errors.term_condn ? (
                    <div className="invalid-feedback">
                      {formik.errors.term_condn}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="Fmfooter">
            <button
              title="Submitted"
              type="submit"
              className="btn btn-submit btn-main btn-primary"
              disabled="disabled"
            >
              {loading && <i className="fas fa-cog fa-spin"></i>} Submitted
            </button>
            {/* <button title="Cancel" className="btn btn-submit btn-main btn-danger ml-2">
              Cancel
            </button> */}
          </div>
        </Form>
      </FormikProvider>
    </div>
  )
}
