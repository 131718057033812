import React, { useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
export default function NotificationList(props) {
  const { notification, index, active } = props;
  const [activeClass, setActiveClass] = useState(0);

  const handleSelectNotification = (e, notificationId, readNotificationId) => {
    //console.log(status.includes("notify-read"));

    props.handleSelectNotification(e, notificationId, readNotificationId);
    setActiveClass(1);
  };

  // const handleSelectNotification = (e, notificationId) => {
  //   setActiveClass("abc");
  //   console.log(activeClass);
  //   props.handleSelectNotification(e, notificationId);
  // };

  return (
    <>
      <li
        key={index}
        onClick={(e) => handleSelectNotification(e, notification.id, notification.readNotification)}
      >
       
        <div
          className={`card notify-card ${
            notification.notificationStatusColor
          } ${active == notification.id ? "notify-active" : ''} ${
            activeClass === 1 ? "notify-read" : ''
          }`}
        >
          <div className="card-left">
            <h4>{notification.subject}</h4>
            <p>{ReactHtmlParser(notification.smallContent)}</p>
          </div>
          <div className="card-right">
            <ul className=" d-flex list-unstyled  notify-include-items">
              <li className="date-time">{notification.expiredTime}</li>
              {notification.isFile == 1 ? (
                <li className="attachment">
                  <i className="fal fa-paperclip"></i>
                </li>
              ) : (
                ""
              )}
              {notification.ish_importanceActive == 1 ? (
                <li className="alert-icon">
                  <i className="fal fa-exclamation-square"></i>
                </li>
              ) : (
                ""
              )}
            </ul>
          </div>
        </div>
      </li>
    </>
  );
}
