import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setUser } from "../../../store/actions";
import { useFormik } from "formik";
import * as Yup from "yup";
import { LoginDetailService } from "../../../services/ProfileService";
import Swal from "sweetalert2";
import { EMAIL_REGEX } from "../../../utils/Constants";

const LoginDetails = () => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  user.password = "";
  user.password_confirmation = "";

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: user,
    validationSchema: Yup.object({
      email: Yup.string().matches(EMAIL_REGEX, "Invalid email address")
        .email("Invalid email address")
        .required("Email is required"),
      password: Yup.string()
        .required("Password is required")
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
        ),
      password_confirmation: Yup.string()
        .required("Confirm Password is required")
        .oneOf([Yup.ref("password"), null], "Passwords must match"),
    }),
    onSubmit: (values) => {
      setLoading(true);
      LoginDetailService(values)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Updated successfully",
          }).then((result) => {
            setLoading(false);
            if (result.value) {
              dispatch(setUser(res.data));              
            }
          });
        })
        .catch((error) => {
          setLoading(false);
          Swal.close();
          Swal.fire({
            icon: "error",
            title: "Email is not valid",
            text: "You cannot use an email that already exists",
          }).then((result) => {});
        });
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="row align-items-center">
          <div className="col-md-3">
            <div className="form-group">
              <label>Email *</label>
              <input
                type="email"
                className={
                  "form-control" +
                  (formik.errors.email && formik.touched.email
                    ? " is-invalid"
                    : "")
                }
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="invalid-feedback d-block">
                  {formik.errors.email}
                </div>
              ) : null}
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label>Password *</label>
              <input
                type="password"
                className={
                  "form-control" +
                  (formik.errors.password && formik.touched.password
                    ? " is-invalid"
                    : "")
                }
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.password && formik.touched.password ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label>Confirm Password *</label>
              <input
                type="password"
                className={
                  "form-control" +
                  (formik.errors.password_confirmation &&
                  formik.touched.password_confirmation
                    ? " is-invalid"
                    : "")
                }
                name="password_confirmation"
                value={formik.values.password_confirmation}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.password_confirmation &&
              formik.touched.password_confirmation ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
          <div className="col-md-3">
            <button disabled={loading} className="btn btn-save btn-success" type="submit" title="Save">
              {loading ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-paper-plane"></i>}
                Update Login Details
            </button>
          </div>
        </div>              
      </form>
    </>
  );
};

export default LoginDetails;
