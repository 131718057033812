import React, { useState, useEffect, useRef } from "react";
import ReactDOM from 'react-dom';
import ReactPlayer from "react-player/lazy";
import Loader from "react-loader-spinner";
import screenfull from "screenfull";
import { APP_LINK } from "../../utils/Constants";
import Swal from "sweetalert2";
import CreateNotesLessson from "./CreateNotesLessson";
import LessonNotesList from "./LessonNotesList";
import ReactHtmlParser from "react-html-parser";
import { FetchFileIcon } from "../../utils/commonFunction";
import { image_url } from "../../utils/ImageUrl";
import { download } from "../../utils/FileDownload";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { updateVideoPlayer } from "../../store/actions";
import UpdateAssembliesNote from "./UpdateAssembliesNote";
import { ref } from "yup";
import moment from "moment";
import { Scrollbar } from "react-scrollbars-custom";

export default function VideoPlayer(props) {
  const {
    ParentResumeBtn,
    playing,
    videoId,
    lessonItemId,
    loopId,
    documentId,
    assemblyName,
    assemblyDescription,
    parentRefId,
    watchedVideo,
    url,
    item_url,
    videoType,
    video_duration,
    watch_in_m_s,
    currentVideoId,
    playerSeek,
    alreadyWatched,
    watchPercentage,
    isWatchedVideo,
    lessonindex,
    ParentPlayerPipMode,
    isAdmin,
    transcription,
    videoViewRef,
    setPlayerSeek,
    setPlaying,
    videoState,
    videoDuration
  } = props;

  let { parentRef } = props;
  const dispatch = useDispatch()
  let playerRef = useRef({});
  let previewRef = useRef({});
  let player = {};
  const [tab, setTab] = useState("description");
  const [addNotes, setAddNotes] = useState(false)
  const [buttonCheckedStatus, setButtonCheckedStatus] = useState(0);
  const videoPlayerShow = useSelector(state => state.learningMaterial.videoPlayer);
  const [isMinimized, setIsMinimized] = useState(false);
  const [videoPlay, setVideoPlay] = useState(Date.now())
  const [speed, setSpeed] = useState(1); // Default speed: 1x
  const [minimized, setMinimized] = useState(false);
  const [refresh, setRefresh] = useState(Date.now())
  const [currentTime, setCurrentTime] = useState(0);
  const [editNote, setEditNote] = useState(false)
  const [editNoteId, setEditNoteId] = useState("0")
  const [volume, setVolume] = useState(0.8);
  const [muted, setMuted] = useState(false);
  const [isPlaying, setIsPlaying] = useState(playing || false);
  const [pause, setPause] = useState(null)
  const [showSettings, setShowSettings] = useState(false);
  const [quality, setQuality] = useState('auto');
  const [previousVolume, setPreviousVolume] = useState(0.8);
  const [showControls, setShowControls] = useState(true);
  const [formattedTime, setFormattedTime] = useState("00:00 / 00:00");
  const [showCaptions, setShowCaptions] = useState(false);
  const [duration, setDuration] = useState(0);
  const [isPreviewVisible, setPreviewVisible] = useState(false);
  const [previewTime, setPreviewTime] = useState(0);
  const [previewUrl, setPreviewUrl] = useState('');
  const previewPlayerRef = useRef(null);

  const controlsTimeoutRef=useRef({})
  const hideControlsAfterDelay = () => {
    if (controlsTimeoutRef.current) {
      clearTimeout(controlsTimeoutRef.current);
    }
    controlsTimeoutRef.current = setTimeout(() => {
      if (isPlaying) {
        setShowControls(false);
      }
    }, 3000); // Hide controls after 3 seconds of inactivity
  };

  useEffect(() => {
    if (isPlaying) {
      hideControlsAfterDelay();
    } else {
      setShowControls(true);
    }

    return () => {
      if (controlsTimeoutRef.current) {
        clearTimeout(controlsTimeoutRef.current);
      }
    };
  }, [isPlaying]);

  const handleSpeedChange = (newSpeed) => {
    setSpeed(newSpeed);
    if (playerRef.current[parentRefId]) {
      const player = playerRef.current[parentRefId].getInternalPlayer();
      if (player) {
        player.playbackRate = newSpeed;
      }
    }
  };
  const toggleSettings = () => {
    setShowSettings(!showSettings);
  };

  const handleQualityChange = (newQuality) => {
    setQuality(newQuality);
    // Implement quality change logic here
    // This might involve reloading the video with a different quality URL
  };


  useEffect(() => {
    if (playerRef.current[parentRefId]) {
      const player = playerRef.current[parentRefId].getInternalPlayer();
      if (player) {
        player.playbackRate = speed;
      }
    }
  }, [speed, parentRefId]);

  const toggleMinimized = () => {
    setMinimized(!minimized);
  };
  const { sid } = useParams()

  // const handleSpeedChange = (e) => {
  //   const newSpeed = parseFloat(e.target.value);
  //   setPlaybackRate(newSpeed); 
  //   if (playerRef[parentRefId] && playerRef[parentRefId].getInternalPlayer()) {
  //     playerRef[parentRefId].getInternalPlayer().playbackRate = newSpeed;  // Ensure that speed is directly applied
  //   }
  // };


  const handleVideoStart = (e, player, assemblyItem, videoId) => {
    e.preventDefault();
    props.ParentHandleVideoStart(player, assemblyItem, videoId);
  };

  const handleVideoResumeRP = (player, assemblyItem, videoId, action) => {
    // playerRef.current.seekTo(playerSeek, 'seconds');
    props.ParentHandleVideoResume(player, assemblyItem, videoId, action);
  };

  const handleVideoRewind = (player, assemblyItem, videoId, rewindSec) => {
    if (rewindSec > 0) {
      let setSeek = parseFloat(playerSeek) - parseFloat(rewindSec);
      if (setSeek > 0) {
        props.ParentHandleVideoRewind(player, assemblyItem, videoId);
        player.seekTo(parseFloat(setSeek));
      } else {
        props.ParentHandleVideoStart(player, assemblyItem, videoId);
      }
    }
  };

  const handleVideoForward = (player, assemblyItem, videoId, forwardSec) => {
    if (forwardSec > 0) {
      let currentSeek = player.getCurrentTime();
      let setSeek = parseFloat(currentSeek) + parseFloat(forwardSec);
      if (setSeek > 0) {
        props.ParentHandleVideoForward(player, assemblyItem, videoId);
        player.seekTo(setSeek);
      }
    }
  };
  

  const handleVideoReady = (e, player, assemblyItem, videoId) => {
    e.preventDefault();
    props.ParentHandleVideoReady(player, assemblyItem, videoId);
    dispatch(updateVideoPlayer(true))
  };

  const handleVideoFullScreen = (e, player, assemblyItem, videoId) => {
    e.preventDefault();
    screenfull.request(ReactDOM.findDOMNode(player));
  };

  const handleVideoTogglePIP = (player, assemblyItem, videoId, action) => {
    props.ParentHandleVideoTogglePIP(player, assemblyItem, videoId, action);
  };

  const handleStop = () => {
    // this.setState({ url: null, playing: false })
    // console.log("stop");
    // console.log("Current time",player.current.getCurrentTime())
    // console.log("duration time",player.current.getDuration())

    // console.log(player);
  };

  const handlePlay = () => {
    // console.log("onPlay");
    // this.setState({ playing: true })
  };
//   const handleProgress = (e, player, assemblyItem, videoId) => {
//     const currentTime = playerRef.current[parentRefId]?.getCurrentTime() || 0;
//     const duration = playerRef.current[parentRefId]?.getDuration() || 0;
    
//     // Format the current time and duration
//     const formattedCurrentTime = moment.utc(currentTime * 1000).format("mm:ss");
//     const formattedDuration = moment.utc(duration * 1000).format("mm:ss");
    
//     // Set the formatted time
//     setFormattedTime(`${formattedCurrentTime} / ${formattedDuration}`);
    
//     // Call any other necessary handlers
//     props.ParentHandleVideoInProgress(playerRef.current[parentRefId], parentRefId, loopId);
// };

  const handleEnded = (e, player, assemblyItem, videoId) => {
    e.preventDefault();
    // console.log("onEnded");
    // this.setState({ playing: this.state.loop })
  };


  //  const handleClickFullscreen = () => {
  //    screenfull.request(findDOMNode(player))
  //  }

  //  const ref = (ply) => {
  //    player = ply;
  //  }

  const downloadFile = async (absolutePath, fileName, itemId) => {
    if (isAdmin != 1) {
      props.parentFileTracking(itemId);
    }
    let value = await download(absolutePath, fileName);
  };

  const handleToggleCaptions = () => {
    setShowCaptions(prev => !prev);
};


// useEffect(() => {
//   const handlePlay = () => {
//     playerRef.current[parentRefId].seekTo(playerSeek); 
//     playerRef.current[parentRefId]?.getInternalPlayer()?.play(); 
//   };

 
//   if (videoPlay && playerRef.current[parentRefId]) {
//     handlePlay(); 
//   }
// }, [videoPlay, parentRefId]);


  useEffect(() => {
    if (tab == "description") {
      setAddNotes(false)
    }
  }, [tab])

  const handlePlayPause = () => setPlaying(!playing);
  const handleSeek = (time) => playerRef.current.seekTo(time);
  // const handleSpeedChange = (speed) => {
  //   if (playerRef.current) {
  //     playerRef.current.playbackRate = speed;
  //   }
  // };

  const handleMouseMove = (e) => {
    if (!duration) return;

    const { left, width } = e.currentTarget.getBoundingClientRect();
    const offsetX = e.clientX - left;
    const percentage = offsetX / width;
    const previewTime = percentage * duration;

    setPreviewTime(previewTime);
    setPreviewVisible(true);

    if (previewPlayerRef.current) {
      previewPlayerRef.current.seekTo(previewTime, 'seconds');
    }
  };

  useEffect(() => {
    // Set the preview URL when the component mounts or when the video URL changes
    if (videoType === 0) { // Vimeo
      setPreviewUrl(`https://player.vimeo.com/video/${item_url}`);
    } else { // YouTube
      setPreviewUrl(`https://www.youtube.com/watch?v=${url}`);
    }
  }, [videoType, item_url, url]);


  const handleToggleFullscreen = () => {
    if (screenfull.isEnabled) {
      screenfull.toggle(ReactDOM.findDOMNode(playerRef.current[parentRefId]));
    }
  };

  const handleVolumeChange = (newVolume) => {
    setVolume(newVolume);
  };

  useEffect(() => {
    if (playerRef.current[parentRefId]) {
      const player = playerRef.current[parentRefId].getInternalPlayer();
      if (player && typeof player.setVolume === 'function') {
        player.setVolume(volume);
      }
    }
  }, [volume, parentRefId]);

  const toggleMute = () => {
    if (muted) {
      setVolume(previousVolume);
      setMuted(false);
    } else {
      setPreviousVolume(volume);
      setVolume(0);
      setMuted(true);
    }
  };

  const handleProgress = (progress) => {
    setCurrentTime(progress.playedSeconds);
  };

const handleDuration = (duration) => {
    setDuration(duration);
};

const handleMouseLeave = () => {
  setPreviewVisible(false);
};

  return (
    <>
      {/* {!videoPlayerShow && <div className="card-list-inner d-flex">
        <div className="card-list-left">
          <div className="card-list-ic d-flex">
            <div className="card-media card-blue-1">
              <i className="fal fa-video"></i>
            </div>
            <div className="card-media-text">
              <p className="mb-1">{assemblyName}</p>
              <span>
                <i className="fal fa-clock"></i> {video_duration}
              </span>
            </div>
          </div>
        </div>
        <div className="card-list-right ml-auto">
          <ul className="learningright-inner d-flex align-items-center">
            <li className="border-left item-progress">
              {watchPercentage != 0 && (
                <div className="progress border-left">
                  <div
                    className="progress-bar progress-bar-striped progress-bar-animated"
                    role="progressbar"
                    style={{ width: "" + watchPercentage + "%" }}
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    {watchPercentage}%
                  </div>
                </div>
              )}
            </li>
            <li className="border-left item-action">
              <div className="media-gp">
                <a
                  ref={videoViewRef}
                  title={(loopId == videoId && playing == true) ||
                    watchedVideo.includes(loopId) == true ||
                    watch_in_m_s != "" ? (
                    (loopId == currentVideoId) == true ? (
                      null
                    ) : "Resume Video"
                  ) : "Watch Video"}
                  className="btn btn-white-bordered"
                  onClick={(e) =>
                    handleVideoReady(
                      e,
                      playerRef.current[parentRefId],
                      parentRefId,
                      loopId
                    )
                  }
                >
                  {(loopId == videoId && playing == true) ||
                    watchedVideo.includes(loopId) == true ||
                    watch_in_m_s != "" ? (
                    (loopId == currentVideoId) == true ? (
                      <Loader
                        type="ThreeDots"
                        color="#00BFFF"
                        height={20}
                        width={20}
                      />
                    ) : (
                      <React.Fragment>
                        <i className="fal fa-play"></i>{" "}
                        <React.Fragment title="Resume Video">Resume Video</React.Fragment>
                      </React.Fragment>
                    )
                  ) : (
                    <React.Fragment>
                      <i className="fal fa-play"></i>{" "}
                      <React.Fragment>Watch Video</React.Fragment>
                    </React.Fragment>
                  )}
                </a>
              </div>
            </li>
            {watchPercentage >= 95 || isWatchedVideo === true ? (
              <li className="item-status">
                <div className="header-status ">
                  <div className="status-gol bg-success">
                    <i className="fal fa-check"></i>
                  </div>
                </div>
              </li>
            ) : null}
          </ul>
        </div>
      </div>} */}

      {/* {loopId == currentVideoId ? ( */}
        <> 
          <div className="card-list-inner">
        {/* <Scrollbar className="h-100"> */}
            <div className={`row rowlec ${minimized ? "d-flex flex-row" : ""}`} style={{marginLeft:"0"}}>
              <div className={`${minimized ? "col-xl-6" : "col-lg-12"} lec-sec-left video__container`} style={{paddingLeft:"0"}}>
                <div className="vidbox" title={`${`${assemblyName.includes(": ") ? assemblyName.split(": ")[1] : assemblyName}`}`}>
                  {/* {videoType == 0 ? ( */}
                    {/* //   Vimeo */}
                    <ReactPlayer
                    url={videoType === 0 ? `https://player.vimeo.com/video/${item_url}#t=${watch_in_m_s}` : `https://www.youtube.com/watch?v=${url}#t=${watch_in_m_s}`}
                      // url={`https://vimeo.com/${item_url}`}
                      onError={(err) => {
                        console.log(err.message)
                        if (err.message.toString().toLowerCase().includes("not found")) {
                          Swal.fire({
                            icon: "error",
                            title: "Video Not Found",
                            text: "This Video Doesn't Exists anymore."
                          })
                        }
                      }}
                      // ref={(ref) => (playerRef[parentRefId] = ref)}
                      ref={(ref) => (playerRef.current[parentRefId] = ref)}
                        onReady={() => {
                          playerRef.current[parentRefId].seekTo(watch_in_m_s / 1000);
                          playerRef.current[parentRefId].play();
                        }}
                        onMouseMove={handleMouseMove}
                        onMouseLeave={handleMouseLeave}
                      onMouseEnter={() => setPreviewVisible(true)} 
                      // onReady={() => {playerRef.current[parentRefId].seekTo(watch_in_m_s / 1000)}}
                      // onReady={() => console.log("onReady")}
                      onPlay={() => {
                        setIsPlaying(true);
                        handleVideoResumeRP(playerRef.current[parentRefId], parentRefId, loopId, true);
                      }}
                      playsinline
                      onEnded={() => {
                        setIsPlaying(false);
                        // console.log("onEnd");
                        handleVideoResumeRP(
                          playerRef.current[parentRefId],
                          parentRefId,
                          loopId,
                          null
                        );
                      }}
                      light={
                        (loopId == currentVideoId && playing == true) ||
                          watchedVideo.includes(loopId) == true
                          ? false
                          : true
                      }
                      // light={
                      //   loopId == currentVideoId && playing == true ? false : true
                      // }
                      playbackRate={speed}
                      // onPlay={() =>
                      //   props.playbackRate(2)
                      // }
                      pip={ParentPlayerPipMode}
                      stopOnUnmount={false}
                      className="react-player m-0"
                      playing={
                        loopId == currentVideoId && playing == true ? true : false
                      }
                      width={"100%"}
                      height={"100%"}
                      volume={volume}
                      progressInterval={1000}
                      onDuration={handleDuration}
                      onPause={() => {
                        setIsPlaying(false);
                        handleVideoResumeRP(playerRef.current[parentRefId], parentRefId, loopId, false);
                      }}
                      onProgress={(state) => {
                        if (playerRef.current[parentRefId]) {
                          handleProgress(state, playerRef.current[parentRefId], parentRefId, loopId);
                        }
                      }}
                      // autoPlay={true}
                      config={{
                        youtube: {
                          playerVars: { showinfo: 0, disablekb: 1, controls: 0 }
                        },
                        vimeo: {
                          playerOptions: {controls: 1  }
                        }
                      }}
                      >
                      </ReactPlayer>
                  {/* ) : (
                    // Youtube
                    <ReactPlayer
                      url={`https://www.youtube.com/watch?v=${url}#t=${watch_in_m_s}`}
                      ref={(ref) => (playerRef.current[parentRefId] = ref)}
                      // onReady={() => playerRef[parentRefId].seekTo(playerSeek)}
                      // onReady={() => console.log("onReady")}
                      // onStart={() => console.log("onStart")}

                      // onPause={() =>
                      //   handleVideoResumeRP(
                      //     playerRef[parentRefId],
                      //     parentRefId,
                      //     loopId,
                      //     false
                      //   )
                      // }
                      // onEnded={() => {
                      //   // console.log("onEnd");
                      //   handleVideoResumeRP(
                      //     playerRef[parentRefId],
                      //     parentRefId,
                      //     loopId,
                      //     null
                      //   );
                      // }}
                      // light={
                      //   (loopId == currentVideoId && playing == true) ||
                      //   watchedVideo.includes(loopId) == true
                      //     ? ""
                      //     : `https://img.youtube.com/vi/${url}/0.jpg`
                      // }

                      // light={
                      //   loopId == currentVideoId && playing == true
                      //     ? ""
                      //     : `https://img.youtube.com/vi/${url}/0.jpg`
                      // }
                      pip={ParentPlayerPipMode}
                      stopOnUnmount={false}
                      className="react-player"
                      playing={
                        true
                      }
                   
                      width={"100%"}
                      height={"100%"}
                      progressInterval={2000}
                      onProgress={(e) => {
                        // console.log("onEnd");
                        handleProgress(
                          e,
                          playerRef.current[parentRefId],
                          parentRefId,
                          loopId
                        );
                      }}

                      youtube="youtube"
                    />
                  )} */}
             

                </div>

                {/* {minimized && tab == "notes" && addNotes && <CreateNotesLessson setRefresh={setRefresh} refresh={refresh} props={props} closeAddNotes={(value) => setAddNotes(value)} subjectId={sid} timestamp={videoState} />}
                {minimized && tab == "notes" && editNote && <UpdateAssembliesNote setRefresh={setRefresh} refresh={refresh} note_id={editNoteId} subjectId={sid} closeEditNotes={() => setEditNote(false)} />} */}
              </div>

              <div className={`${minimized ? "col-xl-6" : "col-lg-12"} lec-sec-right px-0 video__container`}>
                <div className="lesson-video-bottom">
                  {!minimized ? <div className="lec-sec-text-body" style={{padding:"10px 0 0 0"}}>
                    <p title={`${assemblyName.includes(": ") ? assemblyName.split(": ")[1] : assemblyName}`} className="m-0"><b>{assemblyName.includes(": ") ? assemblyName.split(": ")[1] : assemblyName}</b></p>
                  </div> : null}
                  <div className="lecture-detail-bottom">
                    <div className="lecture-tabs-wrap lesson-tabs-wrap">
                      <ul className="nav nav-pills course-nav-sticky m-0 p-0" id="pills-tab" role="tablist">
                        <li className="nav-item " onClick={() => setTab("description")} title="Description">
                          <span className={`nav-link ${tab == "description" ? "active" : ""}`}
                          >
                            Description
                          </span>
                        </li>
                        <li className="nav-item " onClick={() => setTab("notes")} title="My Notes">
                          <span className={`nav-link ${tab == "notes" ? "active" : ""}`}
                          >
                            My Notes
                          </span>
                        </li>
                        {/* <li className="nav-item " onClick={() => setTab("transcription")}>
                        <span className={`nav-link ${tab == "transcription" ? "active" : ""}`}
                        >
                          Transcription
                        </span>
                      </li> */}
                        {tab === "notes" && !minimized && !addNotes && (
                          <li
                            className="add-note-button"
                            onClick={() => {
                              setAddNotes(true);
                              handleVideoResumeRP(playerRef.current[parentRefId], parentRefId, loopId, false);
                            }}>
                            <span className="btn note__btn" title="Add Notes">
                              <i className="fal fa-edit" />
                              Add Notes
                            </span>
                          </li>
                        )}

                      </ul>
                    </div>
                    {/* <Scrollbar className="scroll-course-list"> */}
                    {tab === "description" && (
                      <>
                        <p className="video__desc__content" title={`${assemblyDescription}`}>{assemblyDescription}</p>
                        {/* <div className="lesson-content">

                          {props.assembliesItems && props.assembliesItems.map((lesson, index) => (
                            lesson.item_type === 4 ? (
                              <div className="card-list-inner d-flex ">
                                <div className="card-list-left">
                                  <p className="lead-text-sm m-0">
                                    <i className={FetchFileIcon(lesson.absolutePath)}></i>
                                    {lesson.item_name}
                                  </p>
                                </div>
                                <div className="card-list-right ml-auto">
                                  <ul className="learningright-inner d-flex align-items-center">
                                    <li className="border-left item-action">
                                      <div className="media-gp">
                                        <a
                                          title="Download"
                                          onClick={() => {
                                            downloadFile(
                                              lesson?.upload_type === "library" ? image_url(lesson?.upload_link) : lesson.absolutePath,
                                              lesson.document,
                                              lesson.item_name + "." + (lesson?.upload_type === "library" ? lesson?.upload_link?.split(".").reverse()[0] : lesson.document.split(".").reverse()[0]),
                                              lesson.item_id
                                            );
                                          }}
                                          className="btn btn-white-bordered"
                                        >
                                          <i className="fal fa-download"></i> Download
                                        </a>
                                      </div>
                                    </li>
                                    {lesson.buttonCheckedStatus ||
                                      (buttonCheckedStatus &&
                                        documentId.includes(lesson.item_id)) ? (
                                      <li className="item-status">
                                        <div className="header-status ">
                                          <div className="status-gol bg-success">
                                            <i className="fal fa-check"></i>
                                          </div>
                                        </div>
                                      </li>
                                    ) : (
                                      ""
                                    )}
                                  </ul>
                                </div>
                              </div>
                            ) : null
                          ))}
                        </div> */}
                      </>
                    )}
                    {tab === "notes" && (
                      <>
                        {(minimized || (!minimized && !addNotes)) && (
                          <LessonNotesList
                            lessonItemId={lessonItemId}
                            subjectId={sid}
                            closeEditNotes={(value) => setAddNotes(value)}
                            timestamp={videoState}
                            setPlayerSeek={setPlayerSeek}
                            setVideoPlay={setVideoPlay}
                            setEditNoteId={setEditNoteId}
                            editNoteId={editNoteId}
                            setEditNote={setEditNote}
                            editNote={editNote}
                            minimized={minimized}
                            setRefresh={setRefresh}
                            refresh={refresh}
                          />
                        )}
                        {!minimized && addNotes && (
                          <CreateNotesLessson
                            props={props}
                            closeAddNotes={(value) => setAddNotes(value)}
                            subjectId={sid}
                            timestamp={videoState}
                            setRefresh={setRefresh}
                            refresh={refresh}
                          />
                        )}
                      </>
                    )}
                    {/* </Scrollbar> */}
                    {/* {tab === "transcription" && (
                    <div>{ReactHtmlParser(transcription && transcription !== "" ? (transcription) : "")}</div>
                  )} */}

                  </div>
                </div>
              </div>
            </div>
          {/* </Scrollbar> */}
          </div>
        </>
        {/* ) : null} */}
    </>
  );
}
