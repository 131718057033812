import React from "react";

const AppealStatus = ({status}) => {
    let data = status ? status.toString().toLowerCase() : "";
    switch (data) {
        case "declined":
            return (
                <>
                    <div className="assessment-03">
                        <p className="as-red-bg  as-widget">Declined</p>
                    </div>
                </>
            );
        case "approved":
            return (
                <>
                    <div className="assessment-03">
                        <p className="as-green-bg as-widget">Approved</p>
                    </div>
                </>
            );
        case "appeal-pending":
            return (
                <>
                    <div className="assessment-03">
                        <p className="as-yellow-bg as-widget">Appeal-Pending</p>
                    </div>
                </>
            );
        default:
            return (
                <>
                    <div className="assessment-03">
                        <p className="as-black-bg">-</p>
                    </div>
                </>
            );
            break;
    }
}
export default AppealStatus;
