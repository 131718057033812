import React from "react";
import Skeleton from "react-loading-skeleton";
const SekletonGlobalCalendar = () => {
  return (
    <ul className="list-unstyled m-0">
      {Array(30)
        .fill()
        .map((item, index) => (
          <li key={index}>
            <Skeleton height={30} />
          </li>
        ))}
    </ul>
  );
};
export default SekletonGlobalCalendar;
