import React, { useState } from "react";
import { useHistory, Route, Redirect } from "react-router-dom";
import { getCurrentTimeHomePage } from "../components/common/Helper";
import { Link } from "react-router-dom";
import Header from "../components/common/Header";
import SideBar from "../components/common/SideBar";
import HomeWishes from "../components/common/HomeWishes";
import Swal from "sweetalert2";
import { WitheldStatusRemindMeLater } from "../services/ReRegistrationService";
import { useDispatch, useSelector } from "react-redux";
import { setIsWithheld } from "../store/actions";

function ResultWithheld() {
  const [isRemindMeLoading, setIsRemindMeLoading] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const selectorState = useSelector((state) => state);
  const { isWithHeld } = selectorState;
  const isWithHeldStatus = localStorage.getItem("isResultWitheld");;
  const dispatch = useDispatch();
  const location = useHistory();
  const handleRemindMeLater = () => {
    setIsRemindMeLoading(true);
    WitheldStatusRemindMeLater()
      .then((res) => {
        setIsRemindMeLoading(false);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Remind me later set successfully",
        }).then((result) => {
          if (result.isConfirmed) {
            dispatch(setIsWithheld(false));
            localStorage.setItem("isResultWitheld", false)
            location.push("/home");
          }
        });
      })
      .catch((error) => {
        console.error(error);
        setIsRemindMeLoading(false);
      });
  };
  return (
    <>
      {" "}
      <Header></Header>
      <div className="content-wrapper d-flex flex-wrap on-hold-height">
        <SideBar></SideBar>
        <div className="sidebar-right">
          <div className="home-list-wrap">
            <HomeWishes />
            <div className="on-hold-box card mt-3">
              {/* <br/><br/>
                Your student account status has been set to, {user.status}. You will have limited access to your account. Please submit a ticket or contact student support for further information. 
                <br/><br/><br/>
                Kind regards, 
                <br/><br/>
                AIE */}

              <div className="row align-items-center">
                <div className="col-md-5 ml-auto on-hold-box-L">
                  {/* <figure><img src={dummyImg}/></figure> */}
                  <figure>
                    <svg
                      xmlns="https://www.w3.org/2000/svg"
                      width="448"
                      height="273"
                      viewBox="0 0 448 273"
                    >
                      <g
                        id="Group_67822"
                        data-name="Group 67822"
                        transform="translate(-0.195)"
                      >
                        <g id="Group_147" data-name="Group 147">
                          <g id="Group_144" data-name="Group 144">
                            <g
                              id="Group_145"
                              data-name="Group 145"
                              transform="translate(191.527 189.863)"
                            >
                              <rect
                                id="Rectangle_45"
                                data-name="Rectangle 45"
                                width="1.48"
                                height="43.376"
                                transform="translate(4.598 23.765)"
                                fill="#3f3d56"
                              />
                              <rect
                                id="Rectangle_46"
                                data-name="Rectangle 46"
                                width="1.48"
                                height="63.634"
                                transform="translate(48.903 3.507)"
                                fill="#3f3d56"
                              />
                              <rect
                                id="Rectangle_47"
                                data-name="Rectangle 47"
                                width="1.48"
                                height="50.315"
                                transform="translate(93.298 16.826)"
                                fill="#3f3d56"
                              />
                              <ellipse
                                id="Ellipse_14"
                                data-name="Ellipse 14"
                                cx="4.527"
                                cy="4.527"
                                rx="4.527"
                                ry="4.527"
                                transform="translate(45.206)"
                                fill="#3f3d56"
                              />
                              <ellipse
                                id="Ellipse_15"
                                data-name="Ellipse 15"
                                cx="4.527"
                                cy="4.527"
                                rx="4.527"
                                ry="4.527"
                                transform="translate(89.602 12.579)"
                                fill="#3f3d56"
                              />
                              <ellipse
                                id="Ellipse_16"
                                data-name="Ellipse 16"
                                cx="4.527"
                                cy="4.527"
                                rx="4.527"
                                ry="4.527"
                                transform="translate(0 15.588)"
                                fill="#3f3d56"
                              />
                            </g>
                            <ellipse
                              id="Ellipse_20"
                              data-name="Ellipse 20"
                              cx="224"
                              cy="8.5"
                              rx="224"
                              ry="8.5"
                              transform="translate(0.195 256)"
                              fill="#c7d8f1"
                            />
                            <g
                              id="undraw_mobile_wireframe_re_jxui"
                              transform="translate(24.599)"
                            >
                              <path
                                id="Path_358"
                                data-name="Path 358"
                                d="M330.125,209c-5.149.007-9.321,4.8-9.327,10.722V333.19c.006,5.919,4.178,10.715,9.327,10.722H611.334c5.149-.007,9.321-4.8,9.327-10.722V219.722c-.006-5.919-4.178-10.715-9.327-10.722Z"
                                transform="translate(-281.201 -209)"
                                // fill="#000000"
                                class="s-blue"
                              />
                              <path
                                id="Path_389"
                                data-name="Path 389"
                                d="M170,531.9a89.8,89.8,0,0,1,16.858-21.509,2.307,2.307,0,0,1,2.98-.12l16.824,13.187a2.277,2.277,0,0,1,.878,1.8,2.316,2.316,0,0,1-.885,1.83,54.859,54.859,0,0,0-15.717,20.053,2.315,2.315,0,0,1-1.566,1.3,2.277,2.277,0,0,1-1.954-.423L170.6,534.821A2.307,2.307,0,0,1,170,531.9Z"
                                transform="translate(-150.187 -352.717)"
                                fill="#ccc"
                              />
                              <path
                                id="Path_390"
                                data-name="Path 390"
                                d="M205.585,538.239l-8.828-6.92a1.61,1.61,0,0,1-.274-2.258l.388-.495a1.61,1.61,0,0,1,2.258-.274l8.828,6.92a1.61,1.61,0,0,1,.274,2.258l-.388.494a1.61,1.61,0,0,1-2.258.274Z"
                                transform="translate(-162.444 -361.146)"
                                fill="#fff"
                              />
                              <path
                                id="Path_391"
                                data-name="Path 391"
                                d="M197.969,547.955l-8.829-6.92a1.61,1.61,0,0,1-.274-2.258l.388-.495a1.61,1.61,0,0,1,2.258-.274l8.829,6.92a1.61,1.61,0,0,1,.274,2.258l-.388.495A1.61,1.61,0,0,1,197.969,547.955Z"
                                transform="translate(-158.911 -365.653)"
                                fill="#fff"
                              />
                              <path
                                id="Path_392"
                                data-name="Path 392"
                                d="M1017.326,538.711a89.791,89.791,0,0,1,0-27.328,2.306,2.306,0,0,1,2.271-1.933h21.376a2.277,2.277,0,0,1,1.8.872,2.316,2.316,0,0,1,.433,1.986,54.857,54.857,0,0,0,0,25.478,2.315,2.315,0,0,1-.433,1.986,2.276,2.276,0,0,1-1.8.872H1019.6A2.306,2.306,0,0,1,1017.326,538.711Z"
                                transform="translate(-646.372 -357.115)"
                                fill="#ccc"
                              />
                              <path
                                id="Path_393"
                                data-name="Path 393"
                                d="M1039.7,532.034h-11.217a1.61,1.61,0,0,1-1.608-1.608V529.8a1.61,1.61,0,0,1,1.608-1.608H1039.7a1.61,1.61,0,0,1,1.608,1.608v.628a1.61,1.61,0,0,1-1.608,1.608Z"
                                transform="translate(-651.286 -365.808)"
                                fill="#fff"
                              />
                              <path
                                id="Path_394"
                                data-name="Path 394"
                                d="M1020.264,493.81a5.725,5.725,0,0,0-2.19-7.447,5.035,5.035,0,0,0-.779-.349l-.44-19.466-7.776,5.725,1.61,17.607a5.681,5.681,0,0,0,2.618,6.228,4.979,4.979,0,0,0,6.957-2.3Z"
                                transform="translate(-643.031 -337.212)"
                                fill="#a0616a"
                              />
                              <path
                                id="Path_395"
                                data-name="Path 395"
                                d="M1006.074,443.079l-5.573.336a2.368,2.368,0,0,1-2.414-2.117L993.46,406.48l-7.479-21.239a7.36,7.36,0,0,1,.194-5.434,6.6,6.6,0,0,1,3.783-3.615,6.486,6.486,0,0,1,8.312,4.369l7.606,24.351,2.345,35.561a2.528,2.528,0,0,1-.563,1.784,2.26,2.26,0,0,1-.972.7A2.168,2.168,0,0,1,1006.074,443.079Z"
                                transform="translate(-632.117 -295.125)"
                                fill="#3f3d56"
                              />
                              <path
                                id="Path_396"
                                data-name="Path 396"
                                d="M821.456,492.963h-6.573l-3.126-25.351h9.7Z"
                                transform="translate(-479.992 -237.991)"
                                fill="#a0616a"
                              />
                              <path
                                id="Path_397"
                                data-name="Path 397"
                                d="M948.011,705.414H926.819v-.268a8.249,8.249,0,0,1,8.249-8.249h12.944Z"
                                transform="translate(-604.871 -444.07)"
                                fill="#2f2e41"
                              />
                              <path
                                id="Path_398"
                                data-name="Path 398"
                                d="M857.456,492.963h-6.573l-3.127-25.351h9.7Z"
                                transform="translate(-496.692 -237.991)"
                                fill="#a0616a"
                              />
                              <path
                                id="Path_399"
                                data-name="Path 399"
                                d="M984.011,705.414H962.819v-.268a8.249,8.249,0,0,1,8.249-8.249h12.944Z"
                                transform="translate(-621.571 -444.07)"
                                fill="#2f2e41"
                              />
                              <path
                                id="Path_400"
                                data-name="Path 400"
                                d="M973.122,501.906l-1.569,93.118a2.144,2.144,0,0,1-2.338,2.1l-7.691-.7a2.144,2.144,0,0,1-1.944-1.97l-4.5-58.084a.536.536,0,0,0-1.07.021l-2.251,59.414a2.145,2.145,0,0,1-2.143,2.063h-8.248a2.144,2.144,0,0,1-2.14-2.014l-5.774-94.485,34.311-8.578Z"
                                transform="translate(-607.947 -349.387)"
                                fill="#2f2e41"
                              />
                              <circle
                                id="Ellipse_18"
                                data-name="Ellipse 18"
                                cx="13.167"
                                cy="13.167"
                                r="13.167"
                                transform="translate(327.121 38.747)"
                                fill="#a0616a"
                              />
                              <path
                                id="Path_401"
                                data-name="Path 401"
                                d="M929.856,440.434a2.4,2.4,0,0,1-.732-1.7l-.888-63.5a15.28,15.28,0,0,1,10.337-14.711c1.233-5.6,7.8-6.3,11.627-6.214a5.942,5.942,0,0,1,4.813,2.639l2.744,4.116,6.047,3.545a15.294,15.294,0,0,1,7.221,16.521l-.931,4.235a97.327,97.327,0,0,0,.91,45.387l1.874,7.143a2.412,2.412,0,0,1-2.519,3.017l-2.176-.167a2.409,2.409,0,0,1-2.216-2.165l-.36-3.6a.268.268,0,0,0-.534,0l-.349,3.486a2.412,2.412,0,0,1-2.363,2.172l-30.789.474h-.038A2.4,2.4,0,0,1,929.856,440.434Z"
                                transform="translate(-605.527 -285.145)"
                                fill="#3f3d56"
                              />
                              <path
                                id="Path_402"
                                data-name="Path 402"
                                d="M946.185,316.835c.054,1.861,1.346,2.938,2.933,3.913a16.967,16.967,0,0,0,5.258,1.805,1.608,1.608,0,0,0,2.362-1.407l1.666-6.339a40.122,40.122,0,0,0,1.283-6.185c.4-4.026-.553-8.356-3.363-11.267s-7.683-3.945-11.05-1.7c-4-5.1-22.261,6.172-17.755,9.721.8.631,2.027-.168,3.044-.245a3.248,3.248,0,0,1,2.8.891c.844,1.032.477,2.568.825,3.855a3.6,3.6,0,0,0,4.548,2.331,12.374,12.374,0,0,0,2.359-1.516,3.034,3.034,0,0,1,2.645-.576c1.158.432,1.569,1.85,1.674,3.081a28.54,28.54,0,0,0,.768,3.639Z"
                                transform="translate(-604.866 -257.331)"
                                fill="#2f2e41"
                              />
                              <path
                                id="Path_403"
                                data-name="Path 403"
                                d="M812.243,216.118,810.1,242.387,798.84,269.728l16.619-26.805Z"
                                transform="translate(-473.999 -121.324)"
                                opacity="0.2"
                              />
                              <path
                                id="Path_404"
                                data-name="Path 404"
                                d="M919.654,498.587a5.391,5.391,0,0,0,.17-8.265L927,472.558l-9.758,1.971L911.923,491.1a5.42,5.42,0,0,0,7.731,7.491Z"
                                transform="translate(-597.446 -340.001)"
                                fill="#a0616a"
                              />
                              <path
                                id="Path_405"
                                data-name="Path 405"
                                d="M920.756,441.723l-5.524-1.991a2.425,2.425,0,0,1-1.509-2.905l9.112-33.356,1.389-30.146a6.872,6.872,0,1,1,13.719.832l-2.482,33.01L923.85,440.253a2.406,2.406,0,0,1-3.094,1.47Z"
                                transform="translate(-598.757 -290.971)"
                                fill="#3f3d56"
                              />
                              <path
                                id="Path_406"
                                data-name="Path 406"
                                d="M51.358,481l5.9,2.893,13.967-21.387-8.71-4.27Z"
                                transform="translate(-23.777 -229.091)"
                                fill="#ffb8b8"
                              />
                              <path
                                id="Path_407"
                                data-name="Path 407"
                                d="M180.443,681.729l11.622,5.7h0a8.248,8.248,0,0,1,3.776,11.037l-.118.241-19.029-9.328Z"
                                transform="translate(-153.423 -432.483)"
                                fill="#2f2e41"
                              />
                              <path
                                id="Path_408"
                                data-name="Path 408"
                                d="M160.451,497.573h6.573l3.127-25.351h-9.7Z"
                                transform="translate(-74.385 -235.578)"
                                fill="#ffb8b8"
                              />
                              <path
                                id="Path_409"
                                data-name="Path 409"
                                d="M290.7,697.507h12.944a8.249,8.249,0,0,1,8.249,8.249v.268H290.7Z"
                                transform="translate(-206.309 -439.802)"
                                fill="#2f2e41"
                              />
                              <circle
                                id="Ellipse_19"
                                data-name="Ellipse 19"
                                cx="13.167"
                                cy="13.167"
                                r="13.167"
                                transform="translate(58.781 60.701)"
                                fill="#ffb8b8"
                              />
                              <path
                                id="Path_410"
                                data-name="Path 410"
                                d="M244.188,604a2.413,2.413,0,0,1-2.407-2.307l-5.09-67.743a.8.8,0,0,0-1.516-.347l-32.558,62.157a2.425,2.425,0,0,1-3.216,1.038l-9.076-4.538a2.417,2.417,0,0,1-1.084-3.225l19.428-39.367,12.868-29.489c4.967-9.783,10.241-14.825,16.124-15.413,6.035-.6,12.481,3.547,19.708,12.688l.059.075v.1l-1.072,82.752a2.4,2.4,0,0,1-2.187,2.4l-9.742,1.215Q244.306,604,244.188,604Z"
                                transform="translate(-159.128 -350.362)"
                                fill="#2f2e41"
                              />
                              <path
                                id="Path_411"
                                data-name="Path 411"
                                d="M233.9,489.488a5.759,5.759,0,0,0,1.27-8.739l1.985-52.461-9.65,1.2-.814,51.037a5.79,5.79,0,0,0,7.208,8.961Z"
                                transform="translate(-175.849 -314.913)"
                                fill="#ffb8b8"
                              />
                              <path
                                id="Path_412"
                                data-name="Path 412"
                                d="M239.76,425.159,226.8,423.687a2.582,2.582,0,0,1-2.2-3.23l3.346-12.565a7.172,7.172,0,0,1,14.247,1.661l.443,12.953a2.582,2.582,0,0,1-2.872,2.653Z"
                                transform="translate(-175.605 -302.509)"
                                fill="#2f2e41"
                              />
                              <path
                                id="Path_413"
                                data-name="Path 413"
                                d="M327.036,380.231a5.645,5.645,0,0,0-.128.879L303.878,394.4l-5.6-3.223-5.967,7.812,9.355,6.668a4.289,4.289,0,0,0,5.146-.125l23.748-18.735a5.628,5.628,0,1,0-3.525-6.563Z"
                                transform="translate(-207.058 -290.605)"
                                fill="#ffb8b8"
                              />
                              <path
                                id="Path_414"
                                data-name="Path 414"
                                d="M293.319,402.156l-7.443,9.647a2.413,2.413,0,0,1-3.648.2l-8.426-8.75a6.7,6.7,0,1,1,8.217-10.585l10.568,5.909a2.413,2.413,0,0,1,.732,3.579Z"
                                transform="translate(-197.268 -297.735)"
                                fill="#2f2e41"
                              />
                              <path
                                id="Path_415"
                                data-name="Path 415"
                                d="M284.31,466.879l-14.289-69.6a13.052,13.052,0,0,0-14.931-10.249l-4.766.794a12.779,12.779,0,0,0-8.3,5.178h0a17.847,17.847,0,0,0-2.666,15.177l7.887,28.229-5.853,30.243a2.142,2.142,0,0,0,2.405,2.531c2.931-.419,7.761-1.018,13.309-1.344l3.006-11.594,1.222,11.41c6.815-.191,14.213.143,20.316,1.729a2.146,2.146,0,0,0,2.657-2.5Z"
                                transform="translate(-182.187 -295.689)"
                                class="s-blue"
                                // fill="#467fcf"
                              />
                              <path
                                id="Path_416"
                                data-name="Path 416"
                                d="M220.4,324.483a13.406,13.406,0,0,0-8.827-9.911,19.11,19.11,0,0,0-13.581.739,21.291,21.291,0,0,0-10.7,9.581,11.085,11.085,0,0,1-1.6-.881c-2.535-1.687-4.592-4.09-7.335-5.412-4.835-2.332-10.681-.782-15.387,1.8s-8.877,6.152-13.848,8.179-11.314,2.127-15.078-1.7a13.795,13.795,0,0,0,20.216,16.282c5.446-3.318,8.455-10.274,14.564-12.1,3.38-1.013,7.01-.2,10.427.687a41.238,41.238,0,0,0,6.143,1.27,15.167,15.167,0,0,0,3.963,10.125c4.216,4.438,10.832,5.621,16.953,5.549l.128-.13c-1.874-2.805-3.353-6.695-1.29-9.364,2.037-2.635,6.054-2.246,9.173-3.412C218.664,334.158,221.184,329.054,220.4,324.483Z"
                                transform="translate(-133.286 -261.732)"
                                fill="#2f2e41"
                              />
                              <path
                                id="Path_19679"
                                data-name="Path 19679"
                                d="M55.648,22.526,49.617,4.8h2.59V4.1H40.182v.7h1.739l9.324,25.57h3.219l4.81-16.724L64.75,30.37h3.293L74.851,4.8h2.442V4.1h-7.4v.7H73.63L69.19,22.3,63.677,4.8H65.86V4.1H54.39v.7H56.5l2.22,7.067ZM79,4.1v.7h2.03V29.3H79V30H90.28v-.7H88.245V4.8H90.28V4.1Zm36.3,0H93.462l-.37,9.287h.7c1.887-5.735,3.145-8.51,6.512-8.51h.481V29.223H97.643V30h13.5v-.777H108V4.877h.444c3.367,0,4.514,2.479,6.512,8.51h.7Zm3.071,0v.7h2.183V29.3h-2.183V30h11.544v-.7h-2.146V17.235h7.363V29.3h-2.183V30H144.53v-.7h-2.183V4.8h2.183V4.1H132.941v.7h2.183V16.27h-7.363V4.8h2.146V4.1Z"
                                transform="translate(59.597 47)"
                                fill="#fff"
                              />
                              <path
                                id="Path_19679-2"
                                data-name="Path 19679"
                                d="M118.371,4.1v.7h2.183V29.3h-2.183V30h11.544v-.7h-2.146V17.235h7.363V29.3h-2.183V30H144.53v-.7h-2.183V4.8h2.183V4.1H132.941v.7h2.183V16.27h-7.363V4.8h2.146V4.1Zm28.268,0v.814h2.183V29.186h-2.183V30h20.313l.3-8.066h-.74c-1.11,4.033-4.366,7.252-9.065,7.252h-1.406V17.309h.888c2.59,0,4,1.443,4.884,5.143h.629V11.5h-.555c-.629,2.812-2.183,4.847-4.588,4.847h-1.258V4.914h1.295c4.477,0,6.216,1.665,8.251,6.512h.666l-.37-7.326Zm23.643,0v.814h2.183V29.186h-2.183V30h19.61l.7-8.658h-.777c-2.183,4.514-4.218,7.844-8.806,7.844h-1.332V4.914H183.6V4.1ZM218.53,16.606c0-9.213-5.069-12.506-14.208-12.506H192.881v.7h2.442V29.3h-2.442V30h10.471c8.848,0,15.17-2.96,15.17-13.394ZM202.538,29.3V4.8h.888c5.18,0,7.474,1.85,7.474,10.841V17.79c0,8.288-1.961,11.507-6.956,11.507Z"
                                transform="translate(88.597 47)"
                                fill="#fff"
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </figure>
                </div>

                <div className="col-md-4 mr-auto on-hold-box-R">
                  <h4>
                    Results have been <b>Withheld</b>
                  </h4>
                  <p>
                    Your marks are being withheld due to outstanding balances on
                    your account, you will have limited access to the portal.
                    Please contact the finance department by logging a ticket
                    below.
                  </p>
                  <Link className="btn btn-primary mt-2" to="/addnewticket">
                    <i class="far fa-ticket-alt"></i> Log A Ticket
                  </Link>
                  {isWithHeldStatus == "true" && (
                    <button
                      type="button"
                      className="btn btn-primary additional-btn ml-2 mt-2"
                      onClick={handleRemindMeLater}
                      disabled={isRemindMeLoading}
                    >
                      {isRemindMeLoading ? (
                        <i className="fas fa-cog fa-spin"></i>
                      ) : (
                        <i className="fal fa-bell-slash"></i>
                      )}
                      Remind Me Later
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResultWithheld;
