import React, { useState, useEffect } from "react";
import { InitalRender, originalValues } from "./Helper";
import _ from "underscore";
import { useSelector } from "react-redux";
import UnknownStudent from "../../assets/images/ic_unknown_user.png"
import UnknownStudentStatus from "../../assets/images/ic_unknown_user_online_status.png"

const UserHover = (props) => {

    const [copyText, setCopyText] = useState(0);
    const user = useSelector((state) => state.user);

    const copyEmailText = (email) => {
        setCopyText(1);
        navigator.clipboard.writeText(email);
        setTimeout(() => {
            setCopyText(0)
        }, 1000);
    }

    return (
        <>
            <div className={"assigned-title-info " + props.directionCls}>
                <article>
                    <figure className="bgBlue">
                        {/* {props.user.photo ? <img src={props.user.photo} alt={props.user.fullname} /> : InitalRender(originalValues(
                    props.user.fullname
                ))} */}
                    {props.user ?
                        <>
                            {props.user?.photo ? <img src={props.user.photo} onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.closest('.bgBlue').append(InitalRender(originalValues(props.user.fullname, 23)));
                                currentTarget.remove();
                            }} /> : InitalRender(originalValues(
                                props.user?.fullname ? props.user?.fullname : "Unknown User"
                            ))}                        
                            <span className={
                                // "portal-status-icon" + (props.online != "online-u" ? (_.contains(props.online, props.user.id) || user.id == props.user.id ? " online-u" : " offline-u") : " online-u")
                                `portal-status-icon ${props.user?.activity_status?.toLowerCase() == 'online' ? 'online-u' : props.user?.activity_status?.toLowerCase() == 'donotdisturb' ? 'donotdisturb-u' : 'offline-u'}`
                            }></span>
                        </>
                    :<>
                    <img  src={UnknownStudent} alt="unknown"/>
                    <span className="portal-status-icon border-0">
                        <img src={UnknownStudentStatus} alt="unknown" />
                    </span>
                    </>
                    }

                    </figure>
                    <figcaption>
                        <h4>{originalValues(
                            props.user?.fullname ? props.user?.fullname : "Unknown User"
                        )}</h4>
                        {!props?.hideEmail ?  <p>
                            {props.user?.email ?
                            <>
                            <i className="fal fa-envelope"></i>{" "}
                            <a className="hover-color-blue" onClick={() => copyEmailText(props.user?.email)}>{props.user?.email}</a>
                            </>
                            :null}
                            {copyText ? (
                                <i className="fas fa-check-circle cat-greenhex"></i>
                            ) : (
                                props.user?.email && <i
                                    className="fal fa-copy hover-color-blue"
                                    onClick={() => copyEmailText(props.user?.email)}
                                ></i>
                            )}
                        </p> : null}
                    </figcaption>
                </article>
            </div>
        </>
    );
};
export default React.memo(UserHover);