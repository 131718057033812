import React, { useState, useEffect, useCallback, memo } from "react";
import {
  getAssembliesItem,
  fileTrackingService,
  getAssembliesItemAdmin,
} from "../../services/AssembliesService";

import AssembliesItems from "./AssembliesItems";
import SekletonLearningMaterial from "../../loaders/SekletonLearningMaterial";
import { useDispatch, useSelector } from "react-redux";
import { UpdateLessonType, clearLessonType, updateCurrentActivity, updateCurrentAssembly, updateCurrentCategory } from "../../store/actions";
import ScrollBar from "react-perfect-scrollbar";

function SubjectAssemblies(props) {
  const {
    setQuizId,
    setshow,
    QuizId,
    setrId,
    rId,
    moduleId,
    show,
    getSubContentMethod,
    subassembliesItems,
    assemblyData,
    setassembliesItems,
    setSubassembliesItems,
    setModuleId,
    lessonList = []
  } = props;

  const [loading, setLoading] = useState(false);
  const [currentVideoId, setCurrentVideoId] = useState(0);
  const [buttonCheckedStatus, setButtonCheckedStatus] = useState(0);
  const [subCategoryAcc, setSubCategoryAcc] = useState({ state: null, id: null })
  const [documentId, setDocumentId] = useState([]);
  const [currentLesson, setCurrentLesson] = useState(null);
  // const [lessonList, setLessonList] = useState([]);
  const [currentCategoryData, SetCurrentCategoryData] = useState([]);
  const currentAssembly = useSelector(state => state.learningMaterial.currentAssembly)
  const dispatch = useDispatch();

  const activityArr = useSelector(state => state.learningMaterial.activity_arr);
  const currentActivity = useSelector(state => state.learningMaterial.current_activity);
  const lesson_type = useSelector(state => state.learningMaterial.active_lesson_type);

  // useEffect(() => {
  //   if (moduleId) {
  //     console.log(">>>.here", moduleId)
  //     getCategoryLearningMaterial(moduleId);
  //   }
  // }, [moduleId]);

  // const getCategoryLearningMaterial = useCallback((item) => {
  //   setLoading(true)
  //   getAssembliesItem(item)
  //     .then((res) => {
  //       setassembliesItems(res.data);
  //       setLessonList(res.data);
  //       setLoading(false)
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       setLoading(false)
  //     }).finally(
  //       setLoading(false)
  //     );
  // }, []);

  const handleFileTracking = useCallback((itemId) => {
    fileTrackingService(itemId).then((res) => {
      setButtonCheckedStatus(true);
      setDocumentId(prevDocumentId => [...prevDocumentId, itemId]);
    });
  }, [props.subjectId]);
  function handleLessonList(assemblyCategoryList, currentActivity) {
    const assembliesItems = assemblyCategoryList?.assembliesItems || [];
  
    for (let i = 0; i < assembliesItems.length; i++) {
      const lessons = assembliesItems[i].lessons || [];
      SetCurrentCategoryData(lessons);
  
      const lessonIndex = lessons.findIndex((lesson) => lesson.item_id === currentActivity);
      if (lessonIndex !== -1) {
        if (lessonIndex === lessons.length - 1) {
          const nextItem = assembliesItems[i + 1];
          return nextItem ? nextItem.lessons || [] : null;
        }
        return lessons[lessonIndex + 1];
      }
    }
    return null;
  }

  function handleNextActivity() {
    let nextActivity;
    const parentActivity = currentCategoryData.find((activity) => activity?.item_id === subCategoryAcc.id);

    if (subCategoryAcc.state) {
      const subIndex = parentActivity?.sub_children?.findIndex((sub) => sub.item_id === currentActivity);

      if (subIndex !== -1 && subIndex + 1 < parentActivity.sub_children.length) {
        nextActivity = parentActivity.sub_children[subIndex + 1];
      } else {
        setSubCategoryAcc({ state: false, id: null });
        const parentIndex = currentCategoryData.findIndex((activity) => activity?.item_id === subCategoryAcc.id);
        nextActivity = currentCategoryData[parentIndex + 1];
      }
    } else {
      const activityIndex = currentCategoryData.findIndex((activity) => activity?.item_id === currentActivity);
      if (activityIndex === -1) return console.error("Current activity not found in lesson list");

      if (activityIndex === currentCategoryData.length - 1) {
        for (let i = 0; i < lessonList.assembliesItems.length; i++) {
          const lessons = lessonList.assembliesItems[i].lessons || [];
          const lessonIndex = lessons.findIndex((lesson) => lesson.item_id === currentActivity);

          if (lessonIndex !== -1) {
            if (lessonIndex === lessons.length - 1) {
              const nextAssembliesItem = lessonList.assembliesItems[i + 1];
              if (nextAssembliesItem) {
                SetCurrentCategoryData(nextAssembliesItem.lessons);
                console.log("nextAssembliesItem.lessons?.assembly_id",nextAssembliesItem.lessons[0]?.parent_id)
                setModuleId(nextAssembliesItem.lessons[0]?.parent_id)
              } else return null;
            } else return lessons[lessonIndex + 1];
          }
        }
        return null;
      }

      nextActivity = currentCategoryData[activityIndex + 1];
    }

    if (nextActivity?.sub_children?.length) {
      setSubCategoryAcc({ state: true, id: nextActivity.item_id });
      dispatch(updateCurrentActivity(nextActivity.sub_children[0]?.item_id));
      dispatch(UpdateLessonType(nextActivity.sub_children[0]?.item_type));
    } else {
      dispatch(updateCurrentActivity(nextActivity?.item_id));
      dispatch(UpdateLessonType(nextActivity?.item_type));
    }
  }

  useEffect(() => {
    const findCurrentActivity = () => {
      let currentLessonData = currentCategoryData?.find(lesson => lesson.item_id === currentActivity) ||
        currentCategoryData.flatMap(lesson => lesson.sub_children || []).find(sub => sub.item_id === currentActivity);

      if (currentLessonData) setCurrentLesson(currentLessonData);
    };
    findCurrentActivity();
  }, [currentActivity, currentCategoryData]);

  useEffect(() => {
    handleLessonList(lessonList, currentActivity);
  }, [lessonList,currentCategoryData,currentActivity]);
  
  return (
    <>
      {/* {loading && <SekletonLearningMaterial />} */}
      {/* {lessonList>,length > 0 ? lessonList.} */}
      {/* {lessonList?.length > 0 ? lessonList.map((lesson, lessonindex) => { */}
      {currentLesson ? (<div key={currentLesson.item_id} className="learning-material-listing learning-material-content">
                <div className="learning-material-body">
                  {console.log("currentLesson",currentLesson)}
                  <ul className="sub-ul">
                    <ScrollBar>
                      <AssembliesItems
                        subassembliesItems={props.subassembliesItems}
                        subjectId={props.subjectId}
                        assembliesItems={assemblyData}
                        key={currentLesson.item_id}
                        isAdmin={props.isAdmin}
                        parentSubId={props.subjectId}
                        setQuizId={setQuizId}
                        QuizId={QuizId}
                        setrId={setrId}
                        rId={rId}
                        show={show}
                        lesson={currentLesson}
                        setshow={setshow}
                        parentSetCurrentVideoId={setCurrentVideoId}
                        lessonindex={currentLesson.item_id}
                        parentFileTracking={handleFileTracking}
                        currentVideoId={currentVideoId}
                        getSubContentMethod={getSubContentMethod}
                        buttonCheckedStatus={buttonCheckedStatus}
                        documentId={documentId}
                      />
                    </ScrollBar>
                  </ul>
                </div>
              </div>
        ) : <div className="learning-material-listing" id="accordion" role="tablist" aria-multiselectable="true">
            <div className="subject-header">
              <div className="subject-sub-header">
                <h3>No Content Available.</h3>
              </div>
            </div>
          </div>
      }
      {!loading && (
        <a style={{ float: "right" }} className="next-activity" onClick={handleNextActivity}>
          Go to next activity <span><i className="fal fa-greater-than"></i></span>
        </a>
      )}
    </>
  );
}

export default memo(SubjectAssemblies);
