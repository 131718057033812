import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectTab } from "../../store/actions/index";

const homeLinkCss = {
  color: "#484644",
};
const HomeLinks = (props) => {
  const notificationcount = useSelector((state) => state.notification);
  const examReg = localStorage.getItem("examReg");
  const reReg = localStorage.getItem("reReg");
  const dispatch = useDispatch();
  const ticketNotification = useSelector((state) => state.ticketNotification);
  const chatNotification = useSelector((state) => state.chatNotification);
  // const reRegistrationCount = useSelector((state) => state.reRegistrationCount);
  return (

    <>
      <ul className="home-ul-list list-unstyled">
        <li>
          <Link
            to="/profile"
            className="home-link"
            onClick={() => dispatch(selectTab({ tab: 2 }))}
            style={homeLinkCss}
            title="Always keep your student profile information up to date"
          >
            <div className="card home-bx-card">
              <div className="icon">
                <svg xmlns="https://www.w3.org/2000/svg" width="42.281" height="48.321" viewBox="0 0 42.281 48.321">
                  <g id="my_profile" transform="translate(-72 -327.717)">
                    <g id="user" transform="translate(81.06 327.717)">
                      <path id="Path_848" data-name="Path 848" d="M24.883,24.161A12.08,12.08,0,1,0,12.8,12.08,12.079,12.079,0,0,0,24.883,24.161Zm0-21.14a9.06,9.06,0,1,1-9.06,9.06A9.075,9.075,0,0,1,24.883,3.02Z" transform="translate(-12.803)" fill="#467fcf" />
                    </g>
                    <g id="user-2" data-name="user" transform="translate(72 354.898)">
                      <path id="Path_848-2" data-name="Path 848" d="M29.6,38.409c-2.709,0-4.011,1.51-8.456,1.51s-5.738-1.51-8.456-1.51A12.688,12.688,0,0,0,0,51.093v3.926a4.531,4.531,0,0,0,4.53,4.53H37.751a4.531,4.531,0,0,0,4.53-4.53V51.093A12.688,12.688,0,0,0,29.6,38.409Zm9.664,16.61a1.514,1.514,0,0,1-1.51,1.51H4.53a1.514,1.514,0,0,1-1.51-1.51V51.093a9.671,9.671,0,0,1,9.664-9.664c1.85,0,3.69,1.51,8.456,1.51s6.606-1.51,8.456-1.51a9.671,9.671,0,0,1,9.664,9.664Z" transform="translate(0 -38.409)" fill="#467fcf" />
                    </g>
                  </g>
                </svg>
              </div>
              <div className="home-bx-card-info">
                <h3>My Profile </h3>
                <p>
                  Always keep your student profile
                  <br /> information up to date.
                </p>
              </div>
            </div>
          </Link>
        </li>
        <li>
          <Link
            to="/mycourses"
            className="home-link"
            onClick={() => dispatch(selectTab({ tab: 4 }))}
            style={homeLinkCss}
            title="All your important course information for easy study"
          >
            <div className="card home-bx-card">
              <div className="icon">
                <svg xmlns="https://www.w3.org/2000/svg" id="my_courses" width="50" height="40" viewBox="0 0 50 40">
                  <path id="Path_850" data-name="Path 850" d="M48.75,36.642H40v-6.25a2.5,2.5,0,0,0-2.5-2.5h-15a2.5,2.5,0,0,0-2.5,2.5v6.25H1.25A1.25,1.25,0,0,0,0,37.892v1.25a1.25,1.25,0,0,0,1.25,1.25h47.5A1.25,1.25,0,0,0,50,39.142v-1.25A1.25,1.25,0,0,0,48.75,36.642Zm-12.5,0H23.75v-5h12.5Z" transform="translate(0 -0.392)" fill="#467fcf" />
                  <g id="chalkboard" transform="translate(2.5)">
                    <path id="Path_850-2" data-name="Path 850" d="M6.286,3.75h37.5V32.5h3.75V3.125A3.128,3.128,0,0,0,44.411,0H5.661A3.128,3.128,0,0,0,2.536,3.125V32.5h3.75Z" transform="translate(-2.536)" fill="#467fcf" />
                  </g>
                </svg>
                {chatNotification.chatnotificationcount > 0 && (
                  <small className="notification-bg">
                    {chatNotification.chatnotificationcount > 9
                      ? "9+"
                      : chatNotification.chatnotificationcount}
                  </small>
                )}
              </div>
              <div className="home-bx-card-info">
                <h3>My Courses</h3>
                <p>All your important course information for easy study.</p>
              </div>
            </div>
          </Link>
        </li>
        <li>
          <Link
            to="/mytickets"
            className="home-link"
            onClick={() => dispatch(selectTab({ tab: 5 }))}
            style={homeLinkCss}
            title="Log and track your tickets with the support team"
          >
            <div className="card home-bx-card">
              <div className="icon">
                <svg xmlns="https://www.w3.org/2000/svg" width="50" height="33.333" viewBox="0 0 50 33.333">
                  <g id="my_tickets" transform="translate(-71.736 -509.157)">
                    <path id="Path_855" data-name="Path 855" d="M47.222,77.889H50V68.167A4.167,4.167,0,0,0,45.833,64H4.167A4.167,4.167,0,0,0,0,68.167v9.722H2.778a2.778,2.778,0,0,1,0,5.556H0v9.722a4.167,4.167,0,0,0,4.167,4.167H45.833A4.167,4.167,0,0,0,50,93.167V83.444H47.222a2.778,2.778,0,1,1,0-5.556Zm0,8.333v6.944a1.39,1.39,0,0,1-1.389,1.389H4.167a1.39,1.39,0,0,1-1.389-1.389V86.222a5.556,5.556,0,1,0,0-11.111V68.167a1.39,1.39,0,0,1,1.389-1.389H45.833a1.39,1.39,0,0,1,1.389,1.389v6.944a5.556,5.556,0,1,0,0,11.111Z" transform="translate(71.736 445.157)" fill="#467fcf" />
                    <path id="Path_859" data-name="Path 859" d="M38.923,73.921H15.312A2.083,2.083,0,0,0,13.229,76V88.5a2.083,2.083,0,0,0,2.083,2.083H38.923A2.083,2.083,0,0,0,41.006,88.5V76A2.083,2.083,0,0,0,38.923,73.921ZM38.229,87.81H16.006V76.7H38.229Z" transform="translate(69.618 443.569)" fill="#467fcf" />
                  </g>
                </svg>
                {ticketNotification.ticketnotificationcount > 0 && (
                  <small className="notification-bg">
                    {ticketNotification.ticketnotificationcount > 9
                      ? "9+"
                      : ticketNotification.ticketnotificationcount}
                  </small>
                )}
              </div>
              <div className="home-bx-card-info">
                <h3>My Tickets</h3>
                <p>
                  Log and track your tickets with
                  <br /> the support team.
                </p>
              </div>
            </div>
          </Link>
        </li>
        <li>
          <Link
            to="/mynotifications"
            className="home-link"
            onClick={() => dispatch(selectTab({ tab: 6 }))}
            style={homeLinkCss}
            title="Get important information of direct interest to you and your studies"
          >
            <div className="card home-bx-card">
              <div className="icon">
                <svg xmlns="https://www.w3.org/2000/svg" width="42.14" height="48.16" viewBox="0 0 42.14 48.16">
                  <g id="notifications" transform="translate(-253 -506)">
                    <path id="Path_856" data-name="Path 856" d="M40.76,31.493c-2.63-2.5-4.641-5.124-4.641-14.007,0-7.486-5.963-13.592-13.549-14.33V1.5a1.5,1.5,0,0,0-3.01,0V3.157C11.974,3.9,6.012,10,6.012,17.486c0,8.881-2.012,11.5-4.642,14.007A4.378,4.378,0,0,0,.312,36.312a4.488,4.488,0,0,0,4.2,2.818H37.62a4.489,4.489,0,0,0,4.2-2.819A4.377,4.377,0,0,0,40.76,31.493ZM37.62,36.12H4.511a1.428,1.428,0,0,1-1.065-2.447c3.279-3.122,5.576-6.616,5.576-16.186,0-6.338,5.387-11.467,12.044-11.467s12.044,5.128,12.044,11.466c0,9.533,2.277,13.046,5.576,16.186A1.428,1.428,0,0,1,37.62,36.12Z" transform="translate(253.005 506)" fill="#467fcf" />
                    <path id="Path_860" data-name="Path 860" d="M21.641,46.76a3.015,3.015,0,0,1-3.01-3.013h-3.01a6.02,6.02,0,1,0,12.04,0h-3.01A3.015,3.015,0,0,1,21.641,46.76Z" transform="translate(252.43 504.39)" fill="#467fcf" />
                  </g>
                </svg>
                {/* <img src={MyNotifications} alt="" /> */}
                {notificationcount.notificationcount > 0 && (
                  <small className="notification-bg">
                    {notificationcount.notificationcount > 9
                      ? "9+"
                      : notificationcount.notificationcount}
                  </small>
                )}
              </div>
              <div className="home-bx-card-info">
                <h3>Notifications</h3>
                <p>
                  Get important information of direct
                  <br /> interest to you and your studies.
                </p>
              </div>
            </div>
          </Link>
        </li>
        <li>
          <Link
            to="/calendar"
            className="home-link"
            onClick={() => dispatch(selectTab({ tab: 10 }))}
            style={homeLinkCss}
            title="All your important dates in one location"
          >
            <div className="card home-bx-card">
              <div className="icon">
                <svg xmlns="https://www.w3.org/2000/svg" id="calendar" width="42.14" height="48.16" viewBox="0 0 42.14 48.16">
                  <path id="Path_845" data-name="Path 845" d="M37.625,6.02H33.11V1.129A1.132,1.132,0,0,0,31.981,0h-.753A1.132,1.132,0,0,0,30.1,1.129V6.02H12.04V1.129A1.132,1.132,0,0,0,10.911,0h-.753A1.132,1.132,0,0,0,9.03,1.129V6.02H4.515A4.516,4.516,0,0,0,0,10.535v33.11A4.516,4.516,0,0,0,4.515,48.16h33.11a4.516,4.516,0,0,0,4.515-4.515V10.535A4.516,4.516,0,0,0,37.625,6.02ZM4.515,9.03h33.11a1.509,1.509,0,0,1,1.505,1.505V15.05H3.01V10.535A1.509,1.509,0,0,1,4.515,9.03Zm33.11,36.12H4.515A1.509,1.509,0,0,1,3.01,43.645V18.06H39.13V43.645A1.509,1.509,0,0,1,37.625,45.15Z" fill="#467fcf" />
                  <g id="calendar-alt" transform="translate(9.03 24.08)">
                    <path id="Path_845-2" data-name="Path 845" d="M14.266,31.02H10.5a1.132,1.132,0,0,1-1.129-1.129V26.129A1.132,1.132,0,0,1,10.5,25h3.763A1.132,1.132,0,0,1,15.4,26.129v3.763A1.132,1.132,0,0,1,14.266,31.02Zm9.03,0H19.534a1.132,1.132,0,0,1-1.129-1.129V26.129A1.132,1.132,0,0,1,19.534,25H23.3a1.132,1.132,0,0,1,1.129,1.129v3.763A1.132,1.132,0,0,1,23.3,31.02Zm9.03,0H28.564a1.132,1.132,0,0,1-1.129-1.129V26.129A1.132,1.132,0,0,1,28.564,25h3.763a1.132,1.132,0,0,1,1.129,1.129v3.763A1.132,1.132,0,0,1,32.326,31.02ZM23.3,40.05H19.534a1.132,1.132,0,0,1-1.129-1.129V35.159a1.132,1.132,0,0,1,1.129-1.129H23.3a1.132,1.132,0,0,1,1.129,1.129v3.763A1.132,1.132,0,0,1,23.3,40.05Zm-9.03,0H10.5a1.132,1.132,0,0,1-1.129-1.129V35.159A1.132,1.132,0,0,1,10.5,34.03h3.763A1.132,1.132,0,0,1,15.4,35.159v3.763A1.132,1.132,0,0,1,14.266,40.05Zm18.06,0H28.564a1.132,1.132,0,0,1-1.129-1.129V35.159a1.132,1.132,0,0,1,1.129-1.129h3.763a1.132,1.132,0,0,1,1.129,1.129v3.763A1.132,1.132,0,0,1,32.326,40.05Z" transform="translate(-9.375 -25)" fill="#467fcf" />
                  </g>
                </svg>
                {/* <img src={MyCalender} alt="" /> */}
              </div>
              <div className="home-bx-card-info">
                <h3>Calendar</h3>
                <p>All your important dates in one location</p>
              </div>
            </div>
          </Link>
        </li>

        {(examReg == "true" || examReg == true) && (
          <li>
            <Link
              to="/myexam"
              className="home-link"
              onClick={() => dispatch(selectTab({ tab: 8 }))}
              style={homeLinkCss}
              title="Your Exam Registrations"
            >
              <div className="card home-bx-card">
                <div className="icon">
                  <svg xmlns="https://www.w3.org/2000/svg" id="exam_reg" data-name="exam reg" width="45" height="60" viewBox="0 0 45 60">
                    <path id="Path_4282" data-name="Path 4282" d="M39.375,7.5H28.992a6.2,6.2,0,0,0,.07-.938,6.562,6.562,0,1,0-13.125,0,6.2,6.2,0,0,0,.07.938H5.625A5.626,5.626,0,0,0,0,13.125v41.25A5.626,5.626,0,0,0,5.625,60h33.75A5.626,5.626,0,0,0,45,54.375V13.125A5.626,5.626,0,0,0,39.375,7.5ZM22.5,3.75a2.813,2.813,0,1,1-2.812,2.812A2.806,2.806,0,0,1,22.5,3.75ZM41.25,54.375a1.881,1.881,0,0,1-1.875,1.875H5.625A1.881,1.881,0,0,1,3.75,54.375V13.125A1.881,1.881,0,0,1,5.625,11.25H11.25v2.344A1.41,1.41,0,0,0,12.656,15H32.344a1.41,1.41,0,0,0,1.406-1.406V11.25h5.625a1.881,1.881,0,0,1,1.875,1.875Z" transform="translate(0 0)" fill="#467fcf" />
                  </svg>
                  {/* <img src={ExamReg} alt="" /> */}
                </div>
                <div className="home-bx-card-info">
                  <h3>Exam Registration</h3>
                  <p>Your Exam Registrations</p>
                </div>
              </div>
            </Link>
          </li>
        )}
        {(reReg == true || reReg == "true" ) && (
          <li>
            <Link
              to="/reregistration"
              className="home-link"
              onClick={() => dispatch(selectTab({ tab: 9 }))}
              style={homeLinkCss}
              title="Your Re-Registrations"
            >
              <div className="card home-bx-card">
                <div className="icon">
                  <svg xmlns="https://www.w3.org/2000/svg" width="45" height="60" viewBox="0 0 45 60">
                    <g id="Re-_reg" data-name="Re- reg" transform="translate(-310 -752)">
                      <path id="Path_4283" data-name="Path 4283" d="M32.812,28.125H19.687a.94.94,0,0,0-.937.938v1.875a.94.94,0,0,0,.937.938H32.812a.94.94,0,0,0,.937-.937V29.062A.94.94,0,0,0,32.812,28.125Zm0,11.25H19.687a.94.94,0,0,0-.937.938v1.875a.94.94,0,0,0,.937.938H32.812a.94.94,0,0,0,.937-.938V40.312A.94.94,0,0,0,32.812,39.375ZM13.125,27.187A2.813,2.813,0,1,0,15.937,30,2.806,2.806,0,0,0,13.125,27.187Zm0,11.25a2.813,2.813,0,1,0,2.812,2.813A2.806,2.806,0,0,0,13.125,38.437Z" transform="translate(310 752)" fill="#467fcf" />
                      <path id="Re-_reg-2" data-name="Re- reg" d="M39.375,7.5H28.992a6.2,6.2,0,0,0,.07-.938,6.562,6.562,0,1,0-13.125,0,6.2,6.2,0,0,0,.07.938H5.625A5.626,5.626,0,0,0,0,13.125v41.25A5.626,5.626,0,0,0,5.625,60h33.75A5.626,5.626,0,0,0,45,54.375V13.125A5.626,5.626,0,0,0,39.375,7.5ZM22.5,3.75a2.812,2.812,0,1,1-2.812,2.812A2.806,2.806,0,0,1,22.5,3.75ZM41.25,54.375a1.881,1.881,0,0,1-1.875,1.875H5.625A1.881,1.881,0,0,1,3.75,54.375V13.125A1.881,1.881,0,0,1,5.625,11.25H11.25v2.344A1.41,1.41,0,0,0,12.656,15H32.344a1.41,1.41,0,0,0,1.406-1.406V11.25h5.625a1.881,1.881,0,0,1,1.875,1.875Z" transform="translate(310 752)" fill="#467fcf" />
                    </g>
                  </svg>
                  {/* <img src={ReReg} alt="" /> */}
                  {/* {reRegistrationCount.reRegCount > 0 && (
                  <small className="notification-bg">
                    {reRegistrationCount.reRegCount > 9
                      ? "9+"
                      : reRegistrationCount.reRegCount}
                  </small>
                )} */}
                </div>
                <div className="home-bx-card-info">
                  <h3>Re-Registration</h3>
                  <p>Your Re-Registrations</p>
                </div>
              </div>
            </Link>
          </li>
        )}
        <li>
          <Link
            to="/student-hub/"
            className="home-link"
            onClick={() => dispatch(selectTab({ tab: 11 }))}
            style={homeLinkCss}
            title="Access academic resources, policies, library and how-to guides here"
          >
            <div className="card home-bx-card">
              <div className="icon">
                <svg xmlns="https://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45">
                  <g id="Group_1927" data-name="Group 1927" transform="translate(13175 -1704)">
                    <g id="info" transform="translate(-13175 1704)">
                      <path id="profile" d="M-30.788-91.632a18.821,18.821,0,0,0-13.879,5.76,18.94,18.94,0,0,0-5.715,13.834,18.821,18.821,0,0,0,5.76,13.879,18.94,18.94,0,0,0,13.834,5.715A18.821,18.821,0,0,0-16.909-58.2a18.94,18.94,0,0,0,5.715-13.834,18.821,18.821,0,0,0-5.76-13.879,18.94,18.94,0,0,0-13.834-5.715Zm-15.92,3.673a21.685,21.685,0,0,1,15.92-6.576,21.685,21.685,0,0,1,15.92,6.577,21.685,21.685,0,0,1,6.577,15.92,21.685,21.685,0,0,1-6.577,15.92,21.685,21.685,0,0,1-15.92,6.577,21.685,21.685,0,0,1-15.92-6.577,21.685,21.685,0,0,1-6.577-15.92,21.685,21.685,0,0,1,6.577-15.92Z" transform="translate(53.288 94.538)" fill="#467fcf" />
                      <path id="profile-2" data-name="profile" d="M-9.226-50.211h1.1V-61.2h-1.1a.971.971,0,0,1-.824-.275.971.971,0,0,1-.275-.824v-.731a.971.971,0,0,1,.275-.824.971.971,0,0,1,.824-.275h4.4a.971.971,0,0,1,.824.275.971.971,0,0,1,.275.824v12.82h1.1a.971.971,0,0,1,.824.275.971.971,0,0,1,.275.824v.733a.971.971,0,0,1-.275.823.971.971,0,0,1-.823.274H-9.226a.971.971,0,0,1-.824-.275.971.971,0,0,1-.275-.824v-.733a.971.971,0,0,1,.275-.824.971.971,0,0,1,.824-.275ZM-7.99-71.317a2.816,2.816,0,0,1,2.06-.87,2.816,2.816,0,0,1,2.06.87A2.816,2.816,0,0,1-3-69.257a2.816,2.816,0,0,1-.87,2.06,2.816,2.816,0,0,1-2.06.87,2.816,2.816,0,0,1-2.06-.87,2.816,2.816,0,0,1-.87-2.06,2.816,2.816,0,0,1,.87-2.06Z" transform="translate(28.43 81.517)" fill="#467fcf" />
                    </g>
                  </g>
                </svg>
                {/* <img src={StudentHub} alt="" /> */}
              </div>
              <div className="home-bx-card-info">
                <h3>Student Hub</h3>
                <p> Access academic resources, policies, library and how-to guides here</p>
              </div>
            </div>
          </Link>
        </li>
        {/* <li>
          <Link
            to="/complimentcomments"
            className="home-link"
            onClick={() => dispatch(selectTab({ tab: 7 }))}
            style={homeLinkCss}
          >
            <div className="card home-bx-card">
              <div className="icon">
                <img src={MyFeedback} alt="" />
              </div>
              <div className="home-bx-card-info">
                <h3>Feedback</h3>
                <p>Student feedback and assistance at your fingertips.</p>
              </div>
            </div>
          </Link>
        </li> */}
      </ul>{" "}
    </>
  );
};

export default HomeLinks;
