import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import "./assets/css/all.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import 'react-lazy-load-image-component/src/effects/blur.css';
import "react-circular-progressbar/dist/styles.css";

// import "./assets/css/custom.css";
// import './assets/css/custom-gdc.css';
// import "./assets/css/global.css";
import MainApp from "./MainApp";
import * as serviceWorker from "./serviceWorker";
import axios from "axios";
import { getToken, removeUserSession } from "./utils/Auth";

import Popper from "popper.js";
import "bootstrap/dist/js/bootstrap.bundle.min";
import Swal from "sweetalert2";

import { Provider } from "react-redux";
import { createStore } from "redux";
import rootReducer from "./store/index";
import { gdcVariables, aieVariables } from "./utils/CustomModels.js";
import {
  STYLE_URL_GDC,
  STYLE_URL_AIE,
  STYLE_URL_LOCALHOST_3000,
  STYLE_URL_LOCALHOST_3001,
  LOCAL_HOST_DOMAIN,
} from "./utils/Constants";
import { GetBrand } from "./services/BrandService";
import { setBrand } from "./store/actions";
import { MathJax, MathJaxContext } from "better-react-mathjax";
import Loading from "./components/common/Loading.js";
const AIE = React.lazy(() => import("./components/common/brand/AIE"));
// const GDC = React.lazy(() => import("./components/common/brand/GDC"));

// switch (JSON.parse(localStorage.getItem("brandVariables")).brand) {
//   case 'GDC':
//     require("./assets/css/custom-gdc.css");
//     break;
//   case 'AIE':
//     require("./assets/css/custom.css");
//     break;
//   default:
//     require("./assets/css/custom-gdc.css");
//     break;

// }
//require('./assets/css/custom-gdc.css');

const loadState = () => {
  try {
    const serializedState = localStorage.getItem("state");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (e) {
    return undefined;
  }
};

const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("state", serializedState);
  } catch (e) {
    // Ignore write errors;
  }
};

const peristedState = loadState();

const store = createStore(
  rootReducer,
  peristedState,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  // Others reducers...
);

store.subscribe(() => {
  saveState(store.getState());
});

const customStyle = () => {
  let brandVariables = aieVariables;
  // brandVariables = aieVariables;
  // switch (window.location.host) {
  //   case STYLE_URL_AIE:
  //   case "dev.myaie.ac":
  //   case STYLE_URL_LOCALHOST_3000:
  //     brandVariables = aieVariables;
  //     break;

  //     case STYLE_URL_GDC:
  //     case STYLE_URL_LOCALHOST_3001:
  //       brandVariables = gdcVariables;
  //       break;
  // }
  localStorage.setItem("brandVariablesLocal", JSON.stringify(brandVariables));
}

const BrandSelector = ({ children }) => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    customStyle();
    localStorage.removeItem("brandVariables")
    let domain = window.location.hostname;
    if(domain == "localhost"){
      domain = LOCAL_HOST_DOMAIN
    }
    // GetBrand({ domain: (window.location.host.includes("mygdc.ac") || window.location.host.includes("localhost:3001")) ? "mygdc.ac" : window.location.hostname == "dev.myaie.ac" ? "dev.myaie.ac" : "myaie.ac" /*window.location.hostname*/ })
    GetBrand({ domain: domain })
      .then(({ data }) => {
        localStorage.setItem("brandVariables", JSON.stringify(data.data));
        const localData = data.data;
        document.title = localData.title;
        store.dispatch(setBrand([localData]));
        document.body.style.setProperty("--topbar-color", localData.topbar_color);
        document.body.style.setProperty("--highlight-color", localData.highlight_color);
        document.body.style.setProperty("--sidebar-color", localData.sidebar_color);
        document.body.style.setProperty("--hover-color", localData.hover_color);
        document.body.style.setProperty("--active-color", localData.active_color);
        document.body.style.setProperty("--login-header-color", localData.login_header_color);
        document.body.style.setProperty("--button-color", localData.button_color);
        document.body.style.setProperty("--button-hover-color", localData.button_hover_color);
        document.body.style.setProperty("--button-active-color", localData.button_active_color);

      })
      .catch(() => { })
      .finally(()=>{setLoading(false)});
  }, []);

  return ( !loading ?
    <>
      <React.Suspense fallback={<></>}>
        <AIE />
      </React.Suspense >
      { children }
    </>
    :<>
    <Loading />
    </>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <BrandSelector>
      <Provider store={store}>
        <MathJaxContext>
        <MainApp />
        </MathJaxContext>
      </Provider>
    </BrandSelector>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
