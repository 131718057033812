import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ExamSubjectAppend from "./ExamSubjectAppend";
import { useFormik, FieldArray } from "formik";
import * as Yup from "yup";
import Link from "react-router-dom";
import ExamCenters from "./ExamCenters";
import StudentDateBirth from "./StudentDateBirth";
import { GetMyOpenCloseExam, submitExamRegistration } from "./../../services/ExamService";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { setIsExamReg } from "../../store/actions";

const ExamRightBox = (props) => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const location = useHistory();
  const [rsaId, setRsaId] = useState(false);
  const [passport, setPassport] = useState(false);
  const [examId, setExamId] = useState(false);
  const [subjectList, setSubjectList] = useState([]);
  const [parentSubjectLength, setParentSubjectLength] = useState([
    { subject: "", first: "" },
  ]);
  //const[parentFirstLength,setParentFirstLength]=useState([{ subject: null },{ first: null }]);
  const [appendSubjectNumber, setAppendSubjectNumber] = useState([
    { index: Math.random() },
  ]);
  const [items, setItems] = useState([Math.random()]);
  const [center, setCenter] = useState("");
  const [checked, setChecked] = useState(false);
  const [studentType, setStudentType] = useState("");
  const [loading, setLoading] = useState(false);

  const [isExamId, setIsExamId] = useState(false);
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [secondName, setSecondName] = useState("");
  const [thirdName, setThirdName] = useState("");
  const [gender, setGender] = useState("");
  const [rsaIdentity, setRsaIdentity] = useState("");
  const [bdayDay, setBdayDay] = useState("");
  const [bdayMonth, setBdayMonth] = useState("");
  const [bdayYear, setBdayYear] = useState("");
  const [race, setRace] = useState("");
  const [idNumber, setIdNumber] = useState("");
  const [rsaFieldId, setRsaFieldId] = useState("");
  const [yearoptions, setYearoptions] = useState([]);

  var arrayHelpers = [1];
  let history = useHistory();

  // const handleSubjectAppend=()=>{
  // 	let addSubject = [Math.random()];

  // 	 setItems(...items,[Math.random()]);
  // 	 console.log(items)
  // }

  // const handleSubjectRemove = (value) => {

  // 	arrayHelpers = items.filter(function(item,ind) {
  // 		return item !== value
  // 	});

  // 	setItems(items=>[items,arrayHelpers]);
  // }

  useEffect(() => {
    if (user) {
      setFirstName(user.first_name);
      setLastName(user.last_name);
      setSecondName(user.second_name);
      setThirdName(user.third_name);
      setGender(user.gender);
      setBdayDay(user.bday_day);
      setBdayMonth(user.bday_month);
      setBdayYear(user.bday_year);
      setRace(user.race);
      setRsaIdentity(user.number);
      setIdNumber(user.exam_id_number);
      setIsExamId(user.is_exam_id==1);

      if (user.nationality == "zar") {
        setStudentType("south-african-citizen");
        setRsaId(true);
        setPassport(false);
        setExamId(false);
      } else if (user.nationality != "") {
        setStudentType("international-student");
        setRsaId(false);
        setPassport(true);
        setExamId(true);
      } else {
        setStudentType("");
        setRsaId(true);
        setPassport(false);
        setExamId(false);
      }
    }
  }, []);
  const { examid, examdetails } = props;
  const handleStudentType = (e) => {
    const subjectType = e.target.value;

    //set value to formik form
    setStudentType(subjectType);

    // setRsaIdentity("");
    // setIdNumber("");

    if (subjectType === "south-african-citizen") {
      setRsaId(true);
      setPassport(false);
      setExamId(false);
      setIsExamId(false);
    } else {
      setRsaId(false);
      setPassport(true);
      setExamId(true);
    }
  };

  const handleFirstName = (e) => {
    setFirstName(e.target.value);
  };

  const handleLastName = (e) => {
    setLastName(e.target.value);
  };
  const handleSecondName = (e) => {
    setSecondName(e.target.value);
  };
  const handleThirdName = (e) => {
    setThirdName(e.target.value);
  };
  const handleRsa = (e) => {
    setRsaIdentity(e.target.value);
  };
  const handleRasEdentity = (e) => {
    setRsaIdentity(e.target.value);
  };
  const handleIdNumber = (e) => {
    setIdNumber(e.target.value);
  };
  const handleGender = (e) => {
    setGender(e.target.value);
  };
  const handleRace = (e) => {
    setRace(e.target.value);
  };

  const handleCheck = (e) => {
    setChecked(e.target.checked);
  };

  const handleCheckExamId = (e) => {
    setIsExamId(e.target.checked);
    if(e.target.checked == true){
      setIdNumber("")
    }
  };


  const matchName = /^[a-zA-Z-]+$/;
  const matchSurName = /^[a-zA-Z-\s]+$/;

  const matchId = /^[0-9]{13}$/;
  const matchPassport = /^[0-9a-zA-Z]$/;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      exam: examid,
      last_name: lastName,
      first_name: firstName,
      second_name: secondName,
      third_name: thirdName,
      gender: gender,
      rsa_identity: rsaIdentity,
      exam_id_number: idNumber,
      bday_year: bdayYear>0 ? bdayYear:'',
      bday_month: bdayMonth>0 ? bdayMonth:'',
      bday_day: bdayDay>0 ? bdayDay:'',
      race: race?.toLowerCase(),
      student_type: studentType,
      centre: center,
      subject_: parentSubjectLength,
      term_condn: checked,
      is_exam_id: isExamId,
    },
    validationSchema: Yup.object({
      first_name: Yup.string()
        .matches(matchName, "Name should be A-Z or a-z (no spaces)")
        .required("Required"),
      last_name: Yup.string()
        .matches(matchSurName, "Name should be A-Z or a-z (may contain spaces)")
        .required("Required"),

      second_name: Yup.string().nullable().matches(
        matchName,
        "Name should be A-Z or a-z (no spaces)"
      ),
      third_name: Yup.string().nullable().matches(
        matchName,
        "Name should be A-Z or a-z (no spaces)"
      ),
      student_type: Yup.string().required("Required"),
      rsa_identity: Yup.string().required("Required").when("student_type", {
        is : "south-african-citizen",
        then : Yup.string().required("Required").matches(matchId, `RSA ID number should be 13 numbers`)
      }),
      exam_id_number: Yup.string().nullable().when(["student_type", "is_exam_id"], {
        is: (student_type, is_exam_id) =>{return (student_type=="international-student" && is_exam_id==false)} ,
        then: Yup.string().matches(matchId, "Exam ID number should be 13 numbers.").required("Required"),
      }),
      term_condn: Yup.bool().oneOf(
        [true],
        "Accept Terms & Conditions is required"
      ),
      // exam_id_number:Yup.string()
      // .required('Required'),
      gender: Yup.string().required("Required"),
      race: Yup.string().required("Required"),
      bday_day: Yup.string().required("Required"),
      bday_month: Yup.string().required("Required"),
      bday_year: Yup.string().required("Required"),
      centre: Yup.string().required("Required"),
      subject_: Yup.array()
        .of(
          Yup.object().shape({
            subject: Yup.string().required("Required"), // these constraints take precedence
            first: Yup.string().required("Required"), // these constraints take precedence
          })
        )
        .required("Must have friends"),
    }),
    onSubmit: (values) => {
      setLoading(true);
      const sendData = {
        ...values,
        is_exam_id : values.is_exam_id==true ? 1 : 0
      }
      submitExamRegistration(sendData).then((res) => {
        if (res.data.status == 1) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Updated successfully",
          }).then((result) => {
            if (result.value) {
              GetMyOpenCloseExam({exam_type:"in_progress", exam:0}).then((response)=>{
                const examStatus = response?.data?.open.filter(item => item.registrationStudentStatus === "Not Registered" && (item.remind !==0)).length > 0 ? true : false;
                if(examStatus==true){
                  dispatch(setIsExamReg(examStatus))                
                  localStorage.setItem('examRegStatus',JSON.stringify(examStatus));
                  location.push("/examregistration")
                }else{
                  dispatch(setIsExamReg(examStatus))                
                  localStorage.setItem('examRegStatus',JSON.stringify(examStatus));                  
                  props.handleParentExamStatus("viewed");                
                }                                
              }).catch((error)=>{console.log(error)})
            }
          });
        }
      });
    },
  });

  return (
    <div className="exam-detail-rgt">
      <div className="exam-detail-top d-flex justify-content-between">
        <div className="exam-detail-top-left">
          <div className="exam-bx-info  d-flex">
            <div className="icon">ME</div>
            <div className="exam-bx-info-rgt">
              <h3>External Exam Registration Form</h3>
              <p>
                Register here for your DoE National Exams. Please ensure that
                you have completed all the required fields
              </p>
            </div>
          </div>
        </div>
        {/* <div className="exam-detail-top-right">
					<a href="exam-list.html">Back</a>
				</div>		 */}
      </div>

      <div className="exam-register-bx">
        <div className="card card-form-bx">
          <div className="card-header">Application Comments</div>

          <form onSubmit={formik.handleSubmit}>
            <div className="card-body">
              <div className="theme-form application-form">
                <div className="row">
                  <div className="form-group col-sm-6">
                    <label>Surname as per Your Official ID*</label>
                    <input
                      type="hidden"
                      name="exam"
                      value={formik.values.last_name}
                    />
                    <input
                      type="text"
                      className={
                        "form-control " +
                        (formik.errors.last_name && formik.touched.last_name
                          ? " is-invalid"
                          : "")
                      }
                      placeholder=""
                      name="last_name"
                      value={formik.values.last_name}
                      onChange={(e) => handleLastName(e)}
                      onBlur={formik.handleBlur}
                    />

                    {formik.touched.last_name && formik.errors.last_name ? (
                      <div className="invalid-feedback">
                        {formik.errors.last_name}
                      </div>
                    ) : null}
                  </div>
                  <div className="form-group col-sm-6">
                    <label>First Names as per Your Official ID*</label>
                    <input
                      type="text"
                      className={
                        "form-control " +
                        (formik.touched.first_name && formik.errors.first_name
                          ? " is-invalid"
                          : "")
                      }
                      placeholder="First Name"
                      value={formik.values.first_name}
                      onChange={(e) => handleFirstName(e)}
                      onBlur={formik.handleBlur}
                      name="first_name"
                    />
                    {formik.touched.first_name && formik.errors.first_name ? (
                      <div className="invalid-feedback">
                        {formik.errors.first_name}
                      </div>
                    ) : null}
                  </div>
                  <div className="form-group col-sm-6">
                    <label>
                      Second Name as per Your Official ID (leave empty if not
                      applicable)
                    </label>
                    <input
                      type="text"
                      className={
                        "form-control " +
                        (formik.touched.second_name && formik.errors.second_name
                          ? " is-invalid"
                          : "")
                      }
                      placeholder="Second Name"
                      value={formik.values.second_name}
                      onChange={(e) => handleSecondName(e)}
                      onBlur={formik.handleBlur}
                      name="second_name"
                    />
                    {formik.touched.second_name && formik.errors.second_name ? (
                      <div className="invalid-feedback">
                        {formik.errors.second_name}
                      </div>
                    ) : null}
                  </div>
                  <div className="form-group col-sm-6">
                    <label>
                      Third Name as per Your Official ID (leave empty if not
                      applicable)
                    </label>
                    <input
                      type="text"
                      className={
                        "form-control " +
                        (formik.touched.third_name && formik.errors.third_name
                          ? "is-invalid"
                          : null)
                      }
                      placeholder="Third Name"
                      name="third_name"
                      value={formik.values.third_name}
                      onChange={(e) => handleThirdName(e)}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.third_name && formik.errors.third_name ? (
                      <div className="invalid-feedback">
                        {formik.errors.third_name}
                      </div>
                    ) : null}
                  </div>
                  <div className="form-group col-sm-6">
                    <label>Student Type*</label>
                    <select
                      className={
                        "form-control " +
                        (formik.touched.student_type &&
                        formik.errors.student_type
                          ? " is-invalid"
                          : "")
                      }
                      value={formik.values.student_type}
                      name="student_type"
                      onChange={(e) => handleStudentType(e)}
                      onBlur={formik.handleBlur}
                    >
                      <option value="">-------</option>
                      <option value="south-african-citizen">
                        South African Citizen
                      </option>
                      <option value="international-student">
                        International Student
                      </option>
                    </select>
                    {formik.touched.student_type &&
                    formik.errors.student_type ? (
                      <div className="invalid-feedback">
                        {formik.errors.student_type}
                      </div>
                    ) : null}
                  </div>
                    <div className="form-group col-sm-6">    
                     {rsaId === true ? (
                      <label>RSA ID Number*</label>
                      ) : null}
                      {passport === true ? (
                      <label>Passport Number*</label>
                      ) : null}
                      <input
                        type="text"
                        className={
                          "form-control " +
                          (formik.touched.rsa_identity &&
                          formik.errors.rsa_identity
                            ? " is-invalid"
                            : "")
                        }
                        placeholder=""
                        value={formik.values.rsa_identity}
                        name="rsa_identity"
                        onChange={(e) => handleRsa(e)}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.rsa_identity &&
                      formik.errors.rsa_identity ? (
                        <div className="invalid-feedback">
                          {formik.errors.rsa_identity}
                        </div>
                      ) : null}
                    </div>

                  {examId === true ? (
                    <>
                      <div className="form-group col-sm-6 d-flex align-items-center">
                        <div className="custom-control custom-checkbox text-left">
                          <input
                            type="checkbox"
                            className={
                              "custom-control-input " +
                              (formik.touched.is_exam_id && formik.errors.is_exam_id
                                ? " is-invalid"
                                : "")
                            }
                            name="is_exam_id"
                            onChange={(e) => handleCheckExamId(e)}
                            onBlur={formik.handleBlur}
                            checked={isExamId}
                            id="customCheck2"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customCheck2"
                          >
                            I do not have an Exam ID.
                          </label>
                          {formik.touched.is_exam_id && formik.errors.is_exam_id ? (
                            <div className="invalid-feedback">
                              {formik.errors.is_exam_id}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="form-group col-sm-6">
                        <label>Exam ID Number* (from previous exams)</label>
                        <input
                          type="text"
                          value={formik.values.exam_id_number}
                          className={
                            "form-control " +
                            (formik.touched.exam_id_number &&
                            formik.errors.exam_id_number
                              ? " is-invalid"
                              : "")
                          }
                          placeholder=""
                          name="exam_id_number"
                          onChange={(e) => handleIdNumber(e)}
                          onBlur={formik.handleBlur}
                          disabled={isExamId}
                        />
                        {formik.touched.exam_id_number &&
                        formik.errors.exam_id_number ? (
                          <div className="invalid-feedback">
                            {formik.errors.exam_id_number}
                          </div>
                        ) : null}
                        <div className="helper-text">
                        Previous 13 digit exam ID assigned to you by the DHET.
                        </div>
                      </div>
                    </>
                  ) : null}                  
                  <div className="form-group col-sm-6">
                    <label>Gender*</label>
                    <select
                      className={
                        "form-control " +
                        (formik.touched.gender && formik.errors.gender
                          ? " is-invalid"
                          : "")
                      }
                      name="gender"
                      value={formik.values.gender}
                      onChange={(e) => handleGender(e)}
                      onBlur={formik.handleBlur}
                    >
                      <option value="">-------</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                    {formik.touched.gender && formik.errors.gender ? (
                      <div className="invalid-feedback">
                        {formik.errors.gender}
                      </div>
                    ) : null}
                  </div>
                  <div className="form-group col-sm-6">
                    <label>Race*</label>
                    <select
                      className={
                        "form-control " +
                        (formik.touched.race && formik.errors.race
                          ? " is-invalid"
                          : "")
                      }
                      name="race"
                      value={formik.values.race}
                      onChange={(e) => handleRace(e)}
                      onBlur={formik.handleBlur}
                    >
                      <option value="">-------</option>
                      <option value="asian">Asian</option>
                      <option value="african">African</option>
                      <option value="coloured">Coloured</option>
                      <option value="indian">Indian</option>
                      <option value="white">White</option>
                      <option value="other">Other</option>
                    </select>
                    {formik.touched.race && formik.errors.race ? (
                      <div className="invalid-feedback">
                        {formik.errors.race}
                      </div>
                    ) : null}
                  </div>
                  {yearoptions && (
                    <StudentDateBirth
                      bday_day={user.bday_day > 0 ? user.bday_day:''}
                      bday_month={user.bday_month > 0 ? user.bday_month:''}
                      bday_year={user.bday_year > 0 ? user.bday_year:''}
                      setBdayDay={setBdayDay}
                      setBdayMonth={setBdayMonth}
                      setBdayYear={setBdayYear}
                      formik={formik}
                    />
                  )}

                  <div className="form-group col-12">
                    <label>Detailed description and instructions:</label>
                  </div>
                  <ExamSubjectAppend
                    formik={formik}
                    setParentSubjectLength={setParentSubjectLength}
                    parentSubjectLength={parentSubjectLength}
                    examId={examid}
                  />
                </div>
              </div>

              <ExamCenters
                formik={formik}
                center={center}
                setCenter={setCenter}
                examdetails={examdetails}
              />

              <div className="terms-condition-wrap">
                <h5>Terms & Conditions</h5>
                <p>
                  By submitting this examination registration, I hereby declare
                  that I have read through all the information on the Exam Info
                  page and I accept the rules and regulations as set out by the
                  Academic Institute of Excellence, in accordance with
                  requirements from the DHET, regarding qualifying for
                  eligibility to enter the external exams. I also confirm that
                  the details provided by me are correct.
                </p>
                <div className="custom-control custom-checkbox text-left">
                  <input
                    type="checkbox"
                    className={
                      "custom-control-input " +
                      (formik.touched.term_condn && formik.errors.term_condn
                        ? " is-invalid"
                        : "")
                    }
                    name="term_condn"
                    onChange={(e) => handleCheck(e)}
                    onBlur={formik.handleBlur}
                    checked={checked}
                    id="customCheck1"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customCheck1"
                  >
                    I agree to the terms and conditions.
                  </label>
                  {formik.touched.term_condn && formik.errors.term_condn ? (
                    <div className="invalid-feedback">
                      {formik.errors.term_condn}
                    </div>
                  ) : null}
                </div>
              </div>

              <button
                type="submit"
                className="btn btn-submit btn-primary"
                disabled={loading}
              >
                {loading && <i className="fas fa-cog fa-spin"></i>} Submit
                Application
              </button>

              {formik &&
              formik.submitCount &&
              formik.errors &&
              Object.keys(formik.errors).length ? (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  Please complete all required sections
                </div>
              ) : null}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ExamRightBox;
