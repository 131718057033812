import React from "react";

function ReReSubject(props) {
  const { reRegSubject } = props;
  let subjectType = {
    full: "Full-Time",
    cores: "Correspondence",
    part: "Part-Time",
    fulltimeonline: "Full Time (Online)",
    fulltimecontact: "Full Time (Contact)",
    parttimeonline: "Part-Time (Online)",
  };
  return (
    <>
      {reRegSubject.map((element) => {
        return (
          <React.Fragment>
            <option
              value={element.intakeID}
            >{`${element.courseName} - (Intake ${element.number}-${element.year})`}</option>
          </React.Fragment>
        );
      })}
    </>
  );
}

export default ReReSubject;
