import React, { Component } from "react";
import $ from "jquery";
import Header from "../components/common/Header";
import SideBar from "../components/common/SideBar";
import ReRegistrationLeftBox from "../components/re_registration/ReRegistrationLeftBox";
import ReRegistrationRightBox from "../components/re_registration/ReRegistrationRightBox";
import { getReRegistrationService } from "../services/ReRegistrationService";

class ReRegistrationSchool extends Component {
  constructor(props) {
    super(props);
    this.state = {
      registration: null,
      studentStatus: false,
      progressBar: false,
    };
    console.error=()=>{}
    console.warn=()=>{}
  }

  componentDidMount(props) {
    if (this.props && this.props.match) {
      getReRegistrationService(this.props.match.params.regid).then((res) => {
        this.setState({ registration: res.data });
      });
    }
  }

  updateStatus = () => {
    this.setState({ studentStatus: true, progressBar: true });
  };

  render() {
    return (
      <>
        <Header></Header>
        <div className="content-wrapper d-flex flex-wrap">
          <SideBar></SideBar>
          <div className="sidebar-right">
            <div className="re-registration-detail-wrap d-flex  h-100">
              {this.state.registration && (
                <ReRegistrationLeftBox
                  registration={this.state.registration}
                  studentStatus={this.state.studentStatus}
                  progressBar={this.state.progressBar}
                />
              )}
              {this.state.registration && (
                <ReRegistrationRightBox
                  registration={this.state.registration}
                  updateStatus={this.updateStatus}
                />
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ReRegistrationSchool;
