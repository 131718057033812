import React from "react";
import Skeleton from "react-loading-skeleton";
const SkeletonQaulification = () => {
    return (
      <section>
        <ul className="mycourses-ul-list list-unstyled">
          {Array(6)
            .fill()
            .map((item, index) => (
              <li key={index}>
                <Skeleton height={180} />
              </li>
            ))}
        </ul>
      </section>
    );
  };
  export default SkeletonQaulification;
