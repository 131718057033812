import React, { useEffect, useState } from "react";
import $ from "jquery";
import Logo from "../../assets/images/portal-logo.png";
import { useHistory, Link } from "react-router-dom";
import { removeUserSession } from "../../utils/Auth";
import Swal from "sweetalert2";
import { useSelector, useDispatch, connect } from "react-redux";
import ReactDynamicModal from "react-draggable-resizable-modal";
import ReactPlayer from "react-player";
import ReactTooltip from "react-tooltip";
import { InitalRender } from "./../common/Helper";
import { GetMyUnreadNotificationCount } from "../../services/NotificationService";
import { GetMyUnreadTicketNotificationCount } from "../../services/TicketService";
import { GetMyUnreadChatNotificationCount } from "../../services/QualificationService";

import {
  selectTab,
  unsetWidget,
  unsetUser,
  unsetUnreadNotificationCount,
  unsetTab,
  unSetExamReg,
  unSetReReg,
  modalClose,
  setUnreadNotificationCount,
  setUnreadTicketNotificationCount,
  setUnreadChatNotificationCount,
  setFavouritesCount,
  setReRegistraionCount,
  setIsExamLaunched
} from "../../store/actions";
import {
  getStudentDetails,
  LogoutService,
} from "../../services/ProfileService";
import NotificationBadge from "./NotificationBadge";
import TempProfileImage from "../../assets/images/default-profile.jpeg";
import HauweiIcon from '../../assets/images/Hauwei.svg';
import { loadWidgetChat } from "../../utils/commonFunction.js";
import { originalValues } from "./Helper";
import {
  BASE_URL,
  STYLE_URL_AIE,
  STYLE_URL_GDC,
  STYLE_URL_LOCALHOST_3000,
  STYLE_URL_LOCALHOST_3001,
} from "../../utils/Constants";
import { getAssignStudentResource } from "../../services/StudentHubService";

const Header = (props) => {
  const location = useHistory();
  const dispatch = useDispatch();
  const [copyText, setCopyText] = useState(0);
  const user = useSelector((state) => state.user);
  const modalStatus = useSelector((state) => state.modalStatusVideo);
  //console.log(modalStatus,'regyte5r4y');
  //dispatch(modalOpen('123456g'));
  const notificationcount = useSelector((state) => state.notification);
  const brandConfig = useSelector((state) => state.brand);
  const [liveState, setLiveState] = useState();
  const [refresh, setRefresh] = useState(false);
  // live helper chat
  const studentId = user.id;
  const studentCard =
    "https://www.myaie.ac/Library/Student_Registration_Profiles/" +
    studentId +
    "/documents/pdf/" +
    studentId +
    ".pdf";
  const temp_profile_photo = TempProfileImage;
  // const hoverUserData = useSelector(state => state.hoverUser);
  const hoverUser = useSelector(state => state.hoverUser);
  const isWithHeldStatus = localStorage.getItem("resultWithHeld");
  const { examLaunched } = useSelector(state=>state.examLaunched); 
  const isExamLaunched = location.location.pathname === examLaunched?.url && examLaunched?.isExamLaunched;
  const examPopup = () => dispatch(setIsExamLaunched({...examLaunched, isClicked: true}));

  const closeModal = () => {
    let arr = location.location.pathname.split("/");
    arr.pop();
    arr.pop();
    dispatch(modalClose());
    let path = `${arr.join("/")}`;
    location.push({ pathname: path });
  };

  useEffect(() => {
    if (!localStorage.getItem("unreadNotificationCount")) {
      GetMyUnreadNotificationCount().then((res) => {
        localStorage.removeItem("unreadNotificationCount");
        localStorage.setItem(
          "unreadNotificationCount",
          res.data.notificationsCount
            ? res.data.notificationsCount.unreadNotificationCount
            : 0
        );
        dispatch(
          setUnreadNotificationCount(
            res.data.notificationsCount
              ? res.data.notificationsCount.unreadNotificationCount
              : 0
          )
        );
        // setRefresh((prev) => !prev);
      });
    }
  }, []);

  useEffect(() => {
    // GetMyUnreadNotificationCount({is_reg_registration:1})
    // .then(res=>{
    //   // console.log("count res-------------------------",res.data);
    //   dispatch(setReRegistraionCount(res.data.notificationsCount.unreadNotificationCount ? res.data.notificationsCount.unreadNotificationCount: 0));
    //   setRefresh((prev) => !prev);
    // })
    // .catch(error=>{
    //   console.error(error);
    // })

    getAssignStudentResource({
      viaFormat: [],
      viaCampus: [],
      page: 1,
      limit: 10,
      key: "id",
      sorting: "ASC",
      exportStatus: false,
      viaSearch: "",
      viaTopics: [],
      brand_id: brandConfig.configuration.id
  }).then((res) => { 
        dispatch(
          setFavouritesCount(
            res.data.resource.total
              ? res.data.resource.total
              : 0
          )
        );
        // setRefresh((prev) => !prev);
      });
   
  }, []);

  useEffect(() => {
    GetMyUnreadTicketNotificationCount().then((res) => {
      localStorage.removeItem("unreadTicketNotificationCount");
      localStorage.setItem(
        "unreadTicketNotificationCount",
        res.data.notificationsCount
          ? res.data.notificationsCount.unreadTicketNotificationCount
          : 0
      );
      dispatch(
        setUnreadTicketNotificationCount(
          res.data.notificationsCount
            ? res.data.notificationsCount.unreadTicketNotificationCount
            : 0
        )
      );
      // setRefresh((prev) => !prev);
    });

    GetMyUnreadChatNotificationCount().then((res) => {
      // localStorage.removeItem("unreadChatNotificationCount");
      // console.log(' chat figures are  ', res)
      // localStorage.setItem(
      //   "unreadChatNotificationCount",
      //   res.data.notificationsCount
      //     ? res.data.notificationsCount.unreadChatNotificationCount
      //     : 0
      // );
      dispatch(
        setUnreadChatNotificationCount(
          res?.data?.newsRoomUnreadMessageCount
            ? res.data?.newsRoomUnreadMessageCount
            : 0
        )
        // dispatch(
        //   setUnreadChatNotificationCount(
        //     res?.data?.chatRoomUnreadMessageCount?.chatRoomUnreadMessageCount
        //       ? res.data.chatRoomUnreadMessageCount.chatRoomUnreadMessageCount
        //       : 0
        //   )
        // );
      );
      // setRefresh((prev) => !prev);
    });
  }, []);

  // live helper chat
  const Logout = () => {
    LogoutService(user.id)
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Signing out..",
          timer: 3000,
          onOpen: function () {
            Swal.showLoading();
          },
        }).then(function (result) {
          if (result.dismiss === "timer") {
            document
              .querySelectorAll(".lhc-delayed")
              .forEach((e) => e.remove());
            document
              .querySelectorAll(".hide-online-status")
              .forEach((e) => e.remove());

            document
              .querySelectorAll(".online-status-icon")
              .forEach((e) => e.remove());

            var livehelperChat = document.getElementById(
              "lhc_status_container"
            );
            if (livehelperChat) {
              livehelperChat.remove();
            }
            removeUserSession();
            dispatch(unsetWidget());
            dispatch(unsetUser());
            dispatch(unsetUnreadNotificationCount());
            dispatch(unsetTab()); //side bar unset tab value to default value 1
            localStorage.setItem("tab", 1);
            // localStorage.removeItem("unreadNotificationCount");
            // localStorage.removeItem('examReg');
            // localStorage.removeItem('reReg');
            var brandVariables = localStorage.getItem("brandVariables");
            var brandVariablesLocal = localStorage.getItem(
              "brandVariablesLocal"
            );
            localStorage.clear();
            localStorage.setItem("brandVariables", brandVariables);
            localStorage.setItem("brandVariablesLocal", brandVariablesLocal);
            location.push("/");
          }
        });
      })
      .catch(function (err) {
        var livehelperChat = document.getElementById("lhc_status_container");
        if (livehelperChat) {
          livehelperChat.remove();
        }
        removeUserSession();
        dispatch(unsetUser());
        dispatch(unsetUnreadNotificationCount());
        dispatch(unsetTab()); //side bar unset tab value to default value 1
        localStorage.setItem("tab", 1);
        // localStorage.removeItem("unreadNotificationCount");
        // localStorage.removeItem('examReg');
        // localStorage.removeItem('reReg');
        localStorage.clear();
        location.push("/");
      });
  };

  useEffect(() => {
    $(".user-profile li:has(ul)").addClass("li-has-menu");
    // $(".user-profile .li-has-menu > a").append(
    //   "<span className='toogle-menu-icon'><i className='fal fa-chevron-right'></i></span>"
    // );
    $(".user-profile li:has(ul) ul").addClass("sub-menu");
  }, []);

  const copyEmailText = (e) => {
    setCopyText(1);
    navigator.clipboard.writeText(user.email);
    setTimeout(() => {
      setCopyText(0)
    }, 1000);
  }

  return (
    <>
      <div className="assigned-title-blk01" id="divtoshow">
        <div className="assigned-title-info popup-top">
          <article>
            <figure className="bgBlue">
              {hoverUser.photo ? <img src={BASE_URL + "/" + hoverUser.photo.replace("/home/myaie/public_html/", "")} /> : InitalRender(originalValues(
                hoverUser.first_name + " " + hoverUser.last_name
              ))}
              <span className={`portal-status-icon ${hoverUser.activity_status?.toLowerCase() == 'online' ? 'online-u' : hoverUser.activity_status?.toLowerCase() == 'donotdisturb' ? 'donotdisturb-u' : 'offline-u'}`}></span>
              {/* <span className={"portal-status-icon " + (hoverUser.activity_status === "Online" ? "online-u" : "offline-u")}></span> */}
            </figure>
            <figcaption>
              <h4>{originalValues(
                hoverUser.first_name + " " + hoverUser.last_name
              )}</h4>
              {/* <p>
                <i className="fal fa-envelope"></i>{" "}
                <a href="#!" className="hover-color-blue" onClick={() => copyEmailText(hoverUser.email)} >{hoverUser.email}</a>
                {copyText ? (<i className="fas fa-check-circle cat-greenhex"></i>) : (hoverUser.email && <i className="fal fa-copy hover-color-blue" onClick={() => copyEmailText(hoverUser.email)} ></i>)}
              </p> */}
            </figcaption>
          </article>
        </div>
      </div>
      <ReactDynamicModal
        data-backdrop="static"
        initWidth={640}
        initHeight={410}
        onRequestClose={closeModal}
        isOpen={modalStatus.isOpen}
        data={
          <div className="body">
            <ReactPlayer
              url={`${modalStatus.videoId}`}
              controls={true}
              className="react-player"
              width="100%"
              height="100%"
              pip={true}
              stopOnUnmount={false}
              youtube="youtube"
            />
          </div>
        }
      />
      <header className="header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 d-flex flex-wrap justify-content-between align-items-center">
              <a className="toggle-menu" href="#">
                <i></i>
                <i></i>
                <i></i>
              </a>
              <div className="logo">
                { isExamLaunched
                ? <Link onClick={examPopup}>
                  <img
                    src={brandConfig.configuration && brandConfig.configuration.logo ? brandConfig.configuration.logo : Logo}
                    alt=""
                  />
                  <span className="logotext">
                    <strong>{brandConfig.configuration.title}</strong>
                  </span>
                </Link>
                :
                <Link to="/">
                  <img
                    src={brandConfig.configuration && brandConfig.configuration.logo ? brandConfig.configuration.logo : Logo}
                    alt=""
                  />
                  <span className="logotext">
                    <strong>{brandConfig.configuration.title}</strong>
                    {/* my
                    <strong>
                    </strong>{" "}
                    <span className="student-portal">Student Portal</span> */}
                  </span>
                </Link>
                }
              </div>
              <div className="nav-right d-flex align-content-center">
                <ul className="list-unstyled d-flex align-items-center m-0 nav-ul">
                {isWithHeldStatus == "true" ? isExamLaunched ? <li><Link className="btn btn-danger-withheld rw-sm" onClick={examPopup}>RW</Link><Link className="btn btn-danger-withheld rw-lg" onClick={examPopup}>RESULTS WITHHELD</Link></li> : <li><Link className="btn btn-danger-withheld rw-sm" to="/ResultWithheld">RW</Link><Link className="btn btn-danger-withheld rw-lg" to="/ResultWithheld">RESULTS WITHHELD</Link></li> : null}
                  {user.status != "On Hold" &&  (
                    <li className="question">
                      <a
                        onClick={()=> isExamLaunched ? examPopup() : null}
                        href={ isExamLaunched ? 'javascript:void(0)' : `/student-hub/`}
                        title="How-To Guides and Academic Resources"
                      >
                        <i className="fal fa-info-circle"></i>
                      </a>
                    </li>
                  )}

                  {user.status != "On Hold" &&  (
                    <li className="notification">
                      { isExamLaunched
                        ?
                        <Link
                        title="Get important information of direct interest to you and your studies"
                        onClick={examPopup}
                                              >
                        <i className="fal fa-bell"></i>
                        {notificationcount.notificationcount > 0 ? (
                          <span className="count">
                            {notificationcount.notificationcount > 9
                              ? "9+"
                              : notificationcount.notificationcount}
                          </span>
                        ) : (
                          <></>
                        )}
                        <NotificationBadge />
                      </Link>
                      :
                      <Link
                        title="Get important information of direct interest to you and your studies"
                        to="/mynotifications"
                      >
                        <i className="fal fa-bell"></i>
                        {notificationcount.notificationcount > 0 ? (
                          <span className="count">
                            {notificationcount.notificationcount > 9
                              ? "9+"
                              : notificationcount.notificationcount}
                          </span>
                        ) : (
                          <></>
                        )}
                        <NotificationBadge />
                      </Link>
                    }
                    </li>
                  )}
                  {user.status != "On Hold" &&  (
                    <li className="setting margin-right-20">
                    { isExamLaunched
                      ?
                      <Link title="Settings" onClick={examPopup}>
                        <i className="fal fa-cog"></i>
                      </Link>
                      :
                      <Link title="Settings" to="/settings">
                        <i className="fal fa-cog"></i>
                      </Link>
                    }
                    </li>
                  )}
                  <li className="user-profile assigned-title-blk">
                    { isExamLaunched
                    ? <div className="dropdown-toggle" title="My Account" onClick={examPopup}>
                      <span className="user-drop-down-img">
                        {user.photo == null || user.photo == undefined ? InitalRender(originalValues(user.fullname, 23)) : <img
                          src={
                            user.photo == null || user.photo == undefined
                              ? temp_profile_photo
                              : user.photo
                          }
                          alt="" onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.closest('.user-drop-down-img').append(InitalRender(originalValues(user.fullname, 23)));
                            currentTarget.remove();
                          }}
                        />}
                        <span className="portal-status-icon online-u">
                        </span>
                      </span>
                    </div>
                    :
                    <div className="dropdown-toggle" data-toggle="dropdown" title="My Account" onClick={()=> isExamLaunched ? examPopup() : null}>
                      <span className="user-drop-down-img">
                        {user.photo == null || user.photo == undefined ? InitalRender(originalValues(user.fullname, 23)) : <img
                          src={
                            user.photo == null || user.photo == undefined
                              ? temp_profile_photo
                              : user.photo
                          }
                          alt="" onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.closest('.user-drop-down-img').append(InitalRender(originalValues(user.fullname, 23)));
                            currentTarget.remove();
                          }}
                        />}
                        <span className="portal-status-icon online-u">
                        </span>
                      </span>
                    </div>
                    }
                    <div className="assigned-title-info">
                      <article>
                        <figure className="bgBlue">
                          <div className="user-drop-down-img">
                            {user.photo == null || user.photo == undefined ? InitalRender(originalValues(
                              user.first_name + " " + user.last_name
                            )) : <img
                              src={
                                user.photo == null || user.photo == undefined
                                  ? temp_profile_photo
                                  : user.photo
                              }
                              alt=""
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.closest('.bgBlue').append(InitalRender(originalValues(user.fullname, 23)));
                                currentTarget.remove();
                              }}
                            />}
                            <span className="portal-status-icon online-u"></span>
                          </div>
                        </figure>
                        <figcaption>
                          <h4>{originalValues(
                            user.first_name + " " + user.last_name
                          )}</h4>
                          {/* <p>Online</p> */}
                          <p>
                            <i className="fal fa-envelope"></i>{" "}
                            <a className="hover-color-blue" onClick={copyEmailText}>{user.email}</a>
                            {copyText ? (
                              <i className="fas fa-check-circle cat-greenhex"></i>
                            ) : (
                              <i className="fal fa-copy hover-color-blue" onClick={copyEmailText}
                              >
                              </i>
                            )}

                          </p>
                          {/* <p><i className="fal fa-mobile"></i>{" "}<a href={"tel:" + user.mobile} target="_blank">{user.mobile}</a></p>
                          <p><i className="fal fa-id-badge"></i>{" "}{user.student_crm_id}</p> */}

                        </figcaption>
                      </article>
                    </div>
                    <ul className="dropdown-menu user-profile-dropdown-menu">
                      <li className="profile-container">
                        <div className="login-user-profile d-flex align-items-center">
                          <div className="profile-img-parent">
                            <div className="user-drop-down-img profile-details">
                              {/* <img
                                src={
                                  user.photo == null || user.photo == undefined
                                    ? temp_profile_photo
                                    : user.photo
                                }
                                alt=""
                              /> */}
                              {user.photo === null ? InitalRender(originalValues(user.first_name + " " + user.last_name, 23)) : <img
                                src={user.photo}
                                alt="" onError={({ currentTarget }) => {
                                  currentTarget.onerror = null; // prevents looping
                                  currentTarget.closest('.profile-details').append(InitalRender(originalValues(user.first_name + " " + user.last_name, 23)));
                                  currentTarget.remove();
                                }}
                              />}
                              <span className="portal-status-icon online-u"></span>
                            </div>
                          </div>
                          <div className="profile-name">
                            <div className="profile-name-text">
                              {originalValues(
                                user.first_name + " " + user.last_name
                              )}
                            </div>
                            <p className="m-0">#{user.student_crm_id}</p>
                            {/* <div className="change-picture">
                              <button>Change picture</button>
                            </div> */}
                          </div>
                        </div>
                      </li>

                      {/* <li className="available-container">
                        <a>
                          <span className="status-container">
                            <span className="portal-status-icon">
                              <i className="fas fa-check-circle"></i>
                            </span>
                            <span className="status-text">Available</span>
                          </span>
                        </a>
                        <ul className="list-unstyled">
                          <li>
                            <button>
                              <span className="status-container">
                                <span className="portal-status-icon">
                                  <i className="fas fa-check-circle"></i>
                                </span>
                                <span className="status-text">Available</span>
                              </span>
                            </button>
                          </li>
                          <li>
                            <button>
                              <span className="status-container">
                                <span className="portal-status-icon">
                                  <i className="fas fa-circle"></i>
                                </span>
                                <span className="status-text">Busy</span>
                              </span>
                            </button>
                          </li>
                          <li>
                            <button>
                              <span className="status-container">
                                <span className="portal-status-icon">
                                  <i className="fas fa-minus-circle"></i>
                                </span>
                                <span className="status-text">
                                  Do not disturb
                                </span>
                              </span>
                            </button>
                          </li>
                          <li>
                            <button>
                              <span className="status-container">
                                <span className="portal-status-icon">
                                  <i className="fas fa-clock"></i>
                                </span>
                                <span className="status-text">
                                  Be right back
                                </span>
                              </span>
                            </button>
                          </li>
                          <li>
                            <button>
                              <span className="status-container">
                                <span className="portal-status-icon">
                                  <i className="fas fa-clock"></i>
                                </span>
                                <span className="status-text">Appear away</span>
                              </span>
                            </button>
                          </li>
                        </ul>
                      </li> */}

                      <li className="header-status-li">
                        <button title="Online">
                          <span className="status-container">
                            <span className="portal-status-icon online-u">
                            </span>
                            <span className="status-text">Online</span>
                          </span>
                        </button>
                      </li>
                      {/* <li className="available-container">
                        <a href="javascript:();">
                          <span className="status-container">
                            <span className="portal-status-icon">
                              <i className="fas fa-check-circle"></i>
                            </span>
                            <span className="status-text">Available</span>
                          </span>
                        </a> 
                      <ul className="list-unstyled">
                          <li>
                            <button>
                              <span className="status-container">
                                <span className="portal-status-icon">
                                  <i className="fas fa-check-circle"></i>
                                </span>
                                <span className="status-text">Available</span>
                              </span>
                            </button>
                          </li> 
                      <li>
												<button>
													<span className="status-container">
														<span className="portal-status-icon"><i className="fas fa-circle"></i></span>
														<span className="status-text">Busy</span>
													</span>
												</button>
											</li>
											<li>
												<button>
													<span className="status-container">
														<span className="portal-status-icon"><i className="fas fa-minus-circle"></i></span>
														<span className="status-text">Do not disturb</span>
													</span>
												</button>
											</li>
											<li>
												<button>
													<span className="status-container">
														<span className="portal-status-icon"><i className="fas fa-clock"></i></span>
														<span className="status-text">Be right back</span>
													</span>
												</button>
											</li>
											<li>
												<button>
													<span className="status-container">
														<span className="portal-status-icon"><i className="fas fa-clock"></i></span>
														<span className="status-text">Appear away</span>
													</span>
												</button>
											</li> 
                       </ul> 
                       </li>  */}

                      <li>
                        <Link
                          to={user.status != "On Hold" ? "/profile" : "#"}
                          title="Profile"
                          onClick={() => dispatch(selectTab({ tab: 2 }))}
                          className={user.status == "On Hold" ? "disabled-link" : ""}
                        >
                          <i className="fal fa-user"></i>Profile
                        </Link>
                      </li>
                      {user.status != "On Hold" && (
                        <li>
                          <a title="Settings" href="/settings">
                            <i className="fal fa-cog"></i>Settings
                          </a>
                        </li>
                      )}
                      <li className="divider"></li>

                      {/* <li className="about-container">
                        <a href="#">About</a>
                        <ul className="list-unstyled">
                          <li>
                            <a href="#">Legal</a>
                          </li>
                          <li>
                            <a href="#">Privacy and cookies</a>
                          </li>
                          <li>
                            <a href="#">Third party notice</a>
                          </li>
                        </ul>
                      </li> 
                       <li>
                        <a href="#">
                          <i className="fal fa-arrow-to-bottom"></i>Download the
                          desktop app
                        </a>
                      </li>  */}
                      <li>
                        <a
                          title="My Student Card"
                          href={studentCard}
                          target="_blank"
                        >
                          <i className="fal fa-address-card"></i>My Student Card
                        </a>
                      </li>
                      {(brandConfig.configuration.iosAppUrl || brandConfig.configuration.androidAppUrl || brandConfig.configuration.hauweiAppUrl) &&
                      <li className="divider"></li>}
                      {brandConfig.configuration.iosAppUrl && <li>
                        <a
                          title="Download the Mobile iOS App"
                          href={brandConfig.configuration && brandConfig.configuration.iosAppUrl ? brandConfig.configuration.iosAppUrl : ''}
                          target="_blank"
                        >
                          <i className="fab fa-app-store"></i>Download the
                          Mobile iOS App
                        </a>
                      </li>}
                      {brandConfig.configuration.androidAppUrl && <li>
                        <a
                          title="Download the Mobile Android App"
                          href={brandConfig.configuration && brandConfig.configuration.androidAppUrl ? brandConfig.configuration.androidAppUrl : ''}
                          target="_blank"
                        >
                          <i className="fab fa-google-play"></i>Download the
                          Mobile Android App
                        </a>
                      </li>}                      
                      {brandConfig.configuration.hauweiAppUrl && <li>
                        <a
                          title="Download the Mobile Hauwei App"
                          href={brandConfig.configuration && brandConfig.configuration.hauweiAppUrl ? brandConfig.configuration.hauweiAppUrl : ''}
                          target="_blank"
                        >
                          <img src={HauweiIcon} /> Download the
                          Mobile Hauwei App
                        </a>
                      </li>}
                      <li className="divider"></li>

                      <li>
                        <a title="Logout" onClick={() => Logout()}>
                          <i className="fal fa-sign-out"></i>Logout
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
      <button className="btn btn-primary widget-button" onClick={()=>{window.open("https://api.whatsapp.com/send/?phone=27600982635&text=Hello+AMI&type=phone_number&app_absent=0", "_blank")}}>
        <i class="fab fa-whatsapp" size="lg"></i>
      </button>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
export default connect(mapStateToProps)(Header);
