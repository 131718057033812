import React, { useState } from 'react';
import {MarkComplete} from "../../services/MentoredSubjectService";
import * as Yup from 'yup';
import { useFormik } from "formik";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from 'react-redux';
import { toggleCompleteModule } from '../../store/actions/index.js';

const CompleteMentoredSubject = ({row, updateSubject}) => {
    const [show, setShow] = useState(false);
    const dispatch = useDispatch();
    const completeModuleButton = useSelector(state => state.buttonStates.completeModule);
    let require_sign_off = "";
    let student_intake_status = 3;
    let confirMationText = "Are you sure you want to mark this module as completed?";
    let signoffClass = "";
    
    if (row.require_sign_off == 1) {
        require_sign_off = "checked";
        student_intake_status = 2;
        confirMationText = "This module requires your instructor to sign off. Do you want to continue to complete the module?";
        if (row.signed_off_by > 0) {
        signoffClass = "flex-column";
        }
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
        note: "",
        subject_id:row.id,
        activity:"subject_completed",
        student_intake_status:student_intake_status
        },
        validationSchema: Yup.object({
            note: Yup.string()
              .required('Required'),
        }),
        onSubmit: (values,{resetForm}) => {
        MarkComplete(values)
        .then((res) => {
            Swal.close();
            Swal.fire({
            icon: "success",
            title: 'Successful',
            text:'Successfully marked as complete',
            showConfirmButton: true
            }).then((result) => {
                resetForm({})
                updateSubject()
                dispatch(toggleCompleteModule())
            });
        })
        .catch((error) => {
            Swal.fire({
            icon: "error",
            title: "Unknown Error while saving",
            }).then((result) => {});
        });
        },
    });

    return (
        <>
        <div className={"card course-card-custom collapse mb-3 "+ (completeModuleButton === true ? "show" : " hide")} id="confirmBox" data-parent="#accordionSubject">
           <div className="card-header upper-heading">Confirm Module Completion</div>
            <div className="card-body">
                <p>{confirMationText}</p>
                <div className="theme-form application-form">
                    <div className="row">
                        <div className="form-group col-12">
                        <button onClick={()=>{setShow(true);}} className="btn btn-submit btn-primary">Yes</button>
                        <button onClick={() => {dispatch(toggleCompleteModule());setShow(false);}} className="btn btn-submit btn-main btn-danger ml-2">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
        <div className={"card course-card-custom collapse " + ((completeModuleButton === true && show)? " show" : " hide")} id="markComplete" data-parent="#accordionSubject">
            <div className="card-header upper-heading">Mark as Complete and Feedback</div>
            <div className="card-body">
                <form onSubmit={formik.handleSubmit}>
                    <div className="theme-form application-form">
                        <div className="row">
                            <div className="form-group col-12">
                            <label>Your Feedback</label>
                            <textarea  
                            className={'form-control' + (formik.errors.note && formik.touched.note ? ' is-invalid' : '')}  
                            onChange={formik.handleChange}
                            value={formik.values.note} 
                            name="note"
                            id="note" 
                            placeholder="Please provide extra notes to your lecturer..."></textarea>
                            {formik.touched.note && formik.errors.note ? (
                            <div className="color-red mt-2">{formik.errors.note}</div>
                            ) : null}
                            </div>
                            <div className="form-group col-12">
                            <input onChange={formik.handleChange}
                            value={formik.values.subject_id}
                            name="subject_id"
                            id="subject_id" 
                            type="hidden"/>
                            <button  type="submit" className="btn btn-submit btn-primary"><i className="fal fa-play-circle"></i>Mark As Completed</button>
                            <button onClick={() => {dispatch(toggleCompleteModule());setShow(false);}} className="btn btn-submit btn-main btn-danger ml-2" >Cancel</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        </>
    );
}
export default CompleteMentoredSubject;

