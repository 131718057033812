import moment from "moment";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getCurrentTimeHomePage, wishTiming } from "./Helper";
import { originalValues } from "./Helper";
import Str from "./Str";

export default function HomeWishes(props) {

  const brandConfig = useSelector((state) => state.brand);
  const user = JSON.parse(localStorage.getItem("user"));
  const componentMounted = React.useRef(true)
  const [time, setTime] = useState(moment(Date().toLocaleString()).format(`dddd, ${Str.TABLE_DATE_FORMAT} | ${Str.TABLE_TIME_FORMAT}:ss`))
  //const [realTime, setRealTime] = useState(new Date().toLocaleString());
  useEffect(() => {
    setInterval(() => {
      if (componentMounted.current) { // is component still mounted?
        setTime(moment(Date().toLocaleString()).format(`dddd, ${Str.TABLE_DATE_FORMAT} | ${Str.TABLE_TIME_FORMAT}:ss`));
      }
    }, 1000);
    return () => { // This code runs when component is unmounted
      componentMounted.current = false; // set it to false when we leave the page
  }
  }, []);
  return (
    <>
    {!props.hide ? <div className="my-tickets-heading-blk flex-direction mb-4">
      <h4 className="text-left-align landing-heading heading_color_dynamic">{wishTiming(new Date())} <span>{user != null && originalValues(user.fullname)}</span></h4>
      <div className="text-md-right"><p className=""> {time}</p></div>
    </div> : <span className="ml-auto">{time}</span>}

      {/* <div className="wlcome-user-info">
        <div className="row align-items-center">
          <div className="col-md-6">
            <h4 className="my-md-2 my-0 heading_color_dynamic">
              {wishTiming(new Date())} <span>{user != null && originalValues(user.fullname)}</span>
            </h4>
          </div>
          <div className="col-md-6 text-md-right">
            <p className="my-2">
              {getCurrentTimeHomePage(Date().toLocaleString())}
            </p>
          </div>
        </div>
      </div> */}
    </>
  );
}
