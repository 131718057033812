import React from "react";
import moment from 'moment';
import * as cocoSsd from "@tensorflow-models/coco-ssd";
import "@tensorflow/tfjs";
import "./styles.css";

class ObjectDetection extends React.Component {
  videoRef = React.createRef();
  canvasRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      'person': 0,
      "phone": 0,
      "absent": 0,
      resetCount: 0
    };
  }

  componentDidMount() {
    if (this.props.data && this.props.data.camera) {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        const webCamPromise = navigator.mediaDevices
          .getUserMedia({
            audio: false,
            video: {
              facingMode: "user",
            },
          })
          .then((stream) => {
            window.stream = stream;
            this.videoRef.current.srcObject = stream;
            return new Promise((resolve, reject) => {
              this.videoRef.current.onloadedmetadata = () => {
                resolve();
              };
            });
          })
          .then((model) => {

            this.videoRef.current.addEventListener('loadeddata', (e) => {
              if (this.videoRef.current === null) {
                return false
              }
              const runDetection = () => {
                // Pass in a video stream to the model to obtain a prediction from the MediaPipe graph
                const modelPromise = cocoSsd.load();
                Promise.all([modelPromise, webCamPromise])
                  .then((values) => {
                    this.detectFrame(this.videoRef.current, values[0]);
                  })
                  .catch((error) => {
                    console.error(error);
                  });
              };
              runDetection();
            });
          })
      }
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.resetCount != state.resetCount) {
      state.resetCount = props.resetCount
      state.person = 0
      state.phone = 0
      state.absent = 0
    }
    return null
  }

  componentWillUnmount() {
    const stream = this.videoRef.current.srcObject;
    const tracks = stream && stream.getTracks();

    tracks && tracks.forEach(function (track) {
      track.stop();
    });
    this.videoRef.current.srcObject = window.stream;
  }

  detectFrame = (video, model) => {
    const stream = this.videoRef.current.srcObject;
    // console.log('the value of stream is ', stream)
    if (stream && stream.active) {
      model.detect(video).then((predictions) => {
        // console.log("here", predictions);
        if (predictions.length > 0) {          
          if (predictions.length == 1) {
            if (predictions[0].class == 'cell phone') {
              this.setState({ phone: this.state.phone += 1 })
            }
          }
          else {
            let tempperson = 0
            predictions.map(i => {
              if (i.class == 'person') {
                tempperson += 1
              }
              if (i.class == 'cell phone') {
                this.setState({ phone: this.state.phone += 1 })
              }
            })
            if (tempperson > 1) {
              this.setState({ person: this.state.person += 1 })
            }
          }
        }
        else {
          this.setState({ absent: this.state.absent += 1 })
        }
        // this.renderPredictions(predictions);
      });
    }
    requestAnimationFrame(() => {
      if (this.videoRef.current === null) {
        return false;
      }
      this.detectFrame(video, model);

    });
  };

  renderPredictions = (predictions) => {
    const ctx = this.canvasRef.current.getContext("2d");
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    // Font options.
    const font = "16px sans-serif";
    ctx.font = font;
    ctx.textBaseline = "top";
    predictions.forEach((prediction) => {
      const x = prediction.bbox[0];
      const y = prediction.bbox[1];
      const width = prediction.bbox[2];
      const height = prediction.bbox[3];
      // Draw the bounding box.
      ctx.strokeStyle = "#00FFFF";
      ctx.lineWidth = 4;
      ctx.strokeRect(x, y, width, height);
      // Draw the label background.
      ctx.fillStyle = "#00FFFF";
      const textWidth = ctx.measureText(prediction.class).width;
      const textHeight = parseInt(font, 10); // base 10
      ctx.fillRect(x, y, textWidth + 4, textHeight + 4);
    });

    predictions.forEach((prediction) => {
      const x = prediction.bbox[0];
      const y = prediction.bbox[1];
      // Draw the text last to ensure it's on top.
      ctx.fillStyle = "#000000";
      ctx.fillText(prediction.class, x, y);
    });
  };

  render() {
    return (
      <div className="assessment-warning-logs">
        <video
          className="size"
          autoPlay
          playsInline
          muted
          preload="none"
          ref={this.videoRef}
          width={(this.props.videoWidth && this.props.videoWidth == 'No') ? 0 : 100}
          height={(this.props.videoHeight && this.props.videoHeight == 'No') ? 0 : 100}
        />
        <canvas
          className="size d-none"
          ref={this.canvasRef}
          width="600"
          height="500"
        />

        <div className="assessment-warning-blk">
          <h2>Warning Log</h2>
          {/* {(this.state.person > 0 || this.state.phone > 0 || this.state.absent > 0) && */}
          <ul>
            {(this.props.data && this.props.data.camera) ?
              <>
                {this.state.person > 0 &&
                  <li>
                    <article>
                      <figure>
                        <i className="fal fa-user-friends"></i>
                      </figure>
                      <figcaption>
                        <p>{moment(new Date()).format("D MMMM YYYY HH:mm")}</p>
                        <h4>
                          Warning: <span>Second person detected: {this.state.person}</span>
                        </h4>
                      </figcaption>
                    </article>
                  </li>
                }
                {this.state.phone > 0 &&
                  <li>
                    <article>
                      <figure>
                        <i className="fal fa-mobile-alt"></i>
                      </figure>
                      <figcaption>
                        <p>{moment(new Date()).format("D MMMM YYYY HH:mm")}</p>
                        <h4>
                          Warning: <span>Cell Phone detected: {this.state.phone}</span>
                        </h4>
                      </figcaption>
                    </article>
                  </li>
                }
                {this.state.absent > 0 &&
                  <li>
                    <article>
                      <figure>
                        <i className="fal fa-chair-office"></i>
                      </figure>
                      <figcaption>
                        <p>{moment(new Date()).format("D MMMM YYYY HH:mm")}</p>
                        <h4>
                          Warning: <span>Student is absent: {this.state.absent}</span>
                        </h4>
                      </figcaption>
                    </article>
                  </li>
                }
              </>
              : null
            }
            {this.props.soundCount > 0 &&
              <li>
                <article>
                  <figure>
                    <i className="fal fa-microphone"></i>
                  </figure>
                  <figcaption>
                    <p>{moment(new Date()).format("D MMMM YYYY HH:mm")}</p>
                    <h4>
                      Warning: <span>Noise detected: {this.props.soundCount}</span>
                    </h4>
                  </figcaption>
                </article>
              </li>
            }            
          </ul>
          {/* } */}
        </div>
      </div>
    );
  }
}

export default ObjectDetection;
