import React, { useEffect, useState } from "react";
import NotificationImportantTodayFilterTabs from "./importantNotification/NotificationImportantTodayFilterTabs";
import NotificationImportantYesterdayFilterTabs from "./importantNotification/NotificationImportantYesterdayFilterTabs";
import NotificationImportantPastFilterTabs from "./importantNotification/NotificationImportantPastFilterTabs";

import NotificationImportantLastWeekFilterTabs from "./importantNotification/NotificationImportantLastWeekFilterTabs";
import NotificationImportantTwoWeekAgoFilterTabs from "./importantNotification/NotificationImportantTwoWeekAgoFilterTabs";
import NotificationImportantThreeWeekAgoFilterTabs from "./importantNotification/NotificationImportantThreeWeekAgoFilterTabs";
import NotificationImportantLastMonthFilterTabs from "./importantNotification/NotificationImportantLastMonthFilterTabs";

export default function NotificationImportantFilterTabs(props) {
  const [notificationID, setNotificationID] = useState([0]);
  const [nextPage, setNextPage] = useState(1);
  const [filteredArr, setFilteredArr] = useState([]);

  // Unread
  const { ImportantvisibilityTab, notificationTabDef } = props.functionalParentCompneneLvl1;

  const handleSelectedMyNotification = (filter, day) => {
    props.handleSelectedMyNotification(filter, day);
  };

  const handleSelectNotification = (e, notificationId, readNotificationId) => {
    props.handleSelectNotification(e, notificationId, readNotificationId);
  };

  return (
    <>
      <div className={"tab-pane " + (notificationTabDef == 'important' ? 'show active' : 'fade')} id="important">
        { ImportantvisibilityTab && ImportantvisibilityTab.totalCount ? (
          <>
            {ImportantvisibilityTab &&
            ImportantvisibilityTab.todayNotification &&
            ImportantvisibilityTab.todayNotification.total > 0 ? (
              <NotificationImportantTodayFilterTabs
                notificationTabDef={notificationTabDef}
                functionalParentCompneneLvl2={props}
                handleSelectNotification={handleSelectNotification}
                handleSelectedMyNotification={handleSelectedMyNotification}
                defaultTab={ImportantvisibilityTab.defaultTodayTab}
              />
            ) : null}

            {ImportantvisibilityTab &&
            ImportantvisibilityTab.yesterdayNotification &&
            ImportantvisibilityTab.yesterdayNotification.total > 0 ? (
              <NotificationImportantYesterdayFilterTabs
                notificationTabDef={notificationTabDef}
                functionalParentCompneneLvl2={props}
                handleSelectNotification={handleSelectNotification}
                handleSelectedMyNotification={handleSelectedMyNotification}
                defaultTab={ImportantvisibilityTab.defaultYesterdayTab}
              />
            ) : null}

            {ImportantvisibilityTab &&
            ImportantvisibilityTab.last_weekNotification &&
            ImportantvisibilityTab.last_weekNotification.total > 0 ? (
              <NotificationImportantLastWeekFilterTabs
                notificationTabDef={notificationTabDef}
                functionalParentCompneneLvl2={props}
                handleSelectNotification={handleSelectNotification}
                defaultTab={ImportantvisibilityTab.defaultLastWeekTab}
              />
            ) : null}

            {ImportantvisibilityTab &&
            ImportantvisibilityTab.twoWeekNotification &&
            ImportantvisibilityTab.twoWeekNotification.total > 0 ? (
              <NotificationImportantTwoWeekAgoFilterTabs
                notificationTabDef={notificationTabDef}
                functionalParentCompneneLvl2={props}
                handleSelectNotification={handleSelectNotification}
                defaultTab={ImportantvisibilityTab.defaultTwoWeekAgoTab}
              />
            ) : null}

            {ImportantvisibilityTab &&
            ImportantvisibilityTab.threeWeeNotification &&
            ImportantvisibilityTab.threeWeeNotification.total > 0 ? (
              <NotificationImportantThreeWeekAgoFilterTabs
                notificationTabDef={notificationTabDef}
                functionalParentCompneneLvl2={props}
                handleSelectNotification={handleSelectNotification}
                defaultTab={ImportantvisibilityTab.defaultThreeWeekAgoTab}
              />
            ) : null}

            {ImportantvisibilityTab &&
            ImportantvisibilityTab.LastMonthNotification &&
            ImportantvisibilityTab.LastMonthNotification.total > 0 ? (
              <NotificationImportantLastMonthFilterTabs
                notificationTabDef={notificationTabDef}
                functionalParentCompneneLvl2={props}
                handleSelectNotification={handleSelectNotification}
                defaultTab={ImportantvisibilityTab.defaultLastMonthTab}
              />
            ) : null}

            {ImportantvisibilityTab &&
            ImportantvisibilityTab.pastNotification &&
            ImportantvisibilityTab.pastNotification.total > 0 ? (
              <NotificationImportantPastFilterTabs
                notificationTabDef={notificationTabDef}
                functionalParentCompneneLvl2={props}
                handleSelectNotification={handleSelectNotification}
                handleSelectedMyNotification={handleSelectedMyNotification}
                defaultTab={ImportantvisibilityTab.defaultPastTab}
              />
            ) : null}
          </>
        ) :
          <>
            <div className="no-notification">
              No important notifications.
            </div>
          </>
        }
      </div>
    </>
  );
}
