import React, { useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import NotificationAllFilterTabs from "./NotificationAllFilterTabs";
import NotificationUnreadFilterTabs from "./NotificationUnreadFilterTabs";
import NotificationImportantFilterTabs from "./NotificationImportantFilterTabs";
import { Scrollbar } from "react-scrollbars-custom";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setNotificationCount,
  setUnreadNotificationCount,
} from "../../store/actions";

export default function NotificationLeftBox(props) {
  const history = useHistory();
  const [activeNotification, setActiveNotification] = useState(0);
  const dispatch = useDispatch();
  const { notificationcount } = useSelector(
    (state) => state.notification
  );
  //this.setState({ yesterdayNotification: [...this.state.yesterdayNotification, this.state.newNotification]})

  const handleSelectNotification = (e, notificationId, readNotificationId = 0) => {
    //update active notification id
    //console.log("here")
    setActiveNotification(notificationId);
    props.parentGetNotificationContent(notificationId);
    history.push(`/mynotifications/${notificationId}`);
  };

  useEffect(() => {
    try {
      setActiveNotification(props.nId);
    } catch (error) {}
  }, []);
  return (
    <>
      <div className="notfication-body">
        <Scrollbar>
          <div className="tab-content">
            <NotificationAllFilterTabs
              // Serviece method
              handleSelectNotification={handleSelectNotification}
              functionalParentCompneneLvl1={props}
              activeNotification={activeNotification}
            />

            <NotificationUnreadFilterTabs
              // Serviece method
              handleSelectNotification={handleSelectNotification}
              functionalParentCompneneLvl1={props}
              activeNotification={activeNotification}
            />

            <NotificationImportantFilterTabs
              handleSelectNotification={handleSelectNotification}
              functionalParentCompneneLvl1={props}
              activeNotification={activeNotification}
            />
          </div>
        </Scrollbar>
      </div>
    </>
  );
}
