import React, { useEffect, useState} from "react";
import { Link } from "react-router-dom";
import BackgroundImage from "../assets/images/loginbg.png";
import LoginImage from "../assets/images/card-header.png";
import ResetPasswordForm from "../components/ResetPasswordSetForm";
import { useSelector } from "react-redux";
const ResetPasswordSet = (props) => {
  const brandConfig = useSelector((state) => state.brand);
  const [token, setToken] = useState('');
  useEffect(() => {
    const url = new URL(window.location.href);
    let code = extractUrlValue("token", url.token);
    setToken(code);
  }, []);

  const extractUrlValue = (key, url) => {
    if (typeof (url) === 'undefined')
    url = window.location.href;
    var match = url.match('[?&]' + key + '=([^&]+)');
    return match ? match[1] : null;
  }

  return (
    <>
      <div className="portal-login portal-forgot-password d-flex align-items-center">
        <div
          className="login-box-w100"
        >
          <div className="login-left">
            <div className="login-bg-mobile">
              <img
                src={
                  brandConfig.configuration &&
                  brandConfig.configuration.background_logo
                    ? brandConfig.configuration.background_logo
                    : LoginImage
                }
                alt="Background"
              />
            </div>
            <div className="card portal-login-card text-center">
              <div className="card-header-new">
                <div className="card-header-img">

                  <img
                    src={brandConfig.configuration && brandConfig.configuration.login_header ? brandConfig.configuration.login_header : LoginImage}
                    alt="Background"
                  />

                </div>
              </div>
              <div className="card-body-new">
                <div className="card-body-inr">
                  <div className="portal-login-info">
                    {/* {/ <!-- <h1>COMMUNICATOR</h1> --> /} */}
                    <h3>Set your Password</h3>
                    <p>
                      Enter new password and confirm password
                    </p>
                  </div>
                  <div className="portal-login-form">
                    <ResetPasswordForm token={token} />
                  </div>
                </div>
              </div>
              <div className="card-footer">
                <Link title="Back to Login" to="/">
                  Back to Login
                </Link>
              </div>
            </div>
          </div>

          <div className="login-right">
            <div className="login-right-img">
              <img
                src={brandConfig.configuration && brandConfig.configuration.background_logo ? brandConfig.configuration.background_logo : LoginImage}
                alt="Background"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ResetPasswordSet;