const initialState = {
    isExamReg: false,
  };
  
  const isExamRegReducer = (state = initialState, action) => {
    switch (action.type) {
      case "SET_IS_EXAM_REG":
        state.isExamReg = action.payload;
        return { isExamReg: state.isExamReg };
      default:
        return state;
    }
  };
  
  export default isExamRegReducer;
  