import React, { useEffect, useState } from "react";
import { subjectDuration } from "../../utils/commonFunction.js";
import { getSubjectStatus } from "../../utils/commonFunction.js";
import Str from "../common/Str.js";
import moment from "moment";
import { toggleCompleteModule, toggleNotes, toggleStartModule, clearToggles } from "../../store/actions/index.js";
import { useDispatch, useSelector } from "react-redux";
import { BASE_URL } from "../../utils/Constants.js";
import MinusIcon from '../../assets/images/minus-circle.png';
import IconFinished from '../../assets/images/Finished.png';

const SubjectMonth = ({ title, text }) => (
  <div className="subject-date-bx d-flex">
    <div className="subject-date-icon align-self-start mr-2">
      <i className="fal fa-clock"></i>
    </div>
    <div className="subject-date-detail">
      <strong>{title}</strong>
      <p className="m-0">{text}</p>
    </div>
  </div>
);

export default function MentoredSubject(props) {
  const dispatch = useDispatch()
  const row = props.row;

  const note = useSelector(state => state.buttonStates.notes);
  const completeModule = useSelector(state => state.buttonStates.completeModule);
  const startModule = useSelector(state => state.buttonStates.startModule);
  const isWithHeldStatus = localStorage.getItem("resultWithHeld");

  let require_sign_off = "";
  let student_intake_status = 3;
  let confirMationText = "Are you sure you want to mark this module as completed?";
  let signoffClass = "";
  if (row.require_sign_off == 1) {
    require_sign_off = "checked";
    student_intake_status = 2;
    confirMationText = "This module requires your instructor to sign off. Do you want to continue to complete the module?";
    if (row.signed_off_by > 0) {
      signoffClass = "flex-column";
    }
  }
  
  useEffect(()=>{
    dispatch(clearToggles())
    return ()=>{dispatch(clearToggles());}
  },[])

  const addActiveClass = (classList, button) => {    
    if (button == "start") {
      dispatch(toggleStartModule())  
    } else if (button == "complete") {
      dispatch(toggleCompleteModule())
    } else if (button == "note") {
      dispatch(toggleNotes())
    }
  };
  var icon = getSubjectStatus(row.icon);

  const viewCertificate = (data) =>{
    data.map((item)=>{
      const cert_url = (item.certificate.includes("http") ? "" : (BASE_URL+"/")) +  item.certificate.replace("/home/myaie/public_html","").replace("public/", "");
      window.open(cert_url, '_blank')
    })
  }

  if (row.subject_type === "shortcourse") {
    if(row.type === "selfplaced"){
      switch (row.student_intake_status) {
        // Not Started
        case 0:
          return (
            <>                           
              <li className="start-subject-module-li">
                <a
                  href="#startModule"
                  data-toggle="collapse"
                  className={`btn btn-white-bordered btn-primary ${startModule == true ? "active" : ""}`}
                  onClick={(e) => addActiveClass(e.currentTarget.classList, "start")}
                  title="Start Course"
                >
                  <i className="fal fa-play-circle"></i>Start Course
                </a>                
              </li>                
            </>            
          );
        // In progress
        case 1:
        case 4:
          return (
            <>
              {row?.mentored_check_box ? (
                <li className="notes-subject-li">
                  <a
                    href="#notes"
                    data-toggle="collapse"
                    title="Notes & Feedback"
                    className={`btn btn-white-bordered ${note == true ? "active" : ""}`}
                    onClick={(e) => addActiveClass(e.currentTarget.classList, "note")}
                  >
                    <i className="fal fa-clipboard-list"></i>Notes & Feedback
                  </a>                  
                </li>
              ) : null}              
              <li className="mark-complete-subject-li">
                <button
                  title="Mark As Completed"
                  className={`btn btn-primary btn-white-bordered ${completeModule == true ? "active" : ""}`}
                  onClick={(e) => addActiveClass(e.currentTarget.classList, "complete")}
                >
                  <i className="fal fa-check-square"></i>Mark As Completed
                </button>                
              </li>              
            </>
          );
        // Pending
        case 2:
          return (
            <>                            
              <li className="require-sign-on-off-li">
                <img src={require_sign_off!="" && row.signed_off_by!=0 ? IconFinished : MinusIcon} alt="" />
                <span className="require-sign-on-off-bx">
                  <p className="sign-off-heading">Reviewed</p>                      
                  <p className="m-0">
                  Completed {`(${moment.unix(row.completed_date).format(Str.TABLE_DATE_TIME_FORMAT)})`}
                    {/* {subjectDuration(row.showTargetStart, row.showCompletion)} */}
                  </p>                  
                </span>
              </li>

              {row?.mentored_check_box ? (
                <li className="notes-subject-li">
                  <a title="Notes & Feedback" href="#notes" data-toggle="collapse" className={`btn btn-white-bordered ${note == true ? "active" : ""}`} onClick={(e) => addActiveClass(e.currentTarget.classList, "note")}>
                    <i className="fal fa-clipboard-list"></i>Notes & Feedback
                  </a>                  
                </li>
              ) : null}
            </>              
          );
        // Finished
        case 3:
          return (
            <>                                              
              <li className="require-sign-on-off-li">
                <img src={require_sign_off ? IconFinished : MinusIcon} alt="" />
                <span className="require-sign-on-off-bx">
                  <p className="sign-off-heading">Reviewed by</p>
                  <span className="signed-off-by">{row?.signedOffBy + " (" + row?.signedOffDate +")"}</span>
                </span>
              </li> 
              {row?.mentored_check_box ? (
                <li className="notes-subject-li">
                  <a title="Notes & Feedback" href="#notes" data-toggle="collapse" className={`btn btn-white-bordered ${note == true ? "active" : ""}`} onClick={(e) => addActiveClass(e.currentTarget.classList, "note")}>
                    <i className="fal fa-clipboard-list"></i>Notes & Feedback
                  </a>                  
                </li>
              ) : null}
              {props?.subjectContent?.studentCertification && Array.isArray(props.subjectContent.studentCertification) && props.subjectContent.studentCertification.length && row.subjectStatus=="Finished" ? 
                <li className="start-subject-module-li">
                  <div className="start-subject-module-bx d-flex">
                    <button                 
                      className={`btn btn-white-bordered btn-primary`}
                      onClick={() => viewCertificate(props.subjectContent.studentCertification)}
                      disabled={isWithHeldStatus=="true"}
                      title={isWithHeldStatus=="true" ? "Certificate cannot be accessed when results are withheld." : "View Certificate"}
                    >
                      <i class="fal fa-file-certificate"></i> View Certificate
                    </button>
                  </div>
                </li>
              :null}  
            </>
              
          );
        default:
          return <h1 className="label label-red">Something Went Wrong</h1>;
      }
    }else return (
      <>        
        {props?.subjectContent?.studentCertification && Array.isArray(props.subjectContent.studentCertification) && props.subjectContent.studentCertification.length && row.subjectStatus=="Finished" ? 
          <li className="start-subject-module-li">
            <div className="start-subject-module-bx d-flex">
              <button                 
                className={`btn btn-white-bordered btn-primary`}
                onClick={() => viewCertificate(props.subjectContent.studentCertification)}
                disabled={isWithHeldStatus=="true"}
                title={isWithHeldStatus=="true" ? "Certificate cannot be accessed when results are withheld." : "View Certificate"}
              >
                <i class="fal fa-file-certificate"></i> View Certificate
              </button>
            </div>
          </li>
        :null}           
      </>
    )
    
  }

  switch (row.student_intake_status) {
    case 0:
      return (
        <>
          <li className="start-subject-module-li">
            <a
              href="#startModule"
              data-toggle="collapse"
              className={`btn btn-white-bordered btn-primary ${startModule == true ? "active" : ""}`}
              onClick={(e) => addActiveClass(e.currentTarget.classList, "start")}
              title="Start Course"
            >
              <i className="fal fa-play-circle"></i>Start Course
            </a>              
          </li>                              
        </>
      );
    case 1:
      return (
        row.mentored_check_box ? 
          <>            
            <li className="subject-date-li">
              <div className="subject-date-bx d-flex">                
                <div className="subject-date-detail">
                  <p className="subject-date-heading">Your Committed Start &amp; End Date</p> 
                  <p className="subject-date-content m-0">
                    {moment(row.showTargetStart, "Do MMM YY").format(Str.TABLE_DATE_FORMAT)} to {moment(row.showTargetEnd, "Do MMM YY").format(Str.TABLE_DATE_FORMAT)}{" "}
                    {subjectDuration(row.showTargetStart, row.showTargetEnd)}
                  </p>
                </div>
                <div className="subject-date-edit ml-2">
                  <button                    
                    title="Edit"
                    data-toggle="collapse"
                    className={`btn btn-white-bordered ${startModule == true ? "active" : ""}`}
                    onClick={(e) => addActiveClass(e.currentTarget.classList, "start")}
                  >
                    <i class="fal fa-calendar-edit"></i>
                  </button>
                </div>
              </div>
            </li>             
            <li className="notes-subject-li">
              <a
                href="#notes"
                data-toggle="collapse"
                title="Notes & Feedback"
                className={`btn btn-white-bordered ${note == true ? "active" : ""}`}
                onClick={(e) => addActiveClass(e.currentTarget.classList, "note")}
              >
                <i className="fal fa-clipboard-list"></i>Notes & Feedback
              </a>                
            </li>  
            <li className="mark-complete-subject-li">
              <div className="mark-complete-subject-bx d-flex">
                <button
                  title="Mark As Completed"
                  className={`btn btn-primary btn-white-bordered ${completeModule == true ? "active" : ""}`}
                  onClick={(e) => addActiveClass(e.currentTarget.classList, "complete")}
                >
                  <i className="fal fa-check-square"></i>Mark As Completed
                </button>
              </div>
            </li>
            
            {props?.subjectContent?.studentCertification && Array.isArray(props.subjectContent.studentCertification) && props.subjectContent.studentCertification.length && row.subjectStatus=="Finished" ? 
              <li className="start-subject-module-li">
                <div className="start-subject-module-bx d-flex">
                  <button                 
                    className={`btn btn-white-bordered btn-primary`}
                    onClick={() => viewCertificate(props.subjectContent.studentCertification)}
                    disabled={isWithHeldStatus=="true"}
                    title={isWithHeldStatus=="true" ? "Certificate cannot be accessed when results are withheld." : "View Certificate"}
                  >
                    <i class="fal fa-file-certificate"></i> View Certificate
                  </button>
                </div>
              </li>
            :null}                
          </>  
          :<>            
            {props?.subjectContent?.studentCertification && Array.isArray(props.subjectContent.studentCertification) && props.subjectContent.studentCertification.length && row.subjectStatus=="Finished"  ? 
              <li className="start-subject-module-li">
                <div className="start-subject-module-bx d-flex">
                  <button                 
                    className={`btn btn-white-bordered btn-primary`}
                    onClick={() => viewCertificate(props.subjectContent.studentCertification)}
                    disabled={isWithHeldStatus=="true"}
                    title={isWithHeldStatus=="true" ? "Certificate cannot be accessed when results are withheld." : "View Certificate"}
                  >
                    <i class="fal fa-file-certificate"></i> View Certificate
                  </button>
                </div>
              </li>
            :null}           
          </>        
      )
    case 4:
      return (
        <>
          {row.mentored_check_box ? 
          <>            
            <li className="subject-date-li">
              <div className="subject-date-bx d-flex">                
                <div className="subject-date-detail">
                  <p className="subject-date-heading">Your Committed Start &amp; End Date</p> 
                  <p className="subject-date-content m-0">
                    {moment(row.showTargetStart, "Do MMM YY").format(Str.TABLE_DATE_FORMAT)} to {moment(row.showTargetEnd, "Do MMM YY").format(Str.TABLE_DATE_FORMAT)}{" "}
                    {subjectDuration(row.showTargetStart, row.showTargetEnd)}
                  </p>
                </div>
                <div className="subject-date-edit ml-2">
                  <button                    
                    title="Edit"
                    data-toggle="collapse"
                    className={`btn btn-white-bordered ${startModule == true ? "active" : ""}`}
                    onClick={(e) => addActiveClass(e.currentTarget.classList, "start")}
                  >
                    <i class="fal fa-calendar-edit"></i>
                  </button>
                </div>
              </div>
            </li>                 
            <li className="notes-subject-li">
              <a
                href="#notes"
                data-toggle="collapse"
                title="Notes & Feedback"
                className={`btn btn-white-bordered ${note == true ? "active" : ""}`}
                onClick={(e) => addActiveClass(e.currentTarget.classList, "note")}
              >
                <i className="fal fa-clipboard-list"></i>Notes & Feedback
              </a>              
            </li>
            <li className="mark-complete-subject-li">
              <div className="mark-complete-subject-bx d-flex">
                <button
                  title="Mark As Completed"
                  className={`btn btn-primary btn-white-bordered ${completeModule == true ? "active" : ""}`}
                  onClick={(e) => addActiveClass(e.currentTarget.classList, "complete")}
                >
                  <i className="fal fa-check-square"></i>Mark As Completed
                </button>
              </div>
            </li>
            
            {props?.subjectContent?.studentCertification && Array.isArray(props.subjectContent.studentCertification) && props.subjectContent.studentCertification.length && row.subjectStatus=="Finished" ? 
              <li className="start-subject-module-li">
                <div className="start-subject-module-bx d-flex">
                  <button                 
                    className={`btn btn-white-bordered btn-primary`}
                    onClick={() => viewCertificate(props.subjectContent.studentCertification)}
                    disabled={isWithHeldStatus=="true"}
                    title={isWithHeldStatus=="true" ? "Certificate cannot be accessed when results are withheld." : "View Certificate"}
                  >
                    <i class="fal fa-file-certificate"></i> View Certificate
                  </button>
                </div>
              </li>
            :null} 
          </> : ""}
        </>
      );
    case 2:
      return (
        <>
          {row.mentored_check_box ?
          <>                                         
            <li className="require-sign-on-off-li">
              <img src={require_sign_off!="" && row.signed_off_by!=0 ? IconFinished : MinusIcon} alt="" />
              <span className="require-sign-on-off-bx">
                <p className="sign-off-heading">Reviewed</p>                      
                <p className="m-0">
                Completed {`(${moment.unix(row.completed_date).format(Str.TABLE_DATE_TIME_FORMAT)})`}
                  {/* {subjectDuration(row.showTargetStart, row.showCompletion)} */}
                </p>                  
              </span>
            </li>            
            <li className="notes-subject-li">
              <a href="#notes" title="Notes & Feedback" data-toggle="collapse" className={`btn btn-white-bordered ${note == true ? "active" : ""}`} onClick={(e) => addActiveClass(e.currentTarget.classList, "note")}>
                <i className="fal fa-clipboard-list"></i>Notes & Feedback
              </a>              
            </li>
            {props?.subjectContent?.studentCertification && Array.isArray(props.subjectContent.studentCertification) && props.subjectContent.studentCertification.length && row.subjectStatus=="Finished" ? 
              <li className="start-subject-module-li">
                <div className="start-subject-module-bx d-flex">
                  <button                 
                    className={`btn btn-white-bordered btn-primary`}
                    onClick={() => viewCertificate(props.subjectContent.studentCertification)}
                    disabled={isWithHeldStatus=="true"}
                    title={isWithHeldStatus=="true" ? "Certificate cannot be accessed when results are withheld." : "View Certificate"}
                  >
                    <i class="fal fa-file-certificate"></i> View Certificate
                  </button>
                </div>
              </li>
            :null} 
          </> : ""}
        </>
      );
    case 3:
      return (
        <>
          {row.mentored_check_box ? 
          <>               
            {/* <li className="subject-date-li">
              <div className="subject-date-bx d-flex">
                <div className="subject-date-icon align-self-start mr-2">
                  <i className="fal fa-calendar-alt"></i>
                </div>
                <div className="subject-date-detail">
                  <strong>Your Committed Start &amp; End Date</strong>
                  <p className="m-0">
                    {moment(row.showTargetStart, "Do MMM YY").format(Str.TABLE_DATE_FORMAT)} to {moment(row.showTargetEnd, "Do MMM YY").format(Str.TABLE_DATE_FORMAT)}{" "}
                    {subjectDuration(row.showTargetStart, row.showTargetEnd)}
                  </p>
                </div>
              </div>
            </li> */}
            <li className="require-sign-on-off-li">
              <img src={require_sign_off ? IconFinished : MinusIcon} alt="" />
              <span className="require-sign-on-off-bx">
                <p className="sign-off-heading">Reviewed by</p>
                <span className="signed-off-by">{row?.signedOffBy + " (" + row?.signedOffDate +")"}</span>
              </span>
            </li>                
            <li className="notes-subject-li">
              <a href="#notes" title="Notes & Feedback" data-toggle="collapse" className={`btn btn-white-bordered ${note == true ? "active" : ""}`} onClick={(e) => addActiveClass(e.currentTarget.classList, "note")}>
                <i className="fal fa-clipboard-list"></i>Notes & Feedback
              </a>              
            </li>
            {props?.subjectContent?.studentCertification && Array.isArray(props?.subjectContent?.studentCertification) && props?.subjectContent?.studentCertification.length && row.subjectStatus=="Finished" ? 
              <li className="start-subject-module-li">
                <div className="start-subject-module-bx d-flex">
                  <button                 
                    className={`btn btn-white-bordered btn-primary`}
                    onClick={() => viewCertificate(props?.subjectContent?.studentCertification)}
                    disabled={isWithHeldStatus=="true"}
                    title={isWithHeldStatus=="true" ? "Certificate cannot be accessed when results are withheld." : "View Certificate"}
                  >
                    <i class="fal fa-file-certificate"></i> View Certificate
                  </button>
                </div>
              </li>
            :null}  
          </> : ""}
        </>
      );
    default:
      return <h1 className="label label-red">Something Went Wrong</h1>;
  }
}
