import React, { useState, useEffect } from "react";
import ProgressFormReadOnly from "./re_registration_sub_read_only/ProgressFormReadOnly";
import RedoSubjectReadOnly from "./re_registration_sub_read_only/RedoSubjectReadOnly";
import ReWriteExamReadOnly from "./re_registration_sub_read_only/ReWriteExamReadOnly";
import { useFormik, FieldArray } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { submitReRegistrationService } from "../../../services/ReRegistrationService";

function SchoolOfEngineering(props) {
  const {
    reRegSubject,
    registration,
    subjectProgressReadOnly,
    subjectReWriteReadOnly,
    subjectReDoReadOnly,
    subjectReSubReadOnly,
  } = props;

  const [parentSubjectProgLength, setparentSubjectProgLength] = useState([
    { subject: "" },
  ]);
  const [parentSubjectRedoLength, setParentSubjectRedoLength] = useState([
    { subject: "" },
  ]);
  const [parentSubjectReWriteLength, setparentSubjectReWriteLength] = useState([
    { subject: "" },
  ]);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(true);

  const handleCheck = (e) => {
    setChecked(e.target.checked);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      subjectProgress_: subjectProgressReadOnly,
      subjectRedo_: subjectReDoReadOnly,
      subjectReWrite_: subjectReWriteReadOnly,
      rereg_academy: props.registration.academy,
      rereg_id: props.registration.id,
      term_condn: checked,
    },
    validationSchema: Yup.object({
      subjectProgress_: Yup.array()
        .of(
          Yup.object().shape({
            subject: Yup.string().required("Required"), // these constraints take precedence
          })
        )
        .required("Must have friends"),
      subjectRedo_: Yup.array()
        .of(
          Yup.object().shape({
            subject: Yup.string().required("Required"), // these constraints take precedence
          })
        )
        .required("Must have friends"),
      subjectReWrite_: Yup.array()
        .of(
          Yup.object().shape({
            subject: Yup.string().required("Required"), // these constraints take precedence
          })
        )
        .required("Must have friends"),
      term_condn: Yup.bool().oneOf(
        [true],
        "Accept Terms & Conditions is required"
      ),
    }),
    onSubmit: (values) => {
      submitReRegistrationService(values).then((res) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "You'r Registration have been submited succefully",
          timer: 3000,
          onOpen: function () {
            Swal.showLoading();
          },
        }).then(function (result) {
          setLoading(true);
        });
      });
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit} className="disabled">
        <div className="card-body">
          <div className="theme-form application-form">
            <div className="row">
              {/* <div className="form-group col-sm-12 inputfield">
                <h5 className="textdanger heading-5">Re-Write Exam Only:</h5>
                <label>
                  Select subjects to write exam again, as minimum
                  requirements/marks were not previously obtained. If you do not
                  want to re-write any subjects, then leave blank.
                </label>
                {subjectReWriteReadOnly && (
                  <ReWriteExamReadOnly
                    formik={formik}
                    setparentSubjectReWriteLength={
                      setparentSubjectReWriteLength
                    }
                    parentSubjectReWriteLength={parentSubjectReWriteLength}
                    reRegSubject={reRegSubject}
                  />
                )}
              </div> */}
              <div className="form-group col-sm-12 inputfield">
                <h5 className="textdanger heading-5">Re-register Modules:</h5>
                <label>
                  Select subjects you want to register for again, as subject was
                  either not passed or student wants to attempt it again. If you
                  do not want to redo any subjects, then leave blank.
                </label>
                {subjectReDoReadOnly && (
                  <RedoSubjectReadOnly
                    formik={formik}
                    setParentSubjectRedoLength={setParentSubjectRedoLength}
                    parentSubjectRedoLength={parentSubjectRedoLength}
                    reRegSubject={reRegSubject}
                  />
                )}
              </div>
              <div className="form-group col-sm-12 inputfield">
                <h5 className="textdanger heading-5">Progress From:</h5>
                <label>
                Select subjects you have completed at your current N-Level. Should you NOT wish to Progress with any subjects, kindly leave as blank.
                </label>

                {subjectProgressReadOnly && (
                  <ProgressFormReadOnly
                    formik={formik}
                    setparentSubjectProgLength={setparentSubjectProgLength}
                    parentSubjectProgLength={parentSubjectProgLength}
                    reRegSubject={reRegSubject}
                  />
                )}
              </div>
              <div className="form-group col-sm-12 inputfield">
              <h5 className="textdanger heading-5"> Foundation Programme Progression:</h5>
                <p>Should you have successfully completed the Foundation Program, articulation options will be available to you when the articulation period starts. </p>
              </div>
              <div className="col-12 form-group inputfield">
                <div className="custom-control custom-checkbox text-left box-tm">
                  <input
                    className={
                      "custom-control-input " +
                      (formik.touched.term_condn && formik.errors.term_condn
                        ? " is-invalid"
                        : "")
                    }
                    type="checkbox"
                    name="term_condn"
                    onChange={(e) => handleCheck(e)}
                    onBlur={formik.handleBlur}
                    checked={checked}
                    id="customCheck1"
                    disabled="disabled"
                  />

                  <label
                    className="custom-control-label textdanger"
                    htmlFor="customCheck1"
                  >
                    I hereby confirm that I have read, understood and accepted
                    all <a href='https://www.aie.ac/pgs-142-terms-and-conditions.html' target="_blank">Terms and Conditions</a> as set out in the application form that the above subject selections are correct, and understand
                    that there may be changes made based on the academic outcome.
                  </label>
                  {formik.touched.term_condn && formik.errors.term_condn ? (
                    <div className="invalid-feedback">
                      {formik.errors.term_condn}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="Fmfooter">
            <button
              title="Submitted"
              type="submit"
              className="btn btn-submit btn-main btn-primary"
              disabled="disabled"
            >
              {loading && <i className="fas fa-cog fa-spin"></i>} Submitted
            </button>
            {/* <button
              className="btn btn-submit btn-main btn-danger ml-2"
              disabled="disabled"
            >
              Cancel
            </button> */}
          </div>
        </div>
      </form>
    </>
  );
}
export default SchoolOfEngineering;
