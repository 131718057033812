import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import * as Yup from "yup";

import { ResetPasswordVerify } from "../services/ProfileService";

export default function ResetPasswordForm(props) {
  const [loading, setLoading] = useState(false);
  let history = useHistory();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      password: '',
      password_confirmation: ''
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required("Password is required")
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
        ),
      password_confirmation: Yup.string()
        .required("Confirm Password is required")
        .oneOf([Yup.ref("password"), null], "Passwords must match"),
    }),
    onSubmit: (values) => {
      setLoading(true);
      values.reset_password_token = props.token;
      ResetPasswordVerify(values)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Your password have been reset successfully.",
          }).then((result) => {
            setLoading(false);
            if (result.value) {
              history.push("/login");
            }
          });
        })
        .catch((error) => {
          setLoading(false);
          Swal.close();
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Token is invalid/expired. Please try Forgot your Password to get a new token.",
          }).then((result) => {
            history.push("/resetPassword");
          });
        });
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>

        <div className="input-group">
          <input
            type="password"
            placeholder="Password"
            className={
              "form-control" +
              (formik.errors.password && formik.touched.password
                ? " is-invalid"
                : "")
            }
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            onFocus={formik.handleBlur}
          />
          {formik.touched.password && formik.errors.password ? (
            <>
              <div className="invalid-feedback d-block">
                {formik.errors.password}
              </div>
              <div className="password-criteria-blk password-criteria">
                <h5>Password Criteria!</h5>
                <ul>
                  <li>Minimum 8 characters.</li>
                  <li>At least 1 uppercase letter.</li>
                  <li>At least 1 lowercase letter.</li>
                  <li>At least 1 number.</li>
                  <li>At least 1 special character.</li>
                </ul>
              </div>
            </>
          ) : null}
        </div>
        <div className="input-group">
          <input
            type="password"
            placeholder="Confirm password"
            className={
              "form-control" +
              (formik.errors.password_confirmation &&
                formik.touched.password_confirmation
                ? " is-invalid"
                : "")
            }
            name="password_confirmation"
            value={formik.values.password_confirmation}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.password_confirmation &&
            formik.errors.password_confirmation ? (
            <div className="invalid-feedback d-block">
              {formik.errors.password_confirmation}
            </div>
          ) : null}
        </div>



        <button
          className="btn btn-white-bordered btn-submit-info "
          disabled={loading}
          title="Set New Password"
        >
          {loading ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-paper-plane"></i>} Set New Password
        </button>
      </form>
    </>
  );
}
