const initialState = {
    loading:false,
    reload:false,
    data: [],
};

const ReRegistrationReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_REG_DATA":
            return { 
                ...state,
                loading:false,
                data: action.payload 
            };
        case "SET_REG_RELOAD":
            return{
                ...state,
                reload:action.payload
            }
        case "REG_LOADING":
            return{
                ...state,
                loading:true
            }
        default:
            return state;
    }
};

export default ReRegistrationReducer;
