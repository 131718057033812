import React from 'react'
import loadingAvatar from '../../assets/images/loading-avatar.svg'

export default function Loading() {
  return (
    <div className="loading-wrapper">
      <img className='loading-avatar' alt="loading" src={loadingAvatar} />
      <div className="loading-container">
        <div className="dot-typing"></div>               
      </div>
    </div>
  )
}
