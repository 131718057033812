import moment from "moment";
import React, { useEffect, useState } from "react";
import { TABLE_DATE_TIME_FORMAT } from "../../utils/Constants";
import {
  getFormattedTime,
  getStatusIcon,
  getCurrentTime,
} from "../common/Helper";
import Str from "../common/Str";

export default function ChatGroupList({
  mygroups,
  selectedGroup,
  handleGroupClick,
  group_id,
}) {
  return (
    <>
      <div className="Chat-body">
        <ul className="Chat-list-ul list-unstyled m-0" key={"new-chatlist"}>
          {mygroups.map((group) => {
            return (
              <li
                id={group.id}
                key={`${group.id}_${Math.random()}`}
                onClick={() => handleGroupClick(group)}
              >
                <div
                  className={
                    `card Chat-card ` +
                    (group.group_encrypted_id === group_id ? "Chat-active" : "")
                  }
                >
                  <div className="card-left">
                    <div className="card-user-img">
                      <div
                        className="card-user-img-round"
                        style={{ backgroundColor: "#e6eddd", color: "#4e5d3c" }}
                      >
                        Ls
                      </div>
                      <span className="portal-status-icon">
                        <i className="fas fa-check-circle"></i>
                      </span>
                    </div>
                    <div className="card-user-detail">
                      <h4>{group.group_name}</h4>
                      <p>
                        {group.message.length > 0 &&
                        group.message[0].files.length > 0 ? (
                          <React.Fragment>
                            <i class="fal fa-image"></i>
                          </React.Fragment>
                        ) : null}{" "}
                        {group.message.length > 0 ? (
                          <React.Fragment>
                            {group.message[0].message}
                          </React.Fragment>
                        ) : null}
                      </p>
                    </div>
                  </div>
                  <div className="card-right">
                    <ul
                      key={"new-ul-" + group.id}
                      className=" d-flex list-unstyled  Chat-include-items"
                    >
                      <li key={"new-ul-li-" + group.id} className="date-time">
                        {group.message.length > 0
                          ? moment(getFormattedTime(group.message[0].created_at)).format(Str.TABLE_DATE_TIME_FORMAT)
                          : null}
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            );
          })}
          {/* <li>
										<div className="card Chat-card  Chat-active">
											<div className="card-left">
												<div className="card-user-img">
													<div className="card-user-img-round" style={{'backgroundColor': '#e6eddd', color: '#4e5d3c'}}>
														Ls
													</div>
													<span className="portal-status-icon"><i className="fas fa-check-circle"></i></span>
												</div>
												<div className="card-user-detail">
													<h4>Leon Smalberger</h4>
													<p>This is a REMINDER of your upcoming classes</p>
												</div>
												
											</div>
											<div className="card-right">
												<ul className=" d-flex list-unstyled  Chat-include-items">
													<li className="date-time">8:30 PM</li>
												</ul>
											</div>
										</div>
									</li>
								
									<li>
										<div className="card Chat-card">
											<div className="card-left">
												<div className="card-user-img">
													<div className="card-user-img-round">
														<img src="images/user.jpg" />
													</div>
													<span className="portal-status-icon"><i className="fas fa-clock"></i></span>
												</div>
												<div className="card-user-detail">
													<h4>johan voster </h4>
													<p>this is a preview of message that is unread now</p>
												</div>
											</div>
											<div className="card-right">
												<ul className=" d-flex list-unstyled  Chat-include-items">
													<li className="date-time">4:22 PM</li>
													<li className="tag-important">@</li>
												</ul>
											</div>
										</div>
									</li>
									<li>
										<div className="card Chat-card Chat-read">
											<div className="card-left">
												<div className="card-user-img">
													<div className="card-user-img-round">
														<img src="images/user.jpg" />
													</div>
													<span className="portal-status-icon"><i className="fas fa-clock"></i></span>
												</div>
												<div className="card-user-detail">
													<h4>Annierike Theron</h4>
													<p>this is a preview of message that is read now</p>
												</div>
											</div>
											<div className="card-right">
												<ul className=" d-flex list-unstyled  Chat-include-items">
													<li className="date-time">4:22 PM</li>
												</ul>
											</div>
										</div>
									</li>

									<li>
										<div className="card Chat-card Chat-bulk">
											<div className="card-left">
												<div className="card-user-img">
													<div className="card-user-img-round">
														<img src="images/user.jpg" />
													</div>
													<span className="portal-status-icon"><i className="fas fa-check-circle"></i></span>
												</div>
												<div className="card-user-detail">
													<h4>Yolandie Venter</h4>
													<p>this is a preview of a message unread, with importance message</p>
												</div>
												
											</div>
											<div className="card-right">
												<ul className=" d-flex list-unstyled  Chat-include-items">
													<li className="date-time">4:22 PM</li>
													<li className="tag-important">@</li>
													
													<li className="alert-icon"><i className="fal fa-exclamation-square"></i></li>
												</ul>
											</div>
										</div>
									</li>


									<li>
										<div className="card Chat-card Chat-read">
											<div className="card-left">
												<div className="card-user-img">
													<div className="card-user-img-round" style={{'backgroundColor': '#e7ced1',color: '#67363b'}}>
														Cr
													</div>
													<span className="portal-status-icon"><i className="fas fa-circle"></i></span>
												</div>
												<div className="card-user-detail">
													<h4>Clarise Roos</h4>
													<p>This is the active selected message and is read before</p>
												</div>
											</div>
											<div className="card-right">
												<ul className=" d-flex list-unstyled  Chat-include-items"> 
													<li className="date-time">Sun, 4:22 PM</li>
													<li className="attachment"><i className="fal fa-paperclip"></i></li>
												</ul>
											</div>
										</div>
									</li>


									<li>
										<div className="card Chat-card Chat-gorup-card Chat-read">
											<div className="card-left">
												<div className="card-user-img">
													<div className="card-group-img">
														<i className="fal fa-comments-alt"></i>
													</div>
												</div>
												<div className="card-user-detail">
													<h4>Clarise Roos</h4>
													<p>This is the active selected message and is read before</p>
												</div>
											</div>
											<div className="card-right">
												<ul className=" d-flex list-unstyled  Chat-include-items"> 
													<li className="date-time">Sun, 4:22 PM</li>
													<li className="attachment"><i className="fal fa-paperclip"></i></li>
												</ul>
											</div>
										</div>
									</li> */}
        </ul>
      </div>
    </>
  );
}
