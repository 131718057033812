import React, { useEffect, useState, useMemo } from "react";
import $ from "jquery";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { Scrollbar } from "react-scrollbars-custom";
import ReactTooltip from "react-tooltip";
import { Link, useParams } from "react-router-dom";
import DataTable, { createTheme } from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import moment from "moment";

import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";

import Str from "../../components/common/Str";
import { BASE_URL } from "../../utils/Constants";
import { TrimText } from "../../components/common/TrimText";
import SkeletonTicketList from "../../loaders/SkeletonTicketList";
import { availability, campus, campusesWidget, campusWidget, getCurrentTimeHomePage, InitalRender, resource, resourceWidget, topicsWidget } from "../../components/common/Helper";
import { GetMyTicketsNewService, GetDepartmentsService } from "../../services/TicketService";
import StatusRender, { CatColorRender, CategoryRender, ColorRender, DepartmentRender } from "../../components/ticket/StatusRender";

import { getAllResource, getStudentLibraryDropdown } from "../../services/ResourcesService";
import { addToMyLibrary, removeFromFavorite } from "../../services/StudentHubService";
import { useDispatch, useSelector } from "react-redux";
import { setFavouritesCount } from "../../store/actions";
// console.log("TableStaticData", TableStaticData)


const StyledLi = styled.li`
  background: transparent;
  border: none;
  cursor: pointer;
  margin-left: 10px;
  &.selected {
    border-bottom: 1px blue solid;
  }
`;

export default function ResourceList(props) {
  const { searchValue, globalSearch, resetGlobalSearch , topicsValue, searchBtn } = props;
  const location = useLocation();
  const [totalRows, setTotalRows] = useState("");
  const [listData, setListData] = useState([])
  const [renderTable, setRenderTable] = useState(false);
  const [dropdownList, setDropdownList] = useState({});
  const [page, setPage] = useState(searchValue.page);
  const [perPage, setPerPage] = useState(searchValue.limit);
  const [sortKey, setSortKey] = useState(searchValue.key);
  const [sortOrder, setSortOrder] = useState(searchValue.sort);
  const [loadingId, setLoadingId] = useState('');
  const [topics, setTopics] = useState("")
  const dispatch = useDispatch();
  const favCount = useSelector((state) => state.favourites.favouritesCount);
  const brandConfig = useSelector((state) => state.brand);


  const [param, setParam] = useState({
    viaFormat: searchValue.viaFormat,
    condition1: searchValue.condition1,
    title: searchValue.title,
    condition2: searchValue.condition2,
    keywords: [searchValue.keywords],
    condition3: searchValue.condition3,
    description: searchValue.description,
    place: searchValue.place,
    author: searchValue.author,
    language: searchValue.language,
    from: searchValue.from,
    to: searchValue.to,
    viaAvailability: searchValue.viaAvailability,
    viaCampus: searchValue.viaCampus,
    page: searchValue.page,
    limit: searchValue.limit,
    key: searchValue.key,
    sort: searchValue.sort,
    exportStatus: searchValue.exportStatus,
    viaSearch: searchValue.viaSearch,
    viaTopics: [],
  })

  const handlePageChange = (page) => {
    // setParam({ ...param, page: page })
    setPage(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    // setParam({ ...param, limit: newPerPage });    
    setPerPage(newPerPage);
  };

  const handleSort = (column, sortDirection) => {
    setSortKey(column.selector);
    setSortOrder(sortDirection === "asc" ? "ASC" : "DESC")
    // setParam({
    //   ...param,
    //   key: column.selector,
    //   sort: sortDirection === "asc" ? "ASC" : "DESC"
    // })
  };



  const getData = () => {

    setRenderTable(false);
    const sendData = {
      ...param,
      viaGlobleSearch: globalSearch,
      page: page,
      limit: perPage,
      key: sortKey,
      sort: sortOrder,
      brand_id: brandConfig?.configuration?.id
    }
    getAllResource(sendData).then((res) => {
      if (res.status === 200) {
        const resp = res.data.result;
        setListData(resp.data);
        setTotalRows(resp.total);
        setRenderTable(true);
        setLoadingId('')
      }
    }).catch((err) => { console.log(err) });

  }
  const getStudentLibraryDropdownData = async () => {
    const result = await getStudentLibraryDropdown();
    setDropdownList(result?.data);
  }


  const wordSpliterTopics = (str, type) => {
    const strArr = Array.isArray(str.split(",")) ? str.split(",") : [];
    const newStrArr = strArr.length ? strArr?.map(item => {
    const topic = dropdownList?.topic ? dropdownList?.topic.find(val => val.value == item) : false;
      return item ? <small className="as-widget-resource" style={{color: '#fff',background: topic?.color}}>{topic?.label}</small> : 'NA';      
    }) : "NA";
    return newStrArr;
  }


  useEffect(() => {
    getData()

  }, [param, page, perPage, sortKey, sortOrder, searchBtn])

  useEffect(() => {
    getStudentLibraryDropdownData();
  }, [])

  const columns = useMemo(() => [
    // {
    //   name: "Nr",
    //   selector: "id",
    //   sortable: true,
    //   // maxWidth: "110px",
    //   cell: (row) => (
    //     <div className="ticket-id">
    //       <p>
    //         <span className="overflow-ellipsis2"><b>{row.id}</b></span>
    //       </p>
    //     </div>
    //   ),
    // },
    {
      name: "Title",
      selector: "title",
      wrap: true,
      sortable: true,
      cell: (row) => (
        <Link
          to={{ pathname: row.type === "Book" ? `/student-hub/advance-search/detail/physical/${(row.id)}` : `/student-hub/advance-search/detail/digital/${(row.id)}`, state: { prevPath: location.pathname} }}
          className={"as-text-blue plink"}
          title={row.title}
        >
          {row.title}
        </Link>
      ),
    },
    // {
    //   name: "Author",
    //   selector: "author",
    //   wrap: true,
    //   sortable: true,
    //   cell: (row) => <p className="oneline-ellipsis" title={row.author}>{row.author ? row.author : "NA"}</p>
    //   //   cell: (row) => <StatusRender status={row.Author} />,
    // },
    // {
    //   name: "Edition",
    //   selector: "edition",
    //   wrap: true,
    //   sortable: true,
    //   cell: (row) => <p className="oneline-ellipsis">{row.edition ? <b>#{row.edition}</b> : "NA"}</p>
    // },
    {
      name: "Resource",
      selector: "type",
      wrap: true,
      sortable: true,
      cell: (row) => (
        <p className="oneline-ellipsis">
          {resourceWidget(row.type)}
        </p>
      ),
    },
    {
      name: "Topics",
      selector: "topic",
      wrap: true,
      sortable: true,
      cell: (row) => (
       
          row?.topic ? wordSpliterTopics(row?.topic.toString(), "topics") : 'NA'

      ),
    },
    // {
    //   name: "Campus",
    //   selector: "campus",
    //   wrap: true,
    //   sortable: true,
    //   cell: (row) => (
    //     <p className="oneline-ellipsis">
    //       {row.campus? campusWidget(row.campus) : "NA"}
    //       {/* {row.department
    //         ? <DepartmentRender status={row.Resource} />
    //         : "NA"} */}
    //     </p>
    //   ),
    // },
    // {
    //   name: "Barcode",
    //   selector: "barcode",
    //   wrap: true,
    //   sortable: true,
    //   cell: (row) => <p className="oneline-ellipsis">{row.barcode ? row.barcode : "NA"}</p>
    // },
    // {
    //   name: "Availability",
    //   selector: "availability",
    //   wrap: true,
    //   sortable: true,
    //   cell: (row) => (availability(row.availability)),
    // },
    // {
    //   name: "Location",
    //   selector: "place",
    //   wrap: true,
    //   // sortable: true,
    //   cell: (row) => <p className="oneline-ellipsis">{row.place ? row.place : "NA"}</p>
    // },
    {
      name: "Action",
      // width: "100%",
      // maxWidth: "90px",
      // minWidth: "90px",
      cell: (row) => (
        <div className="assessment-08 advSearch-action-con">
          <div className="as-buttons">
            <Link to={{ pathname: row.type === "Book" ? `/student-hub/advance-search/detail/physical/${(row.id)}` : `/student-hub/advance-search/detail/digital/${(row.id)}`, state: { prevPath: location.pathname } }}>
              <button
                title="Open"
                className="btn btn-primary rounded-circle"
              >
                <i className="fal fa-folder-open"></i>
              </button>
            </Link>
          </div>
          <div className="as-buttons">
            <button
              title={`${row.favorite ? 'Remove from' : 'Add to'} Favourites`}
              className="btn btn-primary rounded-circle"
              onClick={() => { handleFavourties(row.id, row.favorite, row.sp_resource_issued_id); setLoadingId(row.id) }}
            >
              <i className={`${row.id == loadingId ? 'fas fa-cog fa-spin' : row.favorite ? 'fas fa-star' : 'fal fa-star'} `}></i>
            </button>
          </div>
        </div>
      ),

    },
  ]);


  const handleFavourties = (id, favorite, sp_resource_issued_id) => {
    const data = { resource_id: id, sp_resource_issued_id: sp_resource_issued_id }
    if (!favorite) {
      addToMyLibrary(data).then((res) => {
        dispatch(setFavouritesCount(favCount+1))
        getData()
      })
    }
    else {
      removeFromFavorite(data).then((res) => {
        dispatch(setFavouritesCount(favCount-1))
        getData()
      })
    }
  }

  // ** Function to handle type filter  
  const handleSearchFilter = e => {
    const value = e.target.value
    setParam({ ...param, viaSearch: value });
  }

  const handleUpdateFilter = e => {
    const value = e.target.value;
    let arr = [...param.viaFormat];
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1);
    } else {
      arr.push(value);
    }
    setParam({
      ...param,
      viaFormat: [...arr]
    })
  }

  const handleCampusFilter = e => {
    const value = e.target.value;
    let arr = [...param.viaCampus];
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1);
    } else {
      arr.push(value);
    }
    setParam({
      ...param,
      viaCampus: [...arr]
    })
  }
  const handleTopicsFilter = e => {
    const value = e.target.value;
    let arr = [...param.viaTopics];
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1);
    } else {
      arr.push(value);
    }
    setParam({
      ...param,
      viaTopics: [...arr]
    })
  }
 


  const resetFilter = () => {
    setParam({
      ...param,
      viaCampus: [],
      viaFormat: [],
      keywords: [],
      viaSearch: "",
      condition1: "",
      title: "",
      condition2: "",
      condition3: "",
      description: "",
      place: "",
      author: "",
      language: "",
      from: "",
      to: "",
      viaAvailability: "",
      viaTopics: []
    });
    resetGlobalSearch()
  };


  return (
    <>
      <div className="card-table-new custom-table-div01 filter-search-icon01 card card-table-custom custom-table-div">
        <div className="filter-search-bar-blk">
          <div className="filter-button-group">
            <div className="filter-eff filter-data-btn">
              <button className="filter-buttons">
                <i className="fal fa-filter"></i>
              </button>
            </div>
           <div className="assessment-table-filter">
              <div className="btn-type-filter dropdown-comman">
                <div className={`dropdown multiselect`}>
                  <button
                    className={`btn btn-default dropdown-toggle ${param.viaFormat.length ? 'btn-selected' : ''}`}
                    type="button"
                    id="dropdownMenu1"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="true"
                    title="Campus"
                  >
                    <span>
                      Format <i className="fal fa-angle-down"></i>
                    </span>
                  </button>
                  <form className="dropdown-menu remove-list-style">
                    <ul aria-labelledby="dropdownMenu1">
                      {
                         dropdownList?.LibraryResourceType?.map((item, index) => {
                          return (
                            <li className="item-text-green" key={index}>
                              <input
                                type="checkbox"
                                id={item?.value}
                                value={item?.value}
                                onClick={handleUpdateFilter}
                                checked={param.viaFormat.includes(item.value.toString())? true : false}
                              />
                              <label htmlFor={item?.value}>
                                {
                                  resourceWidget(item?.value)
                                }
                              </label>
                            </li>
                          )
                        })
                      }
                    </ul>
                  </form>
                </div>
              </div>
            </div>

            <div className="assessment-table-filter">
              <div className="btn-type-filter dropdown-comman">
                <div className={`dropdown multiselect`}>
                  <button
                    className={`btn btn-default dropdown-toggle ${param.viaCampus.length ? 'btn-selected' : ''}`}
                    type="button"
                    id="dropdownMenu1"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="true"
                    title="Campus"
                  >
                    <span>
                      Campus <i className="fal fa-angle-down"></i>
                    </span>
                  </button>
                  <form className="dropdown-menu remove-list-style">
                    <ul aria-labelledby="dropdownMenu1">
                      {
                        dropdownList?.campus?.map((item, index) => {
                          return (
                            <li key={index}>
                              <input
                                type="checkbox"
                                id={item?.value}
                                value={item?.value}
                                onClick={handleCampusFilter}
                                checked={param.viaCampus.includes(item.value.toString())? true : false}
                              />
                              <label htmlFor={item?.value}>
                                {
                                  campusesWidget(item?.color,item?.label)
                                }
                              </label>
                            </li>
                          )
                        })
                      }
                    </ul>
                  </form>
                </div>
              </div>
            </div>

            <div className="assessment-table-filter">
              <div className="btn-type-filter dropdown-comman">
                <div className={`dropdown multiselect`}>
                  <button
                    className={`btn btn-default dropdown-toggle ${param.viaTopics.length ? 'btn-selected' : ''}`}
                    type="button"
                    id="dropdownMenu1"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="true"
                    title="Topics"
                  >
                    <span>
                      Topics <i className="fal fa-angle-down"></i>
                    </span>
                  </button>
                  <form className="dropdown-menu remove-list-style">
                    <ul aria-labelledby="dropdownMenu1">
                      {
                        dropdownList?.topic?.map((item, index) => {
                          return (
                            <li key={index}>
                              <input
                                type="checkbox"
                                id={item?.value}
                                value={item?.value}
                                onClick={handleTopicsFilter}
                                checked={param.viaTopics.includes(item.value.toString())? true : false}
                              />
                              <label htmlFor={item?.value}>
                                {
                                  topicsWidget(item?.value,item?.color,item?.label)
                                }
                              </label>
                            </li>
                          )
                        })
                      }
                    </ul>
                  </form>
                </div>
              </div>
            </div>

            <div className="button-reset">
              <button className="btn btn-primary" onClick={resetFilter} title="Reset">
                <i className="fal fa-redo"></i>Reset
              </button>
            </div>
          </div>
        </div >
        <div className="data-table-extensions">
          <div className="data-table-extensions-filter">
            <label htmlFor="filterDataTable" className="icon"></label>
            <input
              type="text"
              name="filterDataTable"
              className="filter-text"
              placeholder="Search"
              onChange={handleSearchFilter}
              value={param.viaSearch}
            />
          </div>
          <div className="data-table-extensions-action"></div>
        </div>

        {
          renderTable ?
            <DataTable
              data={listData}
              // defaultSortField={param.key}
              // defaultSortAsc={false}
              columns={columns}
              pagination={true}
              noDataComponent={Str.noRecord}
              paginationDefaultPage={page}
              progressPending={!renderTable}
              progressComponent={<SkeletonTicketList />}
              onSort={handleSort}
              paginationServer
              sortServer
              paginationTotalRows={totalRows}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              highlightOnHover={false}
              paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 50, 100]}
            />
            : <SkeletonTicketList />
        }
      </div >
    </>
  );
}
