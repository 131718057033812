import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import MentoredSubject from '../components/course/MentoredSubject'
import ReactHtmlParser from "react-html-parser";
import { GetSubjectContentServiceAdmin, GetSubjectsService } from '../services/QualificationService';
import { Scrollbar } from "react-scrollbars-custom";

function CourseOverview() {
    const location = useLocation()
    const { sid, qid, stdId } = useParams()
    const [state, setState] = useState({});
    const [loading, setLoading] = useState(false)

    const updateSubject = () => {
        // GetSubjectsService({sid,qid})
        // .then((res) => {
        //       console.log("consoleerespon", res.data)
        //     setState({
        //       qualification: res.data.qualification,
        //       subjects: res.data.subjects.others,
        //       completed_subjects: res.data.subjects.finised,
        //     });
            GetSubjectContentServiceAdmin({ sid: sid, id:stdId })
              .then((res) => {
                setState({ subjectContent: res.data });
              })
              .catch((error) => {});
        //   })
        //   .catch((error) => {});
      };

      useEffect(() => {
        updateSubject()
      }, [])
      

    return (
        <div class="p-4 course_full_vh">
            {/* <Scrollbar> */}
            <div
                role="tabpanel"
                aria-labelledby="pills-Overview-tab"
            >
                {state && state.subjectContent && state.subjectContent.subject && state.subjectContent.subject ? (
                    <div className="Actabs">
                        {/* && props.isAdmin != 1  */}
                        {state.subjectContent.subject.subject_type == "mentored" 
                        ? (
                            <MentoredSubject
                                row={state.subjectContent.subject}
                                updateSubject={updateSubject}
                            />
                        ) : null}
                        {state.subjectContent.subject.welcome ? ReactHtmlParser(state.subjectContent.subject.welcome) : <div class="subject-sub-header"><h3>No Content Available.</h3></div>}
                    </div>
                ) : null}
            </div>
            {/* </Scrollbar> */}
        </div>
    )
}

export default CourseOverview