import React, { useEffect, useState, useRef } from "react";
import ReactHtmlParser from "react-html-parser";
import AssignmentStatus from "../../course/AssignmentStatus";
import AssignmentType from "../../course/AssignmentType";
import Str from "../../common/Str";
// import Exam_recording from "../../../pages/object-detection/Exam_recording";
// import '../../../pages/object-detection/styles.css';
import { SystemIcons } from "../../common/SystemIcons";
import { SysCheckSideIcon } from "../../common/SysCheckSideIcon";
// import AudioDetection from "../../../pages/object-detection/audio-detection";
import Swal from "sweetalert2";
import QuizDescription from "../quiz/QuizDescriptionExam";
import ViewQuiz from "../quiz/ViewQuiz";
import { GetQuizByIdService, GetQuizQuestionsService } from "../../../services/QuizService";
import { useDispatch, useSelector } from "react-redux";
import { clearQuiz, setIsExamLaunched, setQuizComplete } from "../../../store/actions";
import AssessmentDetailTab from "./AssessmentDetailTab";
import { StoreTime } from "../../../services/QualificationService";
import { useHistory } from "react-router-dom";

const Step4 = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { examLaunched } = useSelector(state=>state.examLaunched);
  const isExamLaunched = history.location.pathname === examLaunched?.url && examLaunched?.isExamLaunched;
  const setSoundCounts = (data) => {
    props.soundCount(data)
  }

  useEffect(() => {
    localStorage.removeItem("totaltime");
    if(props.quizId){
      GetQuizByIdService({ qid: props.quizId })
        .then((res) => {
          props.setquiz(res.data[0]);
          props.setresult(res.data[1]);
        })
        .catch((err) => console.log(err));
    }

    return () => props.setquiz("");
  }, []);

  const launchExam = (isLaunched) => {
    setLoading(true);
    if (props.quizId && !isLaunched) {
      Swal.close();
      Swal.fire({
        title: 'Are you sure?',
        text: Str.quizPopup,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Launch',
      }).then(function (result) {
        if (result.value) {
          // props.setStep(5)
          Swal.close()
          props.setIsExamLaunchedToTrue()

          GetQuizQuestionsService({ quid: props.quizId, assignment_id: props.assessmentId })
            .then((res) => {
              // setquiz(res.data);
              dispatch(clearQuiz());
              // dispatch(setQuizComplete(res.data.reduxData));
              const obj = {};
              const allQuizId = [];
              res.data.questions.filter(list=>(list && list.questions && list.questions.length > 0)).map(q=>{
                if(q.type === 0){
                  obj[`qa${q.questions[0].qid}qa`] = q.questions[0]
                  allQuizId.push(q.questions[0].qid);
                } else {
                  obj[`qa${q.questions[0].qid}qa`] = q.questions
                  allQuizId.push(q.questions.map(q=>q.qid));
                }
              })
              // console.log("_______________ Attempts Left _____",(res?.data?.description?.maximum_attempts - res?.data?.totalattempt));
              // console.log("_________ obj>>>>>",Object.values(obj).map(ques=>ques?.qid).join(","));
             
              props.setMaxAttempt(res.data.description.maximum_attempts);
              localStorage.removeItem("totaltime");
              for (let i = 0; i < localStorage.length; i++) {
                let value = localStorage.key(i);
                if (value.startsWith("@")) {
                  localStorage.removeItem(value);
                }
              }
              for (let i = 0; i < localStorage.length; i++) {
                let value = localStorage.key(i);
                if (value.startsWith("@")) {
                  localStorage.removeItem(value);
                }
              }

              res.data.questions.filter(list=>(list && list.questions && list.questions.length > 0)).map(q => {
                q.questions.map(ques=>{
                    localStorage.setItem(`@qa${ques.qid}qa`, 0);
                })
              })
              // const keys = Object.keys(res.data.reduxData);
              // const keys = Object.keys(obj);
              // keys.map((item, index) => {
              //   // localStorage.setItem("@" + item, index == 0 ? 1 : 0);
              //   localStorage.setItem(
              //     "@" + item,
              //     res.data.individual_time[index] || 0
              //   );
              // });
              dispatch(setQuizComplete(obj));
              setLoading(false);
              props.setshow({ question: true });
              props.setStep(5)
              
              StoreTime({ assId: props?.assessmentId, attempted_no: (res?.data?.description?.maximum_attempts - res?.data?.totalattempt) ,questions_order: allQuizId.join(",") })
                .then((res) => {
                  // console.log("STORE TIME:", res);
                  props.setAssignmentReload();
                })
                .catch((error) => {
                  console.error(error);
                });
            })
            .catch((err) => {
              setLoading(false);
              console.log(err);
            });
        }
      }).finally(()=>{
        setLoading(false);
      });
    }
    else {
      // props.launchDownload()
      GetQuizQuestionsService({ quid: props.quizId, assignment_id: props.assessmentId })
        .then((res) => {
          // setquiz(res.data);
          dispatch(clearQuiz());
          // dispatch(setQuizComplete(res.data.reduxData));
          const obj = {};
          res.data.questions.filter(list => (list && list.questions && list.questions.length > 0)).map(q => {
            if (q.type === 0) {
              obj[`qa${q.questions[0].qid}qa`] = q.questions[0]
            } else {
              obj[`qa${q.questions[0].qid}qa`] = q.questions
            }
          })
          // console.log("_______________ Attempts Left _____",(res?.data?.description?.maximum_attempts - res?.data?.totalattempt));
          // console.log("_________ obj>>>>>",Object.values(obj).map(ques=>ques?.qid).join(","));

          props.setMaxAttempt(res.data.description.maximum_attempts);
          localStorage.removeItem("totaltime");
          for (let i = 0; i < localStorage.length; i++) {
            let value = localStorage.key(i);
            if (value.startsWith("@")) {
              localStorage.removeItem(value);
            }
          }
          for (let i = 0; i < localStorage.length; i++) {
            let value = localStorage.key(i);
            if (value.startsWith("@")) {
              localStorage.removeItem(value);
            }
          }

          res.data.questions.filter(list => (list && list.questions && list.questions.length > 0)).map(q => {
            q.questions.map(ques => {
              localStorage.setItem(`@qa${ques.qid}qa`, 0);
            })
          })
          // const keys = Object.keys(res.data.reduxData);
          // const keys = Object.keys(obj);
          // keys.map((item, index) => {
          //   // localStorage.setItem("@" + item, index == 0 ? 1 : 0);
          //   localStorage.setItem(
          //     "@" + item,
          //     res.data.individual_time[index] || 0
          //   );
          // });
          dispatch(setQuizComplete(obj));
          setLoading(false);
          props.setshow({ question: true });
          props.setStep(5)

          StoreTime({ assId: props?.assessmentId, attempted_no: (res?.data?.description?.maximum_attempts - res?.data?.totalattempt), questions_order: Object.values(obj).map(ques => ques?.qid).join(",") })
            .then((res) => {
              // console.log("STORE TIME:", res);
            })
            .catch((error) => {
              console.error(error);
            });
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        }).finally(()=>{
          setLoading(false);
        });
      // props.launchDownload({isLaunchedAndDownload: true, isOnlyDownload: false})
    }
  }

  useEffect(()=>{
    // console.log("____________________________ step 4",isExamLaunched,props.data.remainingDuration)
    if(isExamLaunched && Number(props.data.remainingDuration) > 0){
      launchExam(true);
    }
  },[examLaunched])

  return (
    <>
      <div class="tab-pane fade show active" id="Assessment-Detail">
        <div class="test-series-main-block">
          <div class="test-series-main-left-blk">
            <div class="test-series-main-heading icon-add mb-15">
              <h4>{props.data && props.data.name ? props.data.name : ""}{props.data && props.data.code ? ` (${props.data.code})` : ""}</h4>
              <span class="test-series-main-left-heading-act">
                <SystemIcons data={props.data} />
              </span>
            </div>
            <div className="breadcrumb-btn-blk">
              <div className="custom-breadcrumb">
                <ul>
                  <li onClick={() => {props.stepUpdate(1)}} class="custom-breadcrumb-item active">
                    <a href="javascript:void(0);">
                      <i class="fal fa-home"></i>
                    </a>
                  </li>
                  {props.showSystemCheck &&
                    <li onClick={() => {props.stepUpdate(2)}} class="custom-breadcrumb-item active">
                      <a href="javascript:void(0);">System Check</a>
                    </li>
                  }
                  {props.showIdentification &&
                    <li onClick={() => {props.stepUpdate(3)}} class="custom-breadcrumb-item active">
                      <a href="javascript:void(0);">Student Identification</a>
                    </li>
                  }
                  <li class="custom-breadcrumb-item inactive">
                    <a href="javascript:void(0);">Test</a>
                  </li>
                </ul>
              </div>
              <div className="breadcrumb-btns">
                <button onClick={()=>props.backBtn()} type="button" class="btn btn-primary">
                  <i class="fal fa-chevron-left"></i> Back{" "}
                </button>
                <div className="breadcrumb-right-btns">
                  {(props.data.quiz_id == 0 && props.data.launched == 0) ? 
                    props.data.assFile != '' ?                     
                      <button
                        type="button"
                        className="btn btn-primary"
                        disabled={props?.loader==true}
                        onClick={()=>{props.launchDownload({isLaunchedAndDownload: true, isOnlyDownload: false})}}
                      >
                        {props?.loader==true ?  <i className={`fas fa-cog fa-spin`}></i>  : <i className="fal fa-download"></i>}&nbsp; Launch & Download
                      </button>                    
                    : <button
                        type="button"
                        className="btn btn-primary"
                        disabled={props?.loader==true}
                        onClick={()=>{props.launchDownload({isLaunchedAndDownload: false, isOnlyDownload: false})}}
                      >
                        {props?.loader==true ?  <i className={`fas fa-cog fa-spin`}></i>  : <i className="fal fa-rocket"></i>}&nbsp; Launch
                      </button>                    
                  : 
                      <button
                        onClick={() => { launchExam(false) }}
                        type="button"
                        className="btn btn-primary"
                        disabled={loading==true}
                      >
                        <i className={loading==true ? "fas fa-cog fa-spin" : (props.data.quiz_id ? "fal fa-rocket" : "fal fa-download")}></i>&nbsp;
                        Launch {!props.data.quiz_id && ' & Download'}
                      </button>
                    }
                  {props.data.quiz_id == 0 && props.data.launched == 1 && props.data.assFile != '' ?
                    <button
                      type="button"
                      className="btn btn-primary"
                      disabled={props?.loader==true}
                      onClick={()=>{props.launchDownload({isLaunchedAndDownload: false, isOnlyDownload: true})}}
                    >
                      {props?.loader==true ?  <i className={`fas fa-cog fa-spin`}></i>  : <i className="fal fa-download"></i>}&nbsp; Download
                    </button>
                  : null}
                </div>
              </div>
            </div>
            <div className="test-series-main-heading mb-15">
              <h4>Details</h4>
            </div>
            <AssessmentDetailTab data={props.data} quizId={props.quizId} maxAttempt={props.maxAttempt} step={4} />

            <div className="textseries-content-blk desc-instruction-blk white-brd-blk mb-15">
              <h4>{Str.detailDes}:</h4>
              {props?.data?.details
                ? ReactHtmlParser(props.data.details)
                : Str.noInstruction}
            </div>

            {/* {(props.data.camera || props.data.audio || props.data.screen_share) ?
              <div className="textseries-content-records">
                <div className="row">
                  <div className="col-md-6 mb-15">
                    <div className="audio-video-box">
                      <div className="sound-wave-recognize">
                        <figure>
                          {props.data.camera ?
                            <Exam_recording />
                            : null
                          }
                        </figure>
                      </div>
                      {props.data.audio ?
                        <AudioDetection brandInfo={props.brandInfo} soundCount={setSoundCounts} />
                        : null}
                    </div>
                  </div>
                  <div className="col-md-6 mb-15">
                    {(props.data.camera || props.data.audio || props.data.screen_share) ?
                      props.ObjectDetection
                      : null}
                  </div>
                </div>
              </div>
              : null
            } */}

          </div>

          <div class="test-series-main-right-blk">
            <div className="step-sidebar-space">
              <SysCheckSideIcon props={props} />
            </div>

            <div class="test-series-steps">
              <ul>
                <li class="test-series-steps-item active done">
                  <a href="javascript:void(0);">
                    <span>1</span> <b>Assessment Detail</b>
                  </a>
                </li>
                {props.showSystemCheck ?
                  <li class="test-series-steps-item active done">
                    <a href="javascript:void(0);">
                      <span>2</span> <b>System Check</b>
                    </a>
                  </li>
                  : null}
                {props.showIdentification ?
                  <li class="test-series-steps-item active done">
                    <a href="javascript:void(0);">
                      <span>{props.showSystemCheck ? 3 : 2}</span> <b>{Str.Identification}</b>
                    </a>
                  </li>
                  : null}
                <li class="test-series-steps-item active">
                  <a href="javascript:void(0);">
                    <span>{(props.showSystemCheck && props.showIdentification) ? 4 : (props.showSystemCheck || props.showIdentification) ? 3 : 2}</span> <b>{Str.launchExam}</b>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Step4;
