import axiosInstance from "../utils/axios";

export const GetMyNotificationsService = async (
  notificationFilter,
  day,
  page = 1
) => {
  return await axiosInstance().get("/getMyNotifications", {
    params: {
      day: day,
      page: page,
      notificationFilter: notificationFilter,
    },
  });
};

export const GetMyNotificationsPopupService = async (notificationId) => {
  return await axiosInstance().get("/getMyNotificationsPopup", {
    params: { notificationId: notificationId },
  });
};

export const GetNotificationContentService = async (values) => {
  return await axiosInstance().get("/getMyNotificationContent", {
    params: values,
  });
};

export const UpdateNotificationRead = async (values) => {
  return await axiosInstance().get("/updateNotificationRead", {
    params: values,
  });
}

export const GetFilteredNotificationService = async (
  unread,
  important,
  nextPage = "1"
) => {
  return await axiosInstance().get("/getMyFilteredNotifications", {
    params: { unread: unread, important: important, nextPage: nextPage },
  });
};

export const GetMyNotificationOfAvaibality = async (notificationFilter) => {
  return await axiosInstance().get("/getMyNotificationOfAvaibality", {
    params: { notificationFilter: notificationFilter },
  });
};

export const GetMyUnreadNotificationCount = async (params) => {
  return await axiosInstance().get("/getMyUnreadNotificationCount");
};

export const GetUpdateNotificationReregRead = async (params) => {
  return await axiosInstance().post("/updateNotificationReregRead",params);
};