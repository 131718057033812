import React, { useEffect, useState, useRef } from "react";
import ReactHtmlParser from "react-html-parser";
import Str from "../../common/Str";
import { SystemIcons } from "../../common/SystemIcons";
import AssignmentStatus from "../AssignmentStatus";
import AssignmentType from "../AssignmentType";

const Step1 = (props) => {
  const [checked, setChecked] = useState(false);
  const [enableNext, setEnableNext] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      (props.data.status == 'Pending' || props.data.camera || props.data.audio || props.data.screen_share) ? setEnableNext(false) : setEnableNext(true)
    }, 500);

  }, [props]);


  return (
    <>
      <div className="tab-pane fade show active" id="Assessment-Detail">
        <div className="test-series-main-block">
          <div className="test-series-main-left-blk">
            <div className="test-series-main-heading icon-add mb-15">
              <h4>{props.data && props.data.name ? props.data.name : ""}{props.data && props.data.code ? ` (${props.data.code})` : ""}</h4>
              <span className="test-series-main-left-heading-act">
                <SystemIcons data={props.data} />
              </span>
            </div>

            <div className="breadcrumb-btn-blk">
              <div className="custom-breadcrumb">
                <ul>
                  <li className={`custom-breadcrumb-item ${(checked || enableNext) ? 'active' : 'inactive'}`}>
                    <a href="javascript:void(0);">
                      <i className="fal fa-home"></i>
                    </a>
                  </li>
                  {props.showSystemCheck &&
                    <li className="custom-breadcrumb-item">
                      <a href="javascript:void(0);">{Str.sysCheck}</a>
                    </li>
                  }
                  {props.showIdentification &&
                    <li className="custom-breadcrumb-item">
                      <a href="javascript:void(0);">{Str.stuIdentify}</a>
                    </li>
                  }
                  <li className="custom-breadcrumb-item">
                    <a href="javascript:void(0);">{Str.Test}</a>
                  </li>
                </ul>
              </div>
              <div className="breadcrumb-btns">
                <button onClick={props.backBtn} type="button" className="btn btn-primary">
                  <i className="fal fa-chevron-left"></i> {Str.backAssment}
                </button>
                <div className="breadcrumb-right-btns">
                  {(checked || enableNext) ? (
                    <button type="button" onClick={props.onNext} className="btn btn-primary">
                      {Str.Next} &nbsp;<i className="fal fa-chevron-right"></i>{" "}
                      {props.showSystemCheck ? Str.sysCheck : props.showIdentification ? Str.Identification : Str.launchExam}
                    </button>
                  ) : (
                    <button type="button" class="btn btn-primary disabled">
                      {Str.Next} &nbsp;<i class="fal fa-chevron-right"></i>{" "}
                      {props.showSystemCheck ? Str.sysCheck : props.showIdentification ? Str.Identification : Str.launchExam}
                    </button>
                  )}
                </div>
              </div>
            </div>

            {/* <div class="test-series-main-heading mb-4">
              <h4>{Str.assDetail}</h4>
            </div> */}
            <div className="textseries-content-blk white-brd-blk mb-3">
              <div className="row">
                <div className="col-md-6">
                  <div className="textseries-info-details">
                    <ul>
                      <li>
                        <span className="heading">Type:</span>
                        <span className="content">
                          {props?.data?.ass_type &&
                            <AssignmentType ass_type={props?.data?.ass_type ? props.data.ass_type : ""} />
                          }
                        </span>
                      </li>
                      <li>
                        <span className="heading">Status:</span>
                        <span className="content">
                          {props?.data?.status &&
                            <AssignmentStatus status={props?.data?.status ? props.data.status : ""} />
                          }
                        </span>
                      </li>
                      <li>
                        <span className="heading">Condition:</span>
                        <span className="content">
                          <span className="ts-info-act">
                            <SystemIcons data={props.data} />
                            <button
                              type="button"
                              className="btn btn-secondary"
                              data-toggle="tooltip"
                              data-placement="right"
                              title={props.toolTip}
                            >
                              <i className="fal fa-info-circle"></i>
                            </button>
                          </span>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="textseries-info-details">
                    <ul>
                      <li>
                        <span className="heading">Open Date:</span>
                        <span className="content">
                          {props?.data?.open_date_time
                            ? props.data.open_date_time
                            : ""}
                        </span>
                      </li>
                      <li>
                        <span className="heading">Close Date:</span>
                        <span className="content">
                          {props?.data?.close_date_time
                            ? props.data.close_date_time
                            : ""}
                        </span>
                      </li>
                      <li>
                        <span className="heading">Time Limit:</span>
                        <span className="content">
                          {props?.data?.timeLimitActive ? (
                            <div className="">
                              {props?.data?.timeHour ? props.data.timeHour : "0"}{" "}
                              hours{" "}
                              {props?.data?.timeMin ? props.data.timeMin : "0"}{" "}
                              min
                            </div>
                          ) : "-"}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {props.quizId &&
                <div className="mt-15">
                  <div className="textseries-info-details">
                    <ul>
                      <li>
                        <span class="heading">Attempts:</span>
                        <span class="content">
                          <span class="ts-info-act">
                            {props.maxAttempt}
                          </span>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              }

              {props.data.status == "Pending" ? (
                <div className="innerblue-textseries-content blue-bg-blk small">
                  <p>
                    <strong>Assessment is not open yet</strong>
                  </p>
                </div>
              ) : ((props.data.camera || props.data.audio || props.data.screen_share) ?
                <div className="innerblue-textseries-content notice-blk mt-15">
                  <div>
                    <h5>Exam Conditions Notice <i class="fal fa-exclamation-circle"></i></h5>
                    <p>This exam requires strict exam conditions, please note the following:</p>
                    <ul className="count-list">
                      {props.data.camera ? <li>Your device must have a functioning webcam</li> : null}
                      {props.data.audio ? <li>Your device must have a functioning microphone</li> : null}
                      {/* {props.data.screen_share ? <li>You have not plugged in any secondary display</li> : null} */}
                      {/* <li>Your device must have a functioning webcam and microphone.</li> */}
                      <li>No cell phones or other secondary devices in the room or test area and no talking allowed</li>
                      <li>Your desk/table must be clear or any materials except your test-taking device.</li>
                      <li>No one else can be in the room with you, nor can you leave the view of the camera.</li>
                      <li>The testing room must be well-lit and you must be clearly visible.</li>
                      {props.data.screen_share ? <li>No dual screens/monitors</li> : null}
                      <li>No use of additional applications or internet unless specified in the exam.</li>
                    </ul>
                    {/* <p>
                      <strong>
                        Please allow the portal to access your {props.data.camera ? 'camera' : ""} {(props.data.camera && props.data.audio) ? ", " : null} {props.data.audio ? 'microphone' : ""} {((props.data.camera || props.data.audio) && props.data.screen_share) ? ' and ' : null}
                        {props.data.screen_share ? 'desktop' : ""}
                      </strong>
                    </p> */}
                    <div className="custom-control custom-checkbox">
                      <input
                        onClick={() => { setChecked(!checked) }}
                        type="checkbox"
                        className="custom-control-input"
                        id="customCheck1"
                        name="keepMe"
                        checked={checked ? true : false}
                      />
                      <label className="custom-control-label" htmlFor="customCheck1"> I accept that these items will be monitored via software to ensure there are no additional parties, voices and browser windows present during examination. This will only be viewed by a moderator when/where issues were observed by the software.</label>
                      {/* {Str.acceptSoftMonitor} */}
                    </div>
                  </div>
                </div>
                : null
              )}

            </div>

            {/* <div className="test-series-main-heading mb-15">
              <h4>{Str.detailDes}:</h4>
            </div> */}
            <div className="textseries-content-blk desc-instruction-blk white-brd-blk mb-15">

              <h4>{Str.detailDes}:</h4>
              {props?.data?.details
                ? ReactHtmlParser(props.data.details)
                : Str.noInstruction}
            </div>
          </div>

          <div className="test-series-main-right-blk">

            <div className="step-sidebar-space">
              {/* <div class="btn-action-block text-center">
                <button
                  onClick={props.backBtn}
                  type="button"
                  class="btn btn-primary"
                >
                  <i class="fal fa-chevron-left"></i> Back to your Assessments{" "}
                </button>
              </div> */}
            </div>
            <div className="test-series-steps">
              <ul>
                <li className={`test-series-steps-item active ${(checked || enableNext) ? 'done' : ''}`}>
                  <a href="javascript:void(0);">
                    <span>1</span> <b>{Str.assDetail}</b>
                  </a>
                </li>
                {props.showSystemCheck ?
                  <li className="test-series-steps-item">
                    <a href="javascript:void(0);">
                      <span>2</span> {Str.sysCheck}
                    </a>
                  </li>
                  : null}
                {props.showIdentification ?
                  <li className="test-series-steps-item">
                    <a href="javascript:void(0);">
                      <span>{props.showSystemCheck ? 3 : 2}</span> {Str.Identification}
                    </a>
                  </li>
                  : null}
                <li className="test-series-steps-item">
                  <a href="javascript:void(0);">
                    <span>{(props.showSystemCheck && props.showIdentification) ? 4 : (props.showSystemCheck || props.showIdentification) ? 3 : 2}</span> {Str.launchExam}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div >
    </>
  );
}
export default Step1;
