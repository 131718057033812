import React, { useState, useEffect } from "react";
import $ from "jquery";
import AsyncSelect, { components } from "react-select/async";
import Header from "../components/common/Header";
import SideBar from "../components/common/SideBar";
import Avatar from "../assets/images/avtar.jpg";
import {
  getMyGroupsService,
  getMyGroupFeedService,
} from "../services/FeedService";
import FeedMessageForm from "../components/feed/FeedMessageForm";
import FeedComments from "../components/group_feed/FeedComments";
import GroupFeedMessage from "../components/group_feed/GroupFeedMessage";
import { useHistory, Link } from "react-router-dom";
import SocketConnection from "../utils/SocketConnection";
import ChatMessageForm from "../components/chat/ChatMessageForm";
import ChatGroupList from "../components/chat/ChatGroupList";
import FeedMessages from "../components/group_feed/FeedMessage";
import { useSelector, useDispatch } from "react-redux";
import { setChatGroups } from "../store/actions";
import {
  unSetSelectedGroupsRedux,
  setSelectedGroupsRedux,
} from "../store/actions";
import { toast } from "react-toastify";

let connection, typeEvent;

function MyChats({ location, match }) {
  const [inputValue, setValue] = useState("");
  const [selectedValue, setSelectedValue] = useState(null);

  const [mygroups, setMyGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [myGroup, setMyGroup] = useState(null);
  const [subscription, setSubscription] = useState(null);

  const [groupFeed, setGroupFeed] = useState(null);

  const history = useHistory();

  const dispatch = useDispatch();

  const chats = useSelector((state) => state.chat);
  const user = useSelector((state) => state.user);
  const selectedGroupRedux = useSelector((state) => state.selectedGroup);

  useEffect(() => {
    getMyGroupsService()
      .then((res) => {
        dispatch(setChatGroups(res.data));
        ///dispatch(setSelectedGroups(res.data));
        setMyGroups(res.data);
        setSelectedGroup(res.data[0]);
        let id = match.params.id
          ? match.params.id
          : res.data[0].group_encrypted_id;
        history.push(`/mychats/${id}`);
        setMyGroup(id);
        connection = SocketConnection.connect();
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    async function init() {
      if (match.params.id) {
        //Calling groups feed
        getMyGroupFeedService(match.params.id).then((res) => {
          setGroupFeed(res.data.grpMessage);
        });

        //if (subscription)  subscription.close();

        SocketConnection.subscribe(`group:${match.params.id}`, "test").then(
          async (res) => {
            await setSubscription(res);
            setTimeout(() => {
              // if (subscription) {
              res.on("message", (message) => {
                setGroupFeed((groupFeed) => [...groupFeed, message]);
                // toast.success(`Hey new comment pops up.`, {
                //   position: toast.POSITION.TOP_LEFT,
                // });

                //Updated ticket list
                getMyGroupsService()
                  .then((res) => {
                    dispatch(setChatGroups(res.data));
                    ///dispatch(setSelectedGroups(res.data));
                    setMyGroups(res.data);
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              });
              //}
            }, 1000);

            setMyGroup(match.params.id);

          }
        );
        //   console.log(subscriptionVar);
      }
    }
    init();
  }, [match.params.id]);

  const handleGroupClick = (group) => {
    dispatch(setSelectedGroupsRedux(group));
    setSelectedGroup(group);
    setMyGroup(group.group_encrypted_id);
    history.push(`/mychats/${group.group_encrypted_id}`);
  };

  const handleSideBar = () => {
    $("body").toggleClass("hide-subject-sidebar");
  };

  return (
    <>
      <Header></Header>
      <div className="content-wrapper d-flex flex-wrap">
        <SideBar></SideBar>
        <div className="sidebar-right">
          <div className="Chat-main-wrap">
            <div className="Chat-left-wrap bg-white">
              <button
                className="close-btn-sidebar"
                onClick={() => handleSideBar()}
              >
                <i className="close-bar close-bar-1"></i>
                <i className="close-bar close-bar-2"></i>
                <i className="close-bar close-bar-3"></i>
              </button>
              <div className="Chat-panel">
                <div className="Chat-panel-head d-flex justify-content-between align-items-center">
                  <h5 className="m-0">Chat</h5>

                  <Link to="/newchat">
                    <i className="fal fa-edit"></i>
                  </Link>
                </div>
                <ChatGroupList
                  mygroups={mygroups}
                  selectedGroup={selectedGroup}
                  handleGroupClick={handleGroupClick}
                  group_id={myGroup}
                />
              </div>
            </div>
            <div className="Chat-right-wrap">
              <div className="Chat-detail">
                <div className="Chat-detail-head d-flex">
                  <div className="Chat-head-lft">
                    <div className="icon">
                      <div className="icon-img">
                        <img src="images/user.jpg" />
                      </div>
                      <span className="portal-status-icon">
                        <i className="fas fa-clock"></i>
                      </span>
                    </div>
                  </div>
                  <div className="Chat-head-rgt">
                    {selectedGroup ? <h3>{selectedGroup.group_name}</h3> : null}
                  </div>
                </div>
                <div className="Chat-detail-body">
                  {groupFeed && groupFeed.length > 0 ? (
                    <GroupFeedMessage
                      feed={groupFeed}
                      user={user}
                      online={null}
                    />
                  ) : null}

                  {selectedGroup && subscription ? (
                    <ChatMessageForm
                      group={selectedGroup}
                      subscription={subscription}
                      setGroupFeed={setGroupFeed}
                      groupFeed={groupFeed}
                    />
                  ) : null}

                  {/* <FeedMessages
                    feeds={null}
                    websocket={null}
                    online={null}
                    taggingUsers={null}
                  /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MyChats;
