import React, { useState, useEffect } from "react";
import ExamSummeryList from "./ExamSummeryList";
import { getMyAllExam } from "../../services/ExamService";
import SkeletonExam from "../../loaders/SkeletonExam";

export default function ExamCompleted(props) {
  const [examListsRow, setExamListRow] = useState([]);
  const [loading, setLoading] = useState(true);

  const ExamCompleted = () => {
    getMyAllExam()
      .then((res) => {
        setExamListRow(res.data.examList);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <button
        onClick={() => ExamCompleted()}
        data-toggle="collapse"
        data-target="#completed-exam"
        aria-expanded="false"
        className="hide collapsed hide-completed hide-exam-completed"
      ></button>

      <div className="exam-completed-list collapse" id="completed-exam">
        {loading && <SkeletonExam />}
        {!loading && examListsRow && examListsRow.length ? (
          <ul className="exam-ul-list list-unstyled">
            {examListsRow.map((examDetails, index) => {
              if (examDetails.showCompleted) {
                return <ExamSummeryList key={index} exam={examDetails} />;
              } else {
                return null;
              }
            })}
          </ul>
        ) : null}
      </div>
    </>
  );
}
