import React, { Component } from 'react';
import PropTypes from 'prop-types';

class AssignmentPage extends Component {
    constructor(props) {
        super(props);
    }

    componentWillMount() {

    }

    componentDidMount() {

    }


    render() {
        return (
            <div>
                hello world
            </div>
        );
    }
}

AssignmentPage.propTypes = {

};

export default AssignmentPage;