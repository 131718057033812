import React, {
    useEffect,
    useState,
    useMemo,
    useRef,
    useCallback,
  } from "react";
  import { Link, useRouteMatch } from "react-router-dom";
  import { getRandomInt } from "../../utils/commonFunction.js";
  import { download } from "../../utils/FileDownload";
  import ReactHtmlParser from "react-html-parser";
  //import Popup from 'reactjs-popup';
  //import 'reactjs-popup/dist/index.css';
  import { Popup } from "semantic-ui-react";
  //import 'semantic-ui-css/semantic.min.css'
  //import ShowMoreText from 'react-show-more-text';
  
  import {
    GetSubjectClasses,
    JoinBBBClass,
    CampusClass,
    GetClassDetails,
    SaveRecordingAction,
    SaveFeedbackRating,
    GetClassFiles,
    GetSubjectClassesPageWise,
  } from "../../services/ClassService";
  
  import Swal from "sweetalert2";
  import $ from "jquery";
  import moment from "moment-timezone";
  import "../../assets/js/rating.js";
  import Pagination from "react-js-pagination";
  // moment.tz.setDefault("Africa/Johannesburg");
  $(document).on("click", ".class-list-row", function () {
    setTimeout(() => {
      $("input.rating[type=number], div.rating").each(function () {
        $(this).rating();
      });
    }, 100);
  });
  const CalendarListView = ({
    subjectId,
    status,
    startDateListView,
    endDateListView,
  }) => {
    let sD = moment().startOf("week");
    let eD = moment().endOf("week");
  
    const [showMore, setShowMore] = useState(false);
    const [popupUpdate, setPopupUpdate] = useState(false);
    const [activePage, setActivePage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [classes, setClasses] = useState([]);
    const [classesKey, setClassesKey] = useState([]);
    const handlePageChange = (pageNumber) => {
      if (pageNumber) {
        setActivePage(pageNumber);
        GetSubjectClassesPageWise({
          subjectId,
          pageNumber,
          itemsPerPage,
          status,
          startDateListView,
          endDateListView,
        })
          .then((res) => {
            setClasses(Object.values(res.data.classes));
            setClassesKey(Object.keys(res.data.classes));
            setTotalItems(res.data.length);
          })
          .catch((error) => {});
      }
    };
    useEffect(() => {
      let pageNumber = 1;
      GetSubjectClassesPageWise({
        subjectId,
        pageNumber,
        itemsPerPage,
        status,
        startDateListView,
        endDateListView,
      })
        .then((res) => {
          setClasses(Object.values(res.data.classes));
          setClassesKey(Object.keys(res.data.classes));
          setTotalItems(res.data.length);
        })
        .catch((error) => {});
    }, [subjectId, status, startDateListView, popupUpdate]);
  
    const joinVirtualClassGlobal = (id) => {
      JoinBBBClass({ id })
        .then((res) => {
          if (res.data.error == 0) {
            Swal.close();
            Swal.fire({
              icon: "success",
              title: res.data.title,
              text: res.message,
              showConfirmButton: true,
            }).then((result) => {
              if (result.isConfirmed) {
                window.open(res.data.url, "_blank");
                let item = res.data.class;
                var foundIndex = classes.findIndex((x) => x.id == item.id);
                classes[foundIndex] = item;
                setPopupUpdate(id + getRandomInt(1));
              }
            });
          } else {
            Swal.close();
            Swal.fire({
              icon: "error",
              title: res.data.title,
              text: res.data.message,
              showConfirmButton: true,
            });
          }
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Unknown Error.",
          }).then((result) => {});
        });
    };
  
    const joinCampusClassGlobal = (id) => {
      let action = "enter";
      CampusClass({ id, action })
        .then((res) => {
          if (res.data.error == 0) {
            Swal.close();
            Swal.fire({
              icon: "success",
              title: res.data.title,
              text: res.data.message,
              showConfirmButton: true,
            }).then((result) => {
              if (result.isConfirmed) {
                let item = res.data.class;
                var foundIndex = classes.findIndex((x) => x.id == item.id);
                classes[foundIndex] = item;
                setPopupUpdate(id + getRandomInt(2));
              }
            });
          } else {
            Swal.close();
            Swal.fire({
              icon: "error",
              title: res.data.title,
              text: res.data.message,
              showConfirmButton: true,
            });
          }
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Unknown Error.",
          }).then((result) => {});
        });
    };
  
    const exitCampusClassGlobal = (id) => {
      let action = "exit";
      CampusClass({ id, action })
        .then((res) => {
          if (res.data.error == 0) {
            Swal.close();
            Swal.fire({
              icon: "success",
              title: res.data.title,
              text: res.data.message,
              showConfirmButton: true,
            }).then((result) => {
              if (result.isConfirmed) {
                let item = res.data.class;
                var foundIndex = classes.findIndex((x) => x.id == item.id);
                classes[foundIndex] = item;
                setPopupUpdate(id + getRandomInt(3));
              }
            });
          } else {
            Swal.close();
            Swal.fire({
              icon: "error",
              title: res.data.title,
              text: res.data.message,
              showConfirmButton: true,
            });
          }
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Unknown Error.",
          }).then((result) => {});
        });
    };
  
    const submitRatingGlobal = (id) => {
      let student_rating = $("#class_rating_" + id).val();
      let student_comment = $("#class_comment_" + id).val();
      SaveFeedbackRating({ student_rating, student_comment, id })
        .then((res) => {
          if (res.data.error == 0) {
            Swal.close();
            Swal.fire({
              icon: "success",
              title: res.data.title,
              text: res.data.message,
              showConfirmButton: true,
            }).then((result) => {
              if (result.isConfirmed) {
                let item = res.data.class;
                var foundIndex = classes.findIndex((x) => x.id == item.id);
                classes[foundIndex] = item;
                setPopupUpdate(id + getRandomInt(4));
              }
            });
          } else {
            Swal.close();
            Swal.fire({
              icon: "error",
              title: res.data.title,
              text: res.data.message,
              showConfirmButton: true,
            });
          }
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Unknown Error.",
          }).then((result) => {});
        });
    };
  
    const getTextContentLength = (text, charLen = 100) => {
      text = removeTagsFromStr(text);
      // For Text that is shorter than desired length
      if (text.length <= charLen) return text;
  
      // If text is longer than desired length & showMore is true
      if (text.length > charLen && showMore) {
        return (
          <>
            <p>
              {text}&nbsp;
              <a
                href
                className="show-cls-custom"
                onClick={() => setShowMore(false)}
              >
                Show less
              </a>
            </p>
          </>
        );
      }
      // If text is longer than desired length & showMore is false
      if (text.length > charLen) {
        return (
          <>
            <p>
              {text.slice(0, charLen)}&nbsp;
              <a
                href
                className="show-cls-custom"
                onClick={() => setShowMore(true)}
              >
                Show more
              </a>
            </p>
          </>
        );
      }
    };
  
    const removeTagsFromStr = (str) => {
      str = str?.replace(/<[^>]+>/g, " ");
      str = str?.replace(/&amp;/g, "&");
      if (str === null || str === undefined ||str === "") return false;
      else str = str.toString();
  
      return str.replace(/(<([^>]+)>)/gi, "");
    };
    useEffect(() => {
      $(document).ready(function(){
        $(document).off('click', '.join-virtual-classes-list').on('click', '.join-virtual-classes-list', function(e){
          e.preventDefault();
          var id = $(this).attr('data-id');
          if(id){
            joinVirtualClassGlobal(id);
          }
        });
        $(document).off('click', '.join-campus-classes-list').on('click', '.join-campus-classes-list', function(e){
          e.preventDefault();
          var id = $(this).attr('data-id');
          if(id){
            joinCampusClassGlobal(id);
          }
        });
        $(document).off('click', '.exit-campus-classes-list').on('click', '.exit-campus-classes-list', function(e){
          e.preventDefault();
          var id = $(this).attr('data-id');
          if(id){
            exitCampusClassGlobal(id);
          }
        });
        $(document).off('click', '.submit-rating-global-list').on('click', '.submit-rating-global-list', function(e){
          e.preventDefault();
          var id = $(this).attr('data-id');
          if(id){
            submitRatingGlobal(id);
          }
        });
      });
    },[])
  
    return (
      <>
        <div className="Class-Shedule-List-View">
          <div className="table-responsive">
            <table className="table Class-Shedule-main-table">
              <tbody>
                {classesKey &&
                  classesKey.map((item, index1) => {
                    let res = item.split(",");
                    let day = res[0];
                    let date = res[1];
                    return (
                      <tr key={item + index1}>
                        <td>
                          <table className="table table-bordered">
                            <thead className="thead-dark">
                              <tr>
                                <th colSpan="2">
                                  {day}, {date}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {classes[index1] &&
                                classes[index1].map((row, index11) => {
                                  let currentTime = moment().unix();
                                  let sst = moment(row.class_from, [
                                    "h:mm A",
                                  ]).format("HH:mm:ss");
                                  let eet = moment(row.class_to, [
                                    "h:mm A",
                                  ]).format("HH:mm:ss");
                                  let st = moment(row.class_date + " " + sst);
                                  let et = moment(row.class_date + " " + eet);
                                  let start_time = st.unix();
                                  let end_time = et.unix();
                                  let start_time_live = moment(st)
                                    .subtract(15, "minutes")
                                    .unix();
                                  let returnValue;
                                  let returnValue1;
                                  let star = "";
                                  let notStar = "";
                                  let remainingRating = 5 - row.student_rating;
                                  for (
                                    let index = 1;
                                    index <= row.student_rating;
                                    index++
                                  ) {
                                    star += `<i className="fas fa-star" key={row.id}></i>`;
                                  }
                                  for (
                                    let index = 1;
                                    index <= remainingRating;
                                    index++
                                  ) {
                                    notStar += `<i className="fal fa-star" key={row.id}></i>`;
                                  }
                                  row.student_rating > 0
                                    ? (returnValue = (
                                        <div
                                          className="col-12 meeting-rating-start-col m-0"
                                          key={row.id}
                                        >
                                          <div className="meeting-rating-start d-flex ">
                                            <span className="stars">
                                              {ReactHtmlParser(star)}
                                              {ReactHtmlParser(notStar)}
                                            </span>
                                          </div>
                                        </div>
                                      ))
                                    : (returnValue = (
                                        <div
                                          className="col-12 meeting-rating-start-col"
                                          key={row.id}
                                        >
                                          <div className="meeting-rating-start d-flex">
                                            <input
                                              type="number"
                                              className="rating"
                                              id={`class_rating_${row.id}`}
                                            />
                                            <span className="ml-2">
                                              How Was Your Class ?
                                            </span>
                                          </div>
                                        </div>
                                      ));
                                  row.student_rating > 0
                                    ? (returnValue1 = (
                                        <div
                                          className="col-12 meeting-leave-comment-col m-0"
                                          key={row.id}
                                        >
                                          <span>
                                            {" "}
                                            {row.student_comment?.trim()}
                                          </span>{" "}
                                        </div>
                                      ))
                                    : (returnValue1 = (
                                        <div
                                          className="col-12 meeting-leave-comment-col"
                                          key={row.id}
                                        >
                                          <div className="meeting-leave-comment">
                                            <textarea
                                              rows="2"
                                              className="form-control"
                                              placeholder="leave a comment"
                                              id={`class_comment_${row.id}`}
                                            ></textarea>
                                            <a
                                              className="btn btn-primary submit-rating-global-list"
                                              data-id={`${row.id}`}
                                            >
                                              Submit Feedback
                                            </a>
                                          </div>
                                        </div>
                                      ));
                                  return row.canceled == 1 ? (
                                    <Popup
                                      on="click"
                                      hideOnScroll
                                      flowing
                                      positionFixed
                                      trigger={
                                        <tr
                                          className="class-list-row class-canceled-row"
                                          key={row.id + "" + index11}
                                        >
                                          <td>
                                            {row.class_from}-{row.class_to}
                                          </td>
                                          <td>
                                            {row.class_type == "hybrid" ? (
                                              <h6 className="meeting-title">
                                                {row.class_title}
                                                <i className="fal fa-house-signal"></i>
                                                <i class="fal fa-university"></i>{" "}
                                              </h6>
                                            ) : row.class_type == "on_campus" ? (
                                              <h6 className="meeting-title">
                                                {row.class_title}
                                                <i className="fal fa-university"></i>{" "}
                                              </h6>
                                            ) : (
                                              <h6 className="meeting-title">
                                                {row.class_title}
                                                <i className="fal fa-house-signal"></i>{" "}
                                              </h6>
                                            )}
                                          </td>
                                        </tr>
                                      }
                                    >
                                      <Popup.Content>
                                        <div className="class-list-view-poup-inr">
                                          <div className="meeting-detail-box canceled-meeting-detail-box">
                                            <h6>Class has been cancelled</h6>
                                            <div className="row">
                                              {row.recordings != "" &&
                                              row.recordings != null ? (
                                                <div className="col-12 meeting-btns-col">
                                                  <hr />
                                                  <div className="meeting-btns">
                                                    <ul className="meeting-btns-list">
                                                      {ReactHtmlParser(
                                                        row.recordings
                                                      )}
                                                    </ul>
                                                  </div>
                                                </div>
                                              ) : (
                                                ``
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </Popup.Content>
                                    </Popup>
                                  ) : end_time < currentTime ? (
                                    //Past Classes
                                    <Popup
                                      on="click"
                                      hideOnScroll
                                      flowing
                                      positionFixed
                                      trigger={
                                        <tr
                                          className="class-list-row class-past-row"
                                          key={row.id + "" + index11}
                                        >
                                          <td>
                                            {row.class_from}-{row.class_to}
                                          </td>
                                          <td>
                                            {row.class_type == "hybrid" ? (
                                              <h6 className="meeting-title">
                                                {row.class_title}
                                                <i className="fal fa-house-signal"></i>
                                                <i className="fal fa-university"></i>{" "}
                                              </h6>
                                            ) : row.class_type == "on_campus" ? (
                                              <h6 className="meeting-title">
                                                {row.class_title}
                                                <i className="fal fa-university"></i>{" "}
                                              </h6>
                                            ) : (
                                              <h6 className="meeting-title">
                                                {row.class_title}
                                                <i className="fal fa-house-signal"></i>{" "}
                                              </h6>
                                            )}
                                          </td>
                                        </tr>
                                      }
                                    >
                                      <Popup.Content>
                                        <div className="class-list-view-poup-inr">
                                          <div className="meeting-detail-box past-meeting-detail-box">
                                            {row.class_type == "hybrid" ? (
                                              <label className="label label-orange">
                                                <i className="fal fa-university"></i>{" "}
                                                <i className="fal fa-plus"></i>{" "}
                                                <i className="fal fa-house-signal"></i>{" "}
                                                Hybrid{" "}
                                              </label>
                                            ) : row.class_type == "on_campus" ? (
                                              <label className="label label-purple">
                                                <i className="fal fa-university"></i>{" "}
                                                On Campus{" "}
                                              </label>
                                            ) : (
                                              <label className="label label-green">
                                                <i className="fal fa-house-signal"></i>{" "}
                                                Virtual{" "}
                                              </label>
                                            )}
  
                                            <h6>{row.class_title}</h6>
                                            <ul className="fa-ul meeting-detail-list">
                                              <li>
                                                <i className="fal fa-li fa-calendar-alt"></i>
                                                {row.showDate}, {row.class_from} -{" "}
                                                {row.class_to}
                                              </li>
                                              {ReactHtmlParser(
                                                row.campus_name_room
                                              )}
                                            </ul>
                                            <hr />
                                            <div className="row">
                                              {row.attend == 1 ? (
                                                <div className="col-12 meeting-info-status-col">
                                                  <span className="green-color">
                                                    Present{" "}
                                                    {row.join_type == "campus"
                                                      ? "(on Campus) "
                                                      : "(Online) "}
                                                  </span>
                                                  <span>
                                                    {row.duration} (
                                                    {row.percentage}%)
                                                  </span>
                                                  <span>
                                                    I: {row.student_time_in} O:{" "}
                                                    {row.student_time_out}
                                                  </span>
                                                </div>
                                              ) : (
                                                <div className="col-12 meeting-info-status-col">
                                                  <span className="red-color">
                                                    Absent{" "}
                                                  </span>
                                                  <span>
                                                    {row.duration} (
                                                    {row.percentage}%)
                                                  </span>
                                                  <span>I: 0 O: 0</span>
                                                </div>
                                              )}
                                              {row.attend == 1 ? returnValue : ""}
                                              {row.attend == 1
                                                ? returnValue1
                                                : ""}
                                              {row.recordings != "" &&
                                              row.recordings != null ? (
                                                <div className="col-12 meeting-btns-col">
                                                  <hr />
                                                  <div className="meeting-btns">
                                                    <ul className="meeting-btns-list">
                                                      {ReactHtmlParser(
                                                        row.recordings
                                                      )}
                                                    </ul>
                                                  </div>
                                                </div>
                                              ) : (
                                                ``
                                              )}
                                            </div>
                                            {row.class_description !== "" ? (
                                              <div className="meeting-desc">
                                                <hr />
                                                {getTextContentLength(
                                                  row.class_description?.trim(),
                                                  210
                                                )}
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                            {row.Firstname !== null ? (
                                              <div className="meeting-instructor">
                                                <hr />
                                                <p className="instructor-name">
                                                  <i className="fal fa-chalkboard-teacher"></i>
                                                  {row.Firstname} {row.Lastname}
                                                </p>
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </div>
                                      </Popup.Content>
                                    </Popup>
                                  ) : start_time_live <= currentTime &&
                                  end_time >= currentTime ? (
                                    //Live Classes
                                    <Popup
                                      style={{ zIndex: 1 }}
                                      key={row.id + "" + index11}
                                      on="click"
                                      hideOnScroll
                                      flowing
                                      positionFixed
                                      trigger={
                                        <tr className="class-list-row class-live-row">
                                          <td>
                                            {row.class_from}-{row.class_to}
                                          </td>
                                          <td>
                                            {row.class_type == "hybrid" ? (
                                              <h6 className="meeting-title">
                                                {row.class_title}
                                                <i className="fal fa-house-signal"></i>
                                                <i className="fal fa-university"></i>{" "}
                                              </h6>
                                            ) : row.class_type == "on_campus" ? (
                                              <h6 className="meeting-title">
                                                {row.class_title}
                                                <i className="fal fa-university"></i>{" "}
                                              </h6>
                                            ) : (
                                              <h6 className="meeting-title">
                                                {row.class_title}
                                                <i className="fal fa-house-signal"></i>{" "}
                                              </h6>
                                            )}
                                            <div className="btn-join-title-box">
                                              <button title="Join" className="btn btn-success">
                                                <i className="fal fa-wifi"></i>
                                                Join
                                              </button>
                                            </div>
                                          </td>
                                        </tr>
                                      }
                                    >
                                      <Popup.Content>
                                        <div className="class-list-view-poup-inr">
                                          <div className="meeting-detail-box live-meeting-detail-box">
                                            {row.class_type == "hybrid" ? (
                                              <label className="label label-orange">
                                                <i className="fal fa-university"></i>{" "}
                                                <i className="fal fa-plus"></i>{" "}
                                                <i className="fal fa-house-signal"></i>{" "}
                                                Hybrid{" "}
                                              </label>
                                            ) : row.class_type == "on_campus" ? (
                                              <label className="label label-purple">
                                                <i className="fal fa-university"></i>{" "}
                                                On Campus{" "}
                                              </label>
                                            ) : (
                                              <label className="label label-green">
                                                <i className="fal fa-house-signal"></i>{" "}
                                                Virtual{" "}
                                              </label>
                                            )}
  
                                            <h6>{row.class_title}</h6>
                                            <ul className="fa-ul meeting-detail-list">
                                              <li>
                                                <i className="fal fa-li fa-calendar-alt"></i>
                                                {row.showDate}, {row.class_from} -{" "}
                                                {row.class_to}
                                              </li>
                                              {ReactHtmlParser(
                                                row.campus_name_room
                                              )}
                                            </ul>
                                            <hr />
  
                                            {row.class_type == "hybrid" ? (
                                              <div className="btn-square">
                                                {row.disable_online == "" ? (
                                                  <button
                                                    data-id={`${row.id}`}
                                                    disable={(row?.nestedClassStaus === "Live" && row?.nestedClassSubStaus === "Launched") ? false : true}
                                                    title="Join the class virtually if you are not on campus."
                                                    className={`btn btn-primary join-virtual-class join-virtual-classes-list`}
                                                  >
                                                    <i className="fal fa-house-signal"></i>
                                                    <span className="btn-text">
                                                      Join Virtual Class
                                                    </span>
                                                  </button>
                                                ) : (
                                                  <button
                                                    data-id={`${row.id}`}
                                                    title="Join the class virtually if you are not on campus."
                                                    disabled
                                                    className={`btn btn-primary join-virtual-class join-virtual-classes-list disabled`}
                                                  >
                                                    <i className="fal fa-house-signal"></i>
                                                    <span className="btn-text">
                                                      Join Virtual Class
                                                    </span>
                                                  </button>
                                                )}
  
                                                {row.disable_campus != "" &&
                                                row.hide_checkin != "" ? (
                                                  <button
                                                    data-id={`${row.id}`}
                                                    title="Check in here if you are attending the class on campus."
                                                    disabled
                                                    style={{ display: "none" }}
                                                    className={`btn btn-primary join-campus-class join-campus-classes-list disabled`}
                                                  >
                                                    <i className="fal fa-university"></i>
                                                    <span className="btn-text">
                                                      Check In On Campus
                                                    </span>
                                                  </button>
                                                ) : row.disable_campus != "" &&
                                                  row.hide_checkin == "" ? (
                                                  <button
                                                    data-id={`${row.id}`}
                                                    title="Check in here if you are attending the class on campus."
                                                    disabled
                                                    className={`btn btn-primary join-campus-class join-campus-classes-list disabled`}
                                                  >
                                                    <i className="fal fa-university"></i>
                                                    <span className="btn-text">
                                                      Check In On Campus
                                                    </span>
                                                  </button>
                                                ) : row.disable_campus == "" &&
                                                  row.hide_checkin != "" ? (
                                                  <button
                                                    data-id={`${row.id}`}
                                                    title="Check in here if you are attending the class on campus."
                                                    style={{ display: "none" }}
                                                    className={`btn btn-primary join-campus-class join-campus-classes-list`}
                                                  >
                                                    <i className="fal fa-university"></i>
                                                    <span className="btn-text">
                                                      Check In On Campus
                                                    </span>
                                                  </button>
                                                ) : (
                                                  <button
                                                    disable={(row?.nestedClassStaus === "Live" && row?.nestedClassSubStaus === "Launched") ? false : true}
                                                    data-id={`${row.id}`}
                                                    title="Check in here if you are attending the class on campus."
                                                    className={`btn btn-primary join-campus-class join-campus-classes-list`}
                                                  >
                                                    <i className="fal fa-university"></i>
                                                    <span className="btn-text">
                                                      Check In On Campus
                                                    </span>
                                                  </button>
                                                )}
  
                                                {row.hide_checkout == "" ? (
                                                  <button
                                                    data-id={`${row.id}`}
                                                    title="On Campus Check Out"
                                                    className="btn exit-campus-class exit-campus-classes-list"
                                                  >
                                                    <i
                                                      class="fal fa-window-close"
                                                      aria-hidden="true"
                                                    ></i>
                                                    <span className="btn-text">
                                                      On Campus Check Out
                                                    </span>
                                                  </button>
                                                ) : (
                                                  <button
                                                    data-id={`${row.id}`}
                                                    title="On Campus Check Out"
                                                    style={{ display: "none" }}
                                                    className="btn exit-campus-class exit-campus-classes-list"
                                                  >
                                                    <i
                                                      className="fal fa-window-close"
                                                      aria-hidden="true"
                                                    ></i>
                                                    <span className="btn-text">
                                                      On Campus Check Out
                                                    </span>
                                                  </button>
                                                )}
                                              </div>
                                            ) : row.class_type == "on_campus" ? (
                                              <div className="btn-square">
                                                {row.hide_checkin == "" ? (
                                                  <button
                                                    disable={(row?.nestedClassStaus === "Live" && row?.nestedClassSubStaus === "Launched") ? false : true}
                                                    data-id={`${row.id}`}
                                                    title="Check in here if you are attending the class on campus."
                                                    class="btn btn-primary join-campus-class join-campus-classes-list"
                                                  >
                                                    <i className="fal fa-university"></i>
                                                    <span className="btn-text">
                                                      Check In On Campus
                                                    </span>
                                                  </button>
                                                ) : (
                                                  <button
                                                    data-id={`${row.id}`}
                                                    title="Check in here if you are attending the class on campus."
                                                    style={{ display: "none" }}
                                                    className="btn btn-primary join-campus-class join-campus-classes-list"
                                                  >
                                                    <i className="fal fa-university"></i>
                                                    <span className="btn-text">
                                                      Check In On Campus
                                                    </span>
                                                  </button>
                                                )}
  
                                                {row.hide_checkout == "" ? (
                                                  <button
                                                    data-id={`${row.id}`}
                                                    title="On Campus Check Out"
                                                    className="btn exit-campus-class exit-campus-classes-list"
                                                  >
                                                    <i
                                                      className="fal fa-window-close"
                                                      aria-hidden="true"
                                                    ></i>
                                                    <span className="btn-text">
                                                      On Campus Check Out
                                                    </span>
                                                  </button>
                                                ) : (
                                                  <button
                                                    data-id={`${row.id}`}
                                                    title="On Campus Check Out"
                                                    className="btn exit-campus-class exit-campus-classes-list"
                                                    style={{ display: "none" }}
                                                  >
                                                    <i
                                                      className="fal fa-window-close"
                                                      aria-hidden="true"
                                                    ></i>
                                                    <span className="btn-text">
                                                      On Campus Check Out
                                                    </span>
                                                  </button>
                                                )}
                                              </div>
                                            ) : (
                                              <div className="btn-square">
                                                <button
                                                  disable={(row?.nestedClassStaus === "Live" && row?.nestedClassSubStaus === "Launched") ? false : true}
                                                  onClick={() =>
                                                    joinVirtualClassGlobal(
                                                      `${row.id}`
                                                    )
                                                  }
                                                  title="Join the class virtually if you are not on campus."
                                                  className="btn btn-primary join-virtual-class join-virtual-classes-list "
                                                >
                                                  <i className="fal fa-house-signal"></i>
                                                  <span className="btn-text">
                                                    Join Virtual Class
                                                  </span>
                                                </button>
                                              </div>
                                            )}
  
                                            {row.class_description !== "" ? (
                                              <div className="meeting-desc">
                                                <hr />
                                                {/* <ShowMoreText
                                                    lines={4}
                                                    more='Show more'
                                                    less='Show less'
                                                    className='content-css'
                                                    anchorClass='my-anchor-css-class'
                                                    //onClick={this.executeOnClick}
                                                    expanded={false}
                                                    width={280}
                                                >
                                                  {ReactHtmlParser(row.class_description.trim())}
                                                </ShowMoreText>*/}
  
                                                {getTextContentLength(
                                                  row.class_description?.trim(),
                                                  210
                                                )}
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                            {row.Firstname !== null ? (
                                              <div className="meeting-instructor">
                                                <hr />
                                                <p className="instructor-name">
                                                  <i className="fal fa-chalkboard-teacher"></i>
                                                  {row.Firstname} {row.Lastname}
                                                </p>
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </div>
                                      </Popup.Content>
                                    </Popup>
                                  ) : (
                                    //Upcoming Classes
                                    <Popup
                                      on="click"
                                      hideOnScroll
                                      flowing
                                      positionFixed
                                      trigger={
                                        <tr
                                          class="class-list-row class-upcoming-row"
                                          key={row.id + "" + index11}
                                        >
                                          <td>
                                            {row.class_from}-{row.class_to}
                                          </td>
                                          <td>
                                            {row.class_type == "hybrid" ? (
                                              <h6 className="meeting-title">
                                                {row.class_title}
                                                <i className="fal fa-house-signal"></i>
                                                <i className="fal fa-university"></i>{" "}
                                              </h6>
                                            ) : row.class_type == "on_campus" ? (
                                              <h6 className="meeting-title">
                                                {row.class_title}
                                                <i className="fal fa-university"></i>{" "}
                                              </h6>
                                            ) : (
                                              <h6 className="meeting-title">
                                                {row.class_title}
                                                <i className="fal fa-house-signal"></i>{" "}
                                              </h6>
                                            )}
                                          </td>
                                        </tr>
                                      }
                                    >
                                      <Popup.Content>
                                        <div className="class-list-view-poup-inr">
                                          <div className="meeting-detail-box upcoming-meeting-detail-box">
                                            {row.class_type == "hybrid" ? (
                                              <label className="label label-orange">
                                                <i className="fal fa-university"></i>{" "}
                                                <i className="fal fa-plus"></i>{" "}
                                                <i className="fal fa-house-signal"></i>{" "}
                                                Hybrid{" "}
                                              </label>
                                            ) : row.class_type == "on_campus" ? (
                                              <label className="label label-purple">
                                                <i className="fal fa-university"></i>{" "}
                                                On Campus{" "}
                                              </label>
                                            ) : (
                                              <label className="label label-green">
                                                <i className="fal fa-house-signal"></i>{" "}
                                                Virtual{" "}
                                              </label>
                                            )}
  
                                            <h6>{row.class_title}</h6>
                                            <ul className="fa-ul meeting-detail-list">
                                              <li>
                                                <i className="fal fa-li fa-calendar-alt"></i>
                                                {row.showDate}, {row.class_from} -{" "}
                                                {row.class_to}
                                              </li>
                                              {ReactHtmlParser(
                                                row.campus_name_room
                                              )}
                                            </ul>
                                            <hr />
  
                                            {row.class_type == "hybrid" ? (
                                              <div className="btn-square">
                                                  <button
                                                    data-id={`${row.id}`}
                                                    title="Join the class virtually if you are not on campus."
                                                    className={`btn btn-primary join-virtual-class join-virtual-classes-list`}
                                                    disabled
                                                  >
                                                    <i className="fal fa-house-signal"></i>
                                                    <span className="btn-text">
                                                      Join Virtual Class
                                                    </span>
                                                  </button>
                                                  <button
                                                    data-id={`${row.id}`}
                                                    title="Check in here if you are attending the class on campus."
                                                    disabled
                                                    className={`btn btn-primary join-campus-class join-campus-classes-list`}
                                                  >
                                                    <i className="fal fa-university"></i>
                                                    <span className="btn-text">
                                                      Check In On Campus
                                                    </span>
                                                  </button>
                                              </div>
                                            ) : row.class_type == "on_campus" ? (
                                              <div className="btn-square">
                                                 <button
                                                  data-id={ `${row.id}`}
                                                    disabled
                                                    title="Check in here if you are attending the class on campus."
                                                    class="btn btn-primary join-campus-class join-campus-classes-list"
                                                  >
                                                    <i className="fal fa-university"></i>
                                                    <span className="btn-text">
                                                      Check In On Campus
                                                    </span>
                                                  </button>
                                              </div>
                                            ) : (
                                              <div className="btn-square">
                                                <button
                                                  onClick={() =>
                                                    joinVirtualClassGlobal(
                                                      `${row.id}`
                                                    )
                                                  }
                                                  disabled
                                                  title="Join the class virtually if you are not on campus."
                                                  className="btn btn-primary join-virtual-class join-virtual-classes-list "
                                                >
                                                  <i className="fal fa-house-signal"></i>
                                                  <span className="btn-text">
                                                    Join Virtual Class
                                                  </span>
                                                </button>
                                              </div>
                                            )}
  
                                            {row.class_description !== "" ? (
                                              <div className="meeting-desc">
                                                <hr />
                                                {/* <ShowMoreText
                                                    lines={4}
                                                    more='Show more'
                                                    less='Show less'
                                                    className='content-css'
                                                    anchorClass='my-anchor-css-class'
                                                    //onClick={this.executeOnClick}
                                                    expanded={false}
                                                    width={280}
                                                >
                                                  {ReactHtmlParser(row.class_description.trim())}
                                                </ShowMoreText>*/}
  
                                                {getTextContentLength(
                                                  row.class_description?.trim(),
                                                  210
                                                )}
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                            {row.Firstname !== null ? (
                                              <div className="meeting-instructor">
                                                <hr />
                                                <p className="instructor-name">
                                                  <i className="fal fa-chalkboard-teacher"></i>
                                                  {row.Firstname} {row.Lastname}
                                                </p>
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </div>
                                      </Popup.Content>
                                    </Popup>
                                  );
                                })}
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    );
                  })}
                {classesKey.length == 0 && (
                  <tr>
                    <td>
                      <table className="table table-bordered">
                        <thead className="thead-dark">
                          <tr>
                            <th>No classes found.</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="class-list-row">
                            <td>
                              We could not find any classes. Please confirm with
                              your Support Team or try again later.
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        {(status == "past" || status == "upcoming") && classesKey.length > 0 ? (
          <div className="class-list-view-pagination mt-3">
            <Pagination
              activePage={activePage}
              itemsCountPerPage={itemsPerPage}
              totalItemsCount={totalItems}
              pageRangeDisplayed={5}
              onChange={(pageNumber) => handlePageChange(pageNumber)}
              itemClass="page-item"
              linkClass="page-link"
            />
          </div>
        ) : (
          ""
        )}
      </>
    );
  };
  export default CalendarListView;
  