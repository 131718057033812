const initialState = {
    chatnotificationcount: 0
  };
  
  const chatNotificationReducer = (state = initialState, action) => {
    switch (action.type) {
      case "SET_CHAT_NOTIFICATION_COUNT":
        state.chatnotificationcount = action.payload;
        return {
          chatnotificationcount: state.chatnotificationcount
        };
      case "SET_UNREAD_CHAT_NOTIFICATION_COUNT":
        state.chatnotificationcount = action.payload;
        return {
          chatnotificationcount: state.chatnotificationcount
        };
      case "UNSET_UNREAD_CHAT_NOTIFICATION_COUNT":
        return {
          chatnotificationcount: 0
        };
      case "SET_SELECTED_CHAT_NOTIFICATION":
        state.selectednotification[action.payload[0]] = action.payload[0];
        return {
          chatnotificationcount: state.chatnotificationcount
        };
      default:
        return state;
    }
  };
  
  export default chatNotificationReducer;
  