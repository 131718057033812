import React, { useEffect, useState, createRef, useRef } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw, convertToRaw, ContentState, Modifier, Entity, CompositeDecorator, AtomicBlockUtils, convertFromHTML, SelectionState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import $ from "jquery";
import { FilePond, File, registerPlugin, setOptions } from "react-filepond";
import "filepond/dist/filepond.min.css";
import { API_BASE } from "../../utils/Constants";
import { getToken } from "../../utils/Auth";
import draftToHtml from "draftjs-to-html";
import HtmlParser from "react-html-parser";
import { ShowMoreHide } from "./ShowMoreHide";
import embed from "embed-video";
import htmlToDraft from 'html-to-draftjs';
import ami_logo from '../../assets/images/ami-logo-icon.svg'

function HtmlInputEditorShow({
    // editorState, setEditorState, 
    readOnly, data, isAttachment, setCurrentVideo, showHidePostEdit, allData, isImportant, setIsImportant, sendClick, userData, isMention }) {
    const [users, setUsers] = useState('');
    const [showMore, setShowMore] = useState(false);
    const [showLess, setShowLess] = useState(null);
    const [videoArr, setVideoArr] = useState([]);
    const [editable, setEditable] = useState(false);
    const [sendActive, setSendActive] = useState(false);
    const [files, setFiles] = useState([]);
    const [showFilePond, setShowFilePond] = useState(false);
    const [removeFile, setRemoveFile] = useState(null);
    const [attachments, setAttachments] = useState([]);
    const [sizes, setSizes] = useState([]);
    const [subjectLine, setSubjectLine] = useState('');
    const [inputError, setInputError] = useState('');
    const isInvalid = false;

    const btnAttachment = createRef();
    const filePondRef = createRef();
    const editorReference = useRef();
    const editorRefInput = useRef();

    const HandleSpan = (props) => {
        return (
            <span style={styles.handle} data-offset-key={props.offsetKey}>
                {props.children}
            </span>
        );
    };

    function handleStrategy(contentBlock, callback, contentState) {
        findWithRegex(HANDLE_REGEX, contentBlock, callback);
    }

    const compositeDecorator = new CompositeDecorator([{ strategy: handleStrategy, component: HandleSpan }]);
    const [editorState, setEditorState] = React.useState(() => EditorState.createEmpty(compositeDecorator));
    const onEditorStateChange = (editorState) => setEditorState(editorState);

    useEffect(() => {
        if (showHidePostEdit == allData.id) {
            // console.log('the ids for comment is ', showHidePostEdit, allData.id)
            setEditable(true)
            setShowMore(false)
            setShowLess(false)
            editorReference.current.focusEditor();
        }
        else {
            setEditable(false)
        }
    }, [showHidePostEdit])

    useEffect(() => {
        let isBlock = isJsonString(data)
        if (isBlock) {
            let entitymap = JSON.parse(data).entityMap
            let embedArr = []
            let otherEntityArr = []
            let finalData = JSON.parse(data)
            Object.values(entitymap).map((entity, index) => {
                if (entity.type === 'EMBEDDED_LINK') {
                    let url = (entity.data.src).substring(2, (entity.data.src).length)
                    let thumbId = ''
                    let thumbImg = ''
                    if ((url.substring(0, 7) == 'youtube') || (url.substring(0, 7) == 'www.you')) {
                        thumbId = youtube_parser(url)
                        thumbImg = `https://img.youtube.com/vi/${thumbId}/sddefault.jpg`
                    }
                    else if ((url.substring(0, 7) == 'player.') || (url.substring(0, 7) == 'www.pla')) {
                        thumbId = vimeo_parser(url)
                        thumbImg = `https://vumbnail.com/${thumbId}.jpg`
                    }
                    embedArr.push({ src: (entity.data.src).substring(2, (entity.data.src).length), thumbnail: thumbImg });
                    finalData.entityMap[index] = {}
                }
                else {
                    // otherEntityArr.push(entity)
                }
            });


            let blocks = JSON.parse(data).blocks
            let tempTxt = ''
            blocks.map((i, index) => {
                if (index > 0) {
                    tempTxt = tempTxt + '' + (i.text)
                }
            })


            if (embedArr.length > 0) {
                setVideoArr(embedArr)
            }
            const blocksFromHTML = convertFromRaw(finalData);

            setEditorState(EditorState.createWithContent(blocksFromHTML));
            // }
            // else {
            //     const blocksFromHTML = convertFromHTML(data);
            //     const state = ContentState.createFromBlockArray(
            //         blocksFromHTML.contentBlocks,
            //         blocksFromHTML.entityMap,
            //     )
            //     setEditorState(EditorState.createWithContent(state));
            // }
            // }
        } else {
            // const blocksFromHTML = convertFromHTML(data);
            // const state = ContentState.createFromBlockArray(
            //     blocksFromHTML.contentBlocks,
            //     blocksFromHTML.entityMap,
            // )
            // console.log(">>>>>>>>>>>>>>>>>>>   <<<<<<<<<<<<<<<< 2nd time ", state);
            // setEditorState(EditorState.createWithContent(state));


            const blocksFromHtml = htmlToDraft(data);
            const { contentBlocks, entityMap } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            setEditorState(EditorState.createWithContent(contentState));
        }
    }, []);
    useEffect(() => {
        let isBlock = isJsonString(data)
        if (isBlock) {
            const messageValue = convertToRaw(editorState.getCurrentContent());


            let blocks = messageValue.blocks
            let tempTxt = ''
            let tempTxtAll = ''
            blocks.map((i, index) => {
                if (index > 0) {
                    tempTxt = tempTxt + '' + (i.text).trim()
                }
                tempTxtAll = tempTxtAll + '' + (i.text).trim()
            })
            if (!editable) {
                if ((blocks && (blocks.length > 5)) || (tempTxtAll.length > 200) || (blocks.length > 0 && tempTxt.includes("\n\n"))) {
                    setShowMore(true)
                }
            }

            if (videoArr.length != 0) {
                if (tempTxt == '') {

                    let contentState = editorState.getCurrentContent();
                    const block = contentState.getBlockMap().first();
                    const next = contentState.getBlockAfter(block.getKey());
                    // const nextNext = contentState.getBlockAfter(next.getKey());

                    if (block && next) {
                        const removeSelection = new SelectionState({
                            anchorKey: block.getKey(),
                            anchorOffset: block.getText().length,
                            focusKey: next.getKey(),
                            focusOffset: 0
                        });

                        let newContentState = Modifier.removeRange(
                            contentState,
                            removeSelection,
                            "forward"
                        );

                        let newEditorState = EditorState.push(
                            editorState,
                            newContentState,
                            "remove-range"
                        );
                        setEditorState(newEditorState);
                    }
                }
            }
        }
    }, [editorState])

    useEffect(() => {
        setSubjectLine(allData.subject)
    }, [allData.subject])

    function isJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    const HANDLE_REGEX = /\@[\w]+/g;
    const styles = {
        editor: { border: "1px solid #ddd", cursor: "text", fontSize: 16, minHeight: "40px", padding: 10, background: "#000" },
        handle: { color: "rgba(98, 177, 254, 1.0)", direction: "ltr", unicodeBidi: "bidi-override" },
    };

    function findWithRegex(regex, contentBlock, callback) {
        const text = contentBlock.getText();
        let matchArr, start;
        while ((matchArr = regex.exec(text)) !== null) {
            start = matchArr.index;
            callback(start, start + matchArr[0].length);
        }
    }

    const youtube_parser = (url) => {
        var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        var match = url.match(regExp);
        return (match && match[7].length == 11) ? match[7] : false;
    }

    const vimeo_parser = (url) => {
        let regEx = /(https?:\/\/)?(www\.)?(player\.)?vimeo\.com\/?(showcase\/)*([0-9))([a-z]*\/)*([0-9]{6,11})[?]?.*/;
        let match = url.match(regEx);
        if (match && match.length == 7) {
            let videoId = match[6];
            return videoId
        }
    }

    const renderVideos = () => {
        if (videoArr.length > 0) {
            return videoArr.map(i => {
                return (
                    <div onClick={() => setCurrentVideo(i.src)} className="modal-thumb" data-toggle="modal" data-target="#staticBackdrop">
                        <div className="modal-thumb-play">
                            <span><i className="fas fa-play"></i></span>
                        </div>
                        <img width='100%' src={i.thumbnail} />
                    </div>
                )
            })
        }
        else {
            return null
        }
    }

    const getBase64 = async (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };


    // const addCls = () => {
    //     $('.rdw-editor-toolbar').addClass('toogle-toolbar');
    // }

    // const removeCls = () => {
    //     $('div').removeClass('toogle-toolbar');
    // }

    function uploadImageCallBack(file) {
        return new Promise((resolve, reject) => {
            getBase64(file)
                .then((data) => {
                    resolve({ data: { link: data } });
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    const handlePastedFiles = async (files) => {
        getBase64(files[0])
            .then((data) => {
                setEditorState(insertImage(data));
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const insertImage = (url) => {
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity("IMAGE", "IMMUTABLE", { src: url });
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });
        return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, " ");
    };

    const feedStateChange = (e) => {
        let blocks = e.blocks
        let tempTxt = ''
        blocks.map(i => {
            tempTxt = tempTxt + '' + (i.text).trim()
        })
        let entitymap = e.entityMap
        let embedArr = []
        Object.values(entitymap).forEach(entity => {
            if (entity.type === 'EMBEDDED_LINK') {
                embedArr.push(entity.data.src);
            }
        });

        if ((tempTxt != '') || (embedArr.length != 0) || (attachments?.length != 0)) {
            setSendActive(true)
        }
        else {
            setSendActive(false)
        }
    }

    const handleSendClick = () => {
        if (subjectLine == '' || subjectLine == undefined) {
            setInputError('Please add subject')
            return
        }

        const forFormik = draftToHtml(
            convertToRaw(editorState.getCurrentContent())
        );
        const messageValue = forFormik
        sendClick(messageValue, allData.id, attachments, sizes, subjectLine)
        setAttachments([])
        setSizes([])
        setFiles([]);
        setShowFilePond(false);
        setInputError('')
        // setIsImportant(false)
    }

    const handleDragEnter = event => {
        setShowFilePond(true);
    };

    const handleFiles = (event) => {
        const ufiles = event.target.files;
        if (ufiles.length > 0) {
            for (var i = 0; i < ufiles.length; i++) {
                if (ufiles[i]) {
                    if (filePondRef.current) {
                        filePondRef.current
                            .addFile(ufiles[i])
                            .then((file) => {})
                            .catch((error) => console.log(error));
                    }
                }
            }
        }
    };

    const attachmentsArrUpdate = (file) => {
        let tempAttach = attachments
        for (var i = 0; i < tempAttach.length; i++) {
            if (tempAttach[i].includes(file.split('.')[0])) {
                tempAttach.splice(i, 1);
                break;
            }
        }
        if (tempAttach.length < 1) {
            setTimeout(() => {
                setShowFilePond(false)
                // setShowCurser(!showcursure)
            }, 500);
        }
    }

    const handleUploadedFiles = (response) => {
        let newObj = {}
        let blocks = isJsonString(response)
        if (blocks) {
            newObj = JSON.parse(response)
            setAttachments((attachments) => [...attachments, newObj.fullDocFilePath]);
            setSizes((pre) => [...pre, newObj.size]);
        }
    };

    const handleAttachment = () => {
        btnAttachment.current.click();
    };

    const expand = () => {
        if (showMore) {
            setShowMore(false);
            setShowLess(true)
        }
    }

    const updateSubjectLine = (e) => {
        setSubjectLine((e.target.value).trimLeft())
        setInputError('')
    }

    const imgAndText = (val) => {
        const forFormik = draftToHtml(convertToRaw(val.getCurrentContent()));
        return forFormik
    }

    return (
        <>
            <div className="row_icons_fas ls_box edited-icons">
                {isMention ? <div className=" post-reaction-icon " title="Tag"><i className="fal fa-at"></i></div> : null}
                {isImportant ? <i className="fal fa-exclamation important-icon" title="Important"></i> : null}
                {isAttachment ? <i className="fal fa-paperclip attachment-icon" title="Attachment"></i> : null}
            </div>
            <div className={`title-toolbar new-discussion-box ${!isInvalid ? "post-input-content " : "file-req is-invalid mb-4"} ${showMore ? "show-more-title-div" : ''} ${editable ? '' : ' readonly'}`} onDragEnter={editable ? handleDragEnter : null} onClick={expand}>

                {(allData.subject || editable) ?
                    <div className="post-static-input post-edit-input">
                        <input
                            type="text"
                            className={`form-control  ${editable ? ' edit_main_thread ' : 'post-edit-input'}`}
                            placeholder="Add a Subject"
                            ref={editorRefInput}
                            onChange={updateSubjectLine}
                            value={subjectLine}
                            disabled={!editable}
                            multiple={true}
                            maxLength={180}
                        // onFocus={() => { setInputFocus(true) }}
                        // onBlur={() => {
                        //     setTimeout(() => {
                        //         setInputFocus(false)
                        //     }, 200);
                        // }}
                        />
                    </div>
                    : null}

                <div className={!editable ? "d-none" : " edit_main_thread "}><Editor
                    readOnly={editable ? false : true}
                    editorState={editorState}
                    editorClassName="editorClassName"
                    onEditorStateChange={onEditorStateChange}
                    handlePastedFiles={handlePastedFiles}
                    onChange={e => feedStateChange(e)}
                    ref={editorReference}
                    // onFocus={() => {
                    //     addCls()
                    // }}
                    // onBlur={() => {
                    //     removeCls()
                    // }}
                    mention={{
                        separator: ' ',
                        trigger: '@',
                        suggestions: userData,
                    }}
                    toolbar={{
                        options: ['inline', 'textAlign', 'list', 'embedded'
                            // 'blockType','fontSize', 'fontFamily',   'colorPicker',  'image', 'link', 'emoji',  'remove', 'history'
                        ],
                        inline: {
                            options: ['bold', 'italic', 'underline'],
                            italic: { className: 'i-italic-icon' },
                            bold: { className: 'i-bold-icon' },
                            underline: { className: 'i-underline-icon' },
                        },
                        list: {
                            options: ['unordered', 'ordered'],
                            unordered: { className: 'i-unordered-icon' },
                            ordered: { className: 'i-ordered-icon' },
                            // indent: { className: 'i-indent-icon' },
                            // outdent: { className: 'i-outdent-icon' },
                        },
                        textAlign: {
                            options: ['left', 'center', 'right', 'justify'],
                            left: { className: 'i-left-icon' },
                            center: { className: 'i-center-icon' },
                            right: { className: 'i-right-icon' },
                            justify: { className: 'i-justify-icon' },
                        },
                        blockType: {
                            inDropdown: false,
                            options: ['H1', 'Blockquote'],
                            className: 'i-bloctype-icon',
                            // H1: {className: 'i-h1-icon' },
                            // Blockquote: {className: 'i-blockquote-icon' },
                        },
                        embedded: {
                            className: 'i-embedded-icon',
                            embedCallback: link => {
                                const detectedSrc = /<iframe.*? src="(.*?)"/.exec(embed(link));
                                return (detectedSrc && detectedSrc[1]) || link;
                            }
                        }
                    }}
                /></div>
                {!editable && <ShowMoreHide show={allData.message ? allData.message : ""} />}
                {/* {!editable && <ShowMoreHide showMore={showMore} setShowMore={setShowMore} show={imgAndText(editorState)} editable={editable} setIsImportant={setIsImportant} allData={allData} isImportant={isImportant} handleAttachment={handleAttachment} sendActive={sendActive} handleSendClick={handleSendClick} />} */}
                {editable ? <>
                    <div className="action-input-content edit-buttons-new">
                        <button onClick={() => { setIsImportant(allData, !isImportant) }} className={`btn btn-input-action hold-show ${isImportant ? 'active' : ''}`} title="Important"><i className="fal fa-exclamation hold-show"></i></button>
                        <button onClick={handleAttachment} className="btn btn-input-action hold-show" title="Add Attachment"><i className="fal fa-paperclip hold-show"></i></button>
                        {sendActive ?
                            <button onClick={handleSendClick} className="btn btn-input-action hold-show" title="Send"><i className="fal fa-paper-plane hold-show"></i></button>
                            :
                            <button onClick={() => { }} className="btn btn-input-action hold-show" title="Send" disabled><i className="fal fa-paper-plane hold-show"></i></button>
                        }
                    </div>
                </>
                    : null
                }
                {/* </div> */}
            </div>
            {inputError != '' ? <div className="invalid-feedback d-block">{inputError}</div> : null}
            {showMore ? <div onClick={() => { setShowMore(false); setShowLess(true) }} className="show-more-txt">See more</div> : null}
            {showLess ? <div onClick={() => { setShowLess(false); setShowMore(true) }} className="show-more-txt">See less</div> : null}
            {renderVideos()}
            {editable ? <>
                <input
                    type="file"
                    ref={btnAttachment}
                    className={'hold-show'}
                    style={{ display: "none" }}
                    onChange={(e) => handleFiles(e)}
                />

                <div
                    className={
                        !showFilePond ? "hideFilePond" : "showFilePond"
                    }
                >
                    <FilePond
                        name="post"
                        allowMultiple={true}
                        maxFiles={3}
                        ref={filePondRef}
                        files={files}
                        onupdatefiles={setFiles}
                        beforeRemoveFile={(f) => { setRemoveFile(f.file.name); attachmentsArrUpdate(f.file.name) }}
                        // onremovefile={(err, file)=>{console.log('the removed file is ', file)}}
                        onaddfile={(err, fileItem) => {
                            setShowFilePond(true);
                        }}
                        server={{
                            url: API_BASE,
                            process: {
                                url: "/addFeedAttachments",
                                headers: {
                                    Authorization: `Bearer ${getToken()}`,
                                },
                                onload: (response) => {
                                    handleUploadedFiles(response);
                                },
                                ondata: (formData) => {
                                    formData.append('datasize', true);
                                    return formData;
                                },
                            },
                            revert: {
                                url: `/removeFeedAttachments/${removeFile}`,
                                headers: {
                                    Authorization: `Bearer ${getToken()}`,
                                },
                                onload: (response) => {
                                    setRemoveFile(null);
                                },
                            },
                        }}
                    />
                </div>
            </>
                :
                null
            }

            

        </>
    );
}

export default HtmlInputEditorShow;
