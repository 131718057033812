import React, { useState, useEffect } from "react";
import ReRegistrationList from "./ReRegistrationList";
import { GetReRegistrationList } from "../../services/ReRegistrationService";
import SkeletonReRegistrationList from "../../loaders/SkeletonReRegistrationList";
import { useDispatch, useSelector } from "react-redux";
import { GetMyUnreadNotificationCount, GetUpdateNotificationReregRead } from "../../services/NotificationService";
import { setReRegistraionCount, setUnreadNotificationCount } from "../../store/actions";
function ReRegistration(props) {
  const [reRegistrationList, SetReRegistrationList] = useState([]);
  const [loading, setloading] = useState(false);
  // const user = useSelector(state=>state.user);
  // const dispatch = useDispatch();
  useEffect(() => {
    let mounted = true
    GetReRegistrationList().then((res) => {
      if (mounted) {
        setloading(true);
        SetReRegistrationList(res.data);
      }
    });
    // if(props.id === undefined || props.id === ""){
    //   GetUpdateNotificationReregRead({uId:user.id})
    //   .then(res => {
    //     console.log("resssssss",res);
    //     GetMyUnreadNotificationCount({is_reg_registration:1})
    //       .then(res=>{
    //         // console.log("count res-------------------------",res.data);
    //         dispatch(setReRegistraionCount(res.data.notificationsCount.unreadNotificationCount ? res.data.notificationsCount.unreadNotificationCount: 0));
    //         // dispatch(setUnreadNotificationCount(res.data.notificationsCount.unreadNotificationCount));
    //       })
    //       .catch(error=>{
    //         console.error(error);
    //       })
    //   })
    //   .catch(error=>{
    //     console.error(error);
    //   });
    // }
    return function cleanup() {
      mounted = false
    }

    
  }, [props.id]);

  return (
    <>
      {!loading && <div className="re-registration-list-wrap">
        <div className="my-tickets-heading-blk flex-direction mb-4">
          <h4 className="text-left-align landing-heading heading_color_dynamic">My <span>Re-Registrations</span></h4>
          <div className="text-md-right"><p className=""></p></div>
        </div>

        {/* <h4 className="my-md-2 my-0 landing-heading">
          My <span>Re-Registrations</span>
        </h4> */}
        <ul className="re-registration-ul-list list-unstyled">
          <SkeletonReRegistrationList />
        </ul>
      </div>}
      {loading && <div className="re-registration-list-wrap">
        <h4 className="my-md-2 my-0 landing-heading">
          My <span>Re-Registrations</span>
        </h4>
        <ul className="re-registration-ul-list list-unstyled">
          {reRegistrationList && reRegistrationList.open ? (
            props.id !== undefined 
            ? reRegistrationList.open.filter(item=>item.id === Number(props.id)).map((registration, index) => {
              return <ReRegistrationList registration={registration} key={index} />;
            })
            : reRegistrationList.open.map((registration, index) => {
              // if(registration.reRegistrationStatus !== "Declined"){
                return <ReRegistrationList registration={registration} key={index} />;
              // }
            })
          ) : (
            <SkeletonReRegistrationList />
          )}
        </ul>

        {/* Show Completed */}
        {reRegistrationList && reRegistrationList.close ? (
          <React.Fragment>
            <button
              data-toggle="collapse"
              data-target="#completed-exam"
              aria-expanded="false"
              className="hide collapsed hide-completed"
            ></button>

            <div
              className="re-registration-completed-list collapse"
              id="completed-exam"
            >
              <ul className="re-registration-ul-list list-unstyled">
                {reRegistrationList && reRegistrationList.close ? (
                  reRegistrationList.close.map((registration, index) => {
                    return <ReRegistrationList registration={registration} key={index} />;
                  })
                ) : (
                  <SkeletonReRegistrationList />
                )}
              </ul>
            </div>
          </React.Fragment>
        ) : null}
      </div>}
    </>
  );
}

export default ReRegistration;
